
<div class="generalLoader" *ngIf="loadingFile">
    <div class="icon">
        <img src="assets/img/preload.gif" alt="">
    </div>
</div>

<div class="topttls">
    <app-tittle [label]="'Carga de archivos'" [bottomTxt]="bttmTxt"></app-tittle>

    <div class="btns">
        &nbsp;
    </div>
</div>
<!-- <div class="topSec">
    <div class="docExample">
        <p>Descarga aquí el archivo de ejemplo <a href="javascript:;"> <span>Archivo de ejemplo.csv</span> <i class="fi fi-file-1"></i> </a></p>
    </div>
</div> -->

<div class="fileUp_Wrapper">

    <div class="card card_2">
        <div class="cardBox">
            <div class="icon">
                <img class="hoja_1" src="assets/img/svg/hoja_1.svg" alt="">
                <img class="hoja_2" src="assets/img/svg/hoja_2.svg" alt="">
                <img class="folder" src="assets/img/svg/folder.svg" alt="">
            </div>
            <div class="cardBox_inner">
                <div class="ttl">Subida</div>
                <div class="content">
        
                    <form novalidate [formGroup]="fileUp_inv">
                        <div class="body">Selecciona el tipo de archivo a subir</div>
                        <div class="form_field form_field_var1">
                            <div class="select_box">
                                <select name="fileType" formControlName="fileType" id="">
                                    <option value="null" selected disabled>Seleccionar tipo de archivo</option>
                                    <option *ngFor="let opt of fileTypes" value="{{opt.file_type_id}}">{{opt.name}}</option>
                                </select>
                                <i [class]="'48px_down-arrow' | icons"></i>
                            </div>
                        </div>
                        <div class="form_field" *ngIf="fileUp_inv.controls.fileType.valid">
                            <div class="file_input">
                                <input type="file" formControlName="file" name="fileInp_inv" id="fileInp_inv" (change)="onFileSelect($event)"/>
                                <label for="fileInp_inv">Buscar archivo</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>
