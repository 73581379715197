import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Clipboard } from '@angular/cdk/clipboard';
import { CatalogService } from "src/app/services/catalog.service";
import { GeneralService } from "src/app/services/general.service";
import { InventoryService } from "src/app/services/inventory.service";
import { OrdersService } from "src/app/services/orders.service";
import { PedidosService } from "src/app/services/pedidos.service";
import { TableService } from "src/app/services/table.service";
import { Ng2IzitoastService } from "ng2-izitoast";

import Swal from "sweetalert2";
import { ProvidersService } from "src/app/services/providers.service";
import { ElectronicInvoicesService } from "src/app/services/electronic-invoices.service";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styles: [],
})
export class TableComponent implements OnInit {
  @Input() cols: any[] = [];
  @Input() data: any[] = [];
  @Input() type: string = "";
  @Input() uniqueID: string = "";
  @Output() reloadPage = new EventEmitter();

  selectedLine: any[] = [];
  uniqueIDVar: string = "";

  providerSel: boolean = false;
  provideritem: number = 0;

  currCommentItem: any = null;

  skuFullToAssignProvider: string = "";
  providersBySkuFull: any = [];

  tableCreateCountId: any = null;
  tableCountData: any = null;

  loading = false;

  itemToProcessElectronicInvoices: any;

  // dd_brands:any[] = [];

  constructor(
    public _inv: InventoryService,
    public _gen: GeneralService,
    public _cat: CatalogService,
    public _ped: PedidosService,
    public _ord: OrdersService,
    public route: Router,
    public _table: TableService,
    public iziToast: Ng2IzitoastService,
    private clipboard: Clipboard,
    public _provs: ProvidersService,
    public _elecInv: ElectronicInvoicesService
  ) {}

  ngOnInit(): void {
    if (this.type == "inputs") {
      this.uniqueIDVar = "";
    } else if (this.type == "outputs") {
      this.uniqueIDVar = "";
    } else if (this.type == "configs") {
      this.uniqueIDVar = "";
    } else if (this.type == "catalog") {
      this.uniqueIDVar = "sku_full";
    } else if (this.type == "catalogStock") {
      this.uniqueIDVar = "sku_full";
    } else if (this.type == "pedidos") {
      this.uniqueIDVar = "order_number";
    } else if (this.type == "orders") {
      this.uniqueIDVar = "order_id";
    } else if (this.type == "providers") {
      this.uniqueIDVar = "id_number";
    }
  }

  itmChecked(evt: any, itm: any, type: string) {
    this.resetSelectedItems();

    if (evt.target.checked) {
      this._table.selectedLine.push(itm);
    } else {
      const index = this._table.selectedLine.findIndex(
        (obj) => obj.sale_id === itm.sale_id
      );
      if (index > -1) {
        this._table.selectedLine.splice(index, 1);
      }
    }

    switch (type) {
      case "inputs":
        this._inv.sel_invInputs = this._table.selectedLine;
        break;
      case "outputs":
        this._inv.sel_invOutputs = this._table.selectedLine;
        break;
      case "configuration":
        this._inv.sel_invConfigs = this._table.selectedLine;
        break;
      case "catalog":
        this._cat.sel_catalog = this._table.selectedLine;
        break;
      case "catalogStock":
        this._cat.sel_catalogStock = this._table.selectedLine;
        break;
      case "pedidos":
        this._ped.sel_pedidos = this._table.selectedLine;
        break;
      case "orders":
        this._ord.sel_orders = this._table.selectedLine;
        break;
      case "pending":
        this._ord.sel_pending = this._table.selectedLine;
        break;
      case "providers":
        this._provs.sel_provider = this._table.selectedLine;
        break;

      default:
        this.resetSelectedItems();
        break;
    }

    // console.log(this._inv.sel_invInputs);
    // console.log(this._inv.sel_invOutputs);
    // console.log(this._cat.sel_catalog);
    // console.log(this._cat.sel_catalogStock);
    // console.log(this._ped.sel_pedidos);
    // console.log(this._ord.sel_orders);
    // console.log(this._ord.sel_pending);
  }

  resetAllCheckboxes() {
    let checkboxes: any = [];

    switch (this.type) {
      case "inputs":
        checkboxes = document.getElementsByName("check_inputs");
        break;
      case "outputs":
        checkboxes = document.getElementsByName("check_outputs");
        break;
      case "configuration":
        checkboxes = document.getElementsByName("check_configs");
        break;
      case "catalog":
        checkboxes = document.getElementsByName("check_catalog");
        break;
      case "pedidos":
        checkboxes = document.getElementsByName("check_pedidos");
        break;
      case "orders":
        checkboxes = document.getElementsByName("check_orders");
        break;
      case "pending":
        checkboxes = document.getElementsByName("check_pending");
        break;
      case "providers":
        checkboxes = document.getElementsByName("check_providers");
        break;
      default:
        checkboxes = [];
        break;
    }

    for (let j = 0; j < checkboxes.length; j++) {
      const element: any = checkboxes[j];
      element.checked = false;
    }
    this._table.selectedLine = [];
    this.resetSelectedItems();
  }

  itmCheckedAll(evt: any) {
    let checkboxes: any = [];

    switch (this.type) {
      case "inputs":
        checkboxes = document.getElementsByName("check_inputs");
        break;
      case "outputs":
        checkboxes = document.getElementsByName("check_outputs");
        break;
      case "configuration":
        checkboxes = document.getElementsByName("check_configs");
        break;
      case "catalog":
        checkboxes = document.getElementsByName("check_catalog");
        break;
      case "catalogStock":
        checkboxes = document.getElementsByName("check_catalogStock");
        break;
      case "pedidos":
        checkboxes = document.getElementsByName("check_pedidos");
        break;
      case "orders":
        checkboxes = document.getElementsByName("check_orders");
        break;
      case "pending":
        checkboxes = document.getElementsByName("check_pending");
        break;

      default:
        checkboxes = [];
        break;
    }

    for (let j = 0; j < checkboxes.length; j++) {
      const element: any = checkboxes[j];

      if (evt.target.checked) {
        element.checked = true;
        this._table.selectedLine = JSON.parse(JSON.stringify(this.data));
      } else {
        element.checked = false;
        this._table.selectedLine = [];

        this.resetSelectedItems();
      }
    }

    switch (this.type) {
      case "inputs":
        this._inv.sel_invInputs = this._table.selectedLine;
        break;
      case "outputs":
        this._inv.sel_invOutputs = this._table.selectedLine;
        break;
      case "configuration":
        this._inv.sel_invConfigs = this._table.selectedLine;
        break;
      case "catalog":
        this._cat.sel_catalog = this._table.selectedLine;
        break;
      case "catalogStock":
        this._cat.sel_catalogStock = this._table.selectedLine;
        break;
      case "pedidos":
        this._ped.sel_pedidos = this._table.selectedLine;
        break;
      case "orders":
        this._ord.sel_orders = this._table.selectedLine;
        break;
      case "pending":
        this._ord.sel_pending = this._table.selectedLine;
        break;

      default:
        this._inv.sel_invInputs = [];
        this._inv.sel_invOutputs = [];
        this._inv.sel_invConfigs = [];
        this._cat.sel_catalog = [];
        this._ped.sel_pedidos = [];
        this._ord.sel_orders = [];
        this._ord.sel_pending = [];
        break;
    }

    // console.log(this._inv.sel_invInputs);
    // console.log(this._inv.sel_invOutputs);
    // console.log(this._cat.sel_catalog);
    // console.log(this._cat.sel_catalogStock);
    // console.log(this._ped.sel_pedidos);
    // console.log(this._ord.sel_orders);
    // console.log(this._ord.sel_pending);
  }

  resetSelectedItems() {
    this._inv.sel_invInputs = [];
    this._inv.sel_invOutputs = [];
    this._inv.sel_invConfigs = [];
    this._cat.sel_catalog = [];
    this._ped.sel_pedidos = [];
    this._ord.sel_orders = [];
    this._ord.sel_pending = [];
    this._provs.sel_provider = [];
  }

  editRowSend(form: NgForm, type: string) {
    console.log(form, type);
  }

  saveBtnFx(item: any) {
    if (this.type == "inputs") {
      console.log("inputs");
    } else if (this.type == "outputs") {
      console.log("outputs");
    } else if (this.type == "catalog") {
      // Get Product ID
      let uniqueId = item.sku_full;
      let productId = "";
      this._cat
        .getAllProdsFilterBySKU(uniqueId)
        .then((data: any) => {
          productId = data.data.product.product_id;
        })
        .finally(() => {
          let all = {
            uniqueId: uniqueId,
            productId: productId,
            country: this._cat.dd_model_country,
            manufac: this._cat.dd_model_manufac,
            brand: this._cat.dd_model_brand,
            item: item,
          };

          let item_edit_product = {
            product_id: productId,
            name: item.newName,
          };

          let item_edit_productSku = {
            sku_full: uniqueId,
            ean: item.newEan,
          };

          // Edit name
          let mssg: string = "";
          let mssgTypw: string = "";

          if (item.name != item.newName || item.ean != item.newEan) {
            // console.log(' something change ');

            if (item.name != item.newName && item.ean == item.newEan) {
              this._cat
                .editProdName(item_edit_product)
                .then(({ data }) => {
                  this.swalEditMssg(
                    "Nombre de producto actualizado exitosamente",
                    "success"
                  );
                })
                .catch((error: any) => {
                  this.swalEditMssg("Error al actualizar", "error");
                });
            }
            if (item.ean != item.newEan && item.name == item.newName) {
              this._cat
                .editProdEan(item_edit_productSku)
                .then(({ data }) => {
                  this.swalEditMssg(
                    "Ean de producto actualizado exitosamente",
                    "success"
                  );
                })
                .catch((error: any) => {
                  this.swalEditMssg("Error al actualizar", "error");
                });
            }
            if (item.ean != item.newEan && item.name != item.newName) {
              this._cat
                .editProdEan(item_edit_productSku)
                .then(({ data }) => {
                  this._cat
                    .editProdName(item_edit_product)
                    .then(({ data }) => {
                      this.swalEditMssg(
                        "Nombre y Ean de producto actualizado exitosamente",
                        "success"
                      );
                    })
                    .catch((error: any) => {
                      this.swalEditMssg("Error al actualizar", "error");
                    });
                })
                .catch((error: any) => {
                  this.swalEditMssg("Error al actualizar", "error");
                });
            }
          } else {
            this._cat.editId = "";
            this._cat.editView = !this._cat.editView;
            this.resetAllCheckboxes();
          }
        });
    } else if (this.type == "catalogStock") {
      this._cat.editStockId = "";
      this._cat.editStockView = !this._cat.editStockView;
      console.log("pedidos");
    } else if (this.type == "pedidos") {
      this._ped.editId = "";
      this._ped.editView = !this._ped.editView;
      console.log("pedidos");
    } else if (this.type == "orders") {
      let updateData = [
        {
          order_id: item.order_id,
          invoice: item.newInvoice,
          status_id: item.status,
        },
      ];

      this._ord.updateOrder(updateData).then(() => {
        // console.log( item );
        this.swalEditMssg("Factura actualizada!", "success").then(() => {
          window.location.reload();
        });
      });
    } else if (this.type == "providers") {
      this._provs.editId = "";
      this._provs.editView = !this._provs.editView;
    }
  }
  cancelBtnFx() {
    if (this.type == "inputs") {
      console.log("inputs");
    } else if (this.type == "outputs") {
      console.log("outputs");
    } else if (this.type == "catalog") {
      this._cat.editId = "";
      this._cat.editView = !this._cat.editView;
      // console.log( "catalog" );
    } else if (this.type == "catalogStock") {
      this._cat.editStockId = "";
      this._cat.editStockView = !this._cat.editStockView;
      // console.log( "catalog" );
    } else if (this.type == "pedidos") {
      this._ped.editId = "";
      this._ped.editView = !this._ped.editView;
      console.log("pedidos");
    } else if (this.type == "orders") {
      this._ord.editId = "";
      this._ord.editView = !this._ord.editView;
      console.log("orders");
    } else if (this.type == "providers") {
      
      const itmSelected = this.data.findIndex((itm:any) => itm.id_number === this._provs.sel_provider[0].id_number )
      this.data[itmSelected] = this._provs.originalSelectedItem;
      this._provs.editId = "";
      this._provs.editView = !this._provs.editView;
    }

    this.resetAllCheckboxes();
  }
  swalEditMssg(ttl: string, kind: any = "success") {
    return Swal.fire({
      title: ttl,
      icon: kind,
    }).then(() => {
      this._cat.editId = "";
      this._cat.editView = !this._cat.editView;
      this.resetAllCheckboxes();
    });
  }

  /* * Edit selects * */
  manufact_chng(val: any) {
    this._cat.getBrands(this._cat.dd_model_manufac).then((data: any) => {
      this._cat.dd_edit_brand = data.data;
    });
  }

  gotoPed(id: any) {
    // this.route.navigate([`home/pedidos/${id}`])
    // var url = `/#/home/pedidos/${id}`;
    // window.open(url, '_blank');

    if (this.type == "pedidos") {
      this.route.navigate([`home/pedidos/${id}`]);
    } else {
      var url = `/#/home/pedidos/${id}`;
      window.open(url, "_blank");
    }
  }
  gotoOrd(id: any) {
    // this.route.navigate([`home/ordenes/${id}`])

    var url = `/#/home/ordenes/${id}`;
    window.open(url, "_blank");
  }

  goToInvoice(itm: any) {
    const { invoice_number, order } = itm;

    let paramasUrl;
    if (!order) {
      paramasUrl = `home/ordenes/inventory_entry/${invoice_number}`;
    } else {
      const { order_id } = order;
      paramasUrl = `home/ordenes/inventory_entry/${invoice_number}/${order_id}`;
    }
    return this.route.navigate([`${paramasUrl}`]);
  }

  changeProdCommentFx(item: any) {
    this.currCommentItem = null;

    let prodData = {
      item_id: item.item_id,
      comment: item.comment,
    };

    // console.log( prodData );

    this._ord
      .changeOrderProdComment(prodData)
      .then(({ data }) => {
        Swal.fire("Cambio de comentario exitoso", ``, "success").then(() => {
          // this.loaderView = false;
        });
      })
      .catch((error: any) => {
        let data = error.response.data;

        Swal.fire(
          "Error al crear comentario en item",
          `${data.error}`,
          "error"
        ).then(() => {
          // this.loaderView = false;
        });
      });
  }

  onChangeFileInput(event: Event, reception: any) {
    const element = event.target as HTMLInputElement;
    if (element.files) {
      this._gen.tableLoader = true;
      const formData = new FormData();
      const files = element.files;
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      formData.append("reception", reception);
      this._inv
        .uploadFiles(formData)
        .then(() => {
          this._gen.tableLoader = false;
          Swal.fire({
            title: `${
              files.length === 1
                ? "Archivo cargado exitosamente"
                : `Archivos cargados exitosamente`
            }`,
            html: `${
              files.length === 1
                ? "El archivo fue cargado con exito"
                : `Los ${files.length} archivos fueron cargados con exito`
            }`,
            icon: "success",

            willClose: () => {
              this.reloadPage.emit();
            },
          });
        })
        .catch(() => {
          this._gen.tableLoader = false;
          Swal.fire({
            title: `${
              files.length === 1
                ? "Error al cargar archivo"
                : `Error al cargar archivos`
            }`,
            html: `${
              files.length === 1
                ? "El archivo que intentas subir presenta errores o no es compatible"
                : `Los archivos que intentas subir presentam errores o no son compatibles`
            }`,
            icon: "error",
          });
        });
    }
  }

  getLabelFiles(reception_files: any) {
    if (reception_files.length === 1) return "Descargar 1 archivo";
    return `Descargar ${reception_files.length} archivos`;
  }

  getNameFile(file: any) {
    const fileName = file.split(this._ord.urlBaseFiles);
    if (fileName.length > 1) {
      const nameAndType = decodeURIComponent(fileName[1]);
      const partialName = nameAndType.split(".");
      const finalName = partialName.join(".");
      return finalName;
    }
    return "file";
  }

  getFile(file: any) {
    const fileName = file.split(this._ord.urlBaseFiles);
    if (fileName.length > 1) {
      const nameAndType = decodeURIComponent(fileName[1]);
      const type = nameAndType.split(".").slice(-1);
      switch (type[0]) {
        case "pdf":
          return "application/pdf";
        case "png":
          return "image/png";
        case "jpeg":
        case "jpg":
          return "image/jpeg";
      }
    }
    return "";
  }

  download(itm: any) {
    const files = itm.reception_files;
    files.forEach((element: any, index: number) => {
      const name = this.getNameFile(element.file);
      const type = this.getFile(element.file);
      this._ord.downloadFiles(name, element.file, type).subscribe(
        () => {
          return this.iziToast.show({
            title: "Archivo descargado",
            position: "topRight",
            color: "green",
            transitionIn: "fadeInLeft",
            transitionOut: "fadeOutRight",
            toastOnce: true,
          });
        },
        (err) => {
          console.error(err);
          return this.iziToast.show({
            title: "Error al descargar archivo",
            position: "topRight",
            color: "red",
            transitionIn: "fadeInLeft",
            transitionOut: "fadeOutRight",
            toastOnce: true,
          });
        }
      );
    });
  }

  showAssignProvider(itm: any) {
    this.skuFullToAssignProvider = itm.sku_full;
    this.providersBySkuFull = itm.providers;
    this._gen.assignProviderView = true;
  }

  onReloadTable() {
    this.reloadPage.emit();
  }

  scanProducts(item: any) {
    this.tableCreateCountId = item.cycle_count_id;

    this.tableCountData = {
      nameField: item.name,
      typeField: item.type_count,
      bodegaField: item.bodega.provider_id
    };

    this._gen.barCodeCountView = true;
  }

  goToScannedProducts(item: any) {
    
    const navigationExtras = {
      state: {
        bodega: item.bodega.name,
        name: item.name,
      },
    };

    this.route.navigate([`home/inventory/counts/${item.cycle_count_id}`], navigationExtras);
  }

  closeCounting(itm:any) {

    Swal.fire({
      title: `¿Estás seguro de cerrar el conteo?`,
      text: `Una vez cerrado ya no se podrá escanear ningún producto`,
      icon: 'warning',
      showCancelButton: true,
      showDenyButton: false,
      showConfirmButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Cerrar Conteo',
      denyButtonText: 'Cancelar',
    }).then( (result:any) => {

      if (result.isConfirmed) {
        
        const data = {
          cycle_count_id: itm.cycle_count_id,
          enabled: "False"
        }
        this._inv.closeCounting(data)
        .then(({data}) => {
    
          if(data.msg === "ok") {
            Swal.fire(
              'Conteo Cerrado',
              `Se ha cerrado el conteo ${ itm.name } de forma exitosa`,
              'success'
            ).then( () => {
              this.onReloadTable();
            })
          } else {
            this.showErrorCloseCountingMessage(itm.name)
          }
        })
        .catch(error => {
          console.error({error})
          this.showErrorCloseCountingMessage(itm.name)
        })
        
      }
      if (result.isDismissed) {
        console.log('NO cancel', result);
      }

    })
  }

  subtractCountAmount(item: any) {
    if (item.scanned_products === 0) return;

    const data = {
      product_count_id: item.product_count_id,
    };

    this.loading = true;
    this._inv
      .removeQuantityProductFromCount(data)
      .then(({ data }) => {
        this.loading = false;
        if (data.msg === "ok") {
          this.onReloadTable();
        }
      })
      .catch((error: any) => {
        this.loading = false;
        Swal.fire(
          'Error',
          `${ error?.response?.data?.error }`,
          'error'
        )
      });
  }

  showErrorCloseCountingMessage(name:any) {
    Swal.fire(
      'Error al Cerrar Conteo',
      `No ha sido posible Cerrar el Conteo ${ name }`,
      'error'
    )
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this.iziToast.show({
      title: `Copiado al portapapeles`,
      position: "topRight",
      color: "green",
      transitionIn: 'fadeInLeft',
      transitionOut: 'fadeOutRight',
      toastOnce: true,
    });
  }

  showElectronicInvoice(item:any) {
    this.itemToProcessElectronicInvoices = item;
    this._elecInv.processElecetronicInvoicesView = true;
  }

  getStatusTranslate(status:any) {
    if(status === "success") return "Ejecutado";
    if(status === "error") return "Error";
    return status;
  }
  
}
