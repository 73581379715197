import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ElectronicInvoicesService } from 'src/app/services/electronic-invoices.service';
import { InventoryService } from 'src/app/services/inventory.service';
import {Observable, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-process-electronic-invoices',
  templateUrl: './process-electronic-invoices.component.html',
  styleUrls: []
})
export class ProcessElectronicInvoicesComponent implements OnInit {

  @Input() item:any;
  @Output() reloadTable = new EventEmitter();

  listElectronicInvoices:any = [];
  listElectronicInvoicesError:any = null;
  loaderListElectronicInvoices:boolean = false;

  loaderAllComponent:boolean = false;

  electronicInvoiceForm:FormGroup = new FormGroup({
    electronicInvoice: new FormControl('', [Validators.required]),
  })

  filInvoices = new Observable<any[]>();

  constructor(
    public _elecInv: ElectronicInvoicesService,
    public _inv: InventoryService
  ) { }

  ngOnInit(): void {
    this.getDataElectronicInvoices();
    this.filInvoices = this.electronicInvoiceForm.controls.electronicInvoice.valueChanges.pipe(
      startWith(''),
      map(val => this._filter(val))
    )
  }

  get electronicInvoiceField(){
    return this.electronicInvoiceForm.get('electronicInvoice')
  }

  getDataElectronicInvoices() {
    if (!this.item.provider?.provider_id || !this.item.order?.order_id) {
      this.listElectronicInvoices = [];
      return;
    }
    this.loaderListElectronicInvoices = true;
    this._inv
      .getElectronicInvoicesByProviderId(
        this.item.provider.provider_id,
        this.item.order.order_id
      )
      .then((data) => {
        console.log("DATA -> ", {data})
        if (data.data) {
          this.listElectronicInvoices = data.data.invoinces;
          this.loaderListElectronicInvoices = false;
        } else {
          this.listElectronicInvoices = [];
        }
        this.listElectronicInvoicesError = null;
      })
      .catch((error) => {
        console.error({ error });
        this.listElectronicInvoicesError = error.response.data.error;
        this.listElectronicInvoices = [];
        this.loaderListElectronicInvoices = false;
      });
  }

  closeModal(){
    this._elecInv.processElecetronicInvoicesView = false;
  }

  getElectronicInvoiceValueId(value: string) {
    const matchingInvoice = this.listElectronicInvoices.find((invoice:any) => invoice.consecutive === value);
    return matchingInvoice?.id;
  } 

  async save(evt:any) {

    if(!this.item.reception_id || !this.electronicInvoiceForm.controls.electronicInvoice.value) return; 
    
    const electronicInvoiceId = this.getElectronicInvoiceValueId(this.electronicInvoiceForm.controls.electronicInvoice.value)
    
    const data:any = {
      reception_id: this.item.reception_id,
      invoice_id: electronicInvoiceId
    }
    this.loaderAllComponent = true;
    try {
      const rta = await this._inv.updateReception(data);
      this.loaderAllComponent = false;
      if(rta.data.msg === "ok") {
        Swal.fire(
          'Se asigna la factura de forma exitosa',
          ``,
          'success'
        ).then( () => {
          this.closeModal();
          this.reloadTable.emit()
        })
        .catch( (error:any) => {
          Swal.fire(
            'Error al asignar factura',
            `${error}`,
            'error'
          )
        })
      }
    } catch (error) {
      this.loaderAllComponent = false;
      Swal.fire(
        'Error al asignar factura',
        `${error}`,
        'error'
      )
    }

  }

  private _filter(val: any): any[] {
    const formatVal = val.toLocaleLowerCase();
    return this.listElectronicInvoices.filter((invoice:any) => invoice.consecutive.toLocaleLowerCase().indexOf(formatVal) === 0)
  }

  validateInvoiceInput() {
    const inputValue = this.electronicInvoiceForm.controls.electronicInvoice.value;
    const matchingInvoice = this.listElectronicInvoices.find((invoice:any) => invoice.consecutive === inputValue);

    if (!matchingInvoice) {
      this.electronicInvoiceForm.controls.electronicInvoice.setErrors({ 'invalidInvoice': true });
    } else {
      this.electronicInvoiceForm.controls.electronicInvoice.setErrors(null);
    }
  }

}
