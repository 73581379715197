import { Injectable } from '@angular/core';

declare var EB:any;

@Injectable({
  providedIn: 'root'
})
export class ScannerService {

  constructor() {
    console.log('Scanner Service');
  }
}