import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'idAttachedFilesPipe'
})
export class IdAttachedFilesPipePipe implements PipeTransform {

  private val:string = "";

  constructor(public _inv: InventoryService){}

  transform(value: unknown, ...args: unknown[]): unknown {
    if(this._inv.filterOpts_invoices.files !== "") {
      if(this._inv.filterOpts_invoices.files === "True")
        this.val = "Con archivos adjuntos"
      if(this._inv.filterOpts_invoices.files === "False")
        this.val = "Sin archivos adjuntos"
    }
    return this.val;
  }

}
