
<div class="processPedOverLay">

    <div class="processPedBox processPedBox_inputs animate__animated animate__zoomIn">
        <button class="closeBtn" (click)="closeModal();">
            <span></span>
        </button>

        <div class="processPedInfo" *ngIf="listToChange">
            <div class="ttl">
                Ordenes a procesar
            </div>
            <div class="orderChanged">
                Ordenes con estado: <b>{{ listToChange.length }}</b> / <b> {{ listSuccess.length }} </b>
            </div>
            <div class="orderList">
                <div class="item" *ngFor="let item of listTemp">
                    <div class="num">
                        Num. de pedidos con este estado: <b>{{item.pedidos.length}}</b>
                    </div>
                    
                    <div class="change" *ngIf="listCharge">
                        <div class="currStatus">
                            <span class="badge {{ item.status.name }}">{{item.status.pretty }}</span>
                        </div>
                        <div class="separator">
                            <i class="{{ '48px_arrows-same-direction' | icons }}"></i>
                        </div>
                        <div class="newStatus">
                            <div class="form_field" *ngIf="item.statusChange.length >= 1">
                                <mat-form-field appearance="outline">
                                    <mat-label>Nuevo estado</mat-label>
                                    <mat-select [(ngModel)]="item.currStatus" (ngModelChange)="changeStstusSel( $event )">
                                        <mat-option *ngFor="let opt of item.statusChange" value="{{opt.status_to.name}}">{{opt.status_to.pretty}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="noStatus" *ngIf="item.statusChange.length == 0">
                                <i class="{{ '48px_empty' | icons }}"></i>
                                <span>Sin estado para cambiar</span>
                            </div>
                        </div>
                        <div class="commentStatus" *ngIf="item.currStatus != item.status.name">
                            <div class="form_field form_fieldTextArea">
                                <mat-form-field class="example-full-width" appearance="outline" *ngIf="item.currStatus !== 'holded_news'">
                                    <div>
                                        <mat-label>Deja un comentario</mat-label>
                                        <textarea [(ngModel)]="item.commentStatus" (ngModelChange)="changeStstusSel( $event )" matInput placeholder="Comentario del cambio de estado"></textarea>
                                    </div>
                                </mat-form-field>
                                <mat-form-field appearance="outline" *ngIf="item.currStatus === 'holded_news' && this._gen.typifications.length > 0">
                                    <mat-label>Descripción</mat-label>
                                    <mat-select [(ngModel)]="item.commentStatus" (ngModelChange)="changeStstusSel( $event )">
                                        <mat-option *ngFor="let opt of this._gen.typifications" value="{{opt.name}}">{{opt.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="loader_typifications" *ngIf="item.currStatus === 'holded_news' && this._gen.typifications.length == 0 ">
                                    <div class="icon">
                                        <img src="assets/img/preload.gif" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <!-- <div class="totalsPeds">
                    Total de pedidos a cambiar: <b>{{ listToChangeSend.length }}</b>
                </div> -->
                <button class="btn btn_success" (click)="convertToNew()" [disabled]=" listToChange.length == 0 ">Cambiar estado de {{listToChangeSend.length}} pedidos</button>
            </div>
        </div>
    </div>

</div>