import { Component, OnInit } from '@angular/core';
import { CatalogService } from 'src/app/services/catalog.service';
import { GeneralService } from 'src/app/services/general.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-catalog-stock',
  templateUrl: './catalog-stock.component.html',
  styles: [
  ]
})
export class CatalogStockComponent implements OnInit {

  tableType:string = "catalogStock";
  columnsConfig = [
    { varId: "sku_full", label: "sku full", visible: true, type: "uniqueId", class: "longCell noEllipsis", disabled: false },
    { varId: "sku", label: "Sku", visible: true, type: "sku", class: "", disabled: false },
    { varId: "name", label: "Nombre", visible: true, type: "name", class: "longCell noEllipsis", disabled: false },
    { varId: "ean", label: "Ean", visible: true, type: "ean", class: "", disabled: false },
    { varId: "manufacturer", label: "Laboratorio", visible: true, type: "manufacturer", class: "", disabled: false },
    { varId: "quantity", label: "Cantidad", visible: true, type: "quantity", class: "idCell", disabled: false },
    { varId: "reserved", label: "Reservado", visible: true, type: "reserved", class: "idCell", disabled: false },
    { varId: "provider", label: "Proveedor", visible: true, type: "provider", class: "", disabled: false },
    { varId: "country", label: "País", visible: true, type: "country", class: "", disabled: false },
    { varId: "cost", label: "Costo", visible: true, type: "cost", class: "", disabled: false },
    { varId: "sap", label: "SAP", visible: true, type: "sap", class: "longCell noEllipsis", disabled: false },
  ]
  dataSourcefull:any[] = [];

  colsView:boolean = false;
  filtsView:boolean = false;

  dataSourceCount = 0;
  currPage = 1; 
  currPageView = 100; 
  currPageTotal = 0; 

  dataSource:any[] = [];

  constructor( public _ord:OrdersService, public _cat:CatalogService, public _gen:GeneralService ) { }

  ngOnInit(): void {
    this.getAllProds();
    this.getFiltersOpts();
    
    this._gen.currCountryObs.subscribe((country) => {
      // console.log(country)
      this.getAllProds();
      this.getFiltersOpts();
    })
  }

  /* Filter Opts */
  getFiltersOpts() {
    //  var country = this._cat.filterOptsStock.country.join();
    var manufact = this._cat.filterOptsStock.manufacturer.join();


     var country = this._gen.currCountry.toString();
     this._cat.filterOptsStock.country = country.split(',');
     

    this._gen.getCountry().then( (data:any) => {
      this._gen.allCountries = data.data;
    })

    this._gen.getProvider( country ).then( (data:any) => {
      this._ord.filt_provider = data.data;      
    })

    this._cat.getBrands( manufact ).then( (data:any) => {
      this._cat.filt_brand = data.data;
    })
    this._cat.getManufact().then( (data:any) => {
      this._cat.filt_manufac = data.data;
    })

  }

  /* PAGE COUNT */
  getAllProdsCountFilt( filt:any ) {
    let prom = new Promise ( ( resolve, reject ) => {
      this._cat.getAllProviderProdsCountFilter( filt ).then( (data:any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil( this.dataSourceCount / this.currPageView );
        
        if ( this.currPageTotal == 0 ) {
          this.currPageTotal = 1;
        }
        
        if ( this.currPage > this.currPageTotal ) {
          this.currPage = this.currPageTotal
        }

        resolve( true );
      })
    })
    return prom;
  }

  /* RESET Filters */
  resetFilters_item(item:string) {
    switch (item) {
      // case 'country':
      //   this._cat.filterOptsStock.country = [];
      //   break;
      case 'provider':
        this._cat.filterOptsStock.provider = [];
        break;
      case 'manufacturer':
        this._cat.filterOptsStock.provider = [];
        break;
      case 'sap':
        this._cat.filterOptsStock.provider = "";
        break;
      case 'brand':
        this._cat.filterOptsStock.provider = [];
        break;
      case 'sku':
        this._cat.filterOptsStock.provider = "";
        break;
      case 'sku_full':
        this._cat.filterOptsStock.provider = "";
        break;
      case 'name':
        this._cat.filterOptsStock.provider = "";
        break;
    
      default:
        this._cat.filterOptsStock = {
          // scountry: [],
          provider: [],
          manufacturer: [],
          sap: "",
          brand: [],
          sku: "",
          sku_full: "",
          name: "",
        };

        break;
    }
    this.checkFilter();
  }
  /* RESET */
  resetTobTable( type:string ) {    
    setTimeout(() => {
      if ( type == 'colsView' ) {
        this.filtsView = false;
        if ( this.colsView ) { this.colsView = false } else { this.colsView = true }
      } else if ( type == 'filtsView' ) {
        this.colsView = false;
        if ( this.filtsView ) { this.filtsView = false } else { this.filtsView = true }
      }
    }, 150);
  }
  resetTable( arrFull:any[] ) {
    let prom = new Promise( (resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];      
  
      if( this.dataSourcefull ) {
        for (let d = 0; d < this.dataSourcefull.length; d++) {
          const element:any = this.dataSourcefull[d];        
          let item = {
            id: element.id,
            provider: element.provider,
            sku_full: element.sku_full,
            name: element.name,
            sap: element.sap,
            priority: element.priority,
            cost: element.cost,
            quantity: element.quantity,
            reserved: element.reserved
          }
          this.dataSource.push( item );
        }
      }
      resolve( true);
    })

    return prom;
  }

  /* PAGER FX */
  getAllProds() {
    this._gen.tableLoader = true;
    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._cat.getAllProviderProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {        
        this.resetTable( data.data ).then( (data:any) => {
          this._gen.tableLoader = false;
        });
      })
    });
  }
  changePageInp( page:any, input:boolean = false ) {
    if ( input ) {
      this.currPage = parseInt( page.target.value );
    } else {
      this.currPage = parseInt( page );
    }
    this._gen.tableLoader = true;
    
    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._cat.getAllProviderProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });

  }

  /* filters */
  checkFilterVal() {
    let filt_country, filt_provider, filt_manufacturer, filt_sap, filt_brand, filt_sku, filt_sku_full, filt_name;

    filt_country = `country=${ this._gen.currCountry }&`;

    // if ( this._cat.filterOptsStock.country.length >= 1 ) {
    //   filt_country = `country=${ this._cat.filterOptsStock.country.join() }&`;
    // }
    if ( this._cat.filterOptsStock.provider.length >= 1 ) {
      filt_provider = `provider=${ this._cat.filterOptsStock.provider.join() }&`;
    }
    if ( this._cat.filterOptsStock.manufacturer.length >= 1 ) {
      filt_manufacturer = `manufacturer=${ this._cat.filterOptsStock.manufacturer.join() }&`;
    }
    if ( this._cat.filterOptsStock.sap ) {
      filt_sap = `sap=${ this._cat.filterOptsStock.sap }&`;
    }
    if ( this._cat.filterOptsStock.brand.length >= 1 ) {
      filt_brand = `brand=${ this._cat.filterOptsStock.brand.join() }&`;
    }
    if ( this._cat.filterOptsStock.sku ) {
      filt_sku = `sku=${ this._cat.filterOptsStock.sku }&`;
    }
    if ( this._cat.filterOptsStock.sku_full ) {
      filt_sku_full = `sku_full=${ encodeURIComponent(this._cat.filterOptsStock.sku_full.toUpperCase()) }&`;
    }
    if ( this._cat.filterOptsStock.name ) {
      filt_name = `name=${ this._cat.filterOptsStock.name }&`;
    }

    let filter = `${filt_country}${filt_provider}${filt_manufacturer}${filt_sap}${filt_brand}${filt_sku}${filt_sku_full}${filt_name}`;    
    return filter.replace(/undefined/g,"");
  }
  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._cat.getAllProviderProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        })
      }).catch( (err) => {
        console.log( err );
      })
    });
  }


  /* EDIT */
  editBtnFx() {
    this._cat.editStockId = this._cat.sel_catalogStock[0]?.sku_full;
    this._cat.editStockView = !this._cat.editStockView;
  }
  saveBtnFx() {
    this._cat.editStockId = "";
    this._cat.editStockView = !this._cat.editStockView;
  }
  cancelBtnFx() {
    this._cat.editStockId = "";
    this._cat.editStockView = !this._cat.editStockView;

    this._cat.edit_country( "" )
    this._cat.edit_manufac( "" )
    this._cat.edit_brand( "" )
  }

}
