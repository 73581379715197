import { Injectable } from '@angular/core';
import axios from 'axios';

import { Ng2IzitoastService } from 'ng2-izitoast';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { LoginService } from './login.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  currCountry:number = 0;
  _currCountryChange: Subject<any> = new Subject<any>();
  currCountryObs = this._currCountryChange.asObservable();

  currentUserSubjectPermissions: Subject<any> = new Subject<any>();
  currentUserSubjectPermissionsObs = this.currentUserSubjectPermissions.asObservable();

  currUser:String = "";
  userAdmin:Boolean = false;
  isSuperAdmin:Boolean = false;
  currUserPermissions:String[] = [];

  topbar_user_menu:boolean = false;

  tableLoader:boolean = false;

  serverURL:string = environment.apiMagentoSource;
  serverTkn:string = "";

  barCodeEntryView:boolean = false;
  _barCodeEntryViewChange: Subject<boolean> = new Subject<boolean>();
  barCodeEntryViewObs = this._barCodeEntryViewChange.asObservable();

  barCodeEntryViewPeds:boolean = false;
  _barCodeEntryViewPedsChange: Subject<boolean> = new Subject<boolean>();
  barCodeEntryViewPedsObs = this._barCodeEntryViewPedsChange.asObservable();

  barCodeCountView:boolean = false;
  _barCodeCountViewChange: Subject<boolean> = new Subject<boolean>();
  barCodeCountViewObs = this._barCodeCountViewChange.asObservable();

  allStatus:any[] = [];
  allStatusChange:any = {};

  allOrdsStatus:any[] = [];
  allProdStatus:any[] = [];

  allCountries:any[] = [];
  allCarriers:any[] = [];
  allProdTypes:any[] = [];

  prodStatusView:boolean = false;
  _prodStatusViewChange: Subject<boolean> = new Subject<boolean>();
  prodStatusViewObs = this._prodStatusViewChange.asObservable();

  typifications:any = []

  generalLoader:boolean = false;

  assignProviderView:boolean = false;

  constructor( public iziToast: Ng2IzitoastService ) {
    if( this.serverTkn ) {
      this.getPedStatuses();
      this.getProdsStatuses();      
    }
  }

  /* Filters */
  getCountry() {
    return axios.get(`${this.serverURL}/general/country/`, {
      headers: {'Authorization': `${this.serverTkn }`},
    })
  }
  getReason() {
    return axios.get(`${this.serverURL}/providers/reason/`, {
      headers: {'Authorization': `${this.serverTkn }`},
    })
  }
  getCarriers() {
    return axios.get(`${this.serverURL}/magento/carrier/?country=${ this.currCountry }`, {
      headers: {'Authorization': `${this.serverTkn }`},
    })
  }
  getFileTypes() {
    return axios.get(`${this.serverURL}/general/file_type/?country=${ this.currCountry }`, {
      headers: {'Authorization': `${this.serverTkn }`},
    })
  }
  getBUnit( country:string ) {
    if ( country ) {
      return axios.get(`${this.serverURL}/general/business_unit/`, {
        headers: {'Authorization': `${this.serverTkn}`},
        params: {
          country: country
        },
      })
    } else {
      return axios.get(`${this.serverURL}/general/business_unit/`, {
        headers: {'Authorization': `${this.serverTkn}`},
      })
    }
  }
  getProvider( countryId:string ) {
    if ( countryId ) {
      return axios.get(`${this.serverURL}/providers/provider/`, {
        headers: {'Authorization': `${this.serverTkn}`},
        params: {
          country: countryId
        },
      })
    } else {
      return axios.get(`${this.serverURL}/providers/provider/`, {
        headers: {'Authorization': `${this.serverTkn}`},
      })
    }
  }
  getReference( countryId:string ) {
    return axios.get(`${this.serverURL}/providers/order/?country=${countryId}&status=created`, {
      headers: {'Authorization': `${this.serverTkn}`},
    })
  }
  getOrderStatus( ) {
    return axios.get(`${this.serverURL}/providers/order_status/`, {
      headers: {'Authorization': `${this.serverTkn}`},
    })
  }
  getDateFormat( date:string ) {
    let tempDate = new Date( date );
    let time = {
      year: tempDate.getFullYear(),
      month: tempDate.getMonth() + 1,
      day: tempDate.getDate(),
    }
    let str = `${time.year}-${time.month}-${time.day}`;
    return str;
  }
  getProdType() {
    return axios.get(`${this.serverURL}/catalog/type/`, {
      headers: {'Authorization': `${this.serverTkn }`},
    })
  }

  /* Magento status */
  getPedStatus( ) {
    return axios.get(`${this.serverURL}/magento/status/`, {
      headers: {'Authorization': `${this.serverTkn}`},
    })
  }
  getPedStatusChng( ) {
    return axios.get(`${this.serverURL}/magento/status_change/`, {
      headers: {'Authorization': `${this.serverTkn}`},
    })
  }
  async getPedStatuses() {
    await this.getPedStatus().then( ({data}) => {
      this.allStatus = data;      
    })
    await this.getPedStatusChng().then( ({data}) => {

      let typesGroups:any = {
        "confirmed": [],
        "contactlenses_confirmed": [],
        "order_preparation": [],
        "supplying": [],
        "processing_special": [],
        "order_import": [],
        "packing": [],
        "order_shipped": [],
        "logistics_operator_notification": [],
        "holded_news": [],
        "wrong_address": [],
        "envio_parcial": [],
        "no_status": [],
      };
      
      for (let w = 0; w < data.length; w++) {
        const item = data[w];

        switch ( item.status_from.name ) {
          case "confirmed":
            typesGroups["confirmed"].push( item )
            break;
          case "contactlenses_confirmed":
            typesGroups["contactlenses_confirmed"].push( item )
            break;
          case "order_preparation":
            typesGroups["order_preparation"].push( item )
            break;
          case "supplying":
            typesGroups["supplying"].push( item )
            break;
          case "processing_special":
            typesGroups["processing_special"].push( item )
            break;
          case "order_import":
            typesGroups["order_import"].push( item )
            break;
          case "packing":
            typesGroups["packing"].push( item )
            break;
          case "order_shipped":
            typesGroups["order_shipped"].push( item )
            break;
          case "logistics_operator_notification":
            typesGroups["logistics_operator_notification"].push( item )
            break;
          case "holded_news":
            typesGroups["holded_news"].push( item )
            break;
          case "wrong_address":
            typesGroups["wrong_address"].push( item )
            break;
          case "envio_parcial":
            typesGroups["envio_parcial"].push( item )
            break;
            
          default:
            typesGroups["no_status"].push( item )
            break;
        }
      }
      this.allStatusChange = typesGroups;
    })
    // await console.log( this.allStatus, this.allStatusChange );
  }

  getDescriptionTypifications() {
    return axios.get(`${this.serverURL}/magento/typification/`, {
      headers: {'Authorization': `${this.serverTkn }`},
    })
  }

  async getTypifications() {
    await this.getDescriptionTypifications().then( ({data}) => {
      this.typifications = data;
    })
  }

  /* ORDERS status */
  getAllOrdsStatus() {
    return axios.get(`${this.serverURL}/providers/order_status/`, {
      headers: {'Authorization': `${this.serverTkn}`},
    })
  }

  /* Products status */
  getProdStatus( ) {
    return axios.get(`${this.serverURL}/providers/product_status/`, {
      headers: {'Authorization': `${this.serverTkn}`},
    })
  }
  async getProdsStatuses() {
    await this.getProdStatus().then( ({data}) => {
      this.allProdStatus = data;
    })
  }

  // = = SWALS = =
  swal_newBcode( type:string, mssg:string ) {
    let timerInterval:any;

    if ( type === "success" ) {
      
      return Swal.fire({
        title: 'Código de barras leido exitosamente',
        html: `${mssg}`,
        timer: 500,
        icon: 'success',
        timerProgressBar: true,
  
        willClose: () => {
          clearInterval(timerInterval)
        }
      })

    } else if (  type === "error" ) {

      return Swal.fire({
        title: 'Código de barras tuvo un error',
        html: `${mssg}`,
        timer: 3000,
        icon: 'error',
        timerProgressBar: true,

        willClose: () => {
          clearInterval(timerInterval)
        }
      })

    } else {

      return Swal.fire({
        title: 'Código de barras tuvo un error',
        html: `${mssg}`,
        timer: 500,
        icon: 'error',
        timerProgressBar: true,

        willClose: () => {
          clearInterval(timerInterval)
        }
      })

    }

  }

  // = = TOASTS = =
  toastError( mssj:string ) {
    return this.iziToast.show({
      title: mssj,
      position: "topRight",
      color: "red",
      transitionIn: 'fadeInLeft',
      transitionOut: 'fadeOutRight',
      toastOnce: true,
    });
  }

  /* get Bar code */
  getBarCode( code:string ) {
    return axios.get(`${this.serverURL}/stock/read_code/`, {
      headers: {'Authorization': `${this.serverTkn}`},
      params: {
        "code": code,
        "country": this.currCountry,
      }
    })
  }
  
  /* get Bar code */
  sendFile( data:any ) {    
    return axios.post(`${this.serverURL}/general/upload_file/`, data, {
      headers: {
        'Authorization': `${this.serverTkn}`,
        'Content-Type': "multipart/form-data"
      },
    })
  }


  passEncrypt( value:string ) {
    let encrypt = CryptoJS.AES.encrypt( value, 'l3nt3splu$', {keySize: 128 / 8,} ).toString()
    return encrypt;
  }
  passDecrypt( value:string ) {
    let encrypt = CryptoJS.AES.decrypt( value, 'l3nt3splu$' ).toString( CryptoJS.enc.Utf8 )
    return encrypt;
  }

  camalize(str:string) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }

  // synchronization with Magento
  getLastSyncWithMagento( limit:number ) {
    return axios.get(`${this.serverURL}/general/magento_sync/?limit=${limit}`, {
      headers: {'Authorization': `${this.serverTkn}`},
    })
  }

  // providers cut
  getProvidersCut( limit:number ) {
    return axios.get(`${this.serverURL}/providers/provider_cut_configuration_log/?country=${this.currCountry}&limit=${limit}`, {
      headers: {'Authorization': `${this.serverTkn}`},
    })
  }
}
