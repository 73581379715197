<div class="topttls">
    <app-tittle [label]="'Detalle de Pedido'" [icon]="'48px_box-3d-50'"></app-tittle>

    <div class="btns">
        <button class="btn btn_fill btn_info" *ngIf="prevPed?.sale_id" (click)="goToPrevPed( prevPed )">Anterior pedido</button>
        <button class="btn btn_fill btn_info" *ngIf="nextPed?.sale_id" (click)="goToNextPed( nextPed )">Siguiente pedido</button>
        <button class="btn" (click)="backToPeds()">Volver</button>
    </div>
</div>

<div class="pedDetailWrapper">
    <div class="loaderInfo" *ngIf="!pedData">
        <div class="icon">
            <img src="assets/img/preload.gif" alt="">
        </div>
    </div>
    <div class="loaderGeneral" *ngIf="this._gen.generalLoader">
        <div class="icon">
            <img src="assets/img/preload.gif" alt="">
        </div>
    </div>

    <div class="pedDetailWrap"  *ngIf="pedData">
        <div class="topInfo">
            <div class="ttlBox">
                <div class="ttl">
                    Orden # <span>{{pedData?.order_number}}</span>
                </div>
                <div class="subttl">
                    Creada el: <span>{{pedData?.created_at | date:'d-M-yyyy h:mm'}}</span>
                </div>
                <div class="status">
                    <b>Estado de orden:</b>
                    <span class="badge {{pedData?.status.name}}" *ngIf="!changeStatus">{{pedData?.status.pretty}}</span>
                    
                    <div class="form_field" *ngIf="changeStatus">
                        <mat-form-field appearance="outline">
                            <mat-label>Cambio de estado</mat-label>
                            <mat-select [(ngModel)]="currStatus" (ngModelChange)="changeStatusFx()">
                                <mat-option value="{{currTempStatus?.name}}">{{currTempStatus?.pretty}}</mat-option>
                                <mat-option *ngFor="let opt of posibleStatus" value="{{opt.status_to.name}}">{{opt?.status_to.pretty}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form_field form_fieldTextArea" *ngIf="changeStatus">
                        <mat-form-field class="example-full-width" appearance="outline" *ngIf="currStatus !== 'holded_news'">
                            <mat-label>Deja un comentario</mat-label>
                            <textarea [(ngModel)]="statusComment" matInput placeholder="Ex. It makes me feel..."></textarea>
                          </mat-form-field>
                          <mat-form-field appearance="outline" *ngIf="currStatus === 'holded_news' && this._gen.typifications.length > 0">
                            <mat-label>Descripción</mat-label>
                            <mat-select [(ngModel)]="statusComment">
                                <mat-option *ngFor="let opt of this._gen.typifications" value="{{opt.name}}">{{opt.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="loader_typifications" *ngIf="currStatus === 'holded_news' && this._gen.typifications.length == 0 ">
                            <div class="icon">
                                <img src="assets/img/preload.gif" alt="">
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
            <div class="buttons">
                <button class="btn btn_error" *ngIf="changeStatus" (click)="changeStatus = !changeStatus">Cancelar</button>
                <button class="btn" *ngIf="changeStatus" [disabled]="!changeStatusSave || changeStatDisabled" (click)="changeStatusSaveFx()">Guardar</button>
                <button class="btn" *ngIf="!changeStatus" (click)="changeStatus = !changeStatus">Cambiar estado</button>
                <button class="btn btn_fill btn_success" [disabled]="changeStatus" *ngIf="isAcconteView" (click)="_gen.barCodeEntryViewPeds = true; viewreadCodeSaleId = pedData?.sale_id">Escanear productos</button>
                <button *ngIf="showPartialShipButton" class="btn btn_fill btn_info" [disabled]="!pedData?.carrier.carrier || disabledButton" (click)="genShipFx()">Generar envío parcial</button>
                <button *ngIf="showShipButton" class="btn btn_fill btn_info" [disabled]="!pedData?.carrier.carrier || disabledButton" (click)="genShipFx()">Generar envío</button>
            </div>
        </div>
        <div class="infroWrap">
            <div class="formInfo formInfoCarrier">
                <div class="top">
                    <div class="label">Información de Carrier</div>
                </div>
                <div class="mid">
                    <div class="form_field">
                        <label for="">O. Logístico:</label>
                        <div class="editDrop">
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="pedData?.carrier.carrier" (ngModelChange)="changeCarrier()">
                                    <mat-option *ngFor="let opt of currCarriers" value="{{opt.name}}">{{opt.pretty}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.carrier.carrier}}" disabled> -->
                    </div>
                    <div class="form_field">
                        <label for="">Numero de tracking</label>
                        <input type="text" name="ped_name" id="" value="{{pedData?.carrier.tracking_number}}" disabled>
                    </div>
                </div>
            </div>
            <div class="formInfo formInfoCount">
                <div class="top">
                    <div class="label">Información de cuenta</div>
                    <!-- <button class="btn">Editar</button> -->
                </div>
                <div class="mid">
                    <div class="form_field">
                        <label for="">Nombre</label>
                        <span>{{pedData?.user.customer_name}}</span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.user.customer_name}}" disabled> -->
                    </div>
                    <div class="form_field" *ngIf="pedData?.user.customer_mail">
                        <label for="">Correo electrónico</label>
                        <input type="text" name="ped_name" id="" value="" disabled>
                    </div>
                    <div class="form_field">
                        <label for="">Teléfono</label>
                        <span>{{pedData?.user.customer_phone}}</span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.user.customer_phone}}" disabled> -->
                    </div>
                </div>
            </div>

            <div class="comments_info" *ngIf="pedData?.logs[0]">
                <div class="top">
                    <div class="label">Registro de eventos</div>
                </div>
                <div class="firstComment" *ngIf="!showMoreComments">
                    <div class="comment">
                        <div class="circle" [ngClass]="{'success': pedData.logs[0].success || pedData.logs[0].success == null, 'failed': !pedData.logs[0].success}"></div>
                        <div class="info">
                            <div class="top">
                                <div class="left">
                                    <div class="icon">
                                        <i *ngIf="pedData?.logs[0].action == 'status' " [class]="'48px_change-direction' | icons"></i>
                                        <i *ngIf="pedData?.logs[0].action == 'comment'" [class]="'48px_f-comment' | icons"></i>
                                        <i *ngIf="pedData?.logs[0].action == 'ship' " [class]="'48px_delivery' | icons"></i>
                                        <i *ngIf="pedData?.logs[0].action == 'special_import' || pedData?.logs[0].action == 'delivery_promise'" [class]="'48px_calendar' | icons"></i>
                                    </div>
                                    <div class="userName">
                                        {{ pedData?.logs[0].user.first_name }}&nbsp;{{ pedData?.logs[0].user.last_name }}
                                    </div>
                                    <div class="badgeBox">
                                        <span class="badge" *ngIf="pedData?.logs[0].action == 'status' ">Cambio de estado</span>
                                        <span class="badge" *ngIf="pedData?.logs[0].action == 'comment' ">Comentario</span>
                                        <span class="badge" *ngIf="pedData?.logs[0].action == 'ship' ">Creación de shipment</span>
                                        <span class="badge" *ngIf="pedData?.logs[0].action == 'special_import' ">Especial o Importación</span>
                                        <span class="badge" *ngIf="pedData?.logs[0].action == 'delivery_promise' ">Promesa de entrega</span>
                                    </div>
                                    <div class="statusChange">
                                        <div class="from">{{ pedData?.logs[0].status_from.pretty }}</div>
                                        <i [class]="'48px_arrow-e' | icons"></i>
                                        <div class="to">{{ pedData?.logs[0].status_to.pretty }}</div>
                                    </div>
                                </div>
                                <div class="rigth">
                                    <div class="date">{{ pedData?.logs[0].created_at | date:'M/d/yy, h:mm a' }}</div>
                                </div>
                            </div>
                            <div *ngIf="pedData?.logs[0].type" class="log-type">
                                <span class="badge created">
                                    {{pedData?.logs[0].type}}
                                </span>
                            </div>
                            <div *ngIf="pedData?.logs[0].comment">
                                <p [innerHtml]="pedData?.logs[0].comment"></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="listComment" *ngIf="showMoreComments">
                    <div class="comment" *ngFor="let cmmt of pedData?.logs">
                        <div class="circle" [ngClass]="{'success': cmmt.success || cmmt.success == null, 'failed': !cmmt.success}"></div>
                        <div class="info">
                            <div class="top">
                                <div class="left">
                                    <div class="icon">
                                        <i *ngIf="cmmt.action == 'status' " [class]="'48px_change-direction' | icons"></i>
                                        <i *ngIf="cmmt.action == 'comment'" [class]="'48px_f-comment' | icons"></i>
                                        <i *ngIf="cmmt.action == 'ship' " [class]="'48px_delivery' | icons"></i>
                                        <i *ngIf="cmmt.action ==  'special_import' || cmmt.action ==  'delivery_promise' " [class]="'48px_calendar' | icons"></i>
                                    </div>
                                    <div class="userName">
                                        {{ cmmt.user.first_name }}&nbsp;{{ cmmt.user.last_name }}
                                    </div>
                                    <div class="badgeBox">
                                        <span class="badge" *ngIf="cmmt.action == 'status' ">Cambio de estado</span>
                                        <span class="badge" *ngIf="cmmt.action == 'comment' ">Comentario</span>
                                        <span class="badge" *ngIf="cmmt.action == 'ship' ">Creación de shipment</span>
                                        <span class="badge" *ngIf="cmmt.action == 'special_import' ">Especial o Importación</span>
                                        <span class="badge" *ngIf="cmmt.action == 'delivery_promise' ">Promesa de entrega</span>
                                    </div>
                                    <div class="statusChange">
                                        <div class="from">{{ cmmt.status_from.pretty }}</div>
                                        <i [class]="'48px_arrow-e' | icons"></i>
                                        <div class="to">{{ cmmt.status_to.pretty }}</div>
                                    </div>
                                </div>
                                <div class="rigth">
                                    <div class="date">{{ cmmt.created_at | date:'M/d/yy, h:mm a' }}</div>
                                </div>
                            </div>
                            <div *ngIf="cmmt.type" class="log-type">
                                <span class="badge created">
                                    {{cmmt.type}}
                                </span>
                            </div>
                            <div *ngIf="cmmt.comment">
                                <p [innerHtml]="cmmt.comment"></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="showMoreBtn">
                    <button class="btn" (click)="showMoreComments = !showMoreComments ">
                        <span *ngIf="!showMoreComments">Ver más eventos</span>
                        <span *ngIf="showMoreComments">Ver menos eventos</span>
                    </button>
                </div>
            </div>

            <div class="formInfo formInfoShip">
                <div class="top">
                    <div class="label">Información de entrega</div>
                </div>
                <div class="mid">
                    <div class="form_field" *ngIf="pedData?.shipment.address">
                        <label for="">Dirección</label>
                        <span>{{pedData?.shipment.address}}</span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.shipment.address}}" disabled> -->
                    </div>
                    <div class="form_field" *ngIf="pedData?.shipment.location_other">
                        <label for="">Dir. extra</label>
                        <span>
                            <i *ngIf="pedData?.shipment.location_other.colony">Colonia: <b>{{pedData?.shipment.location_other.colony}}</b> </i><br>
                            <i *ngIf="pedData?.shipment.location_other.delegation">Delegación: <b>{{pedData?.shipment.location_other.delegation}}</b> </i><br>
                        </span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.shipment.location_other}}" disabled> -->
                    </div>
                    <div class="form_field" *ngIf="pedData?.shipment.region">
                        <label for="">Región</label>
                        <span>{{pedData?.shipment.region}}</span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.shipment.region}}" disabled> -->
                    </div>
                    <div class="form_field" *ngIf="pedData?.shipment.city">
                        <label for="">Ciudad</label>
                        <span>{{pedData?.shipment.city}}</span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.shipment.city}}" disabled> -->
                    </div>
                    <div class="form_field" *ngIf="pedData?.shipment.postcode">
                        <label for="">Código Postal</label>
                        <span>{{pedData?.shipment.postcode | postCode}}</span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.shipment.postcode}}" disabled> -->
                    </div>
                    <div class="form_field">
                        <label for="">País</label>
                        <span>{{pedData?.shipment.country}}</span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.shipment.country}}" disabled> -->
                    </div>
                    <div class="form_field">
                        <label for="">Cupón</label>
                        <span>{{pedData?.coupon}}</span>
                        <!-- <input type="text" name="ped_name" id="" value="{{pedData?.shipment.country}}" disabled> -->
                    </div>
                </div>
            </div>
            <div class="formInfo formInfoDate">
                <div class="top">
                    <div class="label">Fecha de Entrega</div>
                </div>
                <div class="mid">
                    <div *ngIf="pedData?.delivery_promise.from_day" class="txtWrap">
                        <span>Esta orden debe ser entregada entre el</span>
                        <b> {{pedData?.delivery_promise.from_day}} de {{pedData?.delivery_promise.from_month | monthName}}</b> y el <b>{{pedData?.delivery_promise.to_day}} de {{pedData?.delivery_promise.to_month | monthName}}</b>
                    </div>
                    <div *ngIf="!pedData?.delivery_promise.from_day" class="txtWrap">
                        <span>Esta orden no tiene fecha de entrega asignada</span>
                    </div>
                </div>
            </div>

            <div class="formInfo formInfoCarrier">
                <div class="top">
                    <div class="label">Productos del pedido</div>
                </div>
            </div>
            <div class="prodList" *ngIf="pedData?.products.length >= 1">
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Producto</td>
                            <td>Status</td>
                            <td *ngIf="showColumn(pedData)">Fecha Prometida<br> Proveedor</td>
                            <td>Comentarios</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pedData?.products; index as i">
                            <td>
                                <img src="{{item?.prod.product.image}}" alt="{{item?.prod.product.name | slice:0:20}}...">
                            </td>
                            <td>
                                <div class="info">
                                    <p class="name"><b>{{item?.prod.product.name}}</b></p>
                                    <p class="qty">Orden: <b><a *ngFor="let ref of item?.reference; index as i" (click)="goToOrder( ref )"><i *ngIf="i >= 1"> , </i>{{ref}}</a></b></p>
                                    <p class="sku">SKU: <b>{{item?.prod.sku}}</b></p>
                                    <p class="skufull">SKU FULL: <b>{{item?.prod.sku_full}}</b></p>
                                    <p class="formula" *ngIf="item?.formula">
                                        <span *ngIf="item?.formula_json">
                                            <i>
                                                <b>Izquierdo</b><br>
                                                <span>SPH: <b>{{item?.formula_json.left.sphere}}</b></span> | <span>CYL: <b>{{item?.formula_json.left.cylinder}}</b></span><br>
                                                <span>AXIS: <b>{{item?.formula_json.left.axis}}</b></span> <span *ngIf="!item?.formula_json.unified">| PD: <b>{{item?.formula_json.left.pupil_distance}}</b></span><br>
                                            </i><br>
                                            <i>
                                                <b>Derecho</b><br>
                                                <span>SPH: <b>{{item?.formula_json.right.sphere}}</b></span> | <span>CYL: <b>{{item?.formula_json.right.cylinder}}</b></span><br>
                                                <span>AXIS: <b>{{item?.formula_json.right.axis}}</b></span> <span *ngIf="!item?.formula_json.unified">| PD: <b>{{item?.formula_json.right.pupil_distance}}</b></span><br>
                                            </i><br>
                                            <i *ngIf="item?.formula_json.unified">
                                                <b>PD unificada: {{item?.formula_json.unified.pupil_distance}}</b><br><br>
                                            </i>
                                            <i>
                                                <b>Lente</b><br>
                                                <b>{{item?.formula_json.lenses.design}}</b> | <b>{{item?.formula_json.lenses.filter}}</b><br>
                                                <b>{{item?.formula_json.lenses.material}}</b>  | <b>{{item?.formula_json.lenses.photo_sensitive}}</b><br>
                                            </i>
                                        </span>
                                        <span *ngIf="!item?.formula_json">Formula: <b>{{item?.formula}}</b></span>
                                    </p>
                                    <p class="dioptry">
                                        <span *ngIf="item?.prod.product?.use_type?.use_type_id == 1">
                                            <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product?.use_type?.use_type_id == 2">
                                            <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                            <span>Cilindro (Cyl): <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                            <span>Eje (Axis): <b>{{ item?.prod.sku_full | diopBySkufull:'Axis' }}</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product?.use_type?.use_type_id == 3">
                                            <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                            <span>Adición (Add): <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product?.use_type?.use_type_id == 4">
                                            <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                            <span>Color: <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product?.use_type?.use_type_id == 6">
                                            <span><b>Solución</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product?.use_type?.use_type_id == 10">
                                            <span><b>Lente oftálmico</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product?.use_type?.use_type_id == 7">
                                            <span><b>Marco</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product?.use_type === null || item?.prod.product?.use_type === 'null' ">
                                            <span><b>--</b></span><br>
                                        </span>
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div class="statBox" >
                                        <span class="badge ordered">
                                            Ordenados ({{item.quantity}})
                                        </span>
                                        <span *ngIf="item.qty_packed > 0" class="badge received">
                                            Escaneado ({{item.qty_packed}})
                                        </span>
                                        <span *ngIf="item.qty_shipped > 0" class="badge order_shipped">
                                            Enviado ({{item.qty_shipped}})
                                        </span>
                                        <span *ngIf="item.qty_special > 0" class="badge order_special">
                                            Especial ({{item.qty_special}})
                                        </span>
                                        <span *ngIf="item.qty_import > 0" class="badge order_import">
                                            Importación ({{item.qty_import}})
                                        </span>
                                        <span *ngIf="item.qty_completed > 0" class="badge completed">
                                            Entregado ({{item.qty_completed}})
                                        </span>
                                        <span *ngIf="item.qty_canceled > 0" class="badge canceled">
                                            Cancelado ({{item.qty_canceled}})
                                        </span>

                                    <button *ngIf="showEditStateBtn(item)" class="edit" (click)="editProdFx( item )">
                                        <i class="{{ '48px_pencil' | icons }}"></i>
                                        <span>Editar estados</span>
                                    </button>
                                </div>
                            </td>
                            <td *ngIf="item.promise_dates.length > 0">
                                <div *ngIf="item.promise_dates.length > 0"  class="datePromise">
                                    <p *ngFor="let promDate of item.promise_dates" [ngClass]="{
                                        'borderStatus': !['order_special', 'order_import'].includes(promDate.status.name),
                                        'borderImportOrSpecial': ['order_special', 'order_import'].includes(promDate.status.name)
                                    }">
                                        <span>{{showDatePromise(promDate)}}</span>
                                        <br>
                                        {{promDate.promise_date}}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div class="commentWrap">
                                    <div class="form_field form_fieldTextArea">

                                        <p *ngIf="i != currCommentItem">
                                            <span class="noComment" *ngIf="!item?.comment">Sin comentario</span>
                                            <span *ngIf="item?.comment">{{item?.comment}}</span>
                                        </p>
                                        <mat-form-field class="example-full-width" appearance="outline" *ngIf="i == currCommentItem">
                                            <mat-label>Deja un comentario</mat-label>
                                            <textarea [(ngModel)]="item.comment" matInput placeholder="Deja un comentario"></textarea>
                                        </mat-form-field>

                                        <button class="edit" *ngIf="i == currCommentItem" (click)="changeProdCommentFx( item )">
                                            <i class="{{ '48px_floppy-disk' | icons }}"></i>
                                            <span>Guardar comentario</span>
                                        </button>
                                        <button class="edit" *ngIf="i != currCommentItem" (click)="currCommentItem = i">
                                            <i class="{{ '48px_pencil' | icons }}"></i>
                                            <span>Editar comentario</span>
                                        </button>
                                    </div>
                                </div>
                                <!-- {{item?.campaign_name}} -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

</div>

<app-read-code  [type]="'ped_salida'" [pedNumber]="pedData?.sale_id" *ngIf="_gen.barCodeEntryViewPeds" variant="item"></app-read-code>

<app-prod-status  [type]="'prod_pedido'" [info]="pedData" [prod]="editProduct" *ngIf="_gen.prodStatusView"></app-prod-status>
