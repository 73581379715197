import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { GeneralService } from 'src/app/services/general.service';
import { LoginService } from 'src/app/services/login.service';

import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})

export class LoginComponent implements OnInit {

  viewPass:boolean = false;

  login:FormGroup = new FormGroup({
    user: new FormControl('', [Validators.required] ), // , Validators.pattern("[^ @]*@[^ @]*"),
    password: new FormControl('', Validators.required ),
    remember: new FormControl(false ),
  });

  constructor( private route:Router, public _log:LoginService, public _gen:GeneralService ) { }

  ngOnInit(): void {

    this.getLocalUser();

  }

  onLogin( form:FormGroup ) {
    this._log.login( form.value.user, form.value.password )
    .then( ( { data } ) => {

      let saveData:any = {};
      this._gen.serverTkn = data.token;
      this._gen.currCountry = data.country;
      this._gen.currUser = data.name;
      this._gen.userAdmin = data.is_admin;
      this._gen.isSuperAdmin = data.is_super_admin;
      this._gen.currUserPermissions = this.getCurrUserPermissions(data.group_permissions, data.permissions)
      this._gen.currentUserSubjectPermissions.next(this._gen.currUserPermissions);

      if ( data.error ) {
        this._gen.toastError( `${data.error}` );
      }
      
      if ( this.login.controls.remember.value ) {
        saveData = {
          name: this._gen.currUser,
          userAdmin: this._gen.userAdmin,
          isSuperAdmin: this._gen.isSuperAdmin,
          currCountry: this._gen.currCountry,
          user: this.login.controls.user.value,
          pass: this._gen.passEncrypt( this.login.controls.password.value ),
          token: this._gen.passEncrypt( this._gen.serverTkn ),
          permissions: this._gen.currUserPermissions
        };

        localStorage.setItem('userData', JSON.stringify( saveData ) );
        localStorage.setItem('currentCountry', JSON.stringify(this._gen.currCountry ));
        this._gen._currCountryChange.next(this._gen.currCountry);
      } else {``
        saveData = {
          name: null,
          userAdmin: null,
          isSuperAdmin: null,
          currCountry: null,
          user: null,
          pass: null,
          token: null,
          permissions: null
        };

        if ( localStorage.getItem('userData') ) {
          localStorage.removeItem('userData')
        }
        if(localStorage.getItem('currentCountry')){
          localStorage.removeItem('currentCountry')
        }

      }      
      
      this.route.navigate(['home']);      
      
    })
    .catch( (err:any) => {    
      this._gen.toastError( `El usuario o la contraseña son incorrectos` );
    })
  }

  

  getLocalUser() {
    if ( localStorage.getItem('userData') ) {
      let dat:any = JSON.parse( localStorage.getItem('userData')! );
      this._gen.currUser = dat.name;
      this.login.controls.user.setValue( dat.user );
      this.login.controls.password.setValue( this._gen.passDecrypt( dat.pass ) );
      this.login.controls.remember.setValue( true );
    }
  }

  getCurrUserPermissions(currUserPermissions:any, permissions:any){
    if(!currUserPermissions || !permissions) return[];
    
    const currUserPermissionsArray = [];
    for (const prop in currUserPermissions) {
      if (Array.isArray(currUserPermissions[prop])) {
        currUserPermissionsArray.push(...currUserPermissions[prop]);
      }
    }

    currUserPermissionsArray.push(...permissions)
    
    return currUserPermissionsArray;
  }

}
