import { Pipe, PipeTransform } from '@angular/core';
import { PedidosService } from '../services/pedidos.service';

@Pipe({
  name: 'idBunit'
})
export class IdBunitPipe implements PipeTransform {

  private val:string = "";

  constructor( public _ped:PedidosService ) {    
  }

  transform(value: any, ...args: unknown[]): unknown {
    for (let f = 0; f < this._ped.filt_bUnit.length; f++) {
      const element = this._ped.filt_bUnit[f];
      
      if ( element.business_unit_id.toString() == value.toString() ) {
        this.val = element.name
      }
    }
    return this.val;
  }

}
