import { Injectable } from '@angular/core';
import axios from 'axios';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  isTrusted:boolean = false;


  constructor( private _gen:GeneralService ) {}


  login( user:string, pass:string ) {
    return axios.post(`${this._gen.serverURL}/users/login/`, {
      user: user,
      password: pass
    })
  }
  checkLogin() {
    if ( localStorage.getItem('userData') ) {
      let dat:any = JSON.parse( localStorage.getItem('userData')! );
      this._gen.serverTkn = this._gen.passDecrypt( dat.token );
    }    

    return axios.post(`${this._gen.serverURL}/users/check_token/`, {
      "token": `${this._gen.serverTkn}`
    })
  }

  getLocalUserName() {
    if ( localStorage.getItem('userData') ) {
      let dat:any = JSON.parse( localStorage.getItem('userData')! );
      this._gen.currUser = dat.name;
      this._gen.currentUserSubjectPermissions.next(dat.permissions);
    }
    if ( localStorage.getItem('userData') ) {
      let dat:any = JSON.parse( localStorage.getItem('userData')! );
      this._gen.userAdmin = dat.userAdmin;
    }
    if ( localStorage.getItem('currentCountry') ) {
      let dat:any = JSON.parse( localStorage.getItem('currentCountry')! );
      this._gen.currCountry = dat;
    }
  }

}
