import { Component, OnInit } from '@angular/core';

import { CatalogService } from 'src/app/services/catalog.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  styles: [
  ]
})
export class CatalogListComponent implements OnInit {

  tableType:string = "catalog";
  columnsConfig = [
    { varId: "newEan", label: "EAN", visible: true, type: "ean", class: "", disabled: false },
    // { varId: "ean", label: "EAN", visible: true, type: "", class: "", disabled: false },
    { varId: "country", label: "País", visible: true, type: "dd_country", class: "idCell", disabled: false },
    { varId: "sku", label: "SKU", visible: true, type: "", class: "", disabled: false },
    { varId: "sku_full", label: "SKU_FULL", visible: true, type: "", class: "longCell", disabled: true },
    // { varId: "name", label: "Nombre", visible: true, type: "", class: "longCell", disabled: false },
    { varId: "newName", label: "Nombre", visible: true, type: "", class: "longCell noEllipsis", disabled: false },
    { varId: "manufacturer", label: "Laboratorio", visible: true, type: "dd_manufact", class: "", disabled: false },
    { varId: "brand", label: "Marca", visible: true, type: "dd_brand", class: "", disabled: false },
    { varId: "type", label: "Tipo", visible: true, type: "", class: "", disabled: false },
  ]
  dataSourcefull:any[] = [];

  colsView:boolean = false;
  filtsView:boolean = false;

  dataSourceCount = 0;
  currPage = 1; 
  currPageView = 100; 
  currPageTotal = 0; 

  dataSource:any[] = [];


  constructor( public _cat:CatalogService, public _gen:GeneralService ) { }

  ngOnInit(): void {
    this.getAllProds();
    this.getFiltersOpts();

    this._gen.currCountryObs.subscribe((country) => {
      // console.log(country)
      this.getAllProds();
      this.getFiltersOpts();
    })
  }

   /* Filter Opts */
   getFiltersOpts() {
    var manufact = this._cat.filterOpts.manufacturer.join();
    // var country = this._cat.filterOpts.country.join();

    var country = this._gen.currCountry.toString();
    this._cat.filterOpts.country = country.split(',');

    this._gen.getCountry().then( (data:any) => {
      this._gen.allCountries = data.data;
    })
    
    this._cat.getBrands( manufact ).then( (data:any) => {
      this._cat.filt_brand = data.data;
    })
    this._cat.getManufact().then( (data:any) => {
      this._cat.filt_manufac = data.data;
    })
    this._cat.getTypes().then( (data:any) => {
      this._cat.filt_types = data.data;
    })
  }

  /* PAGE COUNT */
  getAllProdsCountFilt( filt:any ) {
    let prom = new Promise ( ( resolve, reject ) => {
      this._cat.getAllProdsCountFilt( filt ).then( (data:any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil( this.dataSourceCount / this.currPageView );
        
        if ( this.currPageTotal == 0 ) {
          this.currPageTotal = 1;
        }
        
        if ( this.currPage > this.currPageTotal ) {
          this.currPage = this.currPageTotal
        }

        resolve( true );
      })
    })
    return prom;
  }

  /* RESET Filters */
  resetFilters_item(item:string) {
    switch (item) {
      case 'sku':
        this._cat.filterOpts.sku = "";
        break;
      case 'sku_full':
        this._cat.filterOpts.sku_full = "";
        break;
      // case 'country':
      //   this._cat.filterOpts.country = [];
      //   break;
      case 'name':
        this._cat.filterOpts.name = "";
        break;
      case 'brand':
        this._cat.filterOpts.brand = [];
        break;
      case 'manufacturer':
        this._cat.filterOpts.manufacturer = [];
        break;
      case 'type':
        this._cat.filterOpts.type = [];
        break;
    
      default:
        this._cat.filterOpts = {
          sku: "",
          sku_full: "",
          // country: "",
          name: "",
          brand: [],
          manufacturer: [],
          type: [],
        };

        break;
    }
    this.checkFilter();
  }
  /* RESET */
  resetTobTable( type:string ) {    
    setTimeout(() => {
      if ( type == 'colsView' ) {
        this.filtsView = false;
        if ( this.colsView ) { this.colsView = false } else { this.colsView = true }
      } else if ( type == 'filtsView' ) {
        this.colsView = false;
        if ( this.filtsView ) { this.filtsView = false } else { this.filtsView = true }
      }
    }, 150);
  }
  resetTable( arrFull:any[] ) {
    let prom = new Promise( (resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];
  
      if( this.dataSourcefull ) {
        for (let d = 0; d < this.dataSourcefull.length; d++) {
          const element:any = this.dataSourcefull[d];        
          let item = {
            ean: element.ean,
            country: element.product.country.code,
            sku: element.sku,
            sku_full: element.sku_full,
            name: element.product.name,
            brand: element.product.brand?.name,
            manufacturer: element.product.brand?.manufacturer.name,
            type: element.product.type ? element.product.type.name: 'Indefinido',
            newEan: element.ean,
            newName: element.product.name,
          }
          this.dataSource.push( item );
        }
      }
      resolve( true);
    })    

    return prom;
  }

  /* PAGER FX */
  getAllProds() {
    this._gen.tableLoader = true;
    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._cat.getAllProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {        
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });
  }
  changePageInp( page:any, input:boolean = false ) {
    if ( input ) {
      this.currPage = parseInt( page.target.value );
    } else {
      this.currPage = parseInt( page );
    }
    this._gen.tableLoader = true;
    
    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._cat.getAllProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });

  }

  /* filters */
  checkFilterVal() {
    let filt_sku, filt_sku_full, filt_country, filt_brand, filt_manufacturer, filt_type, filt_name;

    filt_country = `country=${ this._gen.currCountry }&`;

    if ( this._cat.filterOpts.sku ) {
      filt_sku = `sku=${this._cat.filterOpts.sku.toUpperCase()}&`;
    }
    if ( this._cat.filterOpts.sku_full ) {
      filt_sku_full = `sku_full=${encodeURIComponent(this._cat.filterOpts.sku_full.toUpperCase())}&`;
    }
    // if ( this._cat.filterOpts.country ) {
    //   filt_country = `country=${ this._cat.filterOpts.country.join() }&`;
    // }
    if ( this._cat.filterOpts.name ) {
      filt_name = `name=${this._cat.filterOpts.name}&`;
    }
    if ( this._cat.filterOpts.brand.length >= 1 ) {
      filt_brand = `brand=${ this._cat.filterOpts.brand.join() }&`;
    }
    if ( this._cat.filterOpts.manufacturer.length >= 1 ) {
      filt_manufacturer = `manufacturer=${ this._cat.filterOpts.manufacturer.join() }&`;
    }
    if ( this._cat.filterOpts.type.length >= 1 ) {
      filt_type = `type=${this._cat.filterOpts.type.join() }&`;
    }

    let filter = `${filt_sku}${filt_sku_full}${filt_country}${filt_brand}${filt_manufacturer}${filt_name}${filt_type}`;
    return filter.replace(/undefined/g,"");
  }
  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._cat.getAllProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        })
      }).catch( (err) => {
        console.log( err );
      })
    });
  }


  /* EDIT */
  editBtnFx() {
    this._cat.editId = this._cat.sel_catalog[0]?.sku_full;
    this._cat.editView = !this._cat.editView;

    this._gen.getCountry().then( (data:any) => {
      this._cat.dd_edit_country = data.data;
      this._cat.edit_country( this._cat.sel_catalog[0].country )
    })
    this._cat.getManufact().then( (data:any) => {
      this._cat.dd_edit_manufac = data.data;
      this._cat.edit_manufac( this._cat.sel_catalog[0].manufacturer )
      
      this._cat.getBrands( this._cat.dd_model_manufac ).then( (data:any) => {
        this._cat.dd_edit_brand = data.data;
        this._cat.edit_brand( this._cat.sel_catalog[0].brand )
      })
      
    })
    
  }
  saveBtnFx() {
    this._cat.editId = "";
    this._cat.editView = !this._cat.editView;
  }
  cancelBtnFx() {
    this._cat.editId = "";
    this._cat.editView = !this._cat.editView;

    this._cat.edit_country( "" )
    this._cat.edit_manufac( "" )
    this._cat.edit_brand( "" )
  }

}
