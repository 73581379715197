import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-scanned-products",
  templateUrl: "./scanned-products.component.html",
})
export class ScannedProductsComponent implements OnInit {
  tableType: string = "scanned_products";
  countId: any = null;
  countName: string = "";
  bodegaCountName: any = "";

  filtsView: boolean = false;
  colsView: boolean = false;

  dataSource: any[] = [];
  dataSourcefull: any[] = [];

  columnsConfig = [
    {
      varId: "ean",
      label: "EAN",
      visible: true,
      type: "ean",
      class: "",
      disabled: false,
    },
    {
      varId: "scanned_products",
      label: "# Productos escaneados",
      visible: true,
      type: "number",
      class: "",
      disabled: false,
    },
    {
      varId: "expected_products",
      label: "# Productos esperados",
      visible: true,
      type: "number",
      class: "",
      disabled: false,
    },
    {
      varId: "decrease_button",
      label: "Acción",
      visible: true,
      type: "decrease_button",
      class: "",
      disabled: false,
    },
    {
      varId: "sku",
      label: "SKU",
      visible: true,
      type: "",
      class: "",
      disabled: false,
    },
    {
      varId: "sku_full",
      label: "SKU_FULL",
      visible: true,
      type: "",
      class: "longCell",
      disabled: true,
    },
    {
      varId: "name",
      label: "Nombre",
      visible: true,
      type: "",
      class: "longCell noEllipsis",
      disabled: false,
    },
    {
      varId: "manufacturer",
      label: "Laboratorio",
      visible: true,
      type: "dd_manufact",
      class: "",
      disabled: false,
    },
    {
      varId: "brand",
      label: "Marca",
      visible: true,
      type: "dd_brand",
      class: "",
      disabled: false,
    },
    {
      varId: "type",
      label: "Tipo",
      visible: true,
      type: "",
      class: "",
      disabled: false,
    },
  ];

  dataSourceCount = 0;
  currPage = 1;
  currPageView = 100;
  currPageTotal = 0;

  maxDate: Date = new Date(Date.now());

  constructor(
    public _inv: InventoryService,
    public _gen: GeneralService,
    public aRoute: ActivatedRoute,
    public route: Router
  ) {}

  ngOnInit(): void {
    this.aRoute.params.subscribe(({ id }) => {
      this.countId = id;
    });
    const data = history.state;
    if (!data.name) this.route.navigate([`home/inventory/counts/`]);
    if (data) {
      this.countName = data.name;
      this.bodegaCountName = data.bodega;
    }
    this.getAllScannedProducts();
    this.getFiltersOpts();
    this._gen.currCountryObs.subscribe((country) => {
      this.route.navigate(["/home/inventory/counts/"]);
    });
  }

  getFiltersOpts() {
    var country = this._gen.currCountry.toString();
    this._inv.filterOpts_scannedProducts.country = country.split(",");

    this._gen.getCountry().then((data: any) => {
      this._gen.allCountries = data.data;
    });

    this._gen.getProdType().then(({ data }) => {
      this._gen.allProdTypes = data;
    });

    this._inv.getManufact().then((data: any) => {
      this._inv.filt_manufac = data.data;
    });
  }

  /* PAGE COUNT */
  getAllScannedProductsCountFilt(filt: any) {
    let prom = new Promise((resolve, reject) => {
      this._inv.getAllCountsCountById(this.countId, filt).then((data: any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil(
          this.dataSourceCount / this.currPageView
        );

        if (this.currPageTotal == 0) {
          this.currPageTotal = 1;
        }
        if (this.currPage > this.currPageTotal) {
          this.currPage = this.currPageTotal;
        }

        resolve(true);
      });
    });
    return prom;
  }

  getAllScannedProducts() {
    this._gen.tableLoader = true;
    this.getAllScannedProductsCountFilt(this.checkFilterVal()).then(() => {
      this._inv
        .getCountById(this.countId, this.checkFilterVal(), 100)
        .then(({ data }) => {
          this.resetTable(data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  changePageInp(page: any, input: boolean = false) {
    if ( input ) {
      this.currPage = parseInt( page.target.value );
    } else {
      this.currPage = parseInt( page );
    }
    this._gen.tableLoader = true;
    this.getAllScannedProductsCountFilt(this.checkFilterVal()).then(() => {
      this._inv
        .getCountById(this.countId, this.checkFilterVal(), this.currPageView, this.currPage)
        .then(({ data }) => {
          this.resetTable(data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  /* filters */
  checkFilterVal() {
    let filt_country, filt_type, filt_sku, filt_sku_full, filt_diff;

    filt_country = `country=${this._gen.currCountry}&`;

    if (this._inv.filterOpts_scannedProducts.sku) {
      filt_sku = `sku=${this._inv.filterOpts_scannedProducts.sku}&`;
    }

    if (this._inv.filterOpts_scannedProducts.sku_full) {
      filt_sku_full = `sku_full=${this._inv.filterOpts_scannedProducts.sku_full}&`;
    }

    if ( this._inv.filterOpts_scannedProducts.diff.length > 0 ) {
      filt_diff = `diff=${ this._inv.filterOpts_scannedProducts.diff }&`;
    }

    let filter = `${filt_country}${filt_type}${filt_sku}${filt_sku_full}${filt_diff}`;
    
    return filter.replace(/undefined/g, "");
  }

  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getAllScannedProductsCountFilt(this.checkFilterVal()).then(() => {
      this._inv
        .getCountById(this.countId, this.checkFilterVal(), 100)
        .then(({ data }) => {
          this.resetTable(data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    }).catch( (err:any) => {
        console.log( err );
      })
  }

  /* RESET Filters */
  resetFilters_item(item: string) {
    switch (item) {
      case "product_type":
        this._inv.filterOpts_scannedProducts.product_type = [];
        break;
      case "sku":
        this._inv.filterOpts_scannedProducts.sku = "";
        break;
      case "sku_full":
        this._inv.filterOpts_scannedProducts.sku_full = "";
        break;
      case "ean":
        this._inv.filterOpts_scannedProducts.ean = "";
        break;
      case "diff":
        this._inv.filterOpts_scannedProducts.diff = "";
        break;
      default:
        this._inv.filterOpts_scannedProducts = {
          product_type: [],
          sku: "",
          sku_full: "",
          manufacturer: [],
          ean: "",
          diff: "",
        };
        break;
    }
    this.checkFilter();
  }

  /* RESET */
  resetTobTable(type: string) {
    setTimeout(() => {
      if (type == "colsView") {
        this.filtsView = false;
        if (this.colsView) {
          this.colsView = false;
        } else {
          this.colsView = true;
        }
      } else if (type == "filtsView") {
        this.colsView = false;
        if (this.filtsView) {
          this.filtsView = false;
        } else {
          this.filtsView = true;
        }
      }
    }, 150);
  }

  resetTable(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];
      
      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element: any = this.dataSourcefull[d];
        let item = {
          name: element.sku_full.product.name,
          sku: element.sku_full.sku,
          sku_full: element.sku_full.sku_full,
          ean: element.sku_full.ean,
          brand: element.sku_full.product.brand?.name,
          manufacturer: element.sku_full.product.brand?.manufacturer.name,
          type: element.sku_full.product.type?.name,
          scanned_products: element.scanned_products,
          expected_products: element.expected_products,
          product_count_id: element.product_count_id,
        };
        this.dataSource.push(item);
      }

      resolve(true);
    });

    return prom;
  }

  onReloadPage() {
    this.getAllScannedProducts();
  }

  backToCounts() {
    this.route.navigate(["/home/inventory/counts/"]);
  }
}
