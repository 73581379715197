import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { GeneralService } from '../services/general.service';

@Directive({
  selector: '[appRole]'
})
export class RoleDirective {
  
  private permissions:any;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public _gen: GeneralService,
  ) { }

  ngOnInit():void {
    
    this._gen.currentUserSubjectPermissionsObs.subscribe((permissions) => {
      this.updateView();
    });
  }

  @Input()
  set appRole(val: Array<string>) {
    this.permissions = val;
    this.updateView();
  }

  private updateView():void {
    this.viewContainer.clear();
    if(this._gen.isSuperAdmin || this.checkPermission()){
      this.viewContainer.createEmbeddedView(this.templateRef)
    }
  }

  private checkPermission(): boolean {
    let hasPermission = false;

    for( const checkPermission of this.permissions) {
      const permissionFound = this._gen.currUserPermissions.find((p:any) => {
        return (p.toUpperCase() === checkPermission.toUpperCase())
      })

      if(permissionFound) {
        hasPermission = true;
        break;
      }

    }

    return hasPermission;
  }

}
