import { Component } from '@angular/core';
import { GeneralService } from './services/general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {
  title = 'wmsFront';

  constructor( public _gen:GeneralService ) {
    // this.debug();
  }


  debug() {
    setTimeout(() => {
      console.log( this._gen.serverTkn );
    }, 3000);
  }
}
