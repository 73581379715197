import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GeneralService } from "src/app/services/general.service";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-create-count",
  templateUrl: "./create-count.component.html",
})
export class CreateCountComponent implements OnInit {
  @Output() reloadTable = new EventEmitter();

  createCountForm: FormGroup = new FormGroup({
    nameField: new FormControl("", [Validators.required]),
    typeField: new FormControl("", [Validators.required]),
    bodegaField: new FormControl("", [Validators.required]),
  });

  listBodegas: any = [];
  dataLoader: boolean = false;
  createCountId: any = 0;

  constructor(public _inv: InventoryService, public _gen: GeneralService) {}

  ngOnInit(): void {
    this.getAllBodegas();
  }

  getAllBodegas() {
    this.dataLoader = true;
    this._inv
      .getAllBodega(this._gen.currCountry)
      .then(({ data }) => {
        this.listBodegas = data;
        this.dataLoader = false;
      })
      .catch((error) => {
        console.error(error);
        this.dataLoader = false;
      });
  }

  closeModal() {
    this._inv.createCountView = false;
  }

  get nameField() {
    return this.createCountForm.get("nameField");
  }

  get typeField() {
    return this.createCountForm.get("typeField");
  }

  get bodegaField() {
    return this.createCountForm.get("bodegaField");
  }

  save(event: any) {
    const data: any = {
      provider_id: this.bodegaField?.value,
      name: this.nameField?.value,
      type: this.typeField?.value,
    };
    this.dataLoader = true;
    this._inv
      .createCount(data)
      .then((data) => {
        this.dataLoader = false;
        this.createCountId = data.data.ids;
        this._gen.barCodeCountView = true;
        // this.createCountForm.reset();
        // this.resetForm();
      })
      .catch((error) => {
        this.dataLoader = false;
        console.log({ error });
      });
  }

  resetForm() {
    this.createCountId = 0;
    this.bodegaField?.setValue('');
    this.nameField?.setValue("");
    this.typeField?.setValue("");
    this.listBodegas = [];
  }

  onReloadTable(){
    this.reloadTable.emit()
  }
}
