<div class="assignProviderOverLay">
    <div class="assignProviderBox">
        <div class="loaderInfo" *ngIf="_gen.generalLoader">
            <div class="icon">
                <img src="assets/img/preload.gif" alt="">
            </div>
        </div>
        <button class="closeBtn" (click)="closeModal();" [disabled]="showLoader">
            <span></span>
        </button>

        <div class="infoWrap">
            <div class="title">
                Asignar Proveedor: 
                <br/>
                {{skuFull}}
            </div>

            <form [formGroup]="inputs" class="formStatusContainer" (ngSubmit)="save($event)">
                <div class="containerProviderAndPriority">
                    <div class="form_field form_field_assign_provider">
                        <mat-form-field appearance="outline">
                        <mat-label>Proveedor</mat-label>
                        <mat-select formControlName="provider">
                            <mat-option disabled value="null">Proveedor</mat-option>
                            <mat-option *ngFor="let opt of listProviders" value={{opt.provider_id}}>{{opt.name}}</mat-option>
                        </mat-select>
                        </mat-form-field>
                        <p class="error_message" [class.active]="providerField?.touched && providerField?.invalid">
                            <strong *ngIf="providerField?.touched && providerField?.hasError('required')">
                                El proveedor es requerido
                            </strong>
                        </p>
                    </div>
                    <div class="form_field form_field_assign_provider">
                        <div class="field_number">
                            <label for="">Prioridad:</label>
                            <input class="field_number_input" type="text" formControlName="priority">
                        </div>
                        <p class="error_message error_message_priority" [class.active]="priorityField?.touched && priorityField?.invalid">
                            <strong *ngIf="priorityField?.touched && priorityField?.hasError('pattern')">
                                Sólo debe contener números
                            </strong>
                            <strong *ngIf="priorityField?.touched && priorityField?.hasError('required')">
                                La prioridad es requerida
                            </strong>
                            <strong *ngIf="priorityField?.touched && priorityField?.hasError('priority')">
                                Ya existe un proveedor asignado con esta prioridad
                            </strong>
                        </p>
                    </div>
                    <div class="form_field form_field_assign_provider">
                        <div class="field_number">
                            <label for="">SAP:</label>
                            <input class="field_number_input input_sap" type="text" formControlName="sap">
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <button class="btn" type="button" (click)="closeModal();">
                        cerrar
                    </button>
                    <button class="btn btn_success" type="submit">
                        Confirmar
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>