import { Pipe, PipeTransform } from '@angular/core';
import { CatalogService } from '../services/catalog.service';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'idBrand'
})
export class IdBrandPipe implements PipeTransform {

  private val:string = "";

  constructor( public _cat:CatalogService, public _inv:InventoryService ) {
  }

  transform(value:string): any {    
    if ( this._inv.filt_brand ) {
      for (let f = 0; f < this._inv.filt_brand.length; f++) {
        const element = this._inv.filt_brand[f];       
        if ( element.brand_id.toString() == value.toString() ) {
          this.val = element.name
        }
      }
    } 

    if(this._cat.filt_brand) {
      for (let f = 0; f < this._cat.filt_brand.length; f++) {
        const element = this._cat.filt_brand[f];
        if ( element.brand_id == value ) {
          this.val = element.name
        }
      }
    }

    return this.val
  }

}
