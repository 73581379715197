import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { LoginService } from "src/app/services/login.service";
import { OrdersService } from "src/app/services/orders.service";
@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styles: [],
})
export class SideBarComponent implements OnInit {
  countryView: boolean = false;
  countryList: any[] = [];
  currCountrySelInfo: any = {};

  cat_menu: boolean = false;
  inv_menu: boolean = false;
  ord_menu: boolean = false;

  fac_menu:boolean = false;
  prv_menu:boolean = false;

  constructor(
    public _log: LoginService,
    public _gen: GeneralService,
    private route: Router,
    public _ord: OrdersService
  ) {}

  ngOnInit(): void {
    this.getCountries();
    this._log.getLocalUserName();
    if(Array.isArray(this._gen.currUserPermissions) && this._gen.currUserPermissions.length === 0) {
      this.getCurrUserPermissions()
    }
    this._gen.currCountryObs.subscribe((country) => {
      this.getCountries();
      this._log.getLocalUserName();
    });
  }

  logOut() {
    this._gen.serverTkn = "";
    if (localStorage.getItem("currentCountry")) {
      localStorage.removeItem("currentCountry");
    }
    this.route.navigate(["/"]);
  }

  accorClick(type: string) {
    switch (type) {
      case "inv":
        this.cat_menu = false;
        this.inv_menu = !this.inv_menu;
        this.ord_menu = false;
        this.fac_menu = false;
        break;

      case "ord":
        this.cat_menu = false;
        this.inv_menu = false;
        this.ord_menu = !this.ord_menu;
        this.fac_menu = false;
        break;
      case "cat":
        this.cat_menu = !this.cat_menu;
        this.inv_menu = false;
        this.ord_menu = false;
        this.fac_menu = false;
        break;
      
      case "fac":
        this.cat_menu = false;
        this.inv_menu = false;
        this.ord_menu = false;
        this.fac_menu = !this.fac_menu;
        break;

      default:
        this.inv_menu = false;
        this.ord_menu = false;
        this.cat_menu = false;
        this.fac_menu = false;
        break;
    }
  }

  scrollToLast() {
    var element = document.querySelector(".customScrollBar");
    if (!this.ord_menu){
      this.scrollToFn(element, 200);
    } else if( !this.fac_menu){
      this.scrollToFn(element, 350);
    } else {
      this.scrollToFn(element, 0);
    }
  }

  scrollToFn(element: any, top:number){
    setTimeout(() => {
      element?.scrollTo({
        left: 0,
        top,
        behavior: "smooth",
      });
    }, 250);
  }

  getCountries() {
    setTimeout(() => {
      var usrData = {
        name: this._gen.currUser,
        admin: this._gen.userAdmin,
        country_id: this._gen.currCountry,
      };
      if (localStorage.getItem("currentCountry")) {
        let dat: any = JSON.parse(localStorage.getItem("currentCountry")!);
        this._gen.currCountry = dat;
      }

      // console.table( usrData );
      if (this._gen.userAdmin) {
        this._gen.getCountry().then(({ data }) => {
          this.countryList = data;
          this.getSelectedCountry(this._gen.currCountry);
        });
      } else {
        this._gen.getCountry().then(({ data }) => {
          var result = data.filter((cntr: any) => {
            return cntr.country_id === this._gen.currCountry;
          });
          this.countryList = result;
          this.getSelectedCountry(this._gen.currCountry);
        });
      }
    }, 500);
  }

  getSelectedCountry(id: number) {
    let arr: any[] = [];
    let idx;

    for (let h = 0; h < this.countryList.length; h++) {
      const element = this.countryList[h];
      arr.push(element.country_id);
    }
    idx = arr.indexOf(id);
    this.currCountrySelInfo = this.countryList[idx];
  }
  selCountry(idx: number) {
    this.countryView = !this.countryView;
    this._gen.currCountry = idx;
    localStorage.setItem(
      "currentCountry",
      JSON.stringify(this._gen.currCountry)
    );

    this._gen._currCountryChange.next(idx);
    this.getSelectedCountry(this._gen.currCountry);
  }

  cleanInventoryForm(){
    this._ord._cleanInvetoryForm.next(true);
  }

  getCurrUserPermissions() {
    if ( localStorage.getItem('userData') ) {
      let dat:any = JSON.parse( localStorage.getItem('userData')! );
      this._gen.currUserPermissions =  dat.permissions;
      this._gen.isSuperAdmin = dat.isSuperAdmin;
      this._gen.currentUserSubjectPermissions.next(this._gen.currUserPermissions);
    } else {
      this.logOut();
    }
  }
}
