<div class="createCountOverLay">
  <div class="createCountBox animate__animated animate__zoomIn">
    <div class="loaderInfo" *ngIf="dataLoader">
      <div class="icon">
        <img src="assets/img/preload.gif" alt="" />
      </div>
    </div>
    <button class="closeBtn" (click)="closeModal()">
      <span></span>
    </button>

    <div class="createCountInfo">
      <div class="ttl">Crear Conteo</div>

      <form [formGroup]="createCountForm" class="formCreateCountContainer">
        <div class="form_fields_container">
          <div>
            <div class="form_field">
              <mat-form-field appearance="outline" class="form_field_input">
                <mat-label>Nombre</mat-label>
                <input
                  matInput
                  placeholder="Nombre"
                  formControlName="nameField"
                  name="name"
                />
              </mat-form-field>
            </div>
            <p
              class="error_message date_error_message"
              [class.active]="nameField?.touched && nameField?.invalid"
            >
              <strong
                *ngIf="nameField?.touched && nameField?.hasError('required')"
              >
                El nombre es requerido
              </strong>
            </p>
          </div>
          <div>
            <div class="form_field">
              <mat-form-field appearance="outline" class="form_field_input">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="typeField">
                  <mat-option disabled value="null">Tipo</mat-option>
                  <mat-option
                    *ngFor="let opt of _inv.count_listTypes"
                    value="{{ opt.value }}"
                    >{{ opt.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <p
              class="error_message date_error_message"
              [class.active]="typeField?.touched && typeField?.invalid"
            >
              <strong
                *ngIf="typeField?.touched && typeField?.hasError('required')"
              >
                El tipo es requerido
              </strong>
            </p>
          </div>
          <div>
            <div class="form_field">
              <mat-form-field appearance="outline" class="form_field_input">
                <mat-label>Bodega</mat-label>
                <mat-select formControlName="bodegaField">
                  <mat-option disabled value="null">Bodega</mat-option>
                  <mat-option
                    *ngFor="let opt of listBodegas"
                    value="{{ opt.provider_id }}"
                    >{{ opt.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <p
              class="error_message date_error_message"
              [class.active]="bodegaField?.touched && bodegaField?.invalid"
            >
              <strong
                *ngIf="
                  bodegaField?.touched && bodegaField?.hasError('required')
                "
              >
                La bodega es requerida
              </strong>
            </p>
          </div>
        </div>
      </form>
      <div class="buttons">
        <button class="btn" type="button" (click)="closeModal()">Cerrar</button>
        <button
          class="btn btn_success"
          type="button"
          [disabled]="createCountForm.invalid"
          (click)="save($event)"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>

<app-read-code  
  [type]="'count'" 
  *ngIf="_gen.barCodeCountView"
  [countData]="createCountForm.value"
  [createCountId]="createCountId"
  (reloadTable)="onReloadTable()"
></app-read-code>