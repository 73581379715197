
<div class="topttls">
    <app-tittle [label]="'Pedidos'" [icon]="'48px_box-3d-50'"></app-tittle>

    <div class="btns">
        &nbsp;

        <div class="totalProds">
            <div><span>Total registros:</span> <b>{{ dataSourceCount }}</b></div>
        </div>
    </div>
</div>

<div class="topTableWrap">
    <div class="left">
        <!-- <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_ped.sel_pedidos.length == 1 && !_ped.editView" (click)="editBtnFx()">
            <i [class]="'48px_edit' | icons"></i>
            <span>Editar</span>
        </button> -->
        <!-- <button class="btn editBtn btn_success btn_fill animate__animated animate__fadeIn" *ngIf="_ped.sel_pedidos.length == 1 && _ped.editView" (click)="saveBtnFx()">
            <i [class]="'48px_floppy-disk' | icons"></i>
            <span>Guardar</span>
        </button>
        <button class="btn editBtn btn_error btn_fill animate__animated animate__fadeIn" *ngIf="_ped.sel_pedidos.length == 1 && _ped.editView" (click)="cancelBtnFx()">
            <i [class]="'48px_e-remove' | icons"></i>
            <span>Cancelar</span>
        </button> -->

        <!-- <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_ped.sel_pedidos.length >= 1 && !_ped.editView" >
            <i [class]="'48px_trash-can' | icons"></i>
            <span>Eliminar</span>
        </button> -->
    </div>

    <div class="right">

        <button class="btn editBtn" *ngIf="_ped.sel_pedidos.length == 1 && !_ped.editView" (click)="viewDetail( _ped.sel_pedidos[0]?.sale_id )">
            <i [class]="'48px_arrow-up' | icons"></i>
            <span>detalle</span>
        </button>

        <div class="filterView">
            <button class="btn editBtn" (click)="resetTobTable('filtsView')" [disabled]="_ped.editView">
                <i [class]="'48px_filter' | icons"></i>
                <span>Filtrar</span>
            </button>
            <div class="filterItems animate__animated animate__fadeInRight " *ngIf="filtsView">
                <div class="closeFilt" (click)="filtsView = false">
                    <button>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </button>
                </div>
                <div class="filtTtl">
                    <h3>Filtros</h3>
                </div>
                <div class="filtActive customScrollBar">
                    <div class="filtActItm" *ngIf="_ped.filterOpts.country.length >= 1">
                        <span><b>País: </b> <i *ngFor="let country of _ped.filterOpts.country; index as i"> <i *ngIf="i >= 1"> - </i> {{country | idCountry}}</i> </span>
                        <!-- <i [class]="'48px_e-remove' | icons"></i> -->
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.business_unit.length >= 1" (click)="resetFilters_item('business_unit')">
                        <span><b>U. Negocio: </b> <i *ngFor="let b_unit of _ped.filterOpts.business_unit; index as i"> <i *ngIf="i >= 1"> - </i> {{b_unit | idBunit}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.status.length >= 1" (click)="resetFilters_item('status')">
                        <span><b>Estado: </b> <i *ngFor="let status of _ped.filterOpts.status; index as i"> <i *ngIf="i >= 1"> - </i> {{status | idStatus}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.carrier.length >= 1" (click)="resetFilters_item('carrier')">
                        <span><b>O. Logístico: </b> <i *ngFor="let carrier of _ped.filterOpts.carrier; index as i"> <i *ngIf="i >= 1"> - </i> {{carrier}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.provider_id.length >= 1" (click)="resetFilters_item('provider_id')">
                        <span><b>Proveedor: </b> <i *ngFor="let prov of _ped.filterOpts.provider_id; index as i"> <i *ngIf="i >= 1"> - </i>{{prov | idProvider}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.reference.length >= 1" (click)="resetFilters_item('reference')">
                        <span><b># de Orden: </b> <i *ngFor="let ord of _ped.filterOpts.reference; index as i"> <i *ngIf="i >= 1"> - </i>{{ord}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.providers_complete" (click)="resetFilters_item('providers_complete')">
                        <span><b>proveedores: </b> <i *ngIf="_ped.filterOpts.providers_complete == '1'">Completos</i> <i *ngIf="_ped.filterOpts.providers_complete == '0'">Parciales</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.from" (click)="resetFilters_item('from')">
                        <span><b>Desde: </b> <i> {{ _gen.getDateFormat( _ped.filterOpts.from ) }} </i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.to" (click)="resetFilters_item('to')">
                        <span><b>Hasta: </b> <i> {{ _gen.getDateFormat( _ped.filterOpts.to ) }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.confirmed_at_from" (click)="resetFilters_item('confirmed_at_from')">
                        <span><b>Fecha confirmación Desde: </b> <i> {{ _gen.getDateFormat( _ped.filterOpts.confirmed_at_from ) }} </i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.confirmed_at_to" (click)="resetFilters_item('confirmed_at_to')">
                        <span><b>Fecha confirmación Hasta: </b> <i> {{ _gen.getDateFormat( _ped.filterOpts.confirmed_at_to ) }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.prepared_at_from" (click)="resetFilters_item('prepared_at_from')">
                        <span><b>Fecha preparación Desde: </b> <i> {{ _gen.getDateFormat( _ped.filterOpts.prepared_at_from ) }} </i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.prepared_at_to" (click)="resetFilters_item('prepared_at_to')">
                        <span><b>Fecha preparación Hasta: </b> <i> {{ _gen.getDateFormat( _ped.filterOpts.prepared_at_to ) }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.sku_full" (click)="resetFilters_item('sku_full')">
                        <span><b>SKU FULL: </b>{{_ped.filterOpts.sku_full}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.sku" (click)="resetFilters_item('sku')">
                        <span><b>SKU: </b>{{_ped.filterOpts.sku}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.prod" (click)="resetFilters_item('prod')">
                        <span><b>Producto: </b>{{_ped.filterOpts.prod}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.cupon" (click)="resetFilters_item('cupon')">
                        <span><b>Cupón: </b>{{_ped.filterOpts.cupon}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.order_number" (click)="resetFilters_item('order_number')">
                        <span><b># de pedido: </b>{{_ped.filterOpts.order_number}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.tracking_number" (click)="resetFilters_item('tracking_number')">
                        <span><b># de guía: </b>{{_ped.filterOpts.tracking_number}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.city" (click)="resetFilters_item('city')">
                        <span><b>Ciudad </b>{{_ped.filterOpts.city}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.region" (click)="resetFilters_item('region')">
                        <span><b>Region </b>{{_ped.filterOpts.region}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ped.filterOpts.postcode" (click)="resetFilters_item('postcode')">
                        <span><b>Código postal </b>{{_ped.filterOpts.postcode}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                </div>
                <div class="filtList customScrollBar">
                    <div class="filItm filItm_hide">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <mat-select [(ngModel)]="_ped.filterOpts.country" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _gen.allCountries" value="{{opt.country_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>U. negocio</mat-label>
                                <mat-select [(ngModel)]="_ped.filterOpts.business_unit" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _ped.filt_bUnit" value="{{opt.business_unit_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Estado</mat-label>
                                <mat-select [(ngModel)]="_ped.filterOpts.status" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _ped.filt_status" value="{{opt.name}}">{{opt.pretty}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>O. Logístico:</mat-label>
                                <mat-select [(ngModel)]="_ped.filterOpts.carrier" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _gen.allCarriers" value="{{opt.name}}">{{opt.pretty}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Proveedor</mat-label>
                                <mat-select [(ngModel)]="_ped.filterOpts.provider_id" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _ped.filt_provider" value="{{opt.provider_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label># de pedido</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.order_number" name="order_number" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Ciudad</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.city" name="city" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Region</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.region" name="region" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Código postal</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.postcode" name="postcode" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label># de Orden de compra</mat-label>
                                <mat-select [(ngModel)]="_ped.filterOpts.reference" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _ped.filt_reference" value="{{opt}}">{{opt}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm filItm_toogle">
                        <div class="labl">proveedores completos/Parciales</div>
                        <div class="form_field">
                            <mat-button-toggle-group [(ngModel)]="_ped.filterOpts.providers_complete" name="providers_complete" (keydown.enter)="checkFilter()">
                                <mat-button-toggle value="1">Completa</mat-button-toggle>
                                <mat-button-toggle value="0">Parcial</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>    
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp3.open()">
                                <mat-label>Desde</mat-label>
                                <input matInput [matDatepicker]="dp3" [max]="maxDate" [(ngModel)]="_ped.filterOpts.from" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp4.open()">
                                <mat-label>Hasta</mat-label>
                                <input matInput [matDatepicker]="dp4" [max]="maxDate" [(ngModel)]="_ped.filterOpts.to" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                                <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp5.open()">
                                <mat-label>Fecha confirmación Desde</mat-label>
                                <input matInput [matDatepicker]="dp5" [max]="maxDate" [(ngModel)]="_ped.filterOpts.confirmed_at_from" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp5"></mat-datepicker-toggle>
                                <mat-datepicker #dp5 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp6.open()">
                                <mat-label>Fecha confirmación Hasta</mat-label>
                                <input matInput [matDatepicker]="dp6" [max]="maxDate" [(ngModel)]="_ped.filterOpts.confirmed_at_to" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp6"></mat-datepicker-toggle>
                                <mat-datepicker #dp6 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp7.open()">
                                <mat-label>Fecha preparación Desde</mat-label>
                                <input matInput [matDatepicker]="dp7" [max]="maxDate" [(ngModel)]="_ped.filterOpts.prepared_at_from" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp7"></mat-datepicker-toggle>
                                <mat-datepicker #dp7 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp8.open()">
                                <mat-label>Fecha preparación Hasta</mat-label>
                                <input matInput [matDatepicker]="dp8" [max]="maxDate" [(ngModel)]="_ped.filterOpts.prepared_at_to" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp8"></mat-datepicker-toggle>
                                <mat-datepicker #dp8 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>SKU FULL</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.sku_full" name="sku_full" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>SKU</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.sku" name="sku" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Producto</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.prod" name="prod" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Cupón</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.cupon" name="cupon" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label># de guía</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ped.filterOpts.tracking_number" name="tracking_number" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                </div>
                
                <div class="filtSend">
                    <button class="btn" (click)="resetFilters_item('')">
                        Borrar filtros
                    </button>
                    <button class="btn btn_success btn_fill" (click)="checkFilter()">
                        Aplicar filtros
                    </button>
                </div>
            </div>
        </div>

        <div class="colsView">
            <button class="btn editBtn" (click)="resetTobTable('colsView')" [disabled]="_ped.editView">
                <i [class]="'48px_separate-2' | icons"></i>
                <span>Columnas</span>
            </button>
            <div class="columnsItems animate__animated animate__fadeInUp " *ngIf="colsView" appClickOutside (clickOutside)="colsView = false">
                <div class="colItem" *ngFor="let col of columnsConfig; index as i">
                    <div class="ios_checkBox">
                        <input type='checkbox' id='checkbox-{{i}}' [checked]="col.visible" (change)="col.visible = !col.visible">
                        <label for='checkbox-{{i}}'>{{col.label}}</label>
                    </div>
                </div>
            </div>
        </div>

        <mat-form-field appearance="outline" class="actions_wrapper">
            <mat-label>Acciones</mat-label>
            <mat-select>
                <mat-option value="Procesar pedidos" [disabled]="_ped.sel_pedidos.length <= 0">
                    <button class="actions_btn" [disabled]="_ped.sel_pedidos.length <= 0" (click)="processped()">
                        <span>Procesar pedidos</span>
                    </button>
                </mat-option>
                <mat-option value="Imprimir Alistamiento Pedidos">
                    <button class="actions_btn" (click)="printpeds()">
                        <span>Imprimir Alistamiento Pedidos</span>
                    </button>
                </mat-option>
                <mat-option value="Escanear productos">
                    <button class="actions_btn" (click)="_gen.barCodeEntryViewPeds = true">
                        Escanear productos
                    </button>
                </mat-option>
                <!-- <mat-option value="Generar envío" [disabled]="_ped.sel_pedidos.length <= 0">
                    <button class="actions_btn" (click)="shipped()" [disabled]="_ped.sel_pedidos.length <= 0">
                        <span>Generar envío</span>
                    </button>
                </mat-option> -->
            </mat-select>
        </mat-form-field>

    </div>
</div>

<app-table [cols]="columnsConfig" [data]="dataSource" [type]="tableType" [uniqueID]="_ped.editId"></app-table>

<div class="bottomTable">
    <div class="pageInp">
        <div class="form_field">
            <input type="text" name="" id="" value="{{currPage}}" (change)="changePageInp( $event, true )">
        </div>
        <span>de {{currPageTotal}}</span>
    </div>
    
    <div class="pagerWrap">
        <div class="pagerItms">
            <button class="btn btn_sm prev" [disabled]="currPage == 1" (click)="changePageInp( currPage - 1 )"> <i [class]="'48px_left-arrow' | icons"></i> </button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 2 )" [disabled]="currPage <= 2">{{currPage - 2}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 1 )" [disabled]="currPage == 1">{{currPage - 1}}</button>
            <button class="btn btn_sm page active">{{currPage}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 1 )" [disabled]="currPage == currPageTotal" >{{currPage + 1}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 2 )" [disabled]="currPage >= currPageTotal - 1" >{{currPage + 2}}</button>
            <button class="btn btn_sm page" disabled>...</button>
            <button class="btn btn_sm next" (click)="changePageInp( currPageTotal )" [disabled]="currPage == currPageTotal" >{{currPageTotal}}</button>
            <button class="btn btn_sm page last" [disabled]="currPage == currPageTotal" (click)="changePageInp( currPage + 1 )"> <i [class]="'48px_right-arrow' | icons"></i> </button>
        </div>
    </div>

    <div class="perPage">
        <div class="form_field">
            <div class="select_box">
                <select name="" id="" [(ngModel)]="currPageView" (change)=" getAllProds() ">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
                <i [class]="'48px_down-arrow' | icons"></i>
            </div>
        </div>
        <span>por página</span>
    </div>
</div>

<app-ped-detail detailId="{{_ped.detailId}}" *ngIf="_ped.detailView"></app-ped-detail>

<app-process-peds *ngIf="_ped.processView" [list]="processSel"></app-process-peds>

<app-ship-peds *ngIf="_ped.shipView" [list]="shipSel"></app-ship-peds>

<app-read-code  [type]="'ped_salida'" *ngIf="_gen.barCodeEntryViewPeds" variant="massive"></app-read-code>