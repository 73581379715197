<div class="topttls">
  <app-tittle
    [label]="'Entrada de Inventario'"
    [icon]="'48px_box-3d-50'"
  ></app-tittle>
</div>

<div class="inventoryEntryWrapper">
  <div class="loaderInfo" *ngIf="getShowLoaderView()">
    <div class="icon">
      <img src="assets/img/preload.gif" alt="" />
    </div>
  </div>

  <div class="loaderGeneral" *ngIf="loaderOpenOrdersById || loaderReception || loaderElectronicInvoicesByProvider">
    <div class="icon">
      <img src="assets/img/preload.gif" alt="" />
    </div>
  </div>

  <div class="inventoryEntryWrap">
    <div class="topInfo">
      <div class="ttlBox">
        <div class="ttl">Ingreso O.C.</div>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="save($event)" #formDirective="ngForm">
      <div class="inventoryEntryWrapInputs">
        <div class="form_field">
          <label for="">Tipo de entrada*</label>

          <div class="select_box">
            <select 
              formControlName="entry"
              (change)="validateBodegasToShow($event)"
            >
              <option value="" selected [disabled]="disabledTotal">
                Seleccionar tipo
              </option>
              <option
                [disabled]="disabledTotal"
                *ngFor="let itm of ocListReason"
                value="{{ itm.reason_id }}"
              >
                {{ itm.name }}
              </option>
            </select>
            <i [class]="'48px_down-arrow' | icons"></i>
          </div>
          <p
            class="error_message"
            [class.active]="entryField?.touched && entryField?.invalid"
          >
            <strong
              *ngIf="entryField?.touched && entryField?.hasError('required')"
            >
              El tipo de entrada es requerido
            </strong>
          </p>
        </div>
        <div class="form_field">
          <label for="">Número Factura</label>
          <input
            [disabled]="disabledTotal || disableInvoice"
            [readOnly]="disabledTotal || disableInvoice"
            type="text"
            formControlName="invoice_number"
            (blur)="getDataByInvoiceNumber()"
          />
          <p
            class="error_message"
            [class.active]="
              invoiceNumberField?.touched && invoiceNumberField?.invalid
            "
          >
            <strong
              *ngIf="
                invoiceNumberField?.touched &&
                invoiceNumberField?.hasError('required')
              "
            >
              El número de factura es requerido
            </strong>
          </p>
        </div>
        <div class="form_field">
          <label for="">Valor Factura</label>
          <input type="text" formControlName="invoice_value" />
          <p
            class="error_message"
            [class.active]="
              invoiceValueField?.touched && invoiceValueField?.invalid
            "
          >
            <strong
              *ngIf="
                invoiceValueField?.touched &&
                invoiceValueField?.hasError('pattern')
              "
            >
              El número ingresado es inválido
            </strong>
          </p>
        </div>
        <div class="form_field">
          <label for="">Referencia Proveedor*</label>
          <input
            [disabled]="disabledTotal"
            [readOnly]="disabledTotal"
            type="text"
            formControlName="reference"
            (blur)="getDataByReference()"
          />
          <p
            class="error_message"
            [class.active]="referenceField?.touched && referenceField?.invalid"
          >
            <strong
              *ngIf="
                referenceField?.touched && referenceField?.hasError('required')
              "
            >
              El número de referencia es requerido
            </strong>
          </p>
        </div>
        <div class="form_field">
          <label for="">Proveedor*</label>

          <div class="select_box">
            <select
              formControlName="provider"
              (change)="getDataOpenOrdsByProvider()"
              [disabled]="disabledTotal"
            >
              <option value="" selected>Seleccionar tipo</option>
              <option
                *ngFor="let itm of ocListProviders"
                value="{{ itm.provider_id }}"
                [disabled]="disabledTotal"
              >
                {{ itm.name }}
              </option>
            </select>
            <i [class]="'48px_down-arrow' | icons"></i>
          </div>
          <p
            class="error_message"
            [class.active]="providerField?.touched && providerField?.invalid"
          >
            <strong
              *ngIf="
                providerField?.touched && providerField?.hasError('required')
              "
            >
              El proveedor es requerido
            </strong>
          </p>
        </div>
        <div class="form_field">
          <label for="">Orden Interna</label>
          <div class="select_box">
            <select
              formControlName="orderId"
              (change)="getDataByInvoiceNumber(); getOrdsProvider($event); getDataElectronicInvoices()"
              [disabled]="disabledTotal"
            >
              <option
                value=""
                [disabled]="loaderOpenOrdersById || loaderElectronicInvoicesByProvider || disabledTotal"
                selected
              >
                Seleccionar orden
              </option>
              <option
                [disabled]="loaderOpenOrdersById || loaderElectronicInvoicesByProvider || disabledTotal"
                *ngFor="let itm of ocListOpenOrders"
                value="{{ itm.order_id }}"
              >
                {{ itm.reference }}
              </option>
            </select>
            <i [class]="'48px_down-arrow' | icons"></i>
          </div>
          <p
            class="error_message"
            [class.active]="orderField?.touched && orderField?.invalid"
          >
            <strong
              *ngIf="orderField?.touched && orderField?.hasError('required')"
            >
              La Orden Interna es requerida
            </strong>
          </p>
        </div>
      </div>
      <div class="inventoryEntryWrapCommentsAndButtons">
        <div class="form_field">
          <label for="">Bodega de Ingreso*</label>

          <div class="select_box">
            <select formControlName="bodega" [disabled]="disabledTotal">
              <option value="" selected [disabled]="disabledTotal">
                Seleccionar tipo
              </option>
              <option
                [disabled]="disabledTotal"
                *ngFor="let itm of ocListBodegas"
                value="{{ itm.provider_id }}"
              >
                {{ itm.name }}
              </option>
            </select>
            <i [class]="'48px_down-arrow' | icons"></i>
          </div>
          <p
            class="error_message"
            [class.active]="bodegaField?.touched && bodegaField?.invalid"
          >
            <strong
              *ngIf="bodegaField?.touched && bodegaField?.hasError('required')"
            >
              La bodega es requerida
            </strong>
          </p>
        </div>
        <div class="form_field">
          <label for="">Factura Electrónica</label>

          <div class="select_box">
            <select formControlName="electronic_invoice" (change)="electronicInvoiceChange()">
              <option value="" selected>
                Seleccionar Factura
              </option>
              <option
                *ngFor="let itm of electronicInvoices"
                value="{{ itm.id }}"
              >
                {{ itm.consecutive }}
              </option>
            </select>
            <i [class]="'48px_down-arrow' | icons"></i>
          </div>
        </div>
        <div class="form_field">
          <label for="">Subir Archivo</label>
          <label class="btn_uploadFile" [for]="'invoice_file'">Adjuntar</label>
          <input
            type="file"
            [id]="'invoice_file'"
            multiple
            (change)="onChangeFileInput($event)"
            class="input_uploadFile"
            accept=".png, .jpg, .jpeg, .pdf"
          />
          <div
            id="file_names"
            class="file_names"
          >
            <!-- Mostrar los archivos ya subidos -->
            <div *ngFor="let fileName of uploadedFileNames">{{ fileName }}</div>
          </div>
        </div>
      </div>
      <div class="commentsContainer">
        <div class="form_field comments">
            <label for="">Comentario</label>
            <textarea cols="33" rows="10" formControlName="comment"></textarea>
        </div>
      </div>
      <div class="buttonContainer">
        <button class="btn btn_success" type="submit">Continuar</button>
      </div>
    </form>
  </div>
</div>

<app-read-code
  [type]="'inventory_entry'"
  *ngIf="_gen.barCodeEntryViewPeds"
  [reference]="reference"
  [invoiceNumber]="invoiceNumber"
  [orderId]="orderId"
></app-read-code>
