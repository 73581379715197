import { Injectable } from '@angular/core';
import axios from 'axios';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  sel_provider:any[] = [];
  editId:string = "";
  editView:boolean = false;

  filt_provider_type:any[] = [
    {value: "inventario", label: "Inventario"},
    {value: "otro", label: "Otro"},
  ]

  filterOpts_providers: any = {
    country: [],
    provider_type: "",
    natial_tax_ID: "",
    contact_email: "",
    name: ""
  };

  originalSelectedItem: any = null;

  constructor(
    public _gen:GeneralService
  ) { }

  // Count
  getAllProvidersCountFilt( filters:any) {
    return axios.get(`${this._gen.serverURL}/invoicing/provider/?${filters}count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  // Electronic Invoices
  getAllProvidersFilter( filters:any, limit:any = 100, page:number = 1) {
    return axios.get(`${this._gen.serverURL}/invoicing/provider/?${filters}limit=${limit}&page=${page}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  
}
