
<div class="readCodeOverLay">
    
    

    <div class="readCodeBox readCodeBox_inputs animate__animated animate__zoomIn">
        <div class="loaderGeneral" *ngIf="_gen.generalLoader">
            <div class="icon">
                <img src="assets/img/preload.gif" alt="">
            </div>
        </div>
        
        <button class="closeBtn" (click)="closeModal();">
            <span></span>
        </button>

        <div class="loaderInfo" *ngIf="loaderCreateShipment">
            <div class="icon">
                <img src="assets/img/preload.gif" alt="">
            </div>
            <h1>Creando Envío</h1>
        </div>

        <form novalidate [formGroup]="newInput"> <!--  (ngSubmit)="onLogin( newInput )" -->
            <div class="ttl" *ngIf="type == 'inv_entrada'">Nueva entrada</div>
            <div class="ttl" *ngIf="type == 'inv_salida'">Nueva Salida</div>

            <div class="step step_1" *ngIf="currStep == 1 && type != 'ped_salida' ">
                <div class="form_field">
                    <label *ngIf="type == 'inv_entrada'" for="">Tipo de entrada<span>*</span></label>
                    <label *ngIf="type == 'inv_salida'" for="">Tipo de salida<span>*</span></label>

                    <div class="select_box">
                        <select name="ordenCompra" formControlName="ordenCompra" id="" (ngModelChange)="reasonChange($event)">
                            <option value="" selected disabled>Seleccionar tipo</option>
                            <option *ngFor="let itm of ocListReason" value="{{itm.reason_id}}">{{itm.name}}</option>
                        </select>
                        <i [class]="'48px_down-arrow' | icons"></i>
                    </div>
                </div>

                <div class="entryContent">
                    <div class="form_field" *ngIf="newInput.controls.numOcReasonOrderIdType.value != null">
                        <label for="">Número de orden de compra<span>*</span></label>
                        <div class="select_box">
                            <select name="proveedor" formControlName="numOc" id="">
                                <option value="" selected disabled>Seleccionar el Numero de O.C.</option>
                                <option *ngFor="let itm of ocListEntry" value="{{itm.order_id}}">{{itm.reference}}</option>
                            </select>
                            <i [class]="'48px_down-arrow' | icons"></i>
                        </div>
                    </div>
                    <div class="formFieldBodegas" *ngIf="newInput.controls.numOcReasonType.value == 'entry' ||  newInput.controls.numOcReasonType.value == 'exit' ||  newInput.controls.numOcReasonType.value == 'trans'">
                        <div class="form_field">
                            <label for="">Bodega de salida<span>*</span></label>
                            <div class="select_box">
                                <select name="bodega" formControlName="bodega" id="">
                                    <option value="" selected disabled>Seleccionar Bodega</option>
                                    <option *ngFor="let itm of ocListBodega" value="{{itm.provider_id}}">{{itm.name}}</option>
                                </select>
                                <i [class]="'48px_down-arrow' | icons"></i>
                            </div>
                        </div>
                        <div class="separator"  *ngIf="newInput.controls.numOcReasonType.value == 'trans'">
                            <i class="{{ '48px_arrows-same-direction' | icons }}"></i>
                        </div>
                        <div class="form_field"  *ngIf="newInput.controls.numOcReasonType.value == 'trans'">
                            <label for="">Bodega de entrada<span>*</span></label>
                            <div class="select_box">
                                <select name="bodegaExit" formControlName="bodegaExit" id="">
                                    <option value="" selected disabled>Seleccionar Bodega</option>
                                    <option *ngFor="let itm of ocListBodega" value="{{itm.provider_id}}">{{itm.name}}</option>
                                </select>
                                <i [class]="'48px_down-arrow' | icons"></i>
                            </div>
                        </div>
                    </div>
                    <div class="formFieldfactComm" *ngIf="newInput.controls.numOcReasonOrderIdType.value != null">
                        <div class="form_field">
                            <label for="">Factura</label>
                            <input type="text" name="invoice" formControlName="invoice" id="">
                        </div>
                        <div class="separator">
                        </div>
                        <div class="form_field">
                            <label for="">Comentario</label>
                            <textarea name="comment" formControlName="numOcComment" cols="30" rows="10"></textarea>
                        </div>
                    </div>
                </div>
                
                <div class="btnWrap">
                    <button class="btn" type="button" (click)="closeModal();">
                        cerrar
                    </button>
                    <button class="btn btn_success" type="button" (click)="changeStep( newInput, 2 )" [disabled]="newInput.controls.ordenCompra.errors">
                        Continuar
                    </button>
                </div>
            </div>
            <div class="step step_2" *ngIf="currStep == 2">
                <div class="summary" *ngIf="type == 'count'">
                    <div class="cont">
                        <div class="ttl">
                            <span>Últimos Productos:</span>
                        </div>
                        <div class="loader" *ngIf=" ocProdListView.length == 0 ">
                            <div class="icon">
                                <img src="assets/img/preload.gif" alt="">
                            </div>
                        </div>
                        <div class="listItems customScrollBar"  *ngIf=" ocProdListView.length >= 1 ">
                            <div *ngFor="let item of ocProdListView">
                                <div class="item itemCount" *ngIf="item.scanned_products > 0">
                                    <div class="topEntryInventory">
                                        <div class="sku">
                                            {{ item.sku_full }}
                                        </div>
                                        <div class="name">
                                            {{item.name}}
                                        </div>
                                        <div class="qtyAndBtns">
                                            <div class="qty">
                                                Productos Escaneados: {{item.scanned_products}}
                                            </div>
                                            <div>
                                                <div [ngStyle]="{'pointer-events': loading ? 'none' : 'auto'}"  class="btnSubtractAmount" (click)="subtractCountAmount(item)">
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary"  *ngIf="type == 'inventory_entry'" >
                    <div class="cont">
                        <div class="ttl">
                            <span>Productos recibidos:</span>
                        </div>
                        <div class="loader" *ngIf=" ocProdListView.length == 0 ">
                            <div class="icon">
                                <img src="assets/img/preload.gif" alt="">
                            </div>
                        </div>
                        <div class="listItems customScrollBar"  *ngIf=" ocProdListView.length >= 1 ">
                            <div *ngFor="let item of ocProdListView">
                                <div class="item" *ngIf="item.quantity > 0">
                                    <div class="topEntryInventory">
                                        <div class="sku">
                                            {{ item.sku_full }}
                                        </div>
                                        <div class="name">
                                            {{item.name}}
                                        </div>
                                        <div class="qtyAndBtns">
                                            <div class="qty">
                                                Cantidad: {{item.quantity}}
                                            </div>
                                            <div>
                                                <div class="btnSubtractAmount" (click)="subtractAmount(item)">
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="lote">
                                        <span>Lote: {{item.lote.number}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary"  *ngIf="newInput.controls.numOcReasonOrderIdType.value != null || type == 'ped_salida'" >
                    <div class="cont">
                        <div class="ttl">
                            <span *ngIf="type != 'ped_salida'">Productos en esta orden:</span>
                            <span *ngIf="type == 'ped_salida'">Productos en este pedido:</span>
                        </div>
                        <div class="loader" *ngIf=" ocProdListView.length == 0 ">
                            <div class="icon">
                                <img src="assets/img/preload.gif" alt="">
                            </div>
                        </div>
                        <div class="listItems customScrollBar"  *ngIf=" ocProdListView.length >= 1 ">
                            <div class="item" *ngFor="let item of ocProdListView">
                                <div class="itemInfo">
                                    <div class="top">
                                        <div class="sku">
                                            {{ item.sku}}
                                        </div>
                                    </div>
                                    <div class="name">
                                        {{item.name}}
                                    </div>
                                    <div class="qty">
                                        Cantidad: {{item.quantity}}
                                    </div>
                                    <div class="formula">
                                        <span>{{ item.formula }}</span>
                                    </div>
                                </div>
                                <div class="status">
                                    <span class="badge ordered">
                                        Ordenados ({{item.quantity}})
                                    </span>
                                    <span *ngIf="item.qty_packed > 0" class="badge received">
                                        Escaneado ({{item.qty_packed}})
                                    </span>
                                    <span *ngIf="item.qty_shipped > 0" class="badge order_shipped">
                                        Enviado ({{item.qty_shipped}})
                                    </span>
                                    <span *ngIf="item.qty_special > 0" class="badge order_special">
                                        Especial ({{item.qty_special}})
                                    </span>
                                    <span *ngIf="item.qty_import > 0" class="badge order_import">
                                        Importación ({{item.qty_import}})
                                    </span>
                                    <span *ngIf="item.qty_completed > 0" class="badge completed">
                                        Entregado ({{item.qty_completed}})
                                    </span>
                                    <span *ngIf="item.qty_canceled > 0" class="badge canceled">
                                        Cancelado ({{item.qty_canceled}})
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="readCode">
                    <div class="imgWrap">
                        <img src="assets/img/barCode.png" alt="">
                    </div>
                    <div class="ocTxt">
                        <div class="left">
                            <span *ngIf="type != 'ped_salida' && type !='inventory_entry' && type !='count'"># de O. Compra: </span>
                            <span *ngIf="type == 'ped_salida'"># de pedido: </span>
                            <span *ngIf="type == 'count'">Conteo: {{countData?.nameField}}</span>
                            <div>
                                <span *ngIf="type == 'inventory_entry' && invoiceNumber"># Factura: </span>
                                <p *ngIf="type == 'inventory_entry' && invoiceNumber">{{invoiceNumber}}</p>
                            </div>
                            <span *ngIf="type == 'inventory_entry'"># Referencia: </span>
                            <p class="order_number_link" *ngIf="variant == 'massive'" (click)="goToOrderDetail(); this._gen.barCodeEntryViewPeds = false">{{selOc.reference}}</p>
                            <p *ngIf="variant != 'massive' && type != 'inventory_entry' && type != 'count'">{{selOc.reference}}</p>
                            <p *ngIf="type== 'inventory_entry'">{{getReference()}}</p>
                        </div>
                        <!-- <div class="right" *ngIf="type != 'ped_salida'"> 
                            Prods. a escanear: <b>{{selOc.products_sum}}</b>
                        </div> -->
                        <div class="right">
                            <div class="form_field">
                                <label for="">Cantidad</label>
                                <input type="number" placeholder="Cantidad" formControlName="qty" [attr.disabled]="_gen.generalLoader? true: null" min="1">
                            </div>
                        </div>
                    </div>
                    <div class="finalClient" *ngIf="showFinalClientLabel">
                        <span class="finalClient_label">
                            Cliente Final
                        </span>
                    </div>
    
                    <div class="oc_masiveWrap">
                        <div class="form_field form_field_code">
                            <label for="">Escanea el código de barras<span>*</span></label>
                            <input 
                                type="text" 
                                id="barCode" 
                                placeholder="Escanea el código de barras" 
                                formControlName="codeBar" 
                                (input)="barcodeChange( $event )" 
                                [attr.disabled]="_gen.generalLoader
                                    ? true
                                    : null
                                "
                            >
                        </div>
    
                        <div class="codesWrap">
                            <div class="form_field">
                                <label for="">EAN</label>
                                <input type="text" placeholder="EAN" formControlName="ean" (input)="addManual = true" [attr.disabled]="_gen.generalLoader? true: null">
                            </div>
                            <div class="form_field">
                                <label for=""># lote</label>
                                <input type="text" placeholder="lote" formControlName="lote" (input)="addManual = true" [attr.disabled]="_gen.generalLoader? true: null">
                            </div>
                            <div class="form_field">
                                <label for="">Fecha vencimiento</label>
                                <input type="text" placeholder="Fecha" formControlName="expire" (input)="addManual = true" [attr.disabled]="_gen.generalLoader? true: null">
                            </div>
                            <!-- <div class="form_field">
                                <label for="">Cantidad</label>
                                <input type="number" placeholder="Cantidad" formControlName="qty">
                            </div> -->
                            <div class="form_field form_field_hide">
                                <label for="">Country ID</label>
                                <input type="text" placeholder="País" formControlName="country">
                            </div>
                            <div class="form_field form_field_btn" *ngIf="addManual">
                                <button class="btn btn_info" type="button" (click)="addBarcodeManual()" [disabled]="disabledButton">
                                    Agregar
                                </button>
                            </div>
                        </div>
    
                        <div class="bottomText">
                            <div class="disclaimer" *ngIf="scannedCodes.length >= 1">*Al finanlizar este producto se cargará en la base</div>
                        </div>
                    </div>
    
                    <div class="btnWrap">
                        <button class="btn" type="button" *ngIf="type != 'ped_salida' && type != 'inventory_entry' && type != 'count'" (click)="changeStep( newInput, 1 )">
                            Volver
                        </button>
                        <button class="btn" type="button" *ngIf="type == 'ped_salida'" (click)="closeModal();">
                            cerrar
                        </button>
                        <button *ngIf="ocProdListView.length > 0 && type !== 'count'" class="btn btn_success" type="button" (click)="changeStep( newInput, 3 )">
                            Ver productos de la orden
                        </button>
                        <button *ngIf="ocProdListView.length > 0 && type == 'count'" class="btn btn_success" type="button" (click)="changeStep( newInput, 3 )">
                            Ver productos escaneados
                        </button>
                        <button [disabled]="disabledButton"  *ngIf="showPartialShipButton" class="btn btn_fill btn_info" type="button" (click)="genShipFx()">
                            Generar envío parcial
                        </button>
                        <button *ngIf="!showPartialShipButton && showPrintGuideButton()" class="btn btn_fill btn_orange" type="button" (click)="printGuide(lastShipGuide.guide)">
                            Reimprimir guía
                        </button>
                    </div>

                    <div class="shipmentMessageWrapper" *ngIf="showShipmentMsj">
                        <div class="shipmentMessageContainer">
                            <i [class]="this.shipmentMessage.class | icons"></i>
                            <p>{{this.shipmentMessage.text }}</p>
                        </div>
                    </div>

                    <div class="errorCarrierMessageWrapper" *ngIf="showCarrierErrorMessage">
                        <div class="errorCarrierMessageContainer">
                            <i [class]="this.carrierErrorMessage.class | icons"></i>
                            <p>{{this.carrierErrorMessage.text }}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="step step_3" *ngIf="currStep == 3">
                <div class="ocTxt">
                    <div class="left">
                        <span *ngIf="type != 'ped_salida' && type !='inventory_entry' && type !== 'count'"># de O. Compra: </span>
                        <span *ngIf="type == 'ped_salida'"># de pedido: </span>
                        <span *ngIf="type == 'inventory_entry'"># Referencia: </span>
                        <span *ngIf="type == 'count'">Conteo: </span>
                        
                        <b *ngIf="type != 'inventory_entry' && type != 'count'">{{selOc.reference}}</b>
                        <b *ngIf="type == 'inventory_entry'">{{reference}}</b>
                        <b *ngIf="type == 'count'">{{countData?.nameField}}</b>
                    </div>
                    <div class="right">
                        Prods. a escanear: <b>{{selOc.products_sum}}</b>
                    </div>
                </div>
                
                <div class="loader" *ngIf=" ocProdListView.length == 0 ">
                    <div class="icon">
                        <img src="assets/img/preload.gif" alt="">
                    </div>
                </div>

                <div class="oc_masiveWrap" *ngIf="ocProdListView.length >= 1">
                    <div class="codesWrap customScrollBar">
                        <table>
                            <thead>
                                <tr>
                                    <td>EAN</td>
                                    <td>skufull</td>
                                    <td *ngIf="type !== 'count'">Cantidad</td>
                                    <td>Nombre</td>
                                    <!-- <td>Status</td> -->
                                    <!-- <td>Acciones</td> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let itm of ocProdListView; index as i">
                                    <td>
                                        <div *ngIf="itm.ean && itm.ean.ean">
                                            <p *ngFor="let ean of itm.ean.ean" class="ean">
                                                {{ean}}
                                            </p>
                                        </div>
                                        <div *ngIf="itm.ean && !itm.ean.ean">
                                            <p class="ean">
                                                {{itm.ean}}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        {{itm.sku_full}}
                                    </td>
                                    <td *ngIf="type !== 'count'">
                                        {{itm.quantity}}
                                    </td>
                                    <td>
                                        {{itm.name}}
                                    </td>
                                    <!-- <td>

                                        <div class="statBox" *ngIf="editProduct != itm.order_product_id ">

                                            <div class="statusesBox">
                                                <div class="item" *ngFor="let item of itm.statuses">
                                                    <span class="badge {{ item?.status.name }}">{{item?.status.pretty}}</span>
                                                    <div class="text">
                                                        Qty: <strong>{{item.quantity}}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="statChange" *ngIf="editProduct == itm.order_product_id">
                                            <div class="customSelect_status">
                                                <div class="label">Nuevo estado</div>
                                                <div class="select" (click)="changeStatView = !changeStatView">
                                                    {{itm?.newStatus}}
                                                </div>
                                                <div class="selOptions" *ngIf="changeStatView && changeStatidView == itm.order_product_id">
                                                    <div class="item" [ngClass]="{'active': itm?.newStatus == opt.name}" *ngFor="let opt of _gen.allProdStatus" (click)="itm.newStatus = opt?.name; changeStatView = !changeStatView">
                                                        <span>{{opt.pretty}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="customSelect_status">
                                                <div class="label">Cantidad</div>
                                                <div class="select" (click)="changeStatQtyView = !changeStatQtyView">
                                                    {{itm?.newQuantity}}
                                                </div>
                                                <div class="selOptions" *ngIf="changeStatQtyView && changeStatidView == itm.order_product_id">
                                                    <div class="item"  [ngClass]="{'active': itm?.newQuantity == opt}" *ngFor="let opt of itm.quantityArr" (click)="itm.newQuantity = opt; changeStatQtyView = !changeStatQtyView">
                                                        <span>{{opt}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>

                                        <div class="statBox" *ngIf="editProduct != itm.order_product_id ">

                                            <button class="edit" *ngIf="itm.statuses.length >= 1" (click)="editProdFx( itm )">
                                                <i class="{{ '48px_pencil' | icons }}"></i>
                                            </button>

                                        </div>
                                        <div class="statChange" *ngIf="editProduct == itm.order_product_id">

                                            <button class="cancel" *ngIf="itm.newStatus == 'created' " (click)="cancelProdStatusFx()">
                                                <i class="{{ '48px_e-remove' | icons }}"></i>
                                            </button>
                                            <button class="save" *ngIf="itm.newStatus != 'created' " (click)="changeProdStatusFx( itm )">
                                                <i class="{{ '48px_floppy-disk' | icons }}"></i>
                                            </button>
                                        </div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>

                <div class="btnWrap" *ngIf="ocProdListView.length >= 1">
                    <button class="btn" type="button" (click)="changeStep( newInput, 2 )">
                        Volver
                    </button>
                    <!-- <button *ngIf="type != 'inventory_entry'" class="btn btn_success" type="button" (click)="_gen.barCodeEntryView = false">
                        Finalizar carga masiva
                    </button> -->
                </div>

            </div>

        </form>

    </div>

</div>
