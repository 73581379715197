import { Injectable } from '@angular/core';
import axios from 'axios';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from './general.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  sel_catalog:any[] = [];
  editView:boolean = false;
  editId:string = "";
  
  sel_catalogStock:any[] = [];
  editStockId:string = "";
  editStockView:boolean = false;

  filt_brand:any[] = [];
  filt_manufac:any[] = [];
  filt_types:any[] = [];

  filterOpts:any = {
    sku: "",
    sku_full: "",
    country: [],
    name: "",
    brand: [],
    manufacturer: [],
    type: [],
  }
  filterOptsStock:any = {
    country: [],
    provider: [],
    manufacturer: [],
    sap: "",
    brand: [],
    sku: "",
    sku_full: "",
    name: "",
  }
  
  dd_edit_country:any[] = [];
  dd_model_country:any = "";
  dd_edit_manufac:any[] = [];
  dd_model_manufac:any = "";
  dd_edit_brand:any[] = [];
  dd_model_brand:any = "";
  dd_edit_types:any[] = [];

  constructor( public http:HttpClient, public _gen:GeneralService, public _log:LoginService ) { }

  /* Filters */
  getBrands( manufact:string ) {
    if ( manufact ) {
      return axios.get(`${this._gen.serverURL}/catalog/brand/`, {
        headers: {'Authorization': `${this._gen.serverTkn}`},
        params: {
          manufacturer: manufact
        },
      })
    } else {
      return axios.get(`${this._gen.serverURL}/catalog/brand/`, {
        headers: {'Authorization': `${this._gen.serverTkn}`},
      })
    }
  }
  getManufact() {
    return axios.get(`${this._gen.serverURL}/catalog/manufacturer/`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getManufactById( id:any ) {
    return axios.get(`${this._gen.serverURL}/catalog/manufacturer/${id}/`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getTypes() {
    return axios.get(`${this._gen.serverURL}/catalog/type/`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  
  /* COUNT */
  getAllProdsCountFilt( filters:any ) {
    return axios.get(`${this._gen.serverURL}/catalog/products_sku/?${filters}count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  /* products */
  getAllProdsFilter( filters:any, limit:any = 100, page:number = 1) {
    return axios.get(`${this._gen.serverURL}/catalog/products_sku/?${filters}limit=${limit}&page=${page}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  /* products */
  getAllProdsFilterBySKU( sku:any ) {
    return axios.get(`${this._gen.serverURL}/catalog/products_sku/${sku}/`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  getAllProviderProdsCountFilter( filters:any, limit:any = 100, page:number = 1 ) {
    return axios.get(`${this._gen.serverURL}/providers/provider_product/?${filters}&count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllProviderProdsFilter( filters:any, limit:any = 100, page:number = 1 ) {
    return axios.get(`${this._gen.serverURL}/providers/provider_product/?${filters}limit=${limit}&page=${page}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }


  /* Edit Get Vars */
  edit_country( val:string ) {
    if ( val ) {
      for (let f = 0; f < this.dd_edit_country.length; f++) {
        const element = this.dd_edit_country[f];
        if ( element.code == val) {
          this.dd_model_country = element.country_id;
          // console.log( "country: ", this.dd_model_country );
        }
      }
    }  else {
      this.dd_model_country = "";
      // console.log( "country:", this.dd_model_country );
    }
  }
  edit_manufac( val:string ) {
    if ( val ) {
      for (let f = 0; f < this.dd_edit_manufac.length; f++) {
        const element = this.dd_edit_manufac[f];
        if ( element.name == val) {
          this.dd_model_manufac = element.manufacturer_id;
          // console.log( "manufacturer: ", this.dd_model_manufac );
        }
      }
    }  else {
      this.dd_model_manufac = "";
      // console.log( "manufacturer:", this.dd_model_manufac );
    }
  }
  edit_brand( val:string ) {
    if ( val ) {
      for (let f = 0; f < this.dd_edit_brand.length; f++) {
        const element = this.dd_edit_brand[f];
        if ( element.name == val) {
          this.dd_model_brand = element.brand_id;
          // console.log( "brand: ", this.dd_model_brand );
        }
      }
    }  else {
      this.dd_model_brand = "";
      // console.log( "brand:", this.dd_model_brand );
    }
  }

  /* Edit Prod Name */
  editProdName( data:any ) {
    return axios.put(`${this._gen.serverURL}/catalog/products/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    } )
  }
  editProdEan( data:any ) {
    return axios.put(`${this._gen.serverURL}/catalog/products_sku/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    } )
  }
  
}
