import { Injectable } from '@angular/core';
import axios from 'axios';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class ElectronicInvoicesService {

  filterOpts_electronic_invoices: any = {
    country: [],
    consecutive: "",
    name: "",
    date_issue_from: "",
    date_issue_to: "",
    tax: ""
  };

  processElecetronicInvoicesView:boolean = false;

  constructor(
    public _gen:GeneralService
  ) { }

  // Count
  getAllElectronicInvoicesCountFilt( filters:any) {    
    return axios.get(`${this._gen.serverURL}/invoicing/invoice/?${filters}count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  // Electronic Invoices
  getAllElectronicInvoicesFilter( filters:any, limit:any = 100, page:number = 1) {
    return axios.get(`${this._gen.serverURL}/invoicing/invoice/?${filters}limit=${limit}&page=${page}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
}
