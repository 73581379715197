<div class="topttls">
    <app-tittle [label]="'Productos pendientes'" [icon]="'48px_box-3d-50'"></app-tittle>

    <div class="btns">
        &nbsp;

        <div class="totalProds">
            <div><span>Total registros:</span> <b>{{ dataSourceCount }}</b></div>
        </div>
    </div>
</div>

<div class="topTableWrap">
    <div class="left">
        <!-- <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_ord.sel_orders.length == 1 && !_ord.editView" (click)="editBtnFx()">
            <i [class]="'48px_edit' | icons"></i>
            <span>Editar</span>
        </button>
        <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_ord.sel_orders.length >= 1 && !_ord.editView" >
            <i [class]="'48px_trash-can' | icons"></i>
            <span>Eliminar</span>
        </button> -->
    </div>
    <div class="right">

        <div class="filterView">
            <button class="btn editBtn" (click)="resetTobTable('filtsView')" [disabled]="_ord.editView">
                <i [class]="'48px_filter' | icons"></i>
                <span>Filtrar</span>
            </button>
            <div class="filterItems animate__animated animate__fadeInRight " *ngIf="filtsView">
                <div class="closeFilt" (click)="filtsView = false">
                    <button>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </button>
                </div>
                <div class="filtTtl">
                    <h3>Filtros</h3>
                </div>
                <div class="filtActive customScrollBar">
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.order_number" (click)="resetFilters_item('order_number')">
                        <span><b># de pedido: </b>{{_ord.filterOptsPen.order_number}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.country.length >= 1">
                        <span><b>País: </b> <i *ngFor="let country of _ord.filterOptsPen.country; index as i"> <i *ngIf="i >= 1"> - </i>{{country | idCountry}}</i> </span>
                        <!-- <i [class]="'48px_e-remove' | icons"></i> -->
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.business_unit.length >= 1" (click)="resetFilters_item('business_unit')">
                        <span><b>U. Negocio: </b> <i *ngFor="let b_unit of _ord.filterOptsPen.business_unit; index as i"> <i *ngIf="i >= 1"> - </i> {{b_unit | idBunit}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.provider.length >= 1" (click)="resetFilters_item('provider')">
                        <span><b>Proveedor: </b> <i *ngFor="let b_unit of _ord.filterOptsPen.provider; index as i"> <i *ngIf="i >= 1"> - </i> {{b_unit | idProvider}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.priority.length >= 1" (click)="resetFilters_item('priority')">
                        <span><b>Prioridad: </b> <i *ngFor="let prio of _ord.filterOptsPen.priority; index as i"> <i *ngIf="i >= 1"> - </i> {{prio}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.manufacturer.length >= 1" (click)="resetFilters_item('manufacturer')">
                        <span><b>Laboratorio: </b> <i *ngFor="let man of _ord.filterOptsPen.manufacturer; index as i"> <i *ngIf="i >= 1"> - </i> {{man | idManufac}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.sku_full" (click)="resetFilters_item('sku_full')">
                        <span><b>SKU FULL: </b>{{_ord.filterOptsPen.sku_full}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.sku" (click)="resetFilters_item('sku')">
                        <span><b>SKU: </b>{{_ord.filterOptsPen.sku}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOptsPen.name" (click)="resetFilters_item('name')">
                        <span><b>Producto: </b>{{_ord.filterOptsPen.name}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                </div>
                <div class="filtList customScrollBar">
                    <div class="filItm">
                        <!-- <div class="labl">SKU FULL</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label># de pedido</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ord.filterOptsPen.order_number" name="order_number">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm filItm_hide">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOptsPen.country" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option *ngFor="let opt of _gen.allCountries" value="{{opt.country_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>U. negocio</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOptsPen.business_unit" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option *ngFor="let opt of _ord.filt_bUnit" value="{{opt.business_unit_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Proveedor</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOptsPen.provider" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option *ngFor="let opt of _ord.filt_provider" value="{{opt.provider_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Prioridad</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOptsPen.priority" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option value="1">1</mat-option>
                                    <mat-option value="2">2</mat-option>
                                    <mat-option value="3">3</mat-option>
                                    <mat-option value="4">4</mat-option>
                                    <mat-option value="5">5</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Laboratorio</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>laboratorios</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOptsPen.manufacturer" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _inv.filt_manufac" value="{{opt.manufacturer_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">SKU FULL</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>SKU FULL</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ord.filterOptsPen.sku_full" name="sku_full">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">SKU FULL</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>SKU</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ord.filterOptsPen.sku" name="sku">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">SKU FULL</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Producto</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ord.filterOptsPen.name" name="name">
                              </mat-form-field>
                        </div>    
                    </div>
                </div>
                <div class="filtSend">
                    <button class="btn" (click)="resetFilters_item('')">
                        Borrar filtros
                    </button>
                    <button class="btn btn_success btn_fill" (click)="checkFilter()">
                        Aplicar filtros
                    </button>
                </div>
            </div>
        </div>

        <div class="colsView">
            <button class="btn editBtn" (click)="resetTobTable('colsView')" [disabled]="_ord.editView">
                <i [class]="'48px_separate-2' | icons"></i>
                <span>Columnas</span>
            </button>
            <div class="columnsItems animate__animated animate__fadeInUp " *ngIf="colsView" appClickOutside (clickOutside)="colsView = false">
                <div class="colItem" *ngFor="let col of columnsConfig; index as i">
                    <div class="ios_checkBox">
                        <input type='checkbox' id='checkbox-{{i}}' [checked]="col.visible" (change)="col.visible = !col.visible">
                        <label for='checkbox-{{i}}'>{{col.label}}</label>
                    </div>
                </div>
            </div>
        </div>

        <button class="btn editBtn btn_success btn_fill" [disabled]="_ord.sel_pending.length <= 0 || !btnAble" (click)="newOrder()">
            <i [class]="'48px_arrow-up' | icons"></i>
            <span>Nueva orden de compra</span>
        </button>
    </div>
</div>

<app-table [cols]="columnsConfig" [data]="dataSource" [type]="tableType" [uniqueID]="_ord.editPendingId" (reloadPage)="onReloadPage()"></app-table>

