<div class="topttls">
    <app-tittle [label]="'Detalle de Orden de compra'" [icon]="'48px_box-3d-50'"></app-tittle>

    <div class="btns">
        <button class="btn" (click)="backToOrds()">Volver</button>
    </div>
</div>

<div class="ordDetailWrapper">

    <div class="loaderInfo" *ngIf="loaderView">
        <div class="icon">
            <img src="assets/img/preload.gif" alt="">
        </div>
    </div>

    <div class="ordDetailWrap" *ngIf="ordData">
        <div class="topInfo">
            <div class="ttlBox">
                <div class="ttl">
                    Orden # <span>{{ordData?.reference}}</span>
                </div>
                <div class="subttl">
                    Creada el: <span>{{ordData?.created_at | date:'d-M-yyyy h:mm'}}</span>
                </div>
                <div class="status">
                    <b>Estado de orden:</b>
                    <span class="badge {{ordData?.status}}"> {{ordData?.status}}</span>
                </div>
            </div>
            <div class="buttons">
                <button class="btn btn_error" *ngIf="ordData?.status === 'created'" (click)="cancelOrder( ordData?.reference, ordData?.order_id )">Cancelar orden</button>
            </div>
        </div>

        <div class="infroWrap customScrollBar">
            <div class="formInfo formInfoCount">
                <div class="top">
                    <div class="label">Información de cuenta</div>
                    <!-- <button class="btn">Editar</button> -->
                </div>
                <div class="mid">
                    <div class="form_field">
                        <label for="">Nombre</label>
                        <input type="text" name="ord_name" id="" value="{{ordData?.user.first_name}} {{ ordData?.user.last_name }}" disabled>
                    </div>
                    <div class="form_field" *ngIf="ordData?.user.email">
                        <label for="">Correo electrónico</label>
                        <input type="text" name="ord_name" id="" value="{{ordData?.user.email}}" disabled>
                    </div>
                </div>
            </div>
            <div class="formInfo formInfoProvider">
                <div class="top">
                    <div class="label">Información de proveedor</div>
                    <!-- <button class="btn">Editar</button> -->
                </div>
                <div class="mid">
                    <div class="form_field">
                        <label for="">Proveedor</label>
                        <input type="text" name="ord_name" id="" value="{{ordData?.provider.name}}" disabled>
                    </div>
                    <div class="form_field" *ngIf="ordData?.provider.country_name">
                        <label for="">Ciudad</label>
                        <input type="text" name="ord_name" id="" value="{{ordData?.provider.country_name}}" disabled>
                    </div>
                    <div class="referenceContainer">
                        <div class="form_field">
                            <label for="">
                                Referencia Proveedor
                                <input [(ngModel)]="providerRefValue" #inputReference="ngModel" pattern="^[A-Za-z0-9\s\-\_]+$" type="text" name="reference_provider" id="" value="" [disabled]="!enableEditingRef">
                            </label>
                            <p class="error_message" [class.active]="inputReference.touched && inputReference.invalid">
                                <strong *ngIf="inputReference.touched && inputReference.invalid" [class.active]="inputReference.invalid">
                                    La referencia no es válida
                                </strong>
                            </p>
                        </div>
                        <button class="btn success" *ngIf="enableEditingRef" (click)="changeProviderReference()" [disabled]="inputReference.invalid">
                            <i class="{{ '48px_floppy-disk' | icons }}"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn edit" *ngIf="!enableEditingRef" (click)="enableEditingRef = true">
                            <i class="{{ '48px_pencil' | icons }}"></i>
                            <span>Editar</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="comments_info" *ngIf="ordData?.comments">
                <div class="top">
                    <div class="label">Registro de eventos</div>
                </div>
                <div class="firstComment">
                    <div class="comment">
                        <div class="circle"></div>
                        <div class="info">
                            <div class="commentBox" *ngIf="ordData?.comments">
                                <p>{{ ordData?.comments }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="prodList" *ngIf="ordData?.products">
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Producto</td>
                            <td *ngIf="ordData?.status !== 'canceled' ">Estados</td>
                            <td>Comentarios</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ordData?.products; index as i">
                            <td>
                                <img src="{{item?.prod.product.image}}" alt="{{item?.prod.product.name}}">
                            </td>
                            <td>
                                <div class="info">
                                    <p class="name"><b>{{item?.prod.product.name}}</b></p>
                                    <p class="qty">Orden: <b><a (click)="goToPed( item?.order_number )">{{item?.order_number}}</a></b></p>
                                    <p class="qty">Cantidad: <b>{{item?.quantity}}</b></p>
                                    <p class="sku">SKU: <b>{{item?.prod.sku}}</b></p>
                                    <p class="skufull">SKU FULL: <b>{{item?.prod.sku_full}}</b></p>
                                    <p class="dioptry">
                                        <span *ngIf="item?.prod.product.use_type?.use_type_id     == 1">
                                            <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product.use_type?.use_type_id     == 2">
                                            <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                            <span>Cilindro (Cyl): <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                            <span>Eje (Axis): <b>{{ item?.prod.sku_full | diopBySkufull:'Axis' }}</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product.use_type?.use_type_id     == 3">
                                            <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                            <span>Adición (Add): <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                        </span>
                                        <span *ngIf="item?.prod.product.use_type?.use_type_id     == 4">
                                            <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                            <span>Color: <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                        </span>
                                    </p>
                                </div>
                            </td>
                            <td  *ngIf="ordData?.status !== 'canceled' ">
                                <div class="statBox">
                                    <div class="statusesBox">
                                        <div class="item" *ngFor="let item of item.statuses">
                                            <span class="badge {{ item?.status.name }}">{{item?.status.pretty}}</span>
                                            <div class="text">
                                                Qty: <strong>{{item.quantity}}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="edit" *ngIf="item.statuses.length >= 1" (click)="editProdFx( item )">
                                        <i class="{{ '48px_pencil' | icons }}"></i>
                                        <span>Editar estados</span>
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div class="commentWrap">
                                    <div class="form_field form_fieldTextArea">

                                        <p>
                                            <span>{{item?.commentProd}}</span>
                                        </p>
                                        <p *ngIf="i != currCommentItem">
                                            <span class="noComment" *ngIf="!item?.comment">Sin comentario</span>
                                            <span *ngIf="item?.comment">{{item?.comment}}</span>
                                        </p>
                                        <mat-form-field class="example-full-width" appearance="outline" *ngIf="i == currCommentItem">
                                            <mat-label>Deja un comentario</mat-label>
                                            <textarea [(ngModel)]="item.comment" matInput placeholder="Deja un comentario"></textarea>
                                        </mat-form-field>

                                        <button class="edit" *ngIf="i == currCommentItem" (click)="changeProdCommentFx( item )">
                                            <i class="{{ '48px_floppy-disk' | icons }}"></i>
                                            <span>Guardar comentario</span>
                                        </button>
                                        <button class="edit" *ngIf="i != currCommentItem" (click)="currCommentItem = i">
                                            <i class="{{ '48px_pencil' | icons }}"></i>
                                            <span>Editar comentario</span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>

<app-prod-status  [type]="'prod_order'" [info]="ordData" [prod]="editProduct" *ngIf="_gen.prodStatusView"></app-prod-status>