import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CatalogService } from 'src/app/services/catalog.service';
import { GeneralService } from 'src/app/services/general.service';
import { PedidosService } from 'src/app/services/pedidos.service';

@Component({
  selector: 'app-read-code-ped',
  templateUrl: './read-code-ped.component.html',
  styles: [
  ]
})
export class ReadCodePedComponent implements OnInit {

  @Input() saleId:string = "";

  newInput:FormGroup = new FormGroup({
    ordenCompra: new FormControl('', [Validators.required] ),
    numOc: new FormControl('', [Validators.required] ),
    numPv: new FormControl('', [Validators.required] ),
    codeBar: new FormControl('', [Validators.required] ),
    ean: new FormControl('', [Validators.required] ),
    lote: new FormControl('', [Validators.required] ),
    expire: new FormControl('', [Validators.required] ),
    country: new FormControl('', [Validators.required] ),
  });;

  currStep:number = 2;
  scannedCodes:any[] = [];
  pedData:any[] = [];
  
  addManual:boolean = false;

  ocProdList:any = [];    
  ocProdListView:any[] = [];

  constructor(public _gen:GeneralService, public _ped:PedidosService, public _cat:CatalogService) { }

  ngOnInit(): void {

    this.newInput.controls['country'].setValue( this._gen.currCountry );

    this.getPedsProds();
    
  }

  async getPedsProds() {
    this.ocProdList = [];    
    this.ocProdListView = [];
    
    await this._ped.getAllProdsById( this.saleId ).then( ({data}) => {  
      this.pedData = data;

      console.log( this.pedData );
    })
    await this._ped.getAllProdsByOrdId( this.saleId ).then( ({data}) => {  
      this.ocProdList = data;

      console.log( this.ocProdList );

      for (let d = 0; d < this.ocProdList.length; d++) {
        const element = this.ocProdList[d];
        
        this.ocProdListView.push({
          order_product_id: element.order_product_id,
          name: element.name,
          ean: element.ean,
          status: element.status,
          statuses: element.statuses,
          newStatus: element.status,
          campaign_name: element.campaign_name,
          item_id: element.item_id,
          quantity: element.quantity,
          newQuantity: 1,
          sku_full: element.sku_full,
          parts: element.sku_full.split('_')
        })
      }

      console.log( this.ocProdList );
    })

  }

  barcodeChange( evt:any ) {
    let val = evt.target.value;

    this._gen.getBarCode( val ).then( (data:any) => {            
      this.newInput.controls['ean'].setValue( data.data?.ean );
      this.newInput.controls['lote'].setValue( data.data?.number );
      this.newInput.controls['loteId'].setValue( data.data?.lote_id );
      this.newInput.controls['expire'].setValue( data.data?.expiration_date );

      if ( this.newInput.value.ean && this.newInput.value.lote && this.newInput.value.expire ) {
        this.newCompleteCode( );
      } else {
        this.addManual = true;
      }
    })
  }
  addBarcodeManual() {
    // this._ord.genEntryLoteId( this._gen.currCountry , this.newInput.value.lote, this.newInput.value.ean ,this.newInput.value.expire ).then( ({data}) => {
    //   console.log( data );
    // } )
    if ( this.newInput.value.ean && this.newInput.value.lote && this.newInput.value.expire ) {
      this.newCompleteCode( );
    } else {
      this.addManual = true;
    }
  }

  newCompleteCode( ) {  
    // console.log( this.newInput.value );

    this.getPedsProds();

  }

  resetCodeBar() {
    let prom = new Promise( (resolve,reject) => {

      this.addManual = false;

      this.newInput.controls['codeBar'].setValue( "" );
      this.newInput.controls['ean'].setValue( "" );
      this.newInput.controls['lote'].setValue( "" );
      this.newInput.controls['expire'].setValue( "" );
      setTimeout(() => {
        document.getElementById('barCode')?.focus();

        resolve(true);
      }, 200);

    } )
    return prom;
  }

  closeModal() {
    this._gen.barCodeEntryViewPeds = false;
    this.currStep = 2;

    this.newInput.controls['codeBar'].setValue( "" );
    this.newInput.controls['ean'].setValue( "" );
    this.newInput.controls['lote'].setValue( "" );
    this.newInput.controls['expire'].setValue( "" );
    this.newInput.controls['country'].setValue( "" );
    this.newInput.controls['scanId'].setValue( "" );
  }

  changeStep( form:FormGroup, step:any ) {
    this.currStep = step;

    if ( this.currStep == 2 ) {

    }

    if ( this.currStep == 3 ) {
      this.getPedsProds();
    }

    setTimeout(() => {
      if ( this.currStep == 2 ) {
        document.getElementById('barCode')?.focus()
      }
    }, 200);
  }

  // changeProdStatusFx( item:any ) {
        
  //   let prodData = {
  //     order_product_id: item.order_product_id,
  //     quantity: item.newQuantity,
  //     status_from: "created",
  //     status_to: "received"
  //   };      

  //   this._ord.changeOrderProdStatus( prodData )
  //   .then( ({data}) => {
      
  //     Swal.fire(
  //       'Cambio de estado exitoso',
  //       ``,
  //       'success'
  //     ).then( () => {
  //       this.editProduct = 0;  
  //       this.changeStatidView = 0;  
  //       this.changeStatView = false;
  //       this.changeStatQtyView = false;

  //       this.getOrdersProds();
  //     })

  //   }).catch( (error:any) => {
  //     let data = error.response.data;

  //     Swal.fire(
  //       'Error al crear orden de compra',
  //       `${data.error}`,
  //       'error'
  //     ).then( () => {
  //       this.editProduct = 0;  
  //       this.changeStatidView = 0;  
  //       this.changeStatView = false;
  //       this.changeStatQtyView = false;
        

  //       this.getOrdersProds();
  //     })
      
  //   })
    
  // }

}
