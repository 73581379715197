import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from '../services/general.service';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  constructor( public _gen:GeneralService, public _log:LoginService , public route:Router) {
  }

  public canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean>|boolean {

    
    if ( this._log.isTrusted ) {
      return true;
    } else {
      return new Observable<boolean>((observer) => {
        this._log.checkLogin().then( ({data}) => {
          // console.log( data );
          
          setTimeout(() => {
            this._log.isTrusted = data;
            observer.next( this._log.isTrusted );
            if ( !this._log.isTrusted ) {
              this.route.navigate(['/']);
              this._gen.toastError('Token Caducado');
            }
            observer.complete();
          }, 500);
        }).catch( () => {
          this.route.navigate(['/']);
          this._gen.toastError('Token Caducado');
          observer.next( false );
          observer.complete();
        })
      });
    }

  }

  
  
}
