<div class="topttls">
    <app-tittle [label]="'Stock catálogo'" [icon]="'48px_box-3d-50'"></app-tittle>

    <div class="btns">
        &nbsp;

        <div class="totalProds">
            <div><span>Total registros:</span> <b>{{ dataSourceCount }}</b></div>
        </div>
    </div>
</div>

<div class="topTableWrap">
    <div class="left">
        <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_cat.sel_catalog.length == 1 && !_cat.editView" (click)="editBtnFx()">
            <i [class]="'48px_edit' | icons"></i>
            <span>Editar</span>
        </button>
        <!-- <button class="btn editBtn btn_success btn_fill animate__animated animate__fadeIn" *ngIf="_cat.sel_catalog.length == 1 && _cat.editView" (click)="saveBtnFx()">
            <i [class]="'48px_floppy-disk' | icons"></i>
            <span>Guardar</span>
        </button>
        <button class="btn editBtn btn_error btn_fill animate__animated animate__fadeIn" *ngIf="_cat.sel_catalog.length == 1 && _cat.editView" (click)="cancelBtnFx()">
            <i [class]="'48px_e-remove' | icons"></i>
            <span>Cancelar</span>
        </button> -->

        <!-- <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_cat.sel_catalog.length >= 1 && !_cat.editView" >
            <i [class]="'48px_trash-can' | icons"></i>
            <span>Eliminar</span>
        </button> -->
    </div>
    <div class="right">

        <div class="filterView">
            <button class="btn editBtn" (click)="resetTobTable('filtsView')" [disabled]="_cat.editView">
                <i [class]="'48px_filter' | icons"></i>
                <span>Filtrar</span>
            </button>
            <div class="filterItems animate__animated animate__fadeInRight " *ngIf="filtsView">
                <div class="closeFilt" (click)="filtsView = false">
                    <button>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </button>
                </div>
                <div class="filtTtl">
                    <h3>Filtros</h3>
                </div>
                <div class="filtActive customScrollBar">
                    <div class="filtActItm" *ngIf="_cat.filterOptsStock.country.length >= 1">
                        <span><b>País: </b> <i *ngFor="let country of _cat.filterOptsStock.country; index as i"> <i *ngIf="i >= 1"> - </i>{{country | idCountry}}</i> </span>
                        <!-- <i [class]="'48px_e-remove' | icons"></i> -->
                    </div>
                    <div class="filtActItm" *ngIf="_cat.filterOptsStock.provider.length >= 1" (click)="resetFilters_item('provider')">
                        <span><b>Proveedor: </b> <i *ngFor="let b_unit of _cat.filterOptsStock.provider; index as i"> <i *ngIf="i >= 1"> - </i> {{b_unit | idProvider}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>

                    <div class="filtActItm" *ngIf="_cat.filterOptsStock.sku" (click)="resetFilters_item('sku')">
                        <span><b>SKU: </b>{{_cat.filterOptsStock.sku}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_cat.filterOptsStock.sku_full" (click)="resetFilters_item('sku_full')">
                        <span><b>SKU FULL: </b>{{_cat.filterOptsStock.sku_full}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_cat.filterOptsStock.name" (click)="resetFilters_item('sku')">
                        <span><b>Nombre: </b>{{_cat.filterOptsStock.name}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_cat.filterOptsStock.manufacturer.length >= 1" (click)="resetFilters_item('manufacturer')">
                        <span><b>Laboratorio: </b> <i *ngFor="let man of _cat.filterOptsStock.manufacturer; index as i"> <i *ngIf="i >= 1"> - </i> {{man | idManufac}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_cat.filterOptsStock.brand.length >= 1" (click)="resetFilters_item('brand')">
                        <span><b>Marca: </b> <i *ngFor="let man of _cat.filterOptsStock.brand; index as i"> <i *ngIf="i >= 1"> - </i> {{man | idBrand}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_cat.filterOptsStock.sap.length >= 1" (click)="resetFilters_item('sap')">
                        <span><b>SAP: </b>{{_cat.filterOptsStock.sap}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                </div>
                <div class="filtList customScrollBar">
                    <div class="filItm filItm_hide">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <mat-select [(ngModel)]="_cat.filterOptsStock.country" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option *ngFor="let opt of _gen.allCountries" value="{{opt.country_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Proveedor</mat-label>
                                <mat-select [(ngModel)]="_cat.filterOptsStock.provider" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option *ngFor="let opt of _ord.filt_provider" value="{{opt.provider_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_cat.filterOptsStock.name" name="name">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Laboratorio</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>laboratorios</mat-label>
                                <mat-select [(ngModel)]="_cat.filterOptsStock.manufacturer" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option *ngFor="let opt of _cat.filt_manufac" value="{{opt.manufacturer_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Marca</mat-label>
                                <mat-select [(ngModel)]="_cat.filterOptsStock.brand" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option *ngFor="let opt of _cat.filt_brand" value="{{opt.brand_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">SKU</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>SKU</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_cat.filterOptsStock.sku" name="sku">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">SKU FULL</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>SKU FULL</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_cat.filterOptsStock.sku_full" name="sku_full">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">SKU FULL</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>SAP</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_cat.filterOptsStock.sap" name="sku_full">
                              </mat-form-field>
                        </div>    
                    </div>
                </div>
                <div class="filtSend">
                    <button class="btn" (click)="resetFilters_item('')">
                        Borrar filtros
                    </button>
                    <button class="btn btn_success btn_fill" (click)="checkFilter()">
                        Aplicar filtros
                    </button>
                </div>
            </div>
        </div>

        <div class="colsView">
            <button class="btn editBtn" (click)="resetTobTable('colsView')" [disabled]="_cat.editView">
                <i [class]="'48px_separate-2' | icons"></i>
                <span>Columnas</span>
            </button>
            <div class="columnsItems animate__animated animate__fadeInUp " *ngIf="colsView" appClickOutside (clickOutside)="colsView = false">
                <div class="colItem" *ngFor="let col of columnsConfig; index as i">
                    <div class="ios_checkBox">
                        <input type='checkbox' id='checkbox-{{i}}' [checked]="col.visible" (change)="col.visible = !col.visible">
                        <label for='checkbox-{{i}}'>{{col.label}}</label>
                    </div>
                </div>
            </div>
        </div>


        <!-- <button class="btn editBtn btn_success btn_fill" [disabled]="_cat.sel_catalog.length >= 1">
            <i [class]="'48px_arrow-up' | icons"></i>
            <span>Nuevo Producto</span>
        </button> -->

    </div>
</div>

<app-table [cols]="columnsConfig" [data]="dataSource" [type]="tableType" [uniqueID]="_cat.editStockId"></app-table>

<div class="bottomTable">
    <div class="pageInp">
        <div class="form_field">
            <input type="text" name="" id="" value="{{currPage}}" (change)="changePageInp( $event, true )">
        </div>
        <span>de {{currPageTotal}}</span>
    </div>
    
    <div class="pagerWrap">
        <div class="pagerItms">
            <button class="btn btn_sm prev" [disabled]="currPage == 1" (click)="changePageInp( currPage - 1 )"> <i [class]="'48px_left-arrow' | icons"></i> </button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 2 )" [disabled]="currPage <= 2">{{currPage - 2}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 1 )" [disabled]="currPage == 1">{{currPage - 1}}</button>
            <button class="btn btn_sm page active">{{currPage}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 1 )" [disabled]="currPage == currPageTotal" >{{currPage + 1}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 2 )" [disabled]="currPage >= currPageTotal - 1" >{{currPage + 2}}</button>
            <button class="btn btn_sm page" disabled>...</button>
            <button class="btn btn_sm next" (click)="changePageInp( currPageTotal )" [disabled]="currPage == currPageTotal" >{{currPageTotal}}</button>
            <button class="btn btn_sm page last" [disabled]="currPage == currPageTotal" (click)="changePageInp( currPage + 1 )"> <i [class]="'48px_right-arrow' | icons"></i> </button>
        </div>
    </div>

    <div class="perPage">
        <div class="form_field">
            <div class="select_box">
                <select name="" id="" [(ngModel)]="currPageView" (change)=" getAllProds() ">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                </select>
                <i [class]="'48px_down-arrow' | icons"></i>
            </div>
        </div>
        <span>por página</span>
    </div>
</div>