import { Pipe, PipeTransform } from '@angular/core';
import { CatalogService } from '../services/catalog.service';

@Pipe({
  name: 'nameManufact'
})
export class NameManufactPipe implements PipeTransform {

  private val:string = "";

  constructor( public _cat:CatalogService ) {
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    for (let f = 0; f < this._cat.filt_manufac.length; f++) {
      const element = this._cat.filt_manufac[f];
      
      if ( element.name == value ) {
        this.val = element.manufacturer_id
      }
    }
    return this.val
  }

}
