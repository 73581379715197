<div class="processElectronicInvoicesOverLay">
  <div class="processElectronicInvoicesBox animate__animated animate__zoomIn">
    <div class="loaderGeneral" *ngIf="loaderAllComponent">
      <div class="icon">
        <img src="assets/img/preload.gif" alt="" />
      </div>
    </div>

    <button class="closeBtn" (click)="closeModal()">
      <span></span>
    </button>

    <div class="processElectronicInvoicesInfo">
      <div class="ttl">Añadir Factura Electrónica</div>
      <div class="receptionChanged">
        <span>
          Referencia del Proveedor: <b>{{ item.reference }}</b>
        </span>
        <span>
          Proveedor: <b>{{ item.provider.name }}</b>
        </span>
        <span>
          Orden Interna: <b>{{ item.order?.reference }}</b>
        </span>
      </div>
      <div class="listElectronicInvoicesContainer">
        <div class="listElectronicInvoices">
          <div
            class="loaderListElectronicInvoices"
            *ngIf="loaderListElectronicInvoices"
          >
            <div class="icon">
              <img src="assets/img/preload.gif" alt="" />
            </div>
          </div>
          <form
            *ngIf="
              !loaderListElectronicInvoices &&
              listElectronicInvoices.length >= 1 &&
              !listElectronicInvoicesError
            "
            [formGroup]="electronicInvoiceForm"
            class="formStatusContainer"
            (ngSubmit)="save($event)"
          >
            <div class="form_field">
                <mat-form-field appearance="outline">
                <mat-label>Factura Electrónica</mat-label>
                <input
                  matInput
                  formControlName="electronicInvoice"
                  [matAutocomplete]="auto"
                  (input)="validateInvoiceInput()"
                />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let invoice of filInvoices | async" [value]="invoice.consecutive">
                    {{invoice.consecutive}}
                  </mat-option>
                </mat-autocomplete>
                </mat-form-field>
                <p class="error_message"  [class.active]="
                electronicInvoiceField?.touched &&
                electronicInvoiceField?.invalid
              " *ngIf="electronicInvoiceField?.hasError('invalidInvoice')">
                  <strong>Ingrese una factura válida</strong>
                </p>
                <p
                class="error_message"
                [class.active]="
                  electronicInvoiceField?.touched &&
                  electronicInvoiceField?.invalid
                "
              >
                <strong
                  *ngIf="
                    electronicInvoiceField?.touched &&
                    electronicInvoiceField?.hasError('required')
                  "
                >
                  Selecciona una factura electrónica
                </strong>
              </p>
            </div>
            <div class="buttons">
              <button
                class="btn btn_secondary"
                type="button"
                (click)="closeModal()"
              >
                Cancelar
              </button>
              <button
                class="btn btn_success"
                type="submit"
                [disabled]="!electronicInvoiceForm.valid || loaderAllComponent"
              >
                Continuar
              </button>
            </div>
          </form>
          <div
            class="noElectronicInvoice"
            *ngIf="
              !loaderListElectronicInvoices &&
              listElectronicInvoices.length == 0 &&
              !listElectronicInvoicesError
            "
          >
            <i class="{{ '48px_empty' | icons }}"></i>
            <span>No se encontraron facturas electrónicas</span>
          </div>
          <div
            class="errorElectronicInvoices"
            *ngIf="!loaderListElectronicInvoices && listElectronicInvoicesError"
          >
            <i class="{{ '48px_c-warning' | icons }}"></i>
            <span>{{ listElectronicInvoicesError }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
