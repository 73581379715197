import { Component, Input, OnInit } from '@angular/core';
import { CatalogService } from 'src/app/services/catalog.service';
import { PedidosService } from 'src/app/services/pedidos.service';

@Component({
  selector: 'app-ped-detail',
  templateUrl: './ped-detail.component.html',
  styles: [
  ]
})
export class PedDetailComponent implements OnInit {

  @Input() detailId:any;

  pedData:any;

  constructor( public _cat:CatalogService, public _ped:PedidosService ) { }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    let tempProds;
    await this.getProdData().then( (data:any) => {
      tempProds = data;
    });
    await this.getAllData( tempProds ).then( () => {
      // console.log( this.pedData );
    });

  }

  getProdData() {
    let prom = new Promise( (resolve,reject ) => {
      let prods:any[] = [];
      
      this._ped.getAllProdsById( this.detailId ).then( ({ data }) => {  
        for (let p = 0; p < data.products.length; p++) {
          const element = data.products[p];
          let partSku = element.sku_full.split('_');   

          this._cat.getAllProdsFilterBySKU( element.sku_full ).then( ({data}) => {
            prods.push( {
              partSku: partSku,
              item_id: element.item_id,
              quantity: element.quantity,
              campaign_name: element.campaign_name,
              prod: data,
            } );

            resolve( prods );
          })
        }
      })
    })
    return prom;
  }
  getAllData( prods:any ) {

    let prom = new Promise( (resolve,reject) => {
      
      this._ped.getAllProdsById( this.detailId ).then( ({ data }) => {  
        this.pedData = {
          order_number: data.order_number,
          status: data.status,
          user: {
            customer_name: data.customer_name,
            customer_mail: data.customer_mail,
            customer_phone: data.customer_phone,
          },
          shipment: {
            country: data.country.name,
          },
          delivery_promise: {
            from_day: new Date( data.delivery_promise_from ).getDate(),
            from_month: new Date( data.delivery_promise_from ).getMonth(),
            to_day: new Date( data.delivery_promise_to ).getDate(),
            to_month: new Date( data.delivery_promise_to ).getMonth(),
          },
          carrier: {
            carrier: data.carrier,
            tracking_number: data.tracking_number,
          },
          products: prods
        }

        resolve( true )

      })
    })
    return prom;


  }

}
