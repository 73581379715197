import { Injectable } from '@angular/core';
import axios from 'axios';
import { GeneralService } from './general.service';
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  sel_pedidos:any[] = [];
  editView:boolean = false;
  editId:string = "";
  
  filt_country:any[] = [];
  filt_bUnit:any[] = [];
  filt_status:any[] = [];
  filt_provider:any[] = [];
  filt_reference:any[] = [];
  
  detailView:boolean = false;
  detailId:any;

  filterOpts:any = {
    country: [],
    business_unit: [],
    status: [],
    
    from: "",
    to: "",
    
    sku_full: "",
    prod: "",
    coupon: "",

    city: "",
    region: "",
    postcode: "",

    sku: "",
    carrier: [],
    order_number: "",
    tracking_number: "",
    confirmed_at_from: "",
    confirmed_at_to: "",
    prepared_at_from: "",
    prepared_at_to: "",

    provider_id: [],
    reference: [],
    
    providers_complete: ""
  }

  pedListsIds:any[] = [];

  processView:boolean = false;
  shipView:boolean = false;

  constructor( public _gen:GeneralService, public _log:LoginService,  private http:HttpClient) { }

  /* Filters */

  /* COUNT */
  getAllProdsCountFilt( filters:any ) {    
    return axios.get(`${this._gen.serverURL}/magento/sale/?${filters}count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  /* products */
  getAllProdsFilter( filters:any, limit:any = 100, page:number = 1) {    
    return axios.get(`${this._gen.serverURL}/magento/sale/?${filters}limit=${limit}&page=${page}&products=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  
  /* GET BY ID */
  getAllProdsById( id:any ) {    
    return axios.get(`${this._gen.serverURL}/magento/sale/${id}/?products=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllProdsByNumber( number:any ) {    
    return axios.get(`${this._gen.serverURL}/magento/sale/?order_number=${number}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllProdsByOrdId( id:any ) {        
    return axios.get(`${this._gen.serverURL}/providers/order_product/?sale=${id}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  /* CHANGE STATUS */
  changeStatus( data:any ) {    
    return axios.post(`${this._gen.serverURL}/magento/update_status/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  changeStatusSpecialImport( data:any ) {
    return axios.post(`${this._gen.serverURL}/magento/update_status_special_import/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  
  /* CHANGE INFO PEDIDO */
  changeInfoPed( data:any ) {    
    return axios.put(`${this._gen.serverURL}/magento/sale/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  genShipment( data:any ) {    
    return axios.post(`${this._gen.serverURL}/magento/create_shipment/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  genPrints( data:any ) {    
    return axios.post(`${this._gen.serverURL}/providers/ticket_sales/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  getPdfToPrint(url:string) {
    console.log({url})
    return this.http.get(url, {
      responseType: 'blob'
    })
  }

  genPrintsWithStock(payload:any) {
    return axios.post(`${this._gen.serverURL}/providers/strip_printing/?country=${ this._gen.currCountry}`, payload, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
}
