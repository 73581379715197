
<div class="topttls">
    <app-tittle [label]="'Ordenes de compra'" [icon]="'48px_box-3d-50'"></app-tittle>

    <div class="btns">
        &nbsp;

        <div class="totalProds">
            <div><span>Total registros:</span> <b>{{ dataSourceCount }}</b></div>
        </div>
    </div>
</div>

<div class="topTableWrap">
    <div class="left">
        <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_ord.sel_orders.length == 1 && !_ord.editView" (click)="editBtnFx()">
            <i [class]="'48px_edit' | icons"></i>
            <span>Editar</span>
        </button>
        <!-- <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_ord.sel_orders.length >= 1 && !_ord.editView" >
            <i [class]="'48px_trash-can' | icons"></i>
            <span>Eliminar</span>
        </button> -->
    </div>
    <div class="right">
        <button class="btn editBtn" *ngIf="_ord.sel_orders.length == 1 && !_ord.editView" (click)="viewDetail( _ord.sel_orders[0]?.order_id )">
            <i [class]="'48px_arrow-up' | icons"></i>
            <span>detalle</span>
        </button>

        <div class="filterView">
            <button class="btn editBtn" (click)="resetTobTable('filtsView')" [disabled]="_ord.editView">
                <i [class]="'48px_filter' | icons"></i>
                <span>Filtrar</span>
            </button>
            <div class="filterItems animate__animated animate__fadeInRight " *ngIf="filtsView">
                <div class="closeFilt" (click)="filtsView = false">
                    <button>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </button>
                </div>
                <div class="filtTtl">
                    <h3>Filtros</h3>
                </div>
                <div class="filtActive customScrollBar">
                    <div class="filtActItm" *ngIf="_ord.filterOpts.country.length >= 1">
                        <span><b>País: </b> <i *ngFor="let country of _ord.filterOpts.country; index as i"> <i *ngIf="i >= 1"> - </i> {{country | idCountry}}</i> </span>
                        <!-- <i [class]="'48px_e-remove' | icons"></i> -->
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.business_unit.length >= 1" (click)="resetFilters_item('business_unit')">
                        <span><b>U. Negocio: </b> <i *ngFor="let b_unit of _ord.filterOpts.business_unit; index as i"> <i *ngIf="i >= 1"> - </i> {{b_unit | idBunit}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.provider.length >= 1" (click)="resetFilters_item('provider')">
                        <span><b>Proveedor: </b> <i *ngFor="let b_unit of _ord.filterOpts.provider; index as i"> <i *ngIf="i >= 1"> - </i> {{b_unit | idProvider}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.reason.length >= 1" (click)="resetFilters_item('reason')">
                        <span><b>Tipo: </b> <i *ngFor="let reason of _ord.filterOpts.reason; index as i"> <i *ngIf="i >= 1"> - </i> {{reason}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.status.length >= 1" (click)="resetFilters_item('status')">
                        <span><b>Estado: </b> <i *ngFor="let status of _ord.filterOpts.status; index as i"> <i *ngIf="i >= 1"> - </i> {{status}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.from" (click)="resetFilters_item('from')">
                        <span><b>Desde: </b> <i> {{ _gen.getDateFormat( _ord.filterOpts.from ) }} </i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.to" (click)="resetFilters_item('to')">
                        <span><b>Hasta: </b> <i> {{ _gen.getDateFormat( _ord.filterOpts.to ) }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.order_number" (click)="resetFilters_item('order_number')">
                        <span><b>Pedido: </b> <i> {{ _ord.filterOpts.order_number }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.reference" (click)="resetFilters_item('reference')">
                        <span><b>Orden: </b> <i> {{ _ord.filterOpts.reference }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_ord.filterOpts.order_provider" (click)="resetFilters_item('order_provider')">
                        <span><b>Orden Provider: </b> <i> {{ _ord.filterOpts.order_provider }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                </div>
                <div class="filtList customScrollBar">
                    <div class="filItm filItm_hide">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOpts.country" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _gen.allCountries" value="{{opt.country_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>U. negocio</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOpts.business_unit" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _ord.filt_bUnit" value="{{opt.business_unit_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Proveedor</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOpts.provider" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _ord.filt_provider" value="{{opt.provider_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Tipo</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOpts.reason" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _ord.filt_reason" value="{{opt.reason_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Estado</mat-label>
                                <mat-select [(ngModel)]="_ord.filterOpts.status" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let itm of _ord.filt_orderStatus" value="{{itm.name}}">{{itm.pretty}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp3.open()">
                                <mat-label>Desde</mat-label>
                                <input matInput [matDatepicker]="dp3" [max]="maxDate" [(ngModel)]="_ord.filterOpts.from" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp4.open()">
                                <mat-label>Hasta</mat-label>
                                <input matInput [matDatepicker]="dp4" [max]="maxDate" [(ngModel)]="_ord.filterOpts.to" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                                <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Num. de Pedido</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ord.filterOpts.order_number" name="order_number" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Num. de Orden</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ord.filterOpts.reference" name="reference" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Orden Proveedor</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_ord.filterOpts.order_provider" name="order_provider" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                </div>
                <div class="filtSend">
                    <button class="btn" (click)="resetFilters_item('')">
                        Borrar filtros
                    </button>
                    <button class="btn btn_success btn_fill" (click)="checkFilter()">
                        Aplicar filtros
                    </button>
                </div>
            </div>
        </div>

        <div class="colsView">
            <button class="btn editBtn" (click)="resetTobTable('colsView')" [disabled]="_ord.editView">
                <i [class]="'48px_separate-2' | icons"></i>
                <span>Columnas</span>
            </button>
            <div class="columnsItems animate__animated animate__fadeInUp " *ngIf="colsView" appClickOutside (clickOutside)="colsView = false">
                <div class="colItem" *ngFor="let col of columnsConfig; index as i">
                    <div class="ios_checkBox">
                        <input type='checkbox' id='checkbox-{{i}}' [checked]="col.visible" (change)="col.visible = !col.visible">
                        <label for='checkbox-{{i}}'>{{col.label}}</label>
                    </div>
                </div>
            </div>
        </div>

        <button class="btn editBtn btn_success btn_fill" (click)="newOrder()">
            <i [class]="'48px_arrow-up' | icons"></i>
            <span>Crear una O. de compra</span>
        </button>
    </div>
</div>

<app-table [cols]="columnsConfig" [data]="dataSource" [type]="tableType" [uniqueID]="_ord.editId"></app-table>

<div class="bottomTable">
    <div class="pageInp">
        <div class="form_field">
            <input type="text" name="" id="" value="{{currPage}}" (change)="changePageInp( $event, true )">
        </div>
        <span>de {{currPageTotal}}</span>
    </div>
    
    <div class="pagerWrap">
        <div class="pagerItms">
            <button class="btn btn_sm prev" [disabled]="currPage == 1" (click)="changePageInp( currPage - 1 )"> <i [class]="'48px_left-arrow' | icons"></i> </button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 2 )" [disabled]="currPage <= 2">{{currPage - 2}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 1 )" [disabled]="currPage == 1">{{currPage - 1}}</button>
            <button class="btn btn_sm page active">{{currPage}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 1 )" [disabled]="currPage == currPageTotal" >{{currPage + 1}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 2 )" [disabled]="currPage >= currPageTotal - 1" >{{currPage + 2}}</button>
            <button class="btn btn_sm page" disabled>...</button>
            <button class="btn btn_sm next" (click)="changePageInp( currPageTotal )" [disabled]="currPage == currPageTotal" >{{currPageTotal}}</button>
            <button class="btn btn_sm page last" [disabled]="currPage == currPageTotal" (click)="changePageInp( currPage + 1 )"> <i [class]="'48px_right-arrow' | icons"></i> </button>
        </div>
    </div>

    <div class="perPage">
        <div class="form_field">
            <div class="select_box">
                <select name="" id="" [(ngModel)]="currPageView" (change)=" getAllProds() ">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
                <i [class]="'48px_down-arrow' | icons"></i>
            </div>
        </div>
        <span>por página</span>
    </div>
</div>