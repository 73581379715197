import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { PedidosService } from 'src/app/services/pedidos.service';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-ship-peds',
  templateUrl: './ship-peds.component.html',
  styles: [
  ]
})
export class ShipPedsComponent implements OnInit {

  @Input() list:any[] = [];
  listTemp:any;

  listSuccess:any[] = [];
  listToChange:any[] = [];
  listToChangeSend:any[] = [];

  carriers:any = [];

  listCharge:boolean = false;

  constructor( public _gen:GeneralService, public _ped:PedidosService ) { }

  ngOnInit(): void {

    this._gen.getCarriers().then( ({data})=> {

      this.carriers = data;      

      setTimeout(() => {  
        this.init();
      }, 500);

    });

  }

  async init() {
    this.listTemp = JSON.parse(JSON.stringify( this.list ));

    await this.getTotalsuccess ( this.listTemp );    
    
    this.listCharge = !this.listCharge;    
  }

  getTotalsuccess( list:any ) {
    for (let d = 0; d < list.length; d++) {
      const element:any = list[d];      
      this.listSuccess.push( element );
    }
  }

  changeCarrSel( evt:any ) {
    this.listToChange = [];

    for (let d = 0; d < this.listTemp.length; d++) {
      const element:any = this.listTemp[d];      
      if ( element.newCarrier !== '' ) {
        this.listToChange.push( element );
      }
    } 

    this.listToChangeSend = [];
  
    for (let g = 0; g < this.listToChange.length; g++) {
      const stats = this.listToChange[g];      
  
      for (let h = 0; h < stats.pedidos.length; h++) {
        const element = stats.pedidos[h];
        
        this.listToChangeSend.push({
          sale_id: element.sale_id,
          carrier: stats.newCarrier,
        })
      }
      
    }

  }

  convertToShip() {
    // console.log( this.listToChangeSend );

    Swal.fire({
      title: '<strong>Procesando pedidos</strong>',
      icon: 'info',
      html: `<img src="assets/img/preload.gif" alt="">`,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    
    setTimeout(() => {
      
      this._ped.genShipment( this.listToChangeSend ).then( () => {
        Swal.fire(
          `Envio de pedido exitoso`,
          '',
          'success'
        ).then( () => {        
          this.closeModal();
        })
      })
      
    }, 3000);
    
  }

  /* * * * * * */
  closeModal() {
    this._ped.shipView = false;
    this.list = [];
    this.listTemp = '';
  }

}
