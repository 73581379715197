import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogService } from 'src/app/services/catalog.service';
import { GeneralService } from 'src/app/services/general.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PedidosService } from 'src/app/services/pedidos.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styles: [
  ]
})
export class OrderItemComponent implements OnInit {

  detailId:any;
  ordData:any;

  loaderView:boolean = true;

  // editProduct:number = 0;
  // changeStatidView:number = 0;
  // changeStatView:boolean = false;

  editProduct:any;
  
  changeStatQtyView:boolean = false;
  currCommentItem:any = null;
  enableEditingRef: boolean = false;
  providerRefValue:string = '';

  constructor( public aRoute:ActivatedRoute, public route:Router, public _ord:OrdersService, public _cat:CatalogService, public _gen:GeneralService, public _ped:PedidosService ) {
    this.aRoute.params.subscribe( ({id}) => {
      this.detailId = id;
    })
  }

  ngOnInit(): void {
    this.getData();

    this._gen.getPedStatuses();
    this._gen.getProdsStatuses();
    
    this._gen.prodStatusViewObs.subscribe(() => {
      this.initData();
    })
  }
  
  initData() {
    this.loaderView = !this.loaderView;
  
    this.getData();
  
    this._gen.getPedStatuses();
    this._gen.getProdsStatuses();
  }

  async getData() {
    await this.getAllData(  ).then( () => {
      this.loaderView = !this.loaderView;
      // console.log( this.ordData );
    });
  }

  getProdData( products:any ) {
    let prods:any[] = []; 
    if ( products.length >= 1 ) {
      for (let p = 0; p < products.length; p++) {
        let partSku = "";
        const element = products[p]; 
        
        if ( element.sku_full ) {
          partSku = element.sku_full.sku_full.split('_');  
        }
        let qtyArr:any[] = [];
        for (let d = 0; d < element.quantity; d++) {
          qtyArr.push( d + 1 );
        }
        
        prods.push( {

          partSku: partSku,
          order_number: element?.order_number,
          order_product_id: element.order_product_id,
          commentProd: element?.comments,
          comment: element.item?.comment,
          item_id: element.item?.item_id,
          status: element.status,
          statuses: element.statuses,
          newStatus: "created",
          quantity: element.quantity,
          quantityArr: qtyArr,
          newQuantity: 1,
          prod: element.sku_full,
        } );
      }

      return prods;
    } else {
      return [];
    }

  }
  getAllData( ) {

    let prom = new Promise( (resolve,reject) => {
      
      this._ord.getAllProdsById( this.detailId ).then( ({ data }) => {           
                       
        this.ordData = {
          comments: data.comments,
          order_id: data.order_id,
          reference: data.reference,
          created_at: data.created_at,
          status: data.status,
          user: {
            email: data.user.email,
            first_name: data.user.first_name,
            last_name: data.user.last_name,
          },
          provider: {
            name: data.provider.name,
            provider_id: data.provider.provider_id,
            country_id: data.provider.country.country_id,
            country_name: data.provider.country.name,
          },
          external_reference: data.external_reference,
          products: this.getProdData( data.products ),
        }

        this.providerRefValue = data.external_reference;
        
        console.log( this.ordData.products );
        resolve( true )

      })
    })

    
    return prom;


  }

  cancelOrder( orderNum:any, orderId:any ) {

    Swal.fire({
      title: `Deseas cancelar esta orden #${orderNum}`,
      html: `<label class="swal2-label">Comentario de la cancelación</label><textarea id="swal-input1" class="swal2-textarea" placeholder="Coloca el comentario aquí..."></textarea>`,
      icon: 'warning',
      showCancelButton: true,
      showDenyButton: false,
      showConfirmButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Cancelar orden',
      denyButtonText: 'Cancelar orden',
      reverseButtons: true,
      preConfirm:  () => {
        return [
          document.getElementById('swal-input1')
        ]
      }
    }).then( (result:any) => {
      // console.log( result );
      // console.log( result.value[0].value );

      if (result.isConfirmed) {
        // console.log('cancel', orderId, result.value[0].value);

        this._ord.cancelOrder( orderId, result.value[0].value ).then( ({data}) => {
          this.initData();
        }).catch( (error:any) => {
          // console.log( error.response.data.error );
          this._gen.swal_newBcode( "error", error.response.data.error ).then( () => {
            this.initData();
          })
        } )
        
      }
      if (result.isDismissed) {
        console.log('NO cancel', result);
      }

    })

  }


  /* back to pedidos */
  backToOrds() {
    this.route.navigate(['home/ordenes/'])
  }

  editProdFx( itm:any ) {  
    this._gen.prodStatusView = !this._gen.prodStatusView;
    this.editProduct = itm;
  }

  changeProdCommentFx( item:any ) {
    this.currCommentItem = null
    
    this.loaderView = true;
    
    let prodData = {
      item_id: item.item_id,
      comment: item.comment
    };  
    
    // console.log( prodData );
    
    this._ord.changeOrderProdComment( prodData )
    .then( ({data}) => {
      
      Swal.fire(
        'Cambio de comentario exitoso',
        ``,
        'success'
      ).then( () => {
        // this.loaderView = false;

        this.getData();
      })

    }).catch( (error:any) => {
      let data = error.response.data;

      Swal.fire(
        'Error al crear comentario en item',
        `${data.error}`,
        'error'
      ).then( () => {
        // this.loaderView = false;

        this.getData();
      })
      
    })
    
  }

  goToPed( order_number:any ) {
    this._ped.getAllProdsByNumber( order_number ).then( ({data}) => {
      var url = `/#/home/pedidos/${data[0].sale_id}`;
      window.open(url, '_blank');
    })
  }

  changeProviderReference( ) {
    this.enableEditingRef = false
    
    this.loaderView = true;
    
    let providerReferenceOrderdata = {
      order_id: this.ordData.order_id,
      external_reference: this.providerRefValue.toUpperCase()
    };      
  
    this._ord.updateProviderReferenceOrder(providerReferenceOrderdata)
    .then(( { data }) => {
      if(data.msg == "ok"){
        Swal.fire(
          'Cambio de referencia exitoso',
          ``,
          'success'
        ).then( () => {
          this.getData();
        })
      }
    }).catch( (error:any) => {
      let data = error.response.data;
      Swal.fire(
        'Error al crear referencia',
        `${data.error}`,
        'error'
      ).then( () => {
        this.getData();
      })
    })
  }

}
