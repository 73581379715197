<div class="readCodeOverLay" *ngIf="_gen.barCodeEntryViewPeds">

    <div class="readCodeBox readCodeBox_inputs">

        <button class="closeBtn" (click)="closeModal();">
            <span></span>
        </button>

        <!-- <form novalidate [formGroup]="newInput">
            <div class="ttl">Nueva salida de pedido</div>
    
            <div class="step step_2" *ngIf="currStep == 2">
                <div class="summary">
                    <div class="cont">
                        <div class="ttl">Productos en este pedido:</div>
                        <div class="loader" *ngIf=" ocProdListView.length == 0 ">
                            <div class="icon">
                                <img src="assets/img/preload.gif" alt="">
                            </div>
                        </div>
                        <div class="listItems"  *ngIf=" ocProdListView.length >= 1 ">
                            <div class="item" *ngFor="let item of ocProdListView">
                                <div class="top">
                                    <div class="sku">
                                        {{ item.parts[0] }}
                                    </div>
                                    <div class="status">
                                        <span *ngFor="let stat of item.statuses; index as i" class="badge {{ stat.status.name }}">{{stat.status.pretty}} ( {{ stat.quantity }} )</span>
                                    </div>
                                </div>
                                <div class="name">
                                    {{item.name}}
                                </div>
                                <div class="qty">
                                    Cantidad: {{item.quantity}}
                                </div>
                                <div class="formula">
                                    <span>{{ item.parts[1] }} &nbsp;</span>
                                    <span>{{ item.parts[2] }} &nbsp;</span>
                                    <span>{{ item.parts[3] }} &nbsp;</span>
                                    <span>{{ item.parts[4] }} &nbsp;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="readCode">
                    <div class="imgWrap">
                        <img src="assets/img/barCode.png" alt="">
                    </div>
                    <div class="ocTxt">
                        <div class="left">
                            # de O. Compra: <b>{{pedData.order_number}}</b>
                        </div>
                        <div class="right">
                            Prods. a escanear: <b>{{pedData.products_sum}}</b>
                        </div>
                    </div>
    
                    <div class="oc_masiveWrap">
                        <div class="form_field form_field_code">
                            <label for="">Escanea el código de barras<span>*</span></label>
                            <input type="number" id="barCode" placeholder="Escanea el código de barras" formControlName="codeBar" (input)="barcodeChange( $event )">
                        </div>
    
                        <div class="codesWrap">
                            <div class="form_field">
                                <label for="">EAN</label>
                                <input type="text" placeholder="EAN" formControlName="ean" (input)="addManual = true">
                            </div>
                            <div class="form_field">
                                <label for=""># lote</label>
                                <input type="text" placeholder="lote" formControlName="lote" (input)="addManual = true">
                            </div>
                            <div class="form_field">
                                <label for="">Fecha vencimiento</label>
                                <input type="text" placeholder="Fecha" formControlName="expire" (input)="addManual = true">
                            </div>
                            <div class="form_field form_field_hide">
                                <label for="">Country ID</label>
                                <input type="text" placeholder="País" formControlName="country">
                            </div>
                            <div class="form_field form_field_btn" *ngIf="addManual">
                                <button class="btn btn_info" type="button" (click)=" addBarcodeManual() ">
                                    Agregar
                                </button>
                            </div>
                        </div>
    
                        <div class="bottomText">
                            <div class="disclaimer" *ngIf="scannedCodes.length >= 1">*Al finanlizar este producto se cargará en la base</div>
                        </div>
                    </div>
    
                    <div class="btnWrap">
                        <button class="btn" type="button" (click)="closeModal()">
                            Cerrar
                        </button>
                        <button class="btn btn_success" type="button" (click)="changeStep( newInput, 3 )">
                            Ver productos de la orden
                        </button>
                    </div>
                </div>
            </div>

            <div class="step step_3" *ngIf="currStep == 3">
                <div class="ocTxt">
                    <div class="left">
                        # de O. Compra: <b>{{pedData.order_number}}</b>
                    </div>
                    <div class="right">
                        Prods. a escanear: <b>{{pedData.products_sum}}</b>
                    </div>
                </div>
                
                <div class="loader" *ngIf=" ocProdListView.length == 0 ">
                    <div class="icon">
                        <img src="assets/img/preload.gif" alt="">
                    </div>
                </div>

                <div class="oc_masiveWrap" *ngIf="ocProdListView.length >= 1">
                    <div class="codesWrap">
                        <table>
                            <thead>
                                <tr>
                                    <td>EAN</td>
                                    <td>skufull</td>
                                    <td>Cantidad</td>
                                    <td>Campaña</td>
                                    <td>Nombre</td>
                                    <td>Status</td>
                                    <td>Acciones</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let itm of ocProdListView; index as i">
                                    <td>
                                        {{itm.ean}}
                                    </td>
                                    <td>
                                        {{itm.sku_full}}
                                    </td>
                                    <td>
                                        {{itm.quantity}}
                                    </td>
                                    <td>
                                        {{itm.campaign_name}}
                                    </td>
                                    <td>
                                        {{itm.name}}
                                    </td>
                                    <td>

                                        <div class="statBox" *ngIf="editProduct != itm.order_product_id ">

                                            <div class="statusesBox">
                                                <div class="item" *ngFor="let item of itm.statuses">
                                                    <span class="badge {{ item?.status.name }}">{{item?.status.pretty}}</span>
                                                    <div class="text">
                                                        Qty: <strong>{{item.quantity}}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="statChange" *ngIf="editProduct == itm.order_product_id">
                                            <div class="customSelect_status">
                                                <div class="label">Nuevo estado</div>
                                                <div class="select" (click)="changeStatView = !changeStatView">
                                                    {{itm?.newStatus}}
                                                </div>
                                                <div class="selOptions" *ngIf="changeStatView && changeStatidView == itm.order_product_id">
                                                    <div class="item" [ngClass]="{'active': itm?.newStatus == opt.name}" *ngFor="let opt of _gen.allProdStatus" (click)="itm.newStatus = opt?.name; changeStatView = !changeStatView">
                                                        <span>{{opt.pretty}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="customSelect_status">
                                                <div class="label">Cantidad</div>
                                                <div class="select" (click)="changeStatQtyView = !changeStatQtyView">
                                                    {{itm?.newQuantity}}
                                                </div>
                                                <div class="selOptions" *ngIf="changeStatQtyView && changeStatidView == itm.order_product_id">
                                                    <div class="item"  [ngClass]="{'active': itm?.newQuantity == opt}" *ngFor="let opt of itm.quantityArr" (click)="itm.newQuantity = opt; changeStatQtyView = !changeStatQtyView">
                                                        <span>{{opt}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>

                                        <div class="statBox" *ngIf="editProduct != itm.order_product_id ">

                                            <button class="edit" *ngIf="itm.statuses.length >= 1" (click)="editProdFx( itm )">
                                                <i class="{{ '48px_pencil' | icons }}"></i>
                                                <span>Editar estados</span>
                                            </button>

                                        </div>
                                        <div class="statChange" *ngIf="editProduct == itm.order_product_id">

                                            <button class="cancel" *ngIf="itm.newStatus == 'created' " (click)="cancelProdStatusFx()">
                                                <i class="{{ '48px_e-remove' | icons }}"></i>
                                                <span>Cancelar</span>
                                            </button>
                                            <button class="save" *ngIf="itm.newStatus != 'created' " (click)="changeProdStatusFx( itm )">
                                                <i class="{{ '48px_floppy-disk' | icons }}"></i>
                                                <span>Guardar estado</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>

                <div class="btnWrap" *ngIf="ocProdListView.length >= 1">
                    <button class="btn" type="button" (click)="changeStep( newInput, 2 )">
                        Volver
                    </button>
                    <button class="btn btn_success" type="button" (click)="closeModal()">
                        Finalizar carga masiva
                    </button>
                </div>

            </div>
        </form> -->

    </div>

</div>