import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: []
})
export class OrdersComponent implements OnInit {


  constructor(   ) { }

  ngOnInit(): void {
    
  }

  

}
