

<div class="wrap_login">
    <div class="loginBox">
        <div class="imgBox">
            <div class="imgLogin">
                <img src="assets/img/loginImg.png" alt="">
            </div>
            <div class="textWrap">
                <h1>Hola 🙂,<br>Bienvenido<br>a WMS</h1>
                <p>Ingresa tu usuario y contraseña para entrar a la plataforma.</p>
            </div>
        </div>
        <form novalidate [formGroup]="login" (ngSubmit)="onLogin( login )">
            <div class="form_field">
                <label for="">Usuario<span>*</span></label>
                <!-- <input type="email" placeholder="Escribe el usuario" formControlName="mail"> -->
                <input type="text" placeholder="Escribe el usuario" formControlName="user">
            </div>
            <div class="form_field form_field_icon">
                <label for="">Contraseña<span>*</span></label>
                <input type="password" *ngIf="!viewPass" placeholder="Escribe tu contraseña" formControlName="password">
                <input type="text" *ngIf="viewPass" placeholder="Escribe tu contraseña" formControlName="password">
                <i [class]="'48px_eye' | icons" *ngIf="!viewPass" (click)="viewPass = !viewPass;"></i>
                <i [class]="'48px_b-eye' | icons" *ngIf="viewPass" (click)="viewPass = !viewPass;"></i>
            </div>
            <div class="bottomLogin">
                <div class="custom_checkboxWrap">
                    <div class="custom_checkbox">
                        <input type="checkbox" name="" id="check_remember" formControlName="remember">
                        <label for="check_remember"></label>
                    </div>
                    <label for="check_remember">Recordarme</label>
                </div>
                <!-- <div class="forget">
                    <a href="javascript:;">¿Olvidaste tu contraseña?</a>
                </div> -->
            </div>
            <div class="form_field">
                <button type="submit" class="btn btn_success btn_fill" [disabled]="login.invalid">
                    Ingresar
                </button>
            </div>
        </form>
    </div>
</div>