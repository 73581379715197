

<!-- <app-topbar></app-topbar> -->

<div class="midBox_wrapper">
    <app-side-bar></app-side-bar>
    
    <div class="mainWrapper">
        <router-outlet></router-outlet>
    </div>
</div>

<!-- <app-footer></app-footer> -->

