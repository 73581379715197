
<div class="pedDetailOverLay">

    <div class="pedDetailBox">

        <div class="loaderInfo" *ngIf="!pedData">
            <div class="icon">
                <img src="assets/img/preload.gif" alt="">
            </div>
        </div>

        <div class="pedDetailWrap" *ngIf="pedData">
            <div class="topInfo">
                <div class="ttl">
                    Detalle orden: <span>{{pedData?.order_number}}</span>
                </div>
                <div class="status">
                    <b>Estado de orden:</b>
                    <span>{{pedData?.status}}</span>
                </div>
            </div>
            <div class="infroWrap">
                <div class="formInfo formInfoCount">
                    <div class="top">
                        <div class="label">Información de cuenta</div>
                        <!-- <button class="btn">Editar</button> -->
                    </div>
                    <div class="mid">
                        <div class="form_field">
                            <label for="">Nombre</label>
                            <input type="text" name="ped_name" id="" value="{{pedData?.user.customer_name}}" disabled>
                        </div>
                        <div class="form_field" *ngIf="pedData?.user.customer_mail">
                            <label for="">Correo electrónico</label>
                            <input type="text" name="ped_name" id="" value="" disabled>
                        </div>
                        <div class="form_field">
                            <label for="">Teléfono</label>
                            <input type="text" name="ped_name" id="" value="{{pedData?.user.customer_phone}}" disabled>
                        </div>
                    </div>
                </div>
                <div class="formInfo formInfoShip">
                    <div class="top">
                        <div class="label">Información de entrega</div>
                    </div>
                    <div class="mid">
                        <div class="form_field" *ngIf="pedData?.shipment.dir">
                            <label for="">Dirección</label>
                            <input type="text" name="ped_name" id="" value="" disabled>
                        </div>
                        <div class="form_field" *ngIf="pedData?.shipment.neigbor">
                            <label for="">Barrio</label>
                            <input type="text" name="ped_name" id="" value="" disabled>
                        </div>
                        <div class="form_field" *ngIf="pedData?.shipment.city">
                            <label for="">Ciudad</label>
                            <input type="text" name="ped_name" id="" value="" disabled>
                        </div>
                        <div class="form_field">
                            <label for="">País</label>
                            <input type="text" name="ped_name" id="" value="{{pedData?.shipment.country}}" disabled>
                        </div>
                    </div>
                </div>
                <div class="formInfo formInfoDate">
                    <div class="top">
                        <div class="label">Fecha de Entrega</div>
                    </div>
                    <div class="mid">
                        <div class="txtWrap">
                            <span>Esta orden debe ser entregada entre el</span>
                            <b> {{pedData?.delivery_promise.from_day}} de {{pedData?.delivery_promise.from_month | monthName}}</b> y el <b>{{pedData?.delivery_promise.to_day}} de {{pedData?.delivery_promise.to_month | monthName}}</b>
                        </div>
                    </div>
                </div>
                <div class="formInfo formInfoCarrier">
                    <div class="top">
                        <div class="label">Información de Carrier</div>
                    </div>
                    <div class="mid">
                        <div class="form_field">
                            <label for="">carrier</label>
                            <input type="text" name="ped_name" id="" value="{{pedData?.carrier.carrier}}" disabled>
                        </div>
                        <div class="form_field">
                            <label for="">Numero de tracking</label>
                            <input type="text" name="ped_name" id="" value="{{pedData?.carrier.tracking_number}}" disabled>
                        </div>
                    </div>
                </div>
                <div class="prodList">
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Producto</td>
                                <td>Nombre campaña</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of pedData?.products">
                                <td>
                                    <img src="{{item?.prod.product.image}}" alt="{{item?.prod.product.name}}">
                                </td>
                                <td>
                                    <div class="info">
                                        <p class="name"><b>{{item?.prod.product.name}}</b></p>
                                        <p class="qty">Cantidad: <b>{{item?.quantity}}</b></p>
                                        <p class="sku">SKU: <b>{{item?.prod.sku}}</b></p>
                                        <p class="skufull">SKU FULL: <b>{{item?.prod.sku_full}}</b></p>
                                        <p class="dioptry">
                                            <span *ngIf="item?.prod.product.use_type == 1">
                                                <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                            </span>
                                            <span *ngIf="item?.prod.product.use_type == 2">
                                                <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                                <span>Cilindro (Cyl): <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                                <span>Eje (Axis): <b>{{ item?.prod.sku_full | diopBySkufull:'Axis' }}</b></span><br>
                                            </span>
                                            <span *ngIf="item?.prod.product.use_type == 3">
                                                <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                                <span>Adición (Add): <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                            </span>
                                            <span *ngIf="item?.prod.product.use_type == 4">
                                                <span>Poder (Esfera): <b>{{ item?.prod.sku_full | diopBySkufull:'Esfera' }}</b></span><br>
                                                <span>Color: <b>{{ item?.prod.sku_full | diopBySkufull:'Cyl' }}</b></span><br>
                                            </span>
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    {{item?.campaign_name}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="buttons">
                <button class="btn" (click)="_ped.detailView = false; _ped.detailId = '' ">Cancelar</button>
                <button class="btn btn_fill btn_success" disabled>Guardar</button>
            </div>
        </div>

    </div>

</div>
