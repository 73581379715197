import { Pipe, PipeTransform } from '@angular/core';
import { GeneralService } from '../services/general.service';

@Pipe({
  name: 'postCode'
})
export class PostCodePipe implements PipeTransform {

  constructor( public _gen:GeneralService ) {
  }

  transform(value: any, ...args: unknown[]): unknown {
    if (value) {
      if ( this._gen.currCountry == 4 ) {
        let tempVal = value.toString();
        if ( tempVal.length == 4 ) {
          return "0" + tempVal;
        } else {
          return tempVal ;
        }
      } else {
        return value ;
      }
    } else {
      return null;
    }
  }

}
