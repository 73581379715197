import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { PedidosService } from 'src/app/services/pedidos.service';

import { PrintService, UsbDriver } from 'ng-thermal-print';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { TableService } from 'src/app/services/table.service';
let ThermalPrinterEncoder = require('thermal-printer-encoder');
let encoder = new ThermalPrinterEncoder({
  language: 'esc-pos'
});

@Component({
  selector: 'app-pedido-list',
  templateUrl: './pedido-list.component.html',
  styles: [
  ]
})
export class PedidoListComponent implements OnInit {

  tableType: string = "pedidos";
  columnsConfig = [
    { varId: "order_number", label: "Número de orden", visible: true, type: "uniqueId", class: "", disabled: true },
    { varId: "status", label: "Estado", visible: true, type: "status", class: "longCell", disabled: false },
    { varId: "created_at", label: "Fecha de creación", visible: true, type: "date", class: "", disabled: true },
    { varId: "confirmed_at", label: "Fecha confirmación", visible: true, type: "date", class: "", disabled: true },
    { varId: "prepared_at", label: "Fecha preparación", visible: true, type: "date", class: "", disabled: true },
    { varId: "products_sum", label: "Cantidad prods.", visible: true, type: "qty", class: "idCell", disabled: true },
    { varId: "products_sku", label: "SKU Productos", visible: true, type: "products", class: "longCell noEllipsis", disabled: true },
    { varId: "products_name", label: "Nombre del Producto", visible: true, type: "productsName", class: "longCell noEllipsis", disabled: true },
    { varId: "country", label: "País", visible: true, type: "countryType", class: "idCell", disabled: true },
    { varId: "city", label: "Ciudad", visible: true, type: "", class: "", disabled: true },
    { varId: "region", label: "Región", visible: true, type: "", class: "", disabled: true },
    { varId: "postcode", label: "Código postal", visible: true, type: "postcode", class: "", disabled: true },
    { varId: "address", label: "Dirección", visible: true, type: "", class: "noEllipsis", disabled: true },
    { varId: "location_other", label: "Dir. extra", visible: true, type: "location_other", class: "noEllipsis", disabled: true },
    { varId: "business_unit", label: "Unidad de negocio", visible: true, type: "bUnitType", class: "", disabled: true },
    { varId: "customer_name", label: "Nombre", visible: true, type: "", class: "noEllipsis", disabled: true },
    { varId: "customer_phone", label: "Teléfono", visible: true, type: "", class: "", disabled: true },
    { varId: "coupon", label: "Cupón", visible: true, type: "", class: "", disabled: true },
    { varId: "delivery_promise_from", label: "Fecha Promesa desde", visible: true, type: "", class: "", disabled: true },
    { varId: "delivery_promise_to", label: "Fecha Promesa hasta", visible: true, type: "", class: "", disabled: true },
    { varId: "carrier", label: "Mensajeria", visible: true, type: "", class: "", disabled: true },
    { varId: "tracking_number", label: "Número tracking", visible: true, type: "", class: "", disabled: true },
    { varId: "observaciones_ops", label: "Observaciones", visible: true, type: "", class: "noEllipsis", disabled: true },
  ]
  dataSourcefull: any[] = [];

  colsView: boolean = false;
  filtsView: boolean = false;


  dataSourceCount = 0;
  currPage = 1;
  currPageView = 100;
  currPageTotal = 0;

  dataSource: any[] = [];

  processSel: any[] = [];
  shipSel: any[] = [];

  maxDate: Date = new Date(Date.now())


  /* THERMAL PRINT */
  usbPrintDriver: UsbDriver;

  constructor(public _ped: PedidosService, public _gen: GeneralService, public route: Router, private printService: PrintService, public iziToast: Ng2IzitoastService, public _table:TableService) {
    this.usbPrintDriver = new UsbDriver();
      navigator.usb.getDevices().then(devices => {
        const POSInfoStr: string = localStorage.getItem('POSPrinterInfo') || '';
        if (devices.length == 0 || !POSInfoStr.length) {
          navigator.usb.requestDevice({ filters: [] }).then(function (device) {
            let deviceInfo: any = {
              productId: device.productId,
              vendorId: device.vendorId
            }
            localStorage.setItem('POSPrinterInfo', JSON.stringify(deviceInfo));
          });
        }
      });




    //this.usbPrintDriver.requestUsb().subscribe(result => {
    //  this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    //});

    // console.log( this.usbPrintDriver.isConnected );

    // this.usbPrintDriver.isConnected.subscribe( (data:any) => {
    //   if ( data ) {
    //      return this.iziToast.show({
    //         title: "Impresora POS conectada",
    //         position: "topRight",
    //         color: "green",
    //         transitionIn: 'fadeInLeft',
    //         transitionOut: 'fadeOutRight',
    //         toastOnce: true,
    //       });
    //   } else {
    //     return this.iziToast.show({
    //       title: "Error con la Impresora POS, intenta reiniciarla",
    //       position: "topRight",
    //       color: "red",
    //       transitionIn: 'fadeInLeft',
    //       transitionOut: 'fadeOutRight',
    //       toastOnce: true,
    //     });
    //   }
    // })


  }

  usbDeviceConnect(): void {
    const POSInfoStr: string = localStorage.getItem('POSPrinterInfo') || '';
    if (POSInfoStr.length) {
      let POSInfo = JSON.parse(POSInfoStr);
      this.usbPrintDriver = new UsbDriver(POSInfo.vendorId, POSInfo.productId);
      this.usbPrintDriver.connect();
      console.log("Se conecto a la impresora con exito! :)");
    }
    else
      console.log("No se pudo conectar la impresora. No pudo leer la información del local storage.");
  }

  ngOnInit(): void {
    const excludedData = {...this._ped.filterOpts}
    delete excludedData["country"];
    const allFieldsEmpty = Object.entries(excludedData).every(([fieldName, fieldValue]) => this.isEmpty(fieldValue, fieldName));
    if(allFieldsEmpty) {
      this._ped.filterOpts.status = ["supplying"];
    }
    this.getAllProds();
    this.getFiltersOpts();
    this.usbDeviceConnect();

    this._gen.currCountryObs.subscribe((country) => {
      this.getAllProds();
      this.getFiltersOpts();
    })
  }

  /* Filter Opts */
  getFiltersOpts() {
    // var country = this._ped.filterOpts.country.join();
    var country = this._gen.currCountry.toString();
    this._ped.filterOpts.country = country.split(',');

    this._gen.getCountry().then(({ data }) => {
      this._gen.allCountries = data;
    })
    this._gen.getCarriers().then(({ data }) => {
      this._gen.allCarriers = data;
    })
    this._gen.getProvider(country).then(({ data }) => {
      this._ped.filt_provider = data;
    })

    // get reference
    // this._gen.getReference( country ).then( ({data}) => {      
    //   var refs:any[] = [];

    //   for (let d = 0; d < data.length; d++) {
    //     const element = data[d];
    //     refs.push( element.reference )
    //   }      
    //   this._ped.filt_reference = refs;      
    // })

    this._gen.getBUnit(country).then(({ data }) => {
      this._ped.filt_bUnit = data;
    })
    this._gen.getPedStatus().then(({ data }) => {
      this._ped.filt_status = data;
    })



  }

  /* PAGE COUNT */
  getAllProdsCountFilt(filt: any) {
    let prom = new Promise((resolve, reject) => {
      this._ped.getAllProdsCountFilt(filt).then((data: any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil(this.dataSourceCount / this.currPageView);

        if (this.currPageTotal == 0) {
          this.currPageTotal = 1;
        }
        if (this.currPage > this.currPageTotal) {
          this.currPage = this.currPageTotal
        }

        resolve(true);
      })
    })
    return prom;
  }

  /* RESET Filters */
  resetFilters_item(item: string) {
    switch (item) {
      // case 'country':
      //   this._ped.filterOpts.country = [];
      //   break;
      case 'business_unit':
        this._ped.filterOpts.business_unit = [];
        break;
      case 'status':
        this._ped.filterOpts.status = [];
        break;
      case 'carrier':
        this._ped.filterOpts.carrier = [];
        break;
      case 'provider_id':
        this._ped.filterOpts.provider_id = [];
        break;
      case 'reference':
        this._ped.filterOpts.reference = [];
        break;
      case 'from':
        this._ped.filterOpts.from = "";
        break;
      case 'to':
        this._ped.filterOpts.to = "";
        break;
      case 'confirmed_at_from':
        this._ped.filterOpts.confirmed_at_from = "";
        break;
      case 'confirmed_at_to':
        this._ped.filterOpts.confirmed_at_to = "";
        break;
      case 'prepared_at_from':
        this._ped.filterOpts.prepared_at_from = "";
        break;
      case 'prepared_at_to':
        this._ped.filterOpts.prepared_at_to = "";
        break;
      case 'sku_full':
        this._ped.filterOpts.sku_full = "";
        break;
      case 'sku':
        this._ped.filterOpts.sku = "";
        break;
      case 'prod':
        this._ped.filterOpts.prod = "";
        break;
      case 'coupon':
        this._ped.filterOpts.coupon = "";
        break;
      case 'order_number':
        this._ped.filterOpts.order_number = "";
        break;
      case 'providers_complete':
        this._ped.filterOpts.providers_complete = "";
        break;
      case 'tracking_number':
        this._ped.filterOpts.tracking_number = "";
        break;
      case 'city':
        this._ped.filterOpts.city = "";
        break;
      case 'region':
        this._ped.filterOpts.region = "";
        break;
      case 'postcode':
        this._ped.filterOpts.postcode = "";
        break;

      default:
        this._ped.filterOpts = {
          // country: [],
          business_unit: [],
          status: [],

          from: "",
          to: "",

          sku_full: "",
          prod: "",
          coupon: "",

          city: "",
          region: "",
          postcode: "",

          sku: "",
          carrier: [],
          order_number: "",
          tracking_number: "",
          confirmed_at_from: "",
          confirmed_at_to: "",
          prepared_at_from: "",
          prepared_at_to: "",

          provider_id: [],
          reference: [],

          providers_complete: ""
        };

        break;
    }
    this.checkFilter();
  }
  /* RESET */
  resetTobTable(type: string) {
    setTimeout(() => {
      if (type == 'colsView') {
        this.filtsView = false;
        if (this.colsView) { this.colsView = false } else { this.colsView = true }
      } else if (type == 'filtsView') {
        this.colsView = false;
        if (this.filtsView) { this.filtsView = false } else { this.filtsView = true }
      }
    }, 150);
  }
  resetTable(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];

      this._ped.pedListsIds = [];

      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element: any = this.dataSourcefull[d];
        let item = {
          sale_id: element.sale_id,
          order_number: element.order_number,
          status: element.status,
          country: element.country,
          city: element.city,
          address: element.address,
          location_other: {
            colony: element.location_other && element.location_other.colony,
            delegation: element.location_other && element.location_other.delegation,
            all: element.location_other && element.location_other,
          },
          region: element.region,
          postcode: element.postcode,
          business_unit: element.business_unit,
          created_at: element.created_at,
          customer_name: element.customer_name,
          customer_phone: element.customer_phone,
          confirmed_at: element.confirmed_at,
          prepared_at: element.prepared_at,
          products: element.products,
          products_sum: element.products_sum,
          products_sku: element.products_sku,
          products_name: element.products_name,
          delivery_promise_from: element.delivery_promise_from,
          delivery_promise_to: element.delivery_promise_to,
          coupon: element.coupon,
          carrier: element.carrier,
          tracking_number: element.tracking_number,
          observaciones_ops: element.observaciones_ops
        }
        this.dataSource.push(item);
      }

      this._ped.filt_reference = [];
      for (let f = 0; f < this.dataSourcefull.length; f++) {
        const element = this.dataSourcefull[f];

        // Get references
        if (element.references.length >= 1 && element.references[0] !== null) {

          for (let f = 0; f < element.references.length; f++) {
            const elemRef: any = element.references[f];

            if (!this._ped.filt_reference.includes(elemRef) && elemRef != null) {
              this._ped.filt_reference.push(elemRef);
            }

          }
        }

        // Get Ids
        this._ped.pedListsIds.push({
          order_number: element.order_number,
          sale_id: element.sale_id,
        })

      }

      resolve(true);
    })

    return prom;
  }

  /* PAGER FX */
  getAllProds() {
    this._gen.tableLoader = true;
    this.clearSelectedPedsTable()
    this.getAllProdsCountFilt(this.checkFilterVal()).then(() => {
      this._ped.getAllProdsFilter(this.checkFilterVal(), this.currPageView, this.currPage).then(({ data }) => {
        this.resetTable(data).then((data: any) => {
          this._gen.tableLoader = false;
        });
      })
    });
  }
  changePageInp(page: any, input: boolean = false) {
    if (input) {
      this.currPage = parseInt(page.target.value);
    } else {
      this.currPage = parseInt(page);
    }
    this._gen.tableLoader = true;

    this.getAllProdsCountFilt(this.checkFilterVal()).then(() => {
      this._ped.getAllProdsFilter(this.checkFilterVal(), this.currPageView, this.currPage).then((data: any) => {
        this.resetTable(data.data).then((data: any) => {
          this._gen.tableLoader = false;
        });
      })
    });

  }

  /* filters */
  checkFilterVal() {
    let
      filt_business_unit,
      filt_country,
      filt_status,
      filt_carrier,
      filt_from,
      filt_to,
      filt_sku_full,
      filt_sku,
      filt_prod,
      filt_coupon,
      filt_city,
      filt_region,
      filt_postcode,
      filt_order_number,
      filt_tracking_number,
      filt_confirmed_at_from,
      filt_confirmed_at_to,
      filt_prepared_at_from,
      filt_prepared_at_to,
      filt_provider,
      filt_reference,
      filt_providers_complete;

    filt_country = `country=${this._gen.currCountry}&`;


    if (this._ped.filterOpts.business_unit.length >= 1) {
      filt_business_unit = `business_unit=${this._ped.filterOpts.business_unit}&`;
    }
    // if ( this._ped.filterOpts.country.length >= 1 ) {
    //   filt_country = `country=${ this._ped.filterOpts.country.join() }&`;
    // } 
    if (this._ped.filterOpts.status.length >= 1) {
      filt_status = `status=${this._ped.filterOpts.status.join()}&`;
    }
    if (this._ped.filterOpts.carrier.length >= 1) {
      filt_carrier = `carrier=${this._ped.filterOpts.carrier.join()}&`;
    }
    if (this._ped.filterOpts.provider_id.length >= 1) {
      filt_provider = `provider_id=${this._ped.filterOpts.provider_id.join()}&`;
    }
    if (this._ped.filterOpts.reference.length >= 1) {
      filt_reference = `reference=${this._ped.filterOpts.reference.join()}&`;
    }
    if (this._ped.filterOpts.from) {
      filt_from = `created_at_from=${this._gen.getDateFormat(this._ped.filterOpts.from)}&`;
    }
    if (this._ped.filterOpts.to) {
      filt_to = `created_at_to=${this._gen.getDateFormat(this._ped.filterOpts.to)} 23:59&`;
    }
    if (this._ped.filterOpts.confirmed_at_from) {
      filt_confirmed_at_from = `confirmed_at_from=${this._gen.getDateFormat(this._ped.filterOpts.confirmed_at_from)}&`;
    }
    if (this._ped.filterOpts.confirmed_at_to) {
      filt_confirmed_at_to = `confirmed_at_to=${this._gen.getDateFormat(this._ped.filterOpts.confirmed_at_to)} 23:59&`;
    }
    if (this._ped.filterOpts.prepared_at_from) {
      filt_prepared_at_from = `prepared_at_from=${this._gen.getDateFormat(this._ped.filterOpts.prepared_at_from)}&`;
    }
    if (this._ped.filterOpts.prepared_at_to) {
      filt_prepared_at_to = `prepared_at_to=${this._gen.getDateFormat(this._ped.filterOpts.prepared_at_to)} 23:59&`;
    }
    if (this._ped.filterOpts.sku_full) {
      filt_sku_full = `sku_full=${encodeURIComponent(this._ped.filterOpts.sku_full.toUpperCase())}&`;
    }
    if (this._ped.filterOpts.sku) {
      filt_sku = `sku=${this._ped.filterOpts.sku}&`;
    }
    if (this._ped.filterOpts.prod) {
      filt_prod = `product=${this._ped.filterOpts.prod}&`;
    }
    if (this._ped.filterOpts.coupon) {
      filt_coupon = `coupon=${this._ped.filterOpts.coupon}&`;
    }
    if (this._ped.filterOpts.order_number) {
      filt_order_number = `order_number=${this._ped.filterOpts.order_number}&`;
    }
    if (this._ped.filterOpts.providers_complete) {
      filt_providers_complete = `providers_complete=${this._ped.filterOpts.providers_complete}&`;
    }
    if (this._ped.filterOpts.tracking_number) {
      filt_tracking_number = `tracking_number=${this._ped.filterOpts.tracking_number}&`;
    }
    if (this._ped.filterOpts.city) {
      filt_city = `city=${this._ped.filterOpts.city}&`;
    }
    if (this._ped.filterOpts.region) {
      filt_region = `region=${this._ped.filterOpts.region}&`;
    }
    if (this._ped.filterOpts.postcode) {
      filt_postcode = `postcode=${this._ped.filterOpts.postcode}&`;
    }

    let filter = `${filt_business_unit}${filt_country}${filt_status}${filt_carrier}${filt_from}${filt_to}${filt_sku_full}${filt_sku}${filt_prod}${filt_coupon}${filt_order_number}${filt_tracking_number}${filt_confirmed_at_from}${filt_confirmed_at_to}${filt_prepared_at_from}${filt_prepared_at_to}${filt_providers_complete}${filt_provider}${filt_reference}${filt_city}${filt_region}${filt_postcode}`;

    return filter.replace(/undefined/g, "");
  }
  checkFilter() {
    this._gen.tableLoader = true;
    this.clearSelectedPedsTable()
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllProdsCountFilt(this.checkFilterVal()).then(() => {
      this._ped.getAllProdsFilter(this.checkFilterVal(), this.currPageView, this.currPage).then((data: any) => {
        this.resetTable(data.data).then((data: any) => {
          this._gen.tableLoader = false;
        });
      })
    });
  }

  /* EDIT */
  editBtnFx() {
    this._ped.editId = this._ped.sel_pedidos[0]?.order_number;
    this._ped.editView = !this._ped.editView;
  }
  saveBtnFx() {
    this._ped.editId = "";
    this._ped.editView = !this._ped.editView;
  }
  cancelBtnFx() {
    this._ped.editId = "";
    this._ped.editView = !this._ped.editView;
  }


  /* VIEW DETAIL */
  viewDetail(item: any) {
    this._ped.detailId = item;
    this.route.navigate([`/home/pedidos/${item}`])
  }


  processped() {
    let status: any[] = [];
    let pedList: any[] = [];

    for (let g = 0; g < this._ped.sel_pedidos.length; g++) {
      const element = this._ped.sel_pedidos[g];
      if (status.indexOf(element.status.name) === -1) {
        status.push(element.status.name);
        pedList.push({
          status: element.status,
          currStatus: element.status.name,
          commentStatus: "",
          pedidos: [],
          statusChange: []
        })
      }
    }
    for (let j = 0; j < this._ped.sel_pedidos.length; j++) {
      const selected = this._ped.sel_pedidos[j];

      for (let d = 0; d < pedList.length; d++) {
        const element: any = pedList[d];

        if (selected.status.name == element.status.name) {
          element.pedidos.push(selected)
        }

      }

    }

    this.processSel = pedList;
    this._ped.processView = !this._ped.processView;
  }

  shipped() {
    let status: any[] = [];
    let pedList: any[] = [];

    for (let g = 0; g < this._ped.sel_pedidos.length; g++) {
      const element = this._ped.sel_pedidos[g];
      if (status.indexOf(element.status.name) === -1) {
        status.push(element.status.name);
        pedList.push({
          status: element.status,
          currStatus: element.status.name,
          commentCarrier: "",
          pedidos: [],
          newCarrier: ""
        })
      }
    }
    for (let j = 0; j < this._ped.sel_pedidos.length; j++) {
      const selected = this._ped.sel_pedidos[j];

      for (let d = 0; d < pedList.length; d++) {
        const element: any = pedList[d];

        if (selected.status.name == element.status.name) {
          element.pedidos.push(selected)
        }

      }

    }

    this.shipSel = pedList;
    this._ped.shipView = !this._ped.shipView;
  }


  /* THERMAL PRINT */
  printpeds() {
    if (this.usbPrintDriver.isConnected.getValue() || true) {
      const payload: any = []
      this._ped.sel_pedidos.forEach(item => {
        payload.push({ sale_id: item.sale_id })
      })
      this._ped.genPrintsWithStock(payload).then((data: any) => {
        const peds = data.data
        if (Array.isArray(peds) && peds.length > 0) {
          if(peds.length != payload.length) {
            this.iziToast.show({
              title: `Alguna de las ordenes no tiene productos para imprimir`,
              position: "topRight",
              color: "yellow",
              transitionIn: 'fadeInLeft',
              transitionOut: 'fadeOutRight',
              toastOnce: true,
            });
          }
          peds.forEach((item, k) => {
            this.printPed(item)
          })
        } else {
          this.iziToast.show({
            title: `${this._ped.sel_pedidos.length > 1 
              ? 'Las ordenes no tienen productos para imprimir' 
              : `La orden ${this._ped.sel_pedidos[0].order_number} no tiene productos para imprimir`
            }.`,
            position: "topRight",
            color: "yellow",
            transitionIn: 'fadeInLeft',
            transitionOut: 'fadeOutRight',
            toastOnce: true,
          });
        }
      })
    }
    else {
      this.iziToast.show({
        title: "Error con la Impresora POS, intenta reiniciarla",
        position: "topRight",
        color: "red",
        transitionIn: 'fadeInLeft',
        transitionOut: 'fadeOutRight',
        toastOnce: true,
      });
    }
  }
  printPed(ped: any) {

    this.usbPrintDriver.write(this.compileOrderEncode(ped)).catch(error => {
      this.iziToast.show({
        title: "Error con la Impresora POS, intenta refrescar la ventana",
        position: "topRight",
        color: "red",
        transitionIn: 'fadeInLeft',
        transitionOut: 'fadeOutRight',
        toastOnce: true,
      });
    });

  }

  encode_barcode(barcode: string, pedItem: string, pedCarrier: string) {
    return encoder
      .initialize()

      .align('center')
      .barcode(`${barcode}`, 'code128', 60)
      .newline()
      .size('normal')
      .align('center')
      .bold(true)
      .line(`Pedido #: ${pedItem}`)
      .bold(false)
      .newline()
      .size('normal')
      .align('center')
      .bold(true)
      .line(pedCarrier ? pedCarrier.replace(/_/g, " ").replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))): "Sin Carrier")
      .bold(false)
      .newline()

      .encode();
  }
  encode_finalClient() {
    return encoder
      .initialize()
  
      .align('center')
      .bold(true)
      .line("Cliente Final")
      .bold(false)
      .newline()
  
      .encode();
  } 
  encode_userInfo(ped: any) {
    return encoder
      .initialize()

      .line('------------------------------------------------')
      .newline()
      .align('left')
      .line('NOMBRE:')
      .bold(true)
      .line(`${ped.customer_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
      .bold(false)
      .line('DIRECCION:')
      .bold(true)
      .line(ped.address ? `${ped.address.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : 'Sin Direccion')
      .bold(false)
      .line('CIUDAD:')
      .bold(true)
      .line(ped.city ? `${ped.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : 'Sin Ciudad')
      .bold(false)

      .encode();
  }

  encode_addCuponToUserInfo(coupon:any){
    return encoder
      .initialize()
      .line('CUPON:')
      .bold(true)
      .line(`${coupon.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
      .bold(false)

      .encode()
  }

  encode_closeUserInfo(){
    return encoder 
      .initialize()
      .newline()
      .line('------------------------------------------------')

      .encode()
  }
  getEncodeDiop(prodUseType: any, skuFull: any) {
    let diop = skuFull.split('_');

    if (prodUseType === 1) {

      return encoder
        .initialize()

        .align('left')
        .line('Poder (Esfera):')
        .bold(true)
        .line(`${diop[1]}`)
        .bold(false)
        .line('------------------------------------------------')

        .encode();

    } else if (prodUseType === 2) {

      return encoder
        .initialize()

        .align('left')
        .line('Poder (Esfera):')
        .bold(true)
        .line(`${diop[1]}`)
        .bold(false)
        .line('Cilindro (Cyl):')
        .bold(true)
        .line(`${diop[2]}`)
        .bold(false)
        .line('Eje (Axis):')
        .bold(true)
        .line(`${diop[3]}`)
        .bold(false)
        .line('------------------------------------------------')

        .encode();

    } else if (prodUseType === 3) {

      return encoder
        .initialize()

        .align('left')
        .line('Poder (Esfera):')
        .bold(true)
        .line(`${diop[1]}`)
        .bold(false)
        .line('Adicion (add):')
        .bold(true)
        .line(`${diop[2]}`)
        .bold(false)
        .line('------------------------------------------------')

        .encode();

    } else if (prodUseType === 4) {

      return encoder
        .initialize()

        .align('left')
        .line('Poder (Esfera):')
        .bold(true)
        .line(`${diop[1]}`)
        .bold(false)
        .line('Color:')
        .bold(true)
        .line(`${diop[2]}`)
        .bold(false)
        .line('------------------------------------------------')

        .encode();

    } else if (prodUseType === 6) {

      return encoder
        .initialize()

        .align('left')
        .line('Solucion')
        .line('------------------------------------------------')

        .encode();

    } else if (prodUseType === 10) {

      return encoder
        .initialize()

        .align('left')
        .line('Lente oftálmico')
        .line('------------------------------------------------')

        .encode();

    } else if (prodUseType === 7) {

      return encoder
        .initialize()

        .align('left')
        .line('Marco')
        .line('------------------------------------------------')

        .encode();

    } else if (prodUseType === undefined || prodUseType === null || prodUseType === 'null') {

      return encoder
        .initialize()

        .align('left')
        .line('--')
        .line('------------------------------------------------')

        .encode();

    }

  }
  encode_prods(prods: any) {
    let myArrays = [];

    for (let p = 0; p < prods.length; p++) {
      const element = prods[p];
      myArrays.push(encoder.initialize()

        .newline()
        .align('center')
        .bold(true)
        .line(`${element.name === "" ? "-" : element.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
        .bold(false)
        .line('')
        .align('left')
        .bold(true)
        .line(`SKU: ${element.sku_full}`)
        .line(`Formula: ${element.formula === null ? "-" : element.formula}`)
        .line(`Cantidad: ${element.quantity}`)
        .line(`${element.provider.map( (item:any, index:number) => `${index === 0 ? '': ' '}${item.code} (${item.quantity} de ${element.quantity})`)}`)
        .bold(false)
        // .align('center')
        // .barcode(`${element.ean}`, 'ean13', 60)
        // .newline()

        .line('')

        .encode());

      // myArrays.push( this.getEncodeDiop( element.use_type_id, element.sku_full ) )
    }

    let length = 0;
    myArrays.forEach(item => { length += item.length; });

    let order_prods = new Uint8Array(length);
    let offset = 0;

    myArrays.forEach(item => {
      order_prods.set(item, offset);
      offset += item.length;
    });

    return order_prods;
  }
  encode_prods_b2b(prods: any) {
    let myArrays = [];

    for (let p = 0; p < prods.length; p++) {
      const element = prods[p];
      myArrays.push(encoder.initialize()

        .align('center')
        .bold(true)
        .line(`${element.name === "" ? "-" : element.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
        .bold(false)
        .line('')
        .align('left')
        .bold(true)
        .line(`SKU: ${element.sku_full}`)
        .line(`Cantidad: ${element.quantity} - ${element.provider.map( (item:any, index:number) => `${index === 0 ? '': ' '}${item.code} (${item.quantity} de ${element.quantity})`)}`)
        .bold(false)

        .encode());

    }

    let length = 0;
    myArrays.forEach(item => { length += item.length; });

    let order_prods = new Uint8Array(length);
    let offset = 0;

    myArrays.forEach(item => {
      order_prods.set(item, offset);
      offset += item.length;
    });

    return order_prods;
  }
  encode_cut() {
    return encoder
      .initialize()
      .line('')
      .newline()
      .newline()
      .line('')
      .cut('partial')
      .encode();
  }

  compileOrderEncode(pedItem: any) {

    let myArrays = []
    myArrays.push(this.encode_barcode(pedItem.barcode, pedItem.order_number, pedItem.carrier))
    if(this.validateFinalClient(pedItem)) myArrays.push(this.encode_finalClient())
    myArrays.push(this.encode_userInfo(pedItem))
    if(pedItem.coupon) myArrays.push(this.encode_addCuponToUserInfo(pedItem.coupon))
    myArrays.push(this.encode_closeUserInfo())
    if(this.isB2B(pedItem)){
      myArrays.push(this.encode_prods_b2b(pedItem.products))
    } else {
      myArrays.push(this.encode_prods(pedItem.products))
    }
    myArrays.push(this.encode_cut())

    let length = 0;
    myArrays.forEach(item => { length += item.length; });

    let order_encode = new Uint8Array(length);
    let offset = 0;

    myArrays.forEach(item => {
      order_encode.set(item, offset);
      offset += item.length;
    });

    return order_encode;

  }

  clearSelectedPedsTable() {
    this._ped.sel_pedidos = [];
    this._table.selectedLine = [];
  }

  validateFinalClient(pedItem:any){
    const {location_other} = pedItem;
    return Object.keys(location_other).length != 0 && Object.keys(location_other).includes("final_client") && location_other.final_client
  } 

  isB2B(pedItem:any){
    return pedItem.order_number.startsWith('Z') || pedItem.order_number.startsWith('G')
  }

  isEmpty(value:any, fieldName: string) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "string") {
      return value.trim() === "";
    } else {
      return true;
    }
  };
}