import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'idOrder'
})
export class IdOrderPipe implements PipeTransform {

  private val:string = "";

  constructor( public _inv:InventoryService) {
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    for (let f = 0; f < this._inv.filt_orders.length; f++) {
      const element = this._inv.filt_orders[f];      
      
      if ( element.order_id == value ) {
        this.val = element.reference
      }
    }
    return this.val;
  }

}
