import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "idStatus",
})
export class IdStatusPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case "canceled":
        return "Cancelado";
      case "wrong_address":
        return "Dirección Incorrecta";
      case "confirmed":
        return "Confirmado";
      case "order_preparation":
        return "Preparación de Pedido";
      case "supplying":
        return "Abastecimiento";
      case "order_import":
        return "Importación";
      case "packing":
        return "Empaque";
      case "order_shipped":
        return "Envió";
      case "partial_shipment":
        return "Envió Parcial";
      case "completed":
        return "Completado";
      case "order_special":
        return "Orden Especial";
      case "closed":
        return "Cerrado Reembolsado";
      case "contactlenses_confirmed":
        return "Confirmado";
      case "contactlenses_in_verification":
        return "En Verificacion";
      case "logistics_operator_notification":
        return "Novedad Operador Logistico";
      case "new":
        return "Nueva";
      case "holded_news":
        return "Novedades";
      case "payment_declined":
        return "Pago declinado";
      case "order_canceled":
        return "Cancelado";
      case "pending_payment":
        return "Pago Pendiente";
      case "lp_no_disponible":
        return "LP no disponible";
      case "solicitud_lp_ops":
        return "Solicitud LP Ops";
      case "lentes_en_optica":
        return "Lentes en Optica";
      case "adaptacion_confirmada":
        return "Adaptación Confirmada";
      case "lp_optica":
        return "Lp Optica";
      case "expired":
        return "Expirado";
      case "pending_formula_glasses":
        return "Formula pendiente gafas";
      case "confirmation_formula_glasses":
        return "Confirmacion formula gafas";
      case "pending_formula":
        return "Formula pendiente";
      case "no_adaptada_por_optometra":
        return "No Adaptada por Optómetra";
      case "adaptacion_no_exitosa":
        return "Adaptacion no exitosa";
      case "wrong_formula_glasses":
        return "Fórmula Incorrecta Gafas";
      case "cancelado_por_disponibilidad":
        return "Cancelado por Disponibilidad";
      case "confirming_formula":
        return "Confirmando Formula";
      case "pending_payment_offline":
        return "Pago Pendiente Offline";
      case "stateless":
        return "Sin estado";
      case "complete":
        return "Completado";
      default:
        return value;
    }
  }
}
