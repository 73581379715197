
<div class="topttls">
    <app-tittle [label]="'Proveedores'" [icon]="'48px_box-3d-50'"></app-tittle>

    <div class="btns">
        &nbsp;

        <div class="totalProds">
            <div>
                <span>Total registros:</span> 
                <b>{{ dataSourceCount }}</b>
            </div>
        </div>
    </div>
</div>

<div class="topTableWrap">
    <div class="left">
        <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_provs.sel_provider.length == 1 && !_provs.editView" (click)="editBtnFx()">
            <i [class]="'48px_edit' | icons"></i>
            <span>Editar</span>
        </button>
    </div>

    <div class="right">
        <div class="filterView">
            <button class="btn editBtn" (click)="resetTobTable('filtsView')">
                <i [class]="'48px_filter' | icons"></i>
                <span>Filtrar</span>
            </button>
            <div class="filterItems animate__animated animate__fadeInRight " *ngIf="filtsView">
                <div class="closeFilt" (click)="filtsView = false">
                    <button>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </button>
                </div>
                <div class="filtTtl">
                    <h3>Filtros</h3>
                </div>
                <div class="filtActive customScrollBar">
                    <div class="filtActItm" *ngIf="_provs.filterOpts_providers.country.length >= 1">
                        <span><b>País: </b> <i *ngFor="let country of _provs.filterOpts_providers.country; index as i"> <i *ngIf="i >= 1"> - </i> {{country | idCountry}}</i> </span>
                    </div>
                    <!-- <div class="filtActItm" *ngIf="_provs.filterOpts_providers.provider_type" (click)="resetFilters_item('provider_type')">
                        <span><b>Tipo de Proveedor: </b>{{_provs.filterOpts_providers.provider_type}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div> -->
                    <div class="filtActItm" *ngIf="_provs.filterOpts_providers.natial_tax_ID" (click)="resetFilters_item('natial_tax_ID')">
                        <span><b>Número de identificación fiscal: </b>{{_provs.filterOpts_providers.natial_tax_ID}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_provs.filterOpts_providers.name" (click)="resetFilters_item('name')">
                        <span><b>Nombre: </b>{{_provs.filterOpts_providers.name}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <!--
                    <div class="filtActItm" *ngIf="_provs.filterOpts_providers.contact_email" (click)="resetFilters_item('contact_email')">
                        <span><b>Email de contacto: </b> {{_provs.filterOpts_providers.contact_email}}</span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    -->
                </div>
                <div class="filtList customScrollBar">
                    <div class="filItm filItm_hide">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <mat-select 
                                    [(ngModel)]="_provs.filterOpts_providers.country" 
                                    multiple 
                                    (ngModelChange)="getFiltersOpts()" 
                                    (keydown.enter)="checkFilter()"
                                >
                                    <mat-option *ngFor="let opt of _gen.allCountries" value="{{opt.country_id}}">   {{opt.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="filItm">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Tipo de Proveedor</mat-label>
                                <mat-select [(ngModel)]="_provs.filterOpts_providers.provider_type" (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                <mat-option *ngFor="let opt of _provs.filt_provider_type" value="{{opt.value}}">{{opt.label}}</mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>    
                    </div> -->
                    <div class="filItm">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de identificación fiscal</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_provs.filterOpts_providers.natial_tax_ID" name="natial_tax_ID" (keydown.enter)="checkFilter()">
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_provs.filterOpts_providers.name" name="name" (keydown.enter)="checkFilter()">
                            </mat-form-field>
                        </div>    
                    </div>
                    <!--
                    <div class="filItm">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Email de contacto</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_provs.filterOpts_providers.contact_email" name="contact_email" (keydown.enter)="checkFilter()">
                            </mat-form-field>
                        </div>    
                    </div>
                    -->
                </div>
                    
                
                <div class="filtSend">
                    <button class="btn" (click)="resetFilters_item('')">
                        Borrar filtros
                    </button>
                    <button class="btn btn_success btn_fill" (click)="checkFilter()">
                        Aplicar filtros
                    </button>
                </div>
            </div>
        </div>
        <div class="colsView">
            <button class="btn editBtn" (click)="resetTobTable('colsView')">
                <i [class]="'48px_separate-2' | icons"></i>
                <span>Columnas</span>
            </button>
            <div class="columnsItems animate__animated animate__fadeInUp " *ngIf="colsView" appClickOutside (clickOutside)="colsView = false">
                <div class="colItem" *ngFor="let col of columnsConfig; index as i">
                    <div class="ios_checkBox">
                        <input type='checkbox' id='checkbox-{{i}}' [checked]="col.visible" (change)="col.visible = !col.visible">
                        <label for='checkbox-{{i}}'>{{col.label}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-table [cols]="columnsConfig" [data]="dataSource" [type]="tableType" (reloadPage)="onReloadPage()"
></app-table>

<div class="bottomTable">
    <div class="pageInp">
        <div class="form_field">
            <input type="text" name="" id="" value="{{currPage}}">
        </div>
        <span>de {{currPageTotal}}</span>
    </div>
    
    <div class="pagerWrap">
        <div class="pagerItms">
            <button class="btn btn_sm prev" [disabled]="currPage == 1" (click)="changePageInp( currPage - 1 )"> <i [class]="'48px_left-arrow' | icons"></i> </button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 2 )" [disabled]="currPage <= 2">{{currPage - 2}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 1 )" [disabled]="currPage == 1">{{currPage - 1}}</button>
            <button class="btn btn_sm page active">{{currPage}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 1 )" [disabled]="currPage == currPageTotal">{{currPage + 1}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 2 )" [disabled]="currPage >= currPageTotal - 1">{{currPage + 2}}</button>
            <button class="btn btn_sm page" disabled>...</button>
            <button class="btn btn_sm next" (click)="changePageInp( currPageTotal )" [disabled]="currPage == currPageTotal">{{currPageTotal}}</button>
            <button class="btn btn_sm page last" [disabled]="currPage == currPageTotal" (click)="changePageInp( currPage + 1 )"> <i [class]="'48px_right-arrow' | icons"></i> </button>
        </div>
    </div>

    <div class="perPage">
        <div class="form_field">
            <div class="select_box">
                <select name="" id="" [(ngModel)]="currPageView" (change)=" getAllData() ">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
                <i [class]="'48px_down-arrow' | icons"></i>
            </div>
        </div>
        <span>por página</span>
    </div>
</div>