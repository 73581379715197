
<div class="topttls">
    <app-tittle [label]="'Facturas Electrónicas'" [icon]="'48px_box-3d-50'"></app-tittle>

    <div class="btns">
        &nbsp;

        <div class="totalProds">
            <div>
                <span>Total registros:</span> 
                <b>{{ dataSourceCount }}</b>
            </div>
        </div>
    </div>
</div>

<div class="topTableWrap">
    <div class="left">
    </div>

    <div class="right">
        <div class="filterView">
            <button class="btn editBtn" (click)="resetTobTable('filtsView')">
                <i [class]="'48px_filter' | icons"></i>
                <span>Filtrar</span>
            </button>
            <div class="filterItems animate__animated animate__fadeInRight " *ngIf="filtsView">
                <div class="closeFilt" (click)="filtsView = false">
                    <button>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </button>
                </div>
                <div class="filtTtl">
                    <h3>Filtros</h3>
                </div>
                <div class="filtActive customScrollBar">
                    <div class="filtActItm" *ngIf="_electInv.filterOpts_electronic_invoices.country.length >= 1">
                        <span><b>País: </b> <i *ngFor="let country of _electInv.filterOpts_electronic_invoices.country; index as i"> <i *ngIf="i >= 1"> - </i> {{country | idCountry}}</i> </span>
                    </div>
                    <div class="filtActItm" *ngIf="_electInv.filterOpts_electronic_invoices.consecutive.length >= 1" (click)="resetFilters_item('consecutive')">
                        <span>
                            <b>Consecutivo: </b> 
                            <i>{{_electInv.filterOpts_electronic_invoices.consecutive}}</i> 
                        </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_electInv.filterOpts_electronic_invoices.name.length >= 1" (click)="resetFilters_item('name')">
                        <span>
                            <b>Nombre: </b> 
                            <i>{{_electInv.filterOpts_electronic_invoices.name}}</i>
                        </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_electInv.filterOpts_electronic_invoices.date_issue_from" (click)="resetFilters_item('date_issue_from')">
                        <span><b>Desde: </b> <i> {{ _gen.getDateFormat( _electInv.filterOpts_electronic_invoices.date_issue_from ) }} </i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_electInv.filterOpts_electronic_invoices.date_issue_to" (click)="resetFilters_item('date_issue_to')">
                        <span><b>Hasta: </b> <i> {{ _gen.getDateFormat( _electInv.filterOpts_electronic_invoices.date_issue_to ) }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_electInv.filterOpts_electronic_invoices.tax.length >= 1" (click)="resetFilters_item('tax')">
                        <span>
                            <b>Identificador Fiscal: </b> 
                            <i>{{_electInv.filterOpts_electronic_invoices.tax}}</i>
                        </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                </div>
                <div class="filtList customScrollBar">
                    <div class="filItm filItm_hide">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <mat-select 
                                    [(ngModel)]="_electInv.filterOpts_electronic_invoices.country" 
                                    multiple 
                                    (ngModelChange)="getFiltersOpts()" 
                                    (keydown.enter)="checkFilter()"
                                >
                                    <mat-option *ngFor="let opt of _gen.allCountries" value="{{opt.country_id}}">   {{opt.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Consecutivo</mat-label>
                                <input 
                                    matInput 
                                    placeholder="Placeholder" 
                                    [(ngModel)]="_electInv.filterOpts_electronic_invoices.consecutive" 
                                    name="consecutive" 
                                    (keydown.enter)="checkFilter()"
                                >
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input 
                                    matInput 
                                    placeholder="Placeholder" 
                                    [(ngModel)]="_electInv.filterOpts_electronic_invoices.name" 
                                    name="name" 
                                    (keydown.enter)="checkFilter()"
                                >
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp3.open()">
                                <mat-label>Desde</mat-label>
                                <input matInput [matDatepicker]="dp3" [max]="maxDate" [(ngModel)]="_electInv.filterOpts_electronic_invoices.date_issue_from" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp4.open()">
                                <mat-label>Hasta</mat-label>
                                <input matInput [matDatepicker]="dp4" [max]="maxDate" [(ngModel)]="_electInv.filterOpts_electronic_invoices.date_issue_to" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                                <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm">
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Identificador Fiscal</mat-label>
                                <input 
                                    matInput 
                                    placeholder="Placeholder" 
                                    [(ngModel)]="_electInv.filterOpts_electronic_invoices.tax" 
                                    name="tax" 
                                    (keydown.enter)="checkFilter()"
                                >
                              </mat-form-field>
                        </div>    
                    </div>
                </div>
                <div class="filtSend">
                    <button class="btn" (click)="resetFilters_item('')">
                        Borrar filtros
                    </button>
                    <button class="btn btn_success btn_fill" (click)="checkFilter()">
                        Aplicar filtros
                    </button>
                </div>
            </div>
        </div>
        <div class="colsView">
            <button class="btn editBtn" (click)="resetTobTable('colsView')">
                <i [class]="'48px_separate-2' | icons"></i>
                <span>Columnas</span>
            </button>
            <div class="columnsItems animate__animated animate__fadeInUp " *ngIf="colsView" appClickOutside (clickOutside)="colsView = false">
                <div class="colItem" *ngFor="let col of columnsConfig; index as i">
                    <div class="ios_checkBox">
                        <input type='checkbox' id='checkbox-{{i}}' [checked]="col.visible" (change)="col.visible = !col.visible">
                        <label for='checkbox-{{i}}'>{{col.label}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-table [cols]="columnsConfig" [data]="dataSource" [type]="tableType" (reloadPage)="onReloadPage()"
></app-table>

<div class="bottomTable">
    <div class="pageInp">
        <div class="form_field">
            <input type="text" name="" id="" value="{{currPage}}">
        </div>
        <span>de {{currPageTotal}}</span>
    </div>
    
    <div class="pagerWrap">
        <div class="pagerItms">
            <button class="btn btn_sm prev" [disabled]="currPage == 1" (click)="changePageInp( currPage - 1 )"> <i [class]="'48px_left-arrow' | icons"></i> </button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 2 )" [disabled]="currPage <= 2">{{currPage - 2}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 1 )" [disabled]="currPage == 1">{{currPage - 1}}</button>
            <button class="btn btn_sm page active">{{currPage}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 1 )" [disabled]="currPage == currPageTotal">{{currPage + 1}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 2 )" [disabled]="currPage >= currPageTotal - 1">{{currPage + 2}}</button>
            <button class="btn btn_sm page" disabled>...</button>
            <button class="btn btn_sm next" (click)="changePageInp( currPageTotal )" [disabled]="currPage == currPageTotal">{{currPageTotal}}</button>
            <button class="btn btn_sm page last" [disabled]="currPage == currPageTotal" (click)="changePageInp( currPage + 1 )"> <i [class]="'48px_right-arrow' | icons"></i> </button>
        </div>
    </div>

    <div class="perPage">
        <div class="form_field">
            <div class="select_box">
                <select name="" id="" [(ngModel)]="currPageView" (change)=" getAllData() ">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
                <i [class]="'48px_down-arrow' | icons"></i>
            </div>
        </div>
        <span>por página</span>
    </div>
</div>