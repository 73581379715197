import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { PedidosService } from 'src/app/services/pedidos.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
  ]
})
export class DashboardComponent implements OnInit {

  counts:any = {
    canceled: "",
    completed: "",
    closed: "",
    all: "",
  }

  secondTableType: string = "syncs";
  dataSource: any[] = [];
  dataSourcefull: any[] = [];
  columnsConfig = [
    { varId: "data_download_date", label: "Fecha descarga de datos", visible: true, type: "date", class: "", disabled: true },
    { varId: "sync_date", label: "Fecha de sincronización", visible: true, type: "date", class: "", disabled: true },
  ]

  showLoaderCount:boolean = false;

  tableType: string = "provider_cuts";
  dataSourceCutTable: any[] = [];
  dataSourcefullCutTbale: any[] = [];
  columnsConfigCutTable = [
    { varId: "provider", label: "Proveedor", visible: true, type: "", class: "midCell", disabled: true },
    { varId: "cut", label: "Fecha de corte", visible: true, type: "date", class: "midCell", disabled: true },
    { varId: "reference", label: "O.C.", visible: true, type: "", class: "midCell", disabled: true },
    { varId: "status", label: "Estado", visible: true, type: "status", class: "midCell", disabled: true },
  ]

  showLoaderCuts:boolean = false;
  showLoaderSyncs:boolean = false;

  constructor( public _ped:PedidosService, public _gen:GeneralService ) { }

  ngOnInit(): void {
    this.getLastSyncWithMagento();
    this.getProviderCut();
    this._gen.currCountryObs.subscribe((country) => {
      this.getLastSyncWithMagento();
      this.getProviderCut();
    })
  }

  getLastSyncWithMagento(){
    this.showLoaderSyncs = true
    this._gen.getLastSyncWithMagento(1)
      .then(({data}) => {
        this.showLoaderSyncs = false
        this.resetTable(data)

      })
      .catch(err => {
        console.log(err)
        this.showLoaderSyncs = false
      }) 
  }

  getProviderCut(){
    this.showLoaderCuts = true
    this._gen.getProvidersCut(10)
      .then(({data}) => {
        this.showLoaderCuts = false;
        this.resetTableCut(data)
      })
      .catch((err:any) => {
        console.log(err)
        this.showLoaderCuts = false
      })
  }

  resetTable(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];

      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element: any = this.dataSourcefull[d];
        let item = {
          sync_date: element.created_at,
          data_download_date: element.data_download_date,
        }
        this.dataSource.push(item);
      }

      resolve(true);
    })

    return prom;
  }

  resetTableCut(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefullCutTbale = arrFull;
      this.dataSourceCutTable = [];
      for (let d = 0; d < this.dataSourcefullCutTbale.length; d++) {
        const element: any = this.dataSourcefullCutTbale[d];
        let item = {
          provider: element.provider_cut_configuration?.provider?.name,
          cut: element.created_at,
          reference: element.order?.reference,
          status: element.status
        }
        this.dataSourceCutTable.push(item);
      }
      resolve(true);
    })
    return prom;
  }

}
