import { Pipe, PipeTransform } from '@angular/core';
import { GeneralService } from '../services/general.service';
import { InventoryService } from '../services/inventory.service';
import { OrdersService } from '../services/orders.service';
import { PedidosService } from '../services/pedidos.service';

@Pipe({
  name: 'idProvider'
})
export class IdProviderPipe implements PipeTransform {

  private val:string = "";

  constructor( public _ord:OrdersService, public _ped:PedidosService, public _inv:InventoryService ) {
  }

  transform(value: any, ...args: unknown[]): any {
    if ( this._ord.filt_provider ) {
      
      for (let f = 0; f < this._ord.filt_provider.length; f++) {
        const element = this._ord.filt_provider[f];      
        
        if ( element.provider_id.toString() == value.toString() ) {
          this.val = element.name
        }
      }
    } 
    if ( this._ped.filt_provider ) {
      
      for (let f = 0; f < this._ped.filt_provider.length; f++) {
        const element = this._ped.filt_provider[f];      
        
        if ( element.provider_id.toString() == value.toString() ) {
          this.val = element.name
        }
      }
    }

    if ( this._inv.ocListProviders ) {
      
      for (let f = 0; f < this._inv.ocListProviders.length; f++) {
        const element = this._inv.ocListProviders[f];      
        
        if ( element.provider_id.toString() == value.toString() ) {
          this.val = element.name
        }
      }
    }

    return this.val;
  }

}
