import { Component, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { InventoryService } from "src/app/services/inventory.service";
import { OrdersService } from "src/app/services/orders.service";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: [],
})
export class InvoicesComponent implements OnInit {
  tableType: string = "invoices";
  columnsConfig = [
    {
      varId: "inventory_entry_date",
      label: "Fecha de entrada inventario",
      visible: true,
      type: "inventory_entry_date",
      class: "",
      disabled: true,
    },
    {
      varId: "order",
      label: "Orden Interna",
      visible: true,
      type: "order",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "entry_type",
      label: "Tipo de Entrada",
      visible: true,
      type: "entry_type",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "provider",
      label: "Proveedor",
      visible: true,
      type: "provider",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "electronic_invoice",
      label: "Factura Electrónica",
      visible: true,
      type: "electronic_invoice",
      class: "",
      disabled: true,
    },
    {
      varId: "electronic_invoice_assignment_date",
      label: "Fecha de asignación de la factura electrónica",
      visible: true,
      type: "electronic_invoice_assignment_date",
      class: "",
      disabled: true,
    },
    {
      varId: "reference",
      label: "Referencia del Proveedor",
      visible: true,
      type: "reference",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "invoice_number",
      label: "Número Factura",
      visible: true,
      type: "invoice_number",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "invoice_value",
      label: "Valor Factura Manual",
      visible: true,
      type: "invoice_value",
      class: "",
      disabled: true,
    },
    {
      varId: "electronic_invoice_value",
      label: "Valor Factura Electrónica",
      visible: true,
      type: "electronic_invoice_value",
      class: "",
      disabled: true,
    },
    {
      varId: "cost_order_value",
      label: "Valor Negociado Proveedor",
      visible: true,
      type: "cost_order_value",
      class: "",
      disabled: true,
    },
    {
      varId: "bodega",
      label: "Bodega de Ingreso",
      visible: true,
      type: "bodega",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "comment",
      label: "Comentario",
      visible: true,
      type: "comment",
      class: "longCell noEllipsis",
      disabled: true,
    },
    {
      varId: "upload_file",
      label: "Acción",
      visible: true,
      type: "upload_file",
      class: "longCell noEllipsis",
      disabled: true,
    },
    {
      varId: "files",
      label: "Archivos",
      visible: true,
      type: "files",
      class: "longCell noEllipsis",
      disabled: true,
    }
  ];

  dataSourcefull: any[] = [];

  colsView: boolean = false;
  filtsView: boolean = false;

  dataSourceCount = 0;
  currPage = 1;
  currPageView = 100;
  currPageTotal = 0;

  dataSource: any[] = [];

  listProviders: any = [];

  constructor(
    public _gen: GeneralService,
    public _inv: InventoryService,
    public _ord: OrdersService
  ) {}

  ngOnInit(): void {
    this.getAllData();
    this.getFiltersOpts();
    this._gen.currCountryObs.subscribe((country) => {
      this.getAllData();
      this.getFiltersOpts();
      this.colsView = false;
      this.filtsView = false;
    })
  }

  getAllData() {
    this._gen.tableLoader = true;
    this.getAllInvoicesCountFilt(this.checkFilterVal()).then(() => {
      this._inv.getAllInvoicesFilter(this._gen.currCountry, this.checkFilterVal(), this.currPageView, this.currPage).then(({ data }) => {
        this.resetTable(data).then((data: any) => {
          this._gen.tableLoader = false;
        });
      });
    })
  }

  /* PAGE COUNT */
  getAllInvoicesCountFilt(filt: any) {
    let prom = new Promise((resolve, reject) => {
      this._inv.getAllInvoicesCountFilt(this._gen.currCountry, filt).then((data: any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil(
          this.dataSourceCount / this.currPageView
        );

        if (this.currPageTotal == 0) {
          this.currPageTotal = 1;
        }
        if (this.currPage > this.currPageTotal) {
          this.currPage = this.currPageTotal;
        }

        resolve(true);
      });
    });
    return prom;
  }

  resetTable(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];
      
      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element: any = this.dataSourcefull[d];
        let item = {
          inventory_entry_date: element.created_at,
          comment: element.comment,
          invoice_number: element.invoice_number,
          invoice_value: element.invoice_value,
          reference: element.reference,
          provider: element.provider,
          bodega: element.provider_stock,
          entry_type: element.reason,
          reception_id: element.reception_id,
          reception_files: element.reception_files,
          order: element.order,
          electronic_invoices: element.invoice_electronic,
          cost_order_value: element.order_cost,
          electronic_invoice_value: element.invoice_electronic
        };
        this.dataSource.push(item);
      }

      this.getOrders(this.dataSource)

      resolve(true);
    });

    return prom;
  }

  getOrders(dataOrders:any) {
    this._inv.listInternalOrders = dataOrders.map( (item:any) => {
      if(item.order){
        return item.order
      }
    })

    this._inv.listInternalOrders = this._inv.listInternalOrders.filter( (itm:any) => {
      if(itm) {
        return itm
      }
    })
  }

  resetTobTable(type: string) {
    setTimeout(() => {
      if (type == "colsView") {
        this.filtsView = false;
        if (this.colsView) {
          this.colsView = false;
        } else {
          this.colsView = true;
        }
      } else if (type == "filtsView") {
        this.colsView = false;
        if (this.filtsView) {
          this.filtsView = false;
        } else {
          this.filtsView = true;
        }
      }
    }, 150);
  }

  // filters

  /* filters */
  checkFilterVal() {
    let filt_country,
      filt_reference,
      filt_invoice_number,
      filt_reason,
      filt_provider_stock,
      filt_provider,
      filt_order_reference,
      filt_files;

    // filt_country = `country=${this._gen.currCountry}&`;

    if (this._inv.filterOpts_invoices.reference.trim().length > 0) {
      filt_reference = `reference=${this._inv.filterOpts_invoices.reference.trim().toUpperCase()}&`;
    }
    if (this._inv.filterOpts_invoices.invoice_number.trim().length > 0) {
      filt_invoice_number = `invoice_number=${this._inv.filterOpts_invoices.invoice_number.trim().toUpperCase()}&`;
    }
    if (this._inv.filterOpts_invoices.reason.length > 0) {
      filt_reason = `reason=${this._inv.filterOpts_invoices.reason}&`;
    }
    if (this._inv.filterOpts_invoices.provider_stock.length > 0) {
      filt_provider_stock = `provider_stock=${this._inv.filterOpts_invoices.provider_stock}&`;
    }
    if (this._inv.filterOpts_invoices.provider.length > 0) {
      filt_provider = `provider=${this._inv.filterOpts_invoices.provider}&`;
    }
    if( this._inv.filterOpts_invoices.order_reference.length > 0) {
      filt_order_reference = `order=${this._inv.filterOpts_invoices.order_reference}&`
    }
    if( this._inv.filterOpts_invoices.files !== "") {
      filt_files = `files=${this._inv.filterOpts_invoices.files}&`
    }

    let filter = `${filt_reference}${filt_invoice_number}${filt_order_reference}${filt_reason}${filt_provider_stock}${filt_provider}${filt_files}`;
    return filter.replace(/undefined/g, "");
  }

  resetFilters_item(item: string) {
    switch (item) {
      case "reference":
        this._inv.filterOpts_invoices.reference = "";
        break;
      case "order_reference":
        this._inv.filterOpts_invoices.order_reference = "";
        break;
      case "invoice_number":
        this._inv.filterOpts_invoices.invoice_number = "";
        break;
      case "reason":
        this._inv.filterOpts_invoices.reason = "";
        break;
      case "provider_stock":
        this._inv.filterOpts_invoices.provider_stock = "";
        break;
      case "provider":
        this._inv.filterOpts_invoices.provider = "";
        break;
      case "files":
        this._inv.filterOpts_invoices.files = "";
        break;
      default:
        this._inv.filterOpts_invoices = {
          reference: "",
          invoice_number: "",
          reason: "",
          provider_stock: "",
          provider: "",
          order_reference: "",
          files: ""
        };

        break;
    }
    this.checkFilter();
  }

  /* Filter Opts */
  getFiltersOpts() {
    var country = this._gen.currCountry.toString();
    this._inv.filterOpts_invoices.country = country.split(",");

    this._gen.getCountry().then(({ data }) => {
      this._gen.allCountries = data;
    });

    this._ord.getAllstockReasons("entry,cross").then(({ data }) => {
      this._inv.ocListReason = data;
    });

    this._ord.getAllEntryBodega(this._gen.currCountry).then(({ data }) => {
      this._inv.ocListBodegas = data;
    });

    return this._ord
      .getAllProviders(this._gen.currCountry, false)
      .then(({ data }) => {
        this._inv.ocListProviders = data;
        this.listProviders = data;
      });
  }

  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllInvoicesCountFilt(this.checkFilterVal()).then(() => {
      this._inv
        .getAllInvoicesFilter(
          this._gen.currCountry,
          this.checkFilterVal(),
          this.currPageView,
          this.currPage
        )
        .then((data: any) => {
          this.resetTable(data.data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  changePageInp(page: any, input: boolean = false) {
    if (input) {
      this.currPage = parseInt(page.target.value);
    } else {
      this.currPage = parseInt(page);
    }
    this._gen.tableLoader = true;

    this.getAllData()
  }

  onReloadPage(){
    this.getAllData();
  }
}
