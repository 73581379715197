<div class="processPedOverLay">

    <div class="processPedBox processPedBox_inputs animate__animated animate__zoomIn">
        <button class="closeBtn" (click)="closeModal();">
            <span></span>
        </button>

        <div class="processPedInfo" *ngIf="listToChange">
            <div class="ttl">
                Ordenes a cambiar
            </div>
            <div class="orderChanged">
                <!-- Ordenes con cambio de carrier: <b>{{ listToChange.length }}</b> / <b> {{ listSuccess.length }} </b> -->
            </div>
            <div class="orderList">
                <div class="item" *ngFor="let item of listTemp">
                    <div class="num">
                        Num. de pedidos con este estado: <b>{{item.pedidos.length}}</b>
                    </div>
                    
                    <div class="change" *ngIf="listCharge">
                        <div class="currStatus">
                            <span class="badge {{ item.status.name }}">{{item.status.pretty }}</span>
                        </div>
                        <div class="separator">
                            <i class="{{ '48px_arrows-same-direction' | icons }}"></i>
                        </div>
                        <div class="newStatus">
                            <div class="form_field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Nuevo carrier</mat-label>
                                    <mat-select [(ngModel)]="item.newCarrier" (ngModelChange)="changeCarrSel( $event )">
                                        <mat-option *ngFor="let opt of carriers" value="{{opt.name}}">{{opt.pretty}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- <div class="commentStatus" *ngIf="item.newCarrier != '' ">
                            <div class="form_field form_fieldTextArea">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Deja un comentario</mat-label>
                                    <textarea [(ngModel)]="item.commentStatus" (ngModelChange)="changeStstusSel( $event )" matInput placeholder="Comentario del cambio de estado"></textarea>
                                </mat-form-field>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button class="btn btn_success" (click)="convertToShip()" [disabled]=" listToChange.length == 0 ">Generar envío de {{listToChangeSend.length}} pedidos</button>
            </div>
        </div>
    </div>

</div>