import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { InventoryService } from "src/app/services/inventory.service";

@Component({
  selector: "app-counts",
  templateUrl: "./counts.component.html",
})
export class CountsComponent implements OnInit {
  tableType: string = "counts";
  filtsView: boolean = false;
  colsView: boolean = false;

  dataSource: any[] = [];
  dataSourcefull: any[] = [];

  countSel: any = null;

  columnsConfig = [
    {
      varId: "cycle_count_id",
      label: "ID",
      visible: true,
      type: "uniqueId",
      class: "idCell",
      disabled: true,
    },
    {
      varId: "name",
      label: "Nombre",
      visible: true,
      type: "name",
      class: "longCell noEllipsis",
      disabled: false,
    },
    {
      varId: "type_count",
      label: "Tipo",
      visible: true,
      type: "type",
      class: "",
      disabled: true,
    },
    {
      varId: "created_at",
      label: "Fecha de creación",
      visible: true,
      type: "date",
      class: "",
      disabled: true,
    },
    {
      varId: "bodega",
      label: "Bodega",
      visible: true,
      type: "bodega",
      class: "longCell noEllipsis",
      disabled: true,
    },
    {
      varId: "user",
      label: "Usuario",
      visible: true,
      type: "user",
      class: "",
      disabled: false,
    },
    {
      varId: "scanned_products",
      label: "# Productos escaneados",
      visible: true,
      type: "number",
      class: "",
      disabled: false,
    },
    {
      varId: "expected_products",
      label: "# Productos esperados",
      visible: true,
      type: "number",
      class: "",
      disabled: false,
    },
    {
      varId: "scan_products_button",
      label: "Acción",
      visible: true,
      type: "scan_products_button",
      class: "longCell noEllipsis",
      disabled: false,
    },
    {
      varId: "close_counting",
      label: "Cerrar Conteo",
      visible: true,
      type: "close_counting",
      class: "longCell noEllipsis",
      disabled: false,
    },
  ];

  dataSourceCount = 0;
  currPage = 1;
  currPageView = 100;
  currPageTotal = 0;

  maxDate: Date = new Date(Date.now());

  constructor(
    public route: Router,
    public _inv: InventoryService,
    public _gen: GeneralService
  ) {}

  ngOnInit(): void {
    this.getAllCounts();
    this.getFiltersOpts();
    this._gen.currCountryObs.subscribe((country) => {
      this.getAllCounts();
      this.getFiltersOpts();
    });
  }

  getFiltersOpts() {
    var country = this._gen.currCountry.toString();
    this._inv.filterOpts_counts.country = country.split(",");

    this._gen.getCountry().then((data: any) => {
      this._gen.allCountries = data.data;
    });

    this._inv.getAllBodega(this._gen.currCountry).then((data: any) => {
      this._inv.ocListBodegas = data.data;
    });
  }

  /* PAGE COUNT */
  getAllCountsCountFilt(filt: any) {
    let prom = new Promise((resolve, reject) => {
      this._inv.getAllCountsCountFilt(filt).then((data: any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil(
          this.dataSourceCount / this.currPageView
        );
        if (this.currPageTotal == 0) {
          this.currPageTotal = 1;
        }
        if (this.currPage > this.currPageTotal) {
          this.currPage = this.currPageTotal;
        }
        resolve(true);
      });
    });
    return prom;
  }

  getAllCounts() {
    this._gen.tableLoader = true;
    this.getAllCountsCountFilt(this.checkFilterVal()).then(() => {
      this._inv
        .getAllCountsFilter(
          this.checkFilterVal(),
          this.currPageView,
          this.currPage
        )
        .then(({ data }) => {
          this.resetTable(data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  changePageInp(page: any, input: boolean = false) {
    if (input) {
      this.currPage = parseInt(page.target.value);
    } else {
      this.currPage = parseInt(page);
    }
    this._gen.tableLoader = true;
    this.getAllCountsCountFilt(this.checkFilterVal()).then(() => {
      this._inv
        .getAllCountsFilter(
          this.checkFilterVal(),
          this.currPageView,
          this.currPage
        )
        .then((data: any) => {
          this.resetTable(data.data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  /* filters */
  checkFilterVal() {
    let filt_country,
      filt_name,
      filt_type,
      filt_bodega,
      filt_created_at_from,
      filt_created_at_to;

    filt_country = `country=${this._gen.currCountry}&`;

    if (this._inv.filterOpts_counts.name) {
      filt_name = `name=${this._inv.filterOpts_counts.name}&`;
    }

    if (this._inv.filterOpts_counts.type.length > 0) {
      filt_type = `type=${this._inv.filterOpts_counts.type.join()}&`;
    }

    if (this._inv.filterOpts_counts.bodega.length > 0) {
      filt_bodega = `provider=${this._inv.filterOpts_counts.bodega}&`;
    }

    if (this._inv.filterOpts_counts.created_at_from) {
      filt_created_at_from = `created_at_from=${this._gen.getDateFormat(
        this._inv.filterOpts_counts.created_at_from
      )}&`;
    }

    if (this._inv.filterOpts_counts.created_at_to) {
      filt_created_at_to = `created_at_to=${this._gen.getDateFormat(
        this._inv.filterOpts_counts.created_at_to
      )}&`;
    }

    let filter = `${filt_country}${filt_name}${filt_type}${filt_bodega}${filt_created_at_from}${filt_created_at_to}`;
    return filter.replace(/undefined/g, "");
  }
  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();
    this.getAllCountsCountFilt(this.checkFilterVal()).then(() => {
      this._inv
        .getAllCountsFilter(
          this.checkFilterVal(),
          this.currPageView,
          this.currPage
        )
        .then(({ data }) => {
          this.resetTable(data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  /* RESET Filters */
  resetFilters_item(item: string) {
    switch (item) {
      case "country":
        this._inv.filterOpts_counts.country = [];
        break;
      case "bodega":
        this._inv.filterOpts_counts.bodega = [];
        break;
      case "name":
        this._inv.filterOpts_counts.name = "";
        break;
      case "type":
        this._inv.filterOpts_counts.type = [];
        break;
      case "created_at_from":
        this._inv.filterOpts_counts.created_at_from = "";
        break;
      case "created_at_to":
        this._inv.filterOpts_counts.created_at_to = "";
        break;
      default:
        this._inv.filterOpts_counts = {
          count_id: "",
          name: "",
          type: [],
          bodega: [],
          user_id: "",
          created_at_from: "",
          created_at_to: "",
        };
        break;
    }
    this.checkFilter();
  }

  /* RESET */
  resetTobTable(type: string) {
    setTimeout(() => {
      if (type == "colsView") {
        this.filtsView = false;
        if (this.colsView) {
          this.colsView = false;
        } else {
          this.colsView = true;
        }
      } else if (type == "filtsView") {
        this.colsView = false;
        if (this.filtsView) {
          this.filtsView = false;
        } else {
          this.filtsView = true;
        }
      }
    }, 150);
  }

  resetTable(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];

      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element: any = this.dataSourcefull[d];
        let item = {
          cycle_count_id: element.cycle_count_id,
          name: element.name,
          type_count: element.type,
          created_at: element.created_at,
          bodega: element.provider,
          user: `${element.user.first_name} ${element.user.last_name}`,
          scanned_products: element.counts.scanned_products,
          expected_products: element.counts.expected_products,
          enabled: element.enabled
        };
        this.dataSource.push(item);
      }
      resolve(true);
    });

    return prom;
  }

  createCount() {
    this._inv.createCountView = !this._inv.createCountView;
  }

  onReloadTable() {
    this.getAllCounts();
    this.getFiltersOpts();
  }
}
