import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styles: [
  ]
})
export class InventoryComponent implements OnInit {

  constructor( public _gen:GeneralService ) { }

  ngOnInit(): void {
    this._gen.getAllOrdsStatus().then( ({data}) => {
      this._gen.allOrdsStatus = data;      
    });
  }

}
