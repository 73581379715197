import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y/input-modality/input-modality-detector';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CatalogService } from 'src/app/services/catalog.service';
import { GeneralService } from 'src/app/services/general.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { InventoryService } from 'src/app/services/inventory.service';
 
import Swal from 'sweetalert2'

@Component({
  selector: 'app-read-code',
  templateUrl: './read-code.component.html',
  styleUrls: []
})
export class ReadCodeComponent implements OnInit {

  @Input() type:string = '';
  @Input() pedNumber:string = '';
  @Input() variant: string = '';
  @Input() reference: string = '';
  @Input() invoiceNumber:string = '';
  @Input() orderId: string = '';
  @Input() countData: any = null;
  @Input() createCountId:any = null;
  @Output() reloadTable = new EventEmitter();

  pedNumberToSearch:string = ''

  newInput:FormGroup = new FormGroup({
    ordenCompra: new FormControl('', [Validators.required] ),
    numped: new FormControl('0', [Validators.required] ),
    numOc: new FormControl('', [Validators.required] ),
    numOcComment: new FormControl(''),
    bodega: new FormControl(''),
    bodegaExit: new FormControl(''),
    numOcReasonType: new FormControl(''),
    numOcReasonOrderIdType: new FormControl(null),
    numOcReasonIdType: new FormControl(null),
    numCountReasonIdType: new FormControl(null),
    numPv: new FormControl('', [Validators.required] ),
    country: new FormControl('', [Validators.required] ),
    codeBar: new FormControl('', [Validators.required] ),
    ean: new FormControl('', [Validators.required] ),
    lote: new FormControl('', [Validators.required] ),
    loteId: new FormControl('', [Validators.required] ),
    expire: new FormControl('', [Validators.required] ),
    scanId: new FormControl('', [Validators.required] ),
    invoice: new FormControl('', [Validators.required] ),
    qty: new FormControl(1, [Validators.required] ),
  });;

  currStep:number = 1;
  scannedCodes:any[] = [];

  addManual:boolean = false;

  ocListReason:any[] = [];
  ocListReason_sel:any = {};

  ocListBodega:any[] = [];
  ocListEntry:any[] = [];
  ocListExit:any[] = [];
  selOc:any = {};
  ocProdList:any[] = [];
  ocProdListView:any[] = [];
  ocProdListBool:boolean = false;

  editProduct:number = 0;
  changeStatidView:number = 0;
  changeStatView:boolean = false;
  changeStatQtyView:boolean = false;

  readedCode:boolean = false;
  selOcCarrier:any = ''
  showShipmentMsj:boolean = false
  shipmentMessage:any = {}
  showPartialShipButton:boolean = false
  carrierErrorMessage:any = {}
  showCarrierErrorMessage:boolean = false
  loaderCreateShipment:boolean = false
  lastShipGuide:any = {}
  showFinalClientLabel:boolean = false;

  receptionId:number = 0;

  atLeastOneProductUpdated: boolean = false;
  loading=false;

  disabledButton: boolean = false;

  constructor(
   public _gen:GeneralService,
   public _ord:OrdersService,
   public _ped:PedidosService,
   public _cat:CatalogService,
   public _inv:InventoryService
 ) { }

  ngOnInit(): void {   
    if(this.type != 'inventory_entry' && this.type != 'ped_salida' && this.type != 'count'){
      this.getReasons( this.type );
      this._ord.getAllOrdsFilterSelectEntry( this._gen.currCountry ).then( ({data}) => {
        this.ocListEntry = data;
      })
      this._ord.getAllOrdsFilterSelectExit( this._gen.currCountry ).then( ({data}) => {
       this.ocListExit = data;
       // Cuando se resuelve la ultima promesa, se coloca un focus en el input
       document.getElementById('barCode')?.focus();
     })
   }
    this.newInput.controls['country'].setValue( this._gen.currCountry );
    if ( this.type == "ped_salida" ) {
      this.newInput.controls['numOcReasonIdType'].setValue(2);
      this.currStep = 2;     
      if(this.pedNumber){
        this.pedNumberToSearch = this.pedNumber
        this.changeStep( this.newInput, this.currStep );
      }
      this.newInput.controls['ordenCompra'].setValue( 'exit' );
    }
    if(this.type == 'inventory_entry'){
      this.currStep = 2;
      this.changeStep( this.newInput, this.currStep );
    }
    if(this.type == 'count') {
      this.currStep = 2;
      this.changeStep( this.newInput, this.currStep );
    }
 
    // let urlProxy = 'https://onetreelentes.s3.us-east-2.amazonaws.com/lentes-formula/100009/193491.pdf'
    // this._ped.getPdfToPrint(urlProxy).subscribe((response) => { // download file
    //   console.log({response})
    //   var blob = new Blob([response], {type: 'application/pdf'});
    //   const blobUrl = URL.createObjectURL(blob);
    //   const iframe = document.createElement('iframe');
    //   iframe.style.display = 'none';
    //   iframe.src = blobUrl;
    //   document.body.appendChild(iframe);
    //   iframe?.contentWindow?.print();

      // let winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
      //   winPrint!.document.write(blobUrl);
      //   winPrint!.document.close();
      //   winPrint!.focus();
      //   winPrint!.print();
      //   winPrint!.close(); 
    //})

  }

  getReasons( type:any ) {
    let truetype:any;

    if ( type == 'inv_entrada' ) {
      // this.newInput.controls['ordenCompra'].setValue( 'entry,cross' );
      truetype = 'entry,cross,trans';
    } else if ( type == 'inv_salida' ) {
      // this.newInput.controls['ordenCompra'].setValue( 'exit' );
      truetype = 'exit,trans';
    } else if( type == 'inventory_entry'){
      truetype = 'entry,cross';
    } else if( type = 'count'){
      truetype = 'count';
    }

    if( type !== 'count') {
      this._ord.getAllstockReasons( truetype ).then( ({data}) => {
        this.ocListReason = data;
      })
    } else {
      this._ord.getAllstockReasons( truetype ).then( ({data}) => {
        const value = data[0]?.reason_id;
        this.newInput.controls['numCountReasonIdType'].setValue(value);
      })
    }
  }
  reasonChange( evt:any ) {

    this.ocListReason_sel = this.ocListReason.find( elem => elem.reason_id === parseInt(evt) );
    this.newInput.controls['numOcReasonType'].setValue( this.ocListReason_sel?.type );
    this.newInput.controls['numOcReasonOrderIdType'].setValue( this.ocListReason_sel?.order_reason );
    this.newInput.controls['numOcReasonIdType'].setValue( this.ocListReason_sel?.reason_id );
    
    // console.log( this.ocListReason_sel?.type );
    // console.log( this.ocListReason_sel );
    
    if ( this.ocListReason_sel?.order_reason ) {
      this._ord.getAllOrdsFilterSelectEntryReason( this._gen.currCountry, this.ocListReason_sel?.order_reason ).then( ({data}) => {
        this.ocListEntry = data;
      })
    }

    this._ord.getAllEntryBodega( this._gen.currCountry ).then( ({data}) => {
      this.ocListBodega = data;
    })

  }

  async changeStep( form:FormGroup, step:any ) {
    this.currStep = step;

    if ( this.currStep == 1 ){
      this.newInput.reset();
      this.selOc = {};
    }
  
    if( this.type == 'inventory_entry'){
      let reasonToScan = {
        reason_id: 0
      };
      let receptionidToScan = 0
      let commentToScan = '';
      let orderToScan = '';
      try {
        await this._inv.getReceptionByInvoiceNumberAndOrderId(this.invoiceNumber, this._gen.currCountry, this.orderId)
          .then(data => {
            if(data.data.length > 0){
              const { products, reason, comment, provider, provider_stock, reception_id, order } = data.data[0];
              reasonToScan = reason;
              receptionidToScan = reception_id;
              commentToScan = comment;
              orderToScan = order.order_id;
              this.ocProdListView = products.filter( (item:any) => item.quantity > 0);
              this.receptionId = reception_id;
      
              this.resetCodeBar();
      
              this.newInput.controls['bodega'].setValue(provider_stock.provider_id);
            }
          })
        await this._ord.genInventoryEntryScanNoId( reasonToScan.reason_id, receptionidToScan, commentToScan, orderToScan ).then( ({data}) => {
            this.newInput.controls['scanId'].setValue( data.ids );
          })
      } catch(err) {
        console.log({err})
      }
  
    } else if ( this.type == 'count') {

      this.getCountProds();


    } else if ( this.type != "ped_salida" ) {
      if ( this.currStep == 2 ) {     
        if ( this.newInput.controls.invoice.value ) {
          if ( this.newInput.controls.numOc.value ) {
            this._ord.getOrdsFilterById( this.newInput.controls.numOc.value ).then( ({data}) => {
              this.selOc = data;         
              this.getOrdersProds();
    
              let updateData = [
                {
                  order_id: this.selOc.order_id,
                  invoice: this.newInput.controls.invoice.value,
                  status_id: this.selOc.status
                }
              ]
    
              this._ord.updateOrder( updateData ).then( () => {
                console.log( "- - - - -" );
                
                if ( !this.newInput.controls.scanId.value ) {
                  this._ord.genEntryScan( this.selOc.order_id, this.newInput.controls.numOcReasonIdType.value, this.newInput.controls.numOcComment.value ).then( ({data}) => {
                    this.newInput.controls['scanId'].setValue( data.ids );
                  })
                }
              })
              
            })
          } else {
            if ( !this.newInput.controls.scanId.value ) {
              // console.log( this.newInput.controls.numOcReasonType.value );
              
              this._ord.genEntryScanNoId( this.newInput.controls.numOcReasonIdType.value, this.newInput.controls.numOcComment.value ).then( ({data}) => {
                this.newInput.controls['scanId'].setValue( data.ids );
              })
            }
          }
        } else {

          if ( this.newInput.controls.numOc.value ) {
            this._ord.getOrdsFilterById( this.newInput.controls.numOc.value ).then( ({data}) => {
              this.selOc = data;
              this.getOrdersProds();
    
              if ( !this.newInput.controls.scanId.value ) {
                this._ord.genEntryScan( this.selOc.order_id, this.newInput.controls.numOcReasonIdType.value, this.newInput.controls.numOcComment.value ).then( ({data}) => {
                  this.newInput.controls['scanId'].setValue( data.ids );
                })
              }
            })
            this.resetCodeBar()
          } else {
            if ( !this.newInput.controls.scanId.value ) {
              // console.log( this.newInput.controls.numOcReasonType.value );
              
              this._ord.genEntryScanNoId( this.newInput.controls.numOcReasonIdType.value, this.newInput.controls.numOcComment.value ).then( ({data}) => {
                this.newInput.controls['scanId'].setValue( data.ids );
              })
            }
          }
          
        }
      }
      if ( this.currStep == 3 ) {
        this.getOrdersProds();
      }
    } else if ( this.type == "ped_salida" ) {
      if ( this.currStep == 2 ) {
        this._ped.getAllProdsById( this.pedNumberToSearch ).then( ({data}) => {  
          this.selOc = {
            reference: data.order_number,
            sale_id: data.sale_id,
            products_sum: data.products_sum,
          };
          this.selOcCarrier = data.carrier
          if(!this.selOcCarrier) {
            this.carrierErrorMessage.text = `No existe carrier`
            this.carrierErrorMessage.class = '48px_e-remove error'
            this.showCarrierErrorMessage = true
            // tocaría sacarle el focus al barcode input??
          }
          // valida si es cliente Final
          this.validateFinalClient(data)

          this.getPedsProds();

          if ( !this.newInput.controls.scanId.value ) {
            this._ord.genPedScan( this.selOc.sale_id, this.newInput.controls.numOcReasonIdType.value ).then( ({data}) => {             
              this.newInput.controls['scanId'].setValue( data.ids );
            }).catch( (err:any) => {
              console.log( err.response );
              
            })
          }

          
        }).catch( (err) => {
          console.log( err.response.data );
        } )
      }
      if ( this.currStep == 3 ) {
        this.getPedsProds();
      }
    }

    
    setTimeout(() => {
      if ( this.currStep == 2 ) {
        document.getElementById('barCode')?.focus()
      }
    }, 200);
    
    // console.log( form );
  }

  getOrdersProds() {
    if(this.type != 'inventory_entry'){
      this.ocProdList = [];   
      this.ocProdListView = [];
        this._ord.getOrdsFilterById( this.newInput.controls.numOc.value ).then( ({data}) => {    
        this.ocProdList = data.products;     
              
        for (let f = 0; f < this.ocProdList.length; f++) {
          const element:any = this.ocProdList[f];
          let qtyArr:any[] = [];
          for (let d = 0; d < element.quantity; d++) {
            qtyArr.push( d + 1 );
          }
            this.ocProdListView.push({
            order_product_id: element.order_product_id,
            name: element.name,
            ean: element.ean,
            status: element.status,
            statuses: element.statuses,
            newStatus: element.status,
            campaign_name: element.campaign_name,
            quantity: element.quantity,
            quantityArr: qtyArr,
            newQuantity: 1,
            sku_full: element.sku_full.sku_full,
            parts: element.sku_full.sku_full.split('_'),
            sale: 0,
          })
        }
          this.resetCodeBar()
        })
    } else {
      this._inv.getReceptionByInvoiceNumberAndOrderId(this.invoiceNumber, this._gen.currCountry, this.orderId)
        .then(data => {
          const {products} = data.data[0]
          this.ocProdListView = products;
        })
    }
  }

  getPedsProds() {
    this.ocProdList = [];    
    this.ocProdListView = [];

    this._ped.getAllProdsById( this.selOc.sale_id ).then( ({data}) => {
      this.ocProdList = data.products;
      const { scan_shipment } = data

      for (let d = 0; d < this.ocProdList.length; d++) {
        const element = this.ocProdList[d];

        this.ocProdListView.push({
          name: element.sku_full.product.name,
          ean: element.sku_full.ean,
          quantity: element.quantity,
          sku_full: element.sku_full.sku_full,
          sku: element.sku_full.sku,
          formula: element.formula,
          // parts: element.sku_full.sku_full.split('_'),

          item_id: element.item_id,
          sale: element.sale,

          qty_import: element.qty_import,
          qty_completed: element.qty_completed,
          qty_packed: element.qty_packed,
          qty_shipped: element.qty_shipped,
          qty_special: element.qty_special,
          qty_canceled: element.qty_canceled
        })
        
      }

      const totalShippedEnabled = scan_shipment.total_shipped === "true";
      const partialShippedEnabled = scan_shipment.partial_shipped === "true";
      const hasCarrier = this.selOcCarrier;

      if(!(totalShippedEnabled) && hasCarrier && partialShippedEnabled)  {
        this.showPartialShipButton = true
      } else {
        this.showPartialShipButton = false
      }
        
      setTimeout(() => {
        this.resetCodeBar();
      }, 1000);

      if(hasCarrier && totalShippedEnabled) {
        let data = [
          {
            sale_id: this.selOc.sale_id,
            carrier: this.selOcCarrier,
          }
        ]

        this.showLoader()
        this._ped.genShipment(data).then( (data) => {
          this.closeLoader()
          if(data.data[0].status == 'ok') {
            this.shipmentMessage.text = `Envío de pedido ${this.selOc.reference} exitoso`
            this.shipmentMessage.class = '48px_check success'
            this.showShipmentMsj = true
            // Download Pdf ******
            if(data.data[0].guide){
              this.lastShipGuide.guide = data.data[0].guide
              this.lastShipGuide.sale_id = this.selOc.sale_id
              this.printGuide(data.data[0].guide)
            } else {
              this.lastShipGuide = {}
            }
            // Download Pdf ******
            this.getPedsProds();
            } else {
            this.shipmentMessage.text = `Hubo un error al crear el envío`
            this.shipmentMessage.class = '48px_e-remove error'
            this.showShipmentMsj = true
          }
            setTimeout(() => {
            this.showShipmentMsj = false
            this.shipmentMessage = {}
          }, 7500)
        }).catch(err => {
          console.log({err})
          this.closeLoader()
          this.shipmentMessage.text = `Hubo un error al crear el envío`
          this.shipmentMessage.class = '48px_e-remove error'
          this.showShipmentMsj = true
        })
      }
      
    }).catch( (err) => {
      console.log( err.response.data );
      this.resetCodeBar();
    } )
  }

  getCountProds() {
    if(!this.createCountId) return;
    this._inv.getCountById(this.createCountId)
    .then( ({ data }) => {
      this.ocProdList = [];   
      this.ocProdListView = [];
      this.ocProdList = data;
      for (let f = 0; f < this.ocProdList.length; f++) {
        const element:any = this.ocProdList[f];
        this.ocProdListView.push({
          name: element.sku_full.product.name,
          scanned_products: element.scanned_products,
          sku_full: element.sku_full.sku_full,
          product_count_id: element.product_count_id,
          ean: element.sku_full.ean
        })
      }
      
      this.resetCodeBar()
    })
    .catch( error => {
      this.disabledButton = false;
      console.log({error})
    })
  }

  async barcodeChange( evt:any ) {
    let str:string = '';

    if ( !this.readedCode ) {
      this.readedCode = true;
      
      await setTimeout(() => {
        str = evt.target.value.toUpperCase();
        console.log( {str} );
        
        this.newBarCode( str )
      }, 300);

    } else {
      this.closeLoader()
      return;
    }
        
  }
  newBarCode( value:any ) {
    this.showLoader()
      this._gen.getBarCode( value ).then( ({data}) => {
        this.readedCode = false; 
        
        if (Object.keys(data)[0] == 'sale_id') {
          this.pedNumberToSearch = data.sale_id
          this.newInput.controls['scanId'].setValue('')
          this._ped.getAllProdsById( this.pedNumberToSearch ).then( ({data}) => { 
            this.closeLoader()
            this.currStep = 2;
            this.changeStep( this.newInput, this.currStep );
          })
        } else {
          this.newInput.controls['ean'].setValue( data?.ean );
          this.newInput.controls['lote'].setValue( data?.number );
          this.newInput.controls['loteId'].setValue( data?.lote_id );
          this.newInput.controls['expire'].setValue( data?.expiration_date );
    
          if ( !data.error ) {
            if ( this.newInput.value.ean && this.newInput.value.lote && this.newInput.value.expire ) {
              this.newCompleteCode( );
            } else {
              this.addManual = true;
            }
          } else {
            this._gen.swal_newBcode( "error", data.error );
            this.resetCodeBar();
          }
          this.closeLoader()
        }
    
      }).catch( (err) => {
        this.closeLoader()
        this._gen.swal_newBcode( "error", err.response.data.error );
      } )

  }

  addBarcodeManual() {
    this.disabledButton = true;
    this.showLoader()
    if ( !this.newInput.value.ean && !this.newInput.value.lote && !this.newInput.value.expire ) {
      this.addManual = true;
      this.closeLoader()
    }
    this._ord.genEntryLoteId( this._gen.currCountry , this.newInput.value.lote.toUpperCase(), this.newInput.value.ean.toUpperCase() ,this.newInput.value.expire?.toUpperCase() ).then( ({data}) => {
      this.newInput.controls['loteId'].setValue( data.ids );
      this.newCompleteCode( );
    } ).catch( (error:any) => {
      this.closeLoader()
      // console.log( error.response );
      this._gen.swal_newBcode( "error", error.response.data.error );
      this.disabledButton = false;
    } )
  }

  newCompleteCode( ) {

    if( this.type == "count") {
      if(typeof this.newInput.value.qty !== "number" || this.newInput.value.qty <= 0) {
        this.showErrorMessage();
        this.closeLoader()
        return;
      }
      this._inv.genProductEntryInCount( this.createCountId, this.newInput.value.loteId, this.newInput.value.qty, this.countData.bodegaField )
      .then( ({data}) => {
        this.closeLoader();
        if ( data.msg === "ok" ) {
          this.atLeastOneProductUpdated = true;
          this.resetCodeBar().then( () => {
            this._gen.swal_newBcode( "success", "" ).then( () => {
              this.getCountProds();
            })
          })
        } else {
          this.resetCodeBar().then( () => {
            this._gen.swal_newBcode( "error", data.error ).then( () => {
              this.getCountProds();
            })
          })
        }
      })
      .catch( error => {
        console.error({error})
        this.resetCodeBar().then( () => {
          this._gen.swal_newBcode( "error", error.response.data.error ).then( () => {
            this.closeLoader();
          })
        })
      })
    } else if ( this.type != "ped_salida" ) {
      if(typeof this.newInput.value.qty !== "number" || this.newInput.value.qty <= 0) {
        this.showErrorMessage();
        this.closeLoader()
        return;
      }
      this._ord.genAdd( this.newInput.value.scanId, this.newInput.value.loteId, this.newInput.value.bodega, this.newInput.value.bodegaExit, this.newInput.value.qty )
      .then( ({data}) => {
        this.closeLoader()
        if ( !data.error ) {
          this.resetCodeBar().then( () => {
            this._gen.swal_newBcode( "success", "" ).then( () => {
              this.getOrdersProds();
            })
          })
        } else {
          this.resetCodeBar().then( () => {
            this._gen.swal_newBcode( "error", data.error ).then( () => {
              this.getOrdersProds();
            })
          })
        }
      }).catch( (error:any) => {
        // console.log( error.response.data.error );
        this.closeLoader()
        this._gen.swal_newBcode( "error", error.response.data.error ).then( () => {
          this.getOrdersProds();
        })
      } )

    } else if ( this.type == "ped_salida" ) {
      if(typeof this.newInput.value.qty !== "number" || this.newInput.value.qty <= 0) {
        this.showErrorMessage();
        this.closeLoader()
        return;
      }
      this._ord.genAdd( this.newInput.value.scanId, this.newInput.value.loteId, this.newInput.value.bodega, this.newInput.value.bodegaExit, this.newInput.value.qty )
      .then( ({data}) => {
        this.closeLoader()
        if ( !data.error ) {
          this.resetCodeBar().then( () => {
            this._gen.swal_newBcode( "success", "" ).then( () => {
              this.getPedsProds();
            })
          })
        } else {
          this.resetCodeBar().then( () => {
            this._gen.swal_newBcode( "error", data.error ).then( () => {
              this.getPedsProds();
            })
          })
        }
      }).catch( (error:any) => {
        this.closeLoader()
        // console.log( error.response.data.error );
        this._gen.swal_newBcode( "error", error.response.data.error ).then( () => {
          this.getPedsProds();
        })
      } )

    }
  }

  resetCodeBar() {
    let prom = new Promise( (resolve,reject) => {

      this.addManual = false;

      this.newInput.controls['codeBar'].setValue( "" );
      this.newInput.controls['ean'].setValue( "" );
      this.newInput.controls['lote'].setValue( "" );
      this.newInput.controls['expire'].setValue( "" );
      this.newInput.controls['qty'].setValue( 1 );
      setTimeout(() => {
        document.getElementById('barCode')?.focus();
        this.disabledButton = false;
        resolve(true);
      }, 200);

    } )
    return prom;
  }

  closeModal() {
    this._gen.barCodeEntryView = false;
    this._gen._barCodeEntryViewChange.next( false );

    this._gen.barCodeEntryViewPeds = false;
    this._gen._barCodeEntryViewPedsChange.next( false );

    this._gen.barCodeCountView = false;
    this._gen._barCodeCountViewChange.next( false );
    this._inv.createCountView = false;
    if(this.atLeastOneProductUpdated) {
      this.reloadTable.emit()
    }

    this.currStep = 1;
    
    this.newInput.controls['ordenCompra'].setValue( "" );
    this.newInput.controls['numOc'].setValue( "" );
    this.newInput.controls['numOcComment'].setValue( "" );
    this.newInput.controls['numPv'].setValue( "" );
    this.newInput.controls['country'].setValue( "" );
    this.newInput.controls['codeBar'].setValue( "" );
    this.newInput.controls['ean'].setValue( "" );
    this.newInput.controls['lote'].setValue( "" );
    this.newInput.controls['loteId'].setValue( "" );
    this.newInput.controls['expire'].setValue( "" );
    this.newInput.controls['scanId'].setValue( "" );
  
    this.receptionId = 0;
    this.reference = '';
  }

  editProdFx( itm:any ) { 
    this.editProduct = itm.order_product_id; 
    this.changeStatidView = itm.order_product_id; 
    // console.log( itm );
  }

  cancelProdStatusFx() {
    this.editProduct = 0;  
    this.changeStatidView = 0;  
    this.changeStatView = false;
    this.changeStatQtyView = false;
  }
  changeProdStatusFx( item:any ) {
        
    let prodData = {
      order_product_id: item.order_product_id,
      quantity: item.newQuantity,
      status_from: "created",
      status_to: "received"
    };      

    this._ord.changeOrderProdStatus( prodData )
    .then( ({data}) => {
      
      Swal.fire(
        'Cambio de estado exitoso',
        ``,
        'success'
      ).then( () => {
        this.editProduct = 0; 
        this.changeStatidView = 0; 
        this.changeStatView = false;
        this.changeStatQtyView = false;

        this.getOrdersProds();
      })

    }).catch( (error:any) => {
      let data = error.response.data;

      Swal.fire(
        'Error al crear orden de compra',
        `${data.error}`,
        'error'
      ).then( () => {
        this.editProduct = 0;  
        this.changeStatidView = 0;  
        this.changeStatView = false;
        this.changeStatQtyView = false;
        

        this.getOrdersProds();
      })
      
    })
    
  }


  // =======
  ocChanged( evt:any ) {
    this.newInput.controls['invoice'].disable();
    this.newInput.controls['invoice'].setValue( "" );

    this._ord.getOrdsFilterById( evt ).then( ({data}) => {
      this.newInput.controls['numOcReasonType'].setValue( data.reason.type );
      this.newInput.controls['invoice'].setValue( data.invoice );
      this.newInput.controls['invoice'].enable();

      this._ord.getAllEntryBodega( this._gen.currCountry ).then( ({data}) => {
        this.ocListBodega = data;
      })
    })
  }

  existCarrierIndOrder() {
    return this.selOcCarrier
  }

  genShipFx(){
    this.disabledButton = true
    Swal.fire({
      title: '¿Confirma generar envío?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = [
          {
            sale_id: this.selOc.sale_id,
            carrier: this.selOcCarrier,
          }
        ]
        this.showLoader()
        this._ped.genShipment( data )
          .then( (data) => {
            this.closeLoader()
            if(data.data[0].status == 'ok'){
              this.shipmentMessage.text = `Envío parcial de pedido ${this.selOc.reference} exitoso`
              this.shipmentMessage.class = '48px_check success'
              this.showShipmentMsj = true
              if(data.data[0].guide){
                this.lastShipGuide.guide = data.data[0].guide
                this.lastShipGuide.sale_id = this.selOc.sale_id
                this.printGuide(data.data[0].guide)
              } else {
                this.lastShipGuide = {}
              }
              this.getPedsProds();

            } else {
              this.shipmentMessage.text = `Hubo un error al crear el envío`
              this.shipmentMessage.class = '48px_e-remove error'
              this.showShipmentMsj = true
              // document.getElementById('barCode')?.focus();
            }
          })
          .catch(err => {
            this.closeLoader()
            this.shipmentMessage.text = `Hubo un error al crear el envío`
            this.shipmentMessage.class = '48px_e-remove error'
            this.showShipmentMsj = true
            this.disabledButton = false;
              // document.getElementById('barCode')?.focus();
          })
          setTimeout(() => {
            this.showShipmentMsj = false
            this.shipmentMessage = {}
          }, 5000)
        // Swal.fire('Saved!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
        this.disabledButton = false;
        // document.getElementById('barCode')?.focus();
      }
    })
    // document.getElementById('barCode')?.focus();
  }

  printGuide(url: any){
      this._ped.getPdfToPrint(url)
      .subscribe((response) => { // download file
        var blob = new Blob([response], {type: response.type});
        const blobUrl = URL.createObjectURL(blob);
        const iframeAnterior = document.querySelector('iframe');
        if (iframeAnterior) iframeAnterior!.parentNode!.removeChild(iframeAnterior);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.addEventListener("load", function () {
          if (url.includes("api.pickit.com")) {
            var styles = `.container > div:first-of-type > div:last-of-type > .block  { display: none;}`
            var styleSheet = iframe!.contentDocument!.createElement("style")
            styleSheet.innerText = styles
            iframe!.contentDocument!.head.appendChild(styleSheet)
            const body = iframe!.contentDocument!.querySelector('body') as HTMLElement;
            body!.style.setProperty('max-height', '400px');
            body!.style.setProperty('transform', 'scale(.9)');
            body!.style.setProperty('transform-origin', 'top left');
            body!.style.setProperty('min-width', '550px');
          }
        });
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe?.contentWindow?.print();
        // document.getElementById('barCode')?.focus();
      }, error => {
          console.error({error})
          console.log({error})
        }
      )
  }

  showLoader() {
    this._gen.generalLoader = true;
  }

  closeLoader() {
    this._gen.generalLoader = false;
    document.getElementById('barCode')?.focus();
    this.disabledButton = false;
  }

  goToOrderDetail() {
    var url = `/#/home/pedidos/${this.selOc.sale_id}`;
    window.open(url, '_self');
  }

  showPrintGuideButton() {
    return this.lastShipGuide.sale_id === this.selOc.sale_id && this.lastShipGuide.guide
  }

  validateFinalClient(data:any){
    if (!data.location_other) return;
    const {location_other} = data;
    if(Object.keys(location_other).length != 0 && Object.keys(location_other).includes("final_client") && location_other.final_client) {
      this.showFinalClientLabel = true
    } else {
      this.showFinalClientLabel = false
    }
  }
  
  subtractAmount(item:any){
    if(this.receptionId == 0 || item.quantity < 1) return;
    this.ocProdListView = [];
    let data = {
      entry_id: item.entry.entry_id,
    }
    this._inv.subtractItemAmount(data).then(data => {
      const {products} = data.data;
      this.ocProdListView = products.filter( (item:any) => item.quantity > 0);
    })
  }

  getReference(){
    return decodeURIComponent(this.reference)
  }

  subtractCountAmount(item:any) {
    this.ocProdListView = [];
    const data = {
      product_count_id: item.product_count_id
    }
    this.loading = true;
    this._inv.removeQuantityProductFromCount(data)
    .then( ({data}) => {
      this.loading = false;
      if(data.msg === 'ok') {
        this.atLeastOneProductUpdated = true;
        this.getCountProds();
      }
    })
    .catch( (error:any) => {
      this.loading = false;
      console.log({error})
    })
  }

  showErrorMessage() {
    return Swal.fire({
      title: 'Ocurrió un error',
      html: `La cantidad debe ser un número mayor a 0`,
      timer: 3800,
      icon: 'error',
      timerProgressBar: true,
    })
  }
}  
