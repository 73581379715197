
<div class="topttls">
    <app-tittle [label]="'Inventario - ' + inv_type"></app-tittle>

    <div class="btns">
        &nbsp;

        <div class="totalProds">
            <div><span>Total registros:</span> <b>{{ dataSourceCount }}</b></div>
        </div>
    </div>
</div>

<div class="topTableWrap">
    <div class="left">

        <div class="topTabs">
            <div class="tabItem" [ngClass]="{'active': inv_type == 'Entradas'}">
                Entradas
            </div>
            <div class="tabItem" (click)="inv_tabs('Salidas')" [ngClass]="{'active': inv_type == 'Salidas'}">
                Salidas
            </div>
        </div>
        
    </div>
    <div class="right">
        <!-- <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_inv.sel_invInputs.length == 1 ">
            <i [class]="'48px_edit' | icons"></i>
            <span>Editar</span>
        </button>
        <button class="btn editBtn animate__animated animate__fadeIn" *ngIf="_inv.sel_invInputs.length >= 1 ">
            <i [class]="'48px_trash-can' | icons"></i>
            <span>Eliminar</span>
        </button> -->
        <!-- <button class="btn editBtn">
            <i [class]="'48px_search' | icons"></i>
            <span>Buscar</span>
        </button> -->
        <div class="filterView">
            <button class="btn editBtn" (click)="resetTobTable('filtsView')">
                <i [class]="'48px_filter' | icons"></i>
                <span>Filtrar</span>
            </button>
            <div class="filterItems animate__animated animate__fadeInRight " *ngIf="filtsView">
                <div class="closeFilt" (click)="filtsView = false">
                    <button>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </button>
                </div>
                <div class="filtTtl">
                    <h3>Filtros</h3>
                </div>
                <div class="filtActive customScrollBar">
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.country.length >= 1">
                        <span><b>País: </b> <i *ngFor="let country of _inv.filterOpts_input.country; index as i"> <i *ngIf="i >= 1"> - </i>{{country | idCountry}}</i> </span>
                        <!-- <i [class]="'48px_e-remove' | icons"></i> -->
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.order.length >= 1" (click)="resetFilters_item('order')">
                        <span><b>Orden #: </b> <i *ngFor="let order of _inv.filterOpts_input.order; index as i"> <i *ngIf="i >= 1"> - </i> {{order | idOrder}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.status.length >= 1" (click)="resetFilters_item('status')">
                        <span><b>Estado: </b> <i *ngFor="let status of _inv.filterOpts_input.status; index as i"> <i *ngIf="i >= 1"> - </i> {{status}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.entry_from" (click)="resetFilters_item('entry_from')">
                        <span><b>Desde: </b> <i> {{ _gen.getDateFormat( _inv.filterOpts_input.entry_from ) }} </i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.entry_to" (click)="resetFilters_item('entry_to')">
                        <span><b>Hasta: </b> <i> {{ _gen.getDateFormat( _inv.filterOpts_input.entry_to ) }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.product_type.length >= 1" (click)="resetFilters_item('provider_id')">
                        <span><b>Tipo de producto: </b> <i *ngFor="let type of _inv.filterOpts_input.product_type; index as i"> <i *ngIf="i >= 1"> - </i>{{type | idProdtype}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.reason.length >= 1" (click)="resetFilters_item('reason')">
                        <span><b>Motivo: </b> <i *ngFor="let reas of _inv.filterOpts_input.reason; index as i"> <i *ngIf="i >= 1"> - </i>{{reas | idReason}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.lote" (click)="resetFilters_item('lote')">
                        <span><b>Lote: </b> <i> {{ _inv.filterOpts_input.lote }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.exp_from" (click)="resetFilters_item('exp_from')">
                        <span><b>Fecha Exp. Desde: </b> <i> {{ _gen.getDateFormat( _inv.filterOpts_input.exp_from ) }} </i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.exp_to" (click)="resetFilters_item('exp_to')">
                        <span><b>Fecha Exp. Hasta: </b> <i> {{ _gen.getDateFormat( _inv.filterOpts_input.exp_to ) }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.sku" (click)="resetFilters_item('sku')">
                        <span><b>sku: </b> <i> {{ _inv.filterOpts_input.sku }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.sku_full" (click)="resetFilters_item('sku_full')">
                        <span><b>sku full: </b> <i> {{ _inv.filterOpts_input.sku_full }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.name" (click)="resetFilters_item('name')">
                        <span><b>Nombre Producto: </b> <i> {{ _inv.filterOpts_input.name }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.manufacturer.length >= 1" (click)="resetFilters_item('manufacturer')">
                        <span><b>Laboratorio: </b> <i *ngFor="let man of _inv.filterOpts_input.manufacturer; index as i"> <i *ngIf="i >= 1"> - </i> {{man | idManufac}}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.order_id" (click)="resetFilters_item('order_id')">
                        <span><b># de Orden: </b> <i> {{ _inv.filterOpts_input.order_id }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.invoice" (click)="resetFilters_item('invoice')">
                        <span><b>Factura: </b> <i> {{ _inv.filterOpts_input.invoice }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.ean" (click)="resetFilters_item('ean')">
                        <span><b>ean: </b> <i> {{ _inv.filterOpts_input.ean }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                    <div class="filtActItm" *ngIf="_inv.filterOpts_input.bodega" (click)="resetFilters_item('bodega')">
                        <span><b>Bodega: </b> <i> {{ _inv.filterOpts_input.bodega }}</i> </span>
                        <i [class]="'48px_e-remove' | icons"></i>
                    </div>
                </div>
                <div class="filtList customScrollBar">
                    <div class="filItm filItm_hide">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <mat-select [(ngModel)]="_inv.filterOpts_input.country" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _gen.allCountries" value="{{opt.country_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Orden #:</mat-label>
                                <mat-select [(ngModel)]="_inv.filterOpts_input.order" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _inv.filt_orders" value="{{opt.order_id}}">{{opt.reference}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Estado</mat-label>
                                <mat-select [(ngModel)]="_inv.filterOpts_input.status" multiple (ngModelChange)="getFiltersOpts()"(keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _gen.allOrdsStatus" value="{{opt.name}}">{{opt.pretty}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp3.open()">
                                <mat-label>Desde</mat-label>
                                <input matInput [matDatepicker]="dp3" [max]="maxDate" disabled [(ngModel)]="_inv.filterOpts_input.entry_from" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp4.open()">
                                <mat-label>Hasta</mat-label>
                                <input matInput [matDatepicker]="dp4" [max]="maxDate" disabled [(ngModel)]="_inv.filterOpts_input.entry_to">
                                <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                                <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Tipo de producto</mat-label>
                                <mat-select [(ngModel)]="_inv.filterOpts_input.product_type" multiple (ngModelChange)="getFiltersOpts()">
                                    <mat-option *ngFor="let opt of _gen.allProdTypes" value="{{opt.type_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Marca</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Motivo</mat-label>
                                <mat-select [(ngModel)]="_inv.filterOpts_input.reason" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _inv.filt_reason" value="{{opt.reason_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Lote</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_inv.filterOpts_input.lote" name="lote" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp5.open()">
                                <mat-label>Fecha Exp. Desde</mat-label>
                                <input matInput [matDatepicker]="dp5" [max]="maxDate" disabled [(ngModel)]="_inv.filterOpts_input.exp_from" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp5"></mat-datepicker-toggle>
                                <mat-datepicker #dp5 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm filItm_date">
                        <div class="form_field">
                            <mat-form-field appearance="outline" (click)="dp6.open()">
                                <mat-label>Fecha Exp. Hasta</mat-label>
                                <input matInput [matDatepicker]="dp6" [max]="maxDate" disabled [(ngModel)]="_inv.filterOpts_input.exp_to" (keydown.enter)="checkFilter()">
                                <mat-datepicker-toggle matSuffix [for]="dp6"></mat-datepicker-toggle>
                                <mat-datepicker #dp6 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>sku</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_inv.filterOpts_input.sku" name="sku" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>sku full</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_inv.filterOpts_input.sku_full" name="sku_full" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre Producto</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_inv.filterOpts_input.name" name="name" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Laboratorio</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>laboratorios</mat-label>
                                <mat-select [(ngModel)]="_inv.filterOpts_input.manufacturer" multiple (ngModelChange)="getFiltersOpts()" (keydown.enter)="checkFilter()">
                                    <mat-option *ngFor="let opt of _inv.filt_manufac" value="{{opt.manufacturer_id}}">{{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label># de Orden</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_inv.filterOpts_input.order_id" name="order_id" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Factura</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_inv.filterOpts_input.invoice" name="invoice" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>ean</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_inv.filterOpts_input.ean" name="ean" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                    <div class="filItm">
                        <!-- <div class="labl">Name</div> -->
                        <div class="form_field">
                            <mat-form-field appearance="outline">
                                <mat-label>Bodega</mat-label>
                                <input matInput placeholder="Placeholder" [(ngModel)]="_inv.filterOpts_input.bodega" name="bodega" (keydown.enter)="checkFilter()">
                              </mat-form-field>
                        </div>    
                    </div>
                </div>
                <div class="filtSend">
                    <button class="btn" (click)="resetFilters_item('')">
                        Borrar filtros
                    </button>
                    <button class="btn btn_success btn_fill" (click)="checkFilter()">
                        Aplicar filtros
                    </button>
                </div>

            </div>
        </div>

        <div class="colsView">
            <button class="btn editBtn" (click)="resetTobTable('colsView')">
                <i [class]="'48px_separate-2' | icons"></i>
                <span>Columnas</span>
            </button>
            <div class="columnsItems animate__animated animate__fadeInUp " *ngIf="colsView" appClickOutside (clickOutside)="colsView = false">
                <div class="colItem" *ngFor="let col of columnsConfig; index as i">
                    <div class="ios_checkBox">
                        <input type='checkbox' id='checkbox-{{i}}' [checked]="col.visible" (change)="col.visible = !col.visible">
                        <label for='checkbox-{{i}}'>{{col.label}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-table [cols]="columnsConfig" [data]="dataSource" [type]="tableType"></app-table>

<div class="bottomTable">
    <div class="pageInp">
        <div class="form_field">
            <input type="text" name="" id="" value="{{currPage}}" (change)="changePageInp( $event, true )">
        </div>
        <span>de {{currPageTotal}}</span>
    </div>
    
    <div class="pagerWrap">
        <div class="pagerItms">
            <button class="btn btn_sm prev" [disabled]="currPage == 1" (click)="changePageInp( currPage - 1 )"> <i [class]="'48px_left-arrow' | icons"></i> </button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 2 )" [disabled]="currPage <= 2">{{currPage - 2}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage - 1 )" [disabled]="currPage == 1">{{currPage - 1}}</button>
            <button class="btn btn_sm page active">{{currPage}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 1 )" [disabled]="currPage == currPageTotal" >{{currPage + 1}}</button>
            <button class="btn btn_sm page" (click)="changePageInp( currPage + 2 )" [disabled]="currPage >= currPageTotal - 1" >{{currPage + 2}}</button>
            <button class="btn btn_sm page" disabled>...</button>
            <button class="btn btn_sm next" (click)="changePageInp( currPageTotal )" [disabled]="currPage == currPageTotal" >{{currPageTotal}}</button>
            <button class="btn btn_sm page last" [disabled]="currPage == currPageTotal" (click)="changePageInp( currPage + 1 )"> <i [class]="'48px_right-arrow' | icons"></i> </button>
        </div>
    </div>

    <div class="perPage">
        <div class="form_field">
            <div class="select_box">
                <select name="" id="" [(ngModel)]="currPageView" (change)=" getAllEntries() ">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
                <i [class]="'48px_down-arrow' | icons"></i>
            </div>
        </div>
        <span>por página</span>
    </div>
</div>

<app-read-code  [type]="'inv_entrada'" *ngIf="_gen.barCodeEntryView"></app-read-code>
