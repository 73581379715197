import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { PedidoListComponent } from 'src/app/pages/inner/pedidos/pedido-list/pedido-list.component'

import Swal from 'sweetalert2';
import { TableService } from 'src/app/services/table.service';

@Component({
  selector: 'app-process-peds',
  templateUrl: './process-peds.component.html',
  styles: [
  ]
})
export class ProcessPedsComponent implements OnInit {

  @Input() list:any[] = [];
  listTemp:any;

  listSuccess:any[] = [];

  listToChange:any[] = [];
  listToChangeSend:any[] = [];

  listCharge:boolean = false;

  changeDone:boolean = false;

  constructor( public _ped:PedidosService, public _gen:GeneralService, public _pedListComponent: PedidoListComponent, public _table:TableService ) { }

  ngOnInit(): void {
    this._gen.getPedStatuses().then( ()=> {

      setTimeout(() => {  
        this.init();
      }, 500);

    });

  }
  
  async init() {
    this.listTemp = JSON.parse(JSON.stringify( this.list ));

    await this.getStatusChanges ( this.listTemp );
    await this.getTotalsuccess ( this.listTemp );

    this.listCharge = !this.listCharge;
  }

  getStatusChanges( list:any ) {
  
    for (let f = 0; f < list.length; f++) {
      const element:any = list[f];
      if ( this._gen.allStatusChange[`${element.status.name}`] ) {
        element.statusChange = this._gen.allStatusChange[`${element.status.name}`];        
      }
    }
  }

  getTotalsuccess( list:any ) {
    for (let d = 0; d < list.length; d++) {
      const element:any = list[d];      

      if ( element.statusChange.length !== 0 ) {
        this.listSuccess.push( element );
      }
    }
  }

  changeStstusSel( evt:any ) {
    this.listToChange = [];

    for (let d = 0; d < this.listTemp.length; d++) {
      const element:any = this.listTemp[d];
      if(element.currStatus === "holded_news" && this._gen.typifications.length === 0) {
        this._gen.getTypifications()
      }
      if ( element.currStatus !== element.status.name ) {
        this.listToChange.push( element );
      }
    }    
    this.listToChangeSend = [];
  
    for (let g = 0; g < this.listToChange.length; g++) {
      const stats = this.listToChange[g];      
  
      for (let h = 0; h < stats.pedidos.length; h++) {
        const element = stats.pedidos[h];
        
        this.listToChangeSend.push({
          sale_id: element.sale_id,
          status: stats.currStatus,
          comment: stats.commentStatus
        })
      }
      
    }
  }

  convertToNew() {
    
    Swal.fire({
      title: '<strong>Procesando pedidos</strong>',
      icon: 'info',
      html: `<img src="assets/img/preload.gif" alt="">`,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    
    setTimeout(() => {
      this._ped.changeStatus( this.listToChangeSend ).then( ({data}) => {

        if (data[0] === "ok") {
          this.changeDone = true;
  
          Swal.fire(
            'Cambio de estado exitoso',
            `Se actualizó el estado de ${ this.listToChangeSend.length } pedidos`,
            'success'
          )
        } else {
          this.showErrorChangeStatusMessage();
        }

      }).catch( (error) => {
        console.error({error});
        this.showErrorChangeStatusMessage();
      })
    }, 3000);
  }

  showErrorChangeStatusMessage() {
    Swal.fire(
      'Error en cambio de estado',
      `no es posible cambiar el estado de los ${ this.listToChangeSend.length } pedidos seleccionados`,
      'error'
    )
  }

  closeModal() {
    this._ped.processView = false;
    if(this.changeDone) {
      this._pedListComponent.getAllProds();
    }
  }

}
