import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { ScannerService } from 'src/app/services/scanner.service';

@Component({
  selector: 'app-outputs',
  templateUrl: './outputs.component.html',
  styles: [
  ]
})
export class OutputsComponent implements OnInit {

  inv_type:string = "Salidas";

  dataSourcefull:any[] = [];

  colsView:boolean = false;
  filtsView:boolean = false;
  filterOpts:any = {
    order: []
  }

  tableType:string = "outputs";
  columnsConfig = [
    { varId: "exit_id", label: "ID", visible: true, type: "uniqueId", class: "", disabled: true },
    { varId: "created_at", label: "Fecha de creación", visible: true, type: "date", class: "", disabled: true },
    { varId: "quantity", label: "Cantidad", visible: true, type: "", class: "idCell", disabled: true },
    { varId: "order", label: "Num Orden", visible: true, type: "order", class: "", disabled: true },
    { varId: "reason", label: "Motivo", visible: true, type: "", class: "", disabled: true },
    { varId: "country", label: "País", visible: true, type: "country", class: "", disabled: true },
    { varId: "lote", label: "Num. de lote", visible: true, type: "lote", class: "", disabled: true },
    { varId: "sku", label: "Sku", visible: true, type: "sku", class: "", disabled: false },
    { varId: "ean", label: "ean", visible: true, type: "ean", class: "", disabled: false },
    { varId: "sku_full", label: "Sku full", visible: true, type: "sku_full", class: "longCell noEllipsis", disabled: false },
    { varId: "name", label: "Nombre", visible: true, type: "name", class: "longCell noEllipsis", disabled: false },
    { varId: "manufacturer", label: "Laboratorio", visible: true, type: "manufacturer", class: "", disabled: false },
    { varId: "provider", label: "proveedor", visible: true, type: "provider", class: "", disabled: true },
  ]
  
  dataSourceCount = 0;
  currPage = 1; 
  currPageView = 100; 
  currPageTotal = 0; 

  dataSource:any[] = [];

  maxDate:Date = new Date( Date.now() );

  constructor( public route:Router, public _inv:InventoryService, public _scan:ScannerService, public  _gen:GeneralService ) { }

  ngOnInit(): void {
    this.getAllExits();
    this.getFiltersOpts();
    
    this._gen.currCountryObs.subscribe((country) => {
      // console.log(country)
      this.getAllExits();
      this.getFiltersOpts();
    })
  }

  /* Filter Opts */
  getFiltersOpts() {
    // var country = this._inv.filterOpts_exit.country.join();

    var country = this._gen.currCountry.toString();
    this._inv.filterOpts_exit.country = country.split(',');

    this._gen.getCountry().then( (data:any) => {
      this._gen.allCountries = data.data;
    })

    this._inv.getAllOrders().then( ({data}) => {
      this._inv.filt_orders = data;
    })
    this._gen.getProdType().then( ({data}) => {
      this._gen.allProdTypes = data;      
    })
    this._gen.getReason().then( ({data}) => {
      this._inv.filt_reason = data;
    })
    this._inv.getManufact().then( (data:any) => {
      this._inv.filt_manufac = data.data;
    })
  }

  /* PAGE COUNT */
  getAllExitCountFilt( filt:any ) {
    let prom = new Promise ( ( resolve, reject ) => {
      this._inv.getAllExitCountFilt( filt ).then( (data:any) => {
        this.dataSourceCount = data.data.count;        
        this.currPageTotal = Math.ceil( this.dataSourceCount / this.currPageView );      
        
        if ( this.currPageTotal == 0 ) {
          this.currPageTotal = 1;
        }
        if ( this.currPage > this.currPageTotal ) {
          this.currPage = this.currPageTotal
        }

        resolve( true );
      })
    })
    return prom;
  }

  /* PAGER FX */
  getAllExits() {
    this._gen.tableLoader = true;
    this.getAllExitCountFilt( this.checkFilterVal() ).then( () => {
      this._inv.getAllExitFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          this._gen.tableLoader = false;
        });
      })
    });
  }
  changePageInp( page:any, input:boolean = false ) {
    if ( input ) {
      this.currPage = parseInt( page.target.value );
    } else {
      this.currPage = parseInt( page );
    }
    this._gen.tableLoader = true;
    
    this.getAllExitCountFilt( this.checkFilterVal() ).then( () => {
      this._inv.getAllExitFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });

  }

  /* filters */
  checkFilterVal() {
    let filt_country, filt_order, filt_status, filt_entry_from, filt_entry_to, filt_reason, filt_product_type, filt_lote,
    filt_exp_from, filt_exp_to, filt_sku, filt_sku_full, filt_name, filt_manufacturer, filt_order_id, filt_invoice, filt_ean, filt_bodega;

    filt_country = `country=${ this._gen.currCountry }&`;

    // if ( this._ped.filterOpts_exit.country.length >= 1 ) {
    //   filt_country = `country=${ this._ped.filterOpts_exit.country.join() }&`;
    // }
    if ( this._inv.filterOpts_exit.order.length ) {
      filt_order = `order=${this._inv.filterOpts_exit.order}&`;
    }
    if ( this._inv.filterOpts_exit.status.length ) {
      filt_status = `status=${ this._inv.filterOpts_exit.status.join() }&`;
    }
    if ( this._inv.filterOpts_exit.entry_from ) {
      filt_entry_from = `entry_from=${ this._gen.getDateFormat( this._inv.filterOpts_exit.entry_from) }&`;
    }
    if ( this._inv.filterOpts_exit.entry_to ) {
      filt_entry_to = `entry_to=${ this._gen.getDateFormat( this._inv.filterOpts_exit.entry_to) } 23:59&`;
    }
    if ( this._inv.filterOpts_exit.product_type.length ) {
      filt_product_type = `product_type=${ this._inv.filterOpts_exit.product_type }&`;
    }
    if ( this._inv.filterOpts_exit.reason.length ) {
      filt_reason = `reason=${ this._inv.filterOpts_exit.reason }&`;
    }
    if ( this._inv.filterOpts_exit.lote ) {
      filt_lote = `lote=${ this._inv.filterOpts_exit.lote }&`;
    }
    if ( this._inv.filterOpts_exit.exp_from ) {
      filt_exp_from = `exp_from=${ this._gen.getDateFormat( this._inv.filterOpts_exit.exp_from) }&`;
    }
    if ( this._inv.filterOpts_exit.exp_to ) {
      filt_exp_to = `exp_to=${ this._gen.getDateFormat( this._inv.filterOpts_exit.exp_to) } 23:59&`;
    }
    if ( this._inv.filterOpts_exit.sku ) {
      filt_sku = `sku=${ this._inv.filterOpts_exit.sku }&`;
    }
    if ( this._inv.filterOpts_exit.sku_full ) {
      filt_sku_full = `sku_full=${ encodeURIComponent(this._inv.filterOpts_exit.sku_full.toUpperCase()) }&`;
    }
    if ( this._inv.filterOpts_exit.name ) {
      filt_name = `name=${ this._inv.filterOpts_exit.name }&`;
    }
    if ( this._inv.filterOpts_exit.manufacturer.length ) {
      filt_manufacturer = `manufacturer=${ this._inv.filterOpts_exit.manufacturer }&`;
    }
    if ( this._inv.filterOpts_exit.order_id ) {
      filt_order_id = `order_id=${ this._inv.filterOpts_exit.order_id }&`;
    }
    if ( this._inv.filterOpts_exit.invoice ) {
      filt_invoice = `invoice=${ this._inv.filterOpts_exit.invoice }&`;
    }
    if ( this._inv.filterOpts_exit.ean ) {
      filt_ean = `ean=${ this._inv.filterOpts_exit.ean }&`;
    }
    if ( this._inv.filterOpts_exit.bodega ) {
      filt_bodega = `bodega=${ this._inv.filterOpts_exit.bodega }&`;
    }

    let filter = `${filt_country}${filt_order}${filt_status}${filt_entry_from}${filt_entry_to}${filt_reason}${filt_product_type}${filt_lote}${filt_exp_from}${filt_exp_to}${filt_sku}${filt_sku_full}${filt_name}${filt_manufacturer}${filt_order_id}${filt_invoice}${filt_ean}${filt_bodega}`;    
    return filter.replace(/undefined/g,"");
  }
  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllExitCountFilt( this.checkFilterVal() ).then( () => {
      this._inv.getAllExitFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });
  }

  /* RESET Filters */
  resetFilters_item(item:string) {
    switch (item) {
      // case 'country':
      //   this._ped.filterOpts_exit.country = [];
      //   break;
      case 'order':
        this._inv.filterOpts_exit.order = [];
        break;
      case 'status':
        this._inv.filterOpts_exit.status = [];
        break;
      case 'entry_from':
        this._inv.filterOpts_exit.entry_from = [];
        break;
      case 'entry_to':
        this._inv.filterOpts_exit.entry_to = [];
        break;
      case 'product_type':
        this._inv.filterOpts_exit.product_type = "";
        break;
      case 'reason':
        this._inv.filterOpts_exit.reason = "";
        break;
      case 'lote':
        this._inv.filterOpts_exit.lote = "";
        break;
      case 'exp_from':
        this._inv.filterOpts_exit.exp_from = [];
        break;
      case 'exp_to':
        this._inv.filterOpts_exit.exp_to = [];
        break;
      case 'sku':
        this._inv.filterOpts_exit.sku = "";
        break;
      case 'sku_full':
        this._inv.filterOpts_exit.sku_full = "";
        break;
      case 'name':
        this._inv.filterOpts_exit.name = "";
        break;
      case 'manufacturer':
        this._inv.filterOpts_exit.manufacturer = "";
        break;
      case 'order_id':
        this._inv.filterOpts_exit.order_id = "";
        break;
      case 'invoice':
        this._inv.filterOpts_exit.invoice = "";
        break;
      case 'ean':
        this._inv.filterOpts_exit.ean = "";
        break;
      case 'bodega':
        this._inv.filterOpts_exit.bodega = "";
        break;
            
      default:
        this._inv.filterOpts_exit = {
          // country: [],
          order: [],
          status: [],

          entry_from: "",
          entry_to: "",
          product_type: [],
          reason: [],

          lote: "",
          exp_from: "",
          exp_to: "",
          sku: "",
          sku_full: "",
          name: "",
          manufacturer: [],
          order_id: "",
          invoice: "",
          ean: "",
          bodega: "",
        };

        break;
    }
    this.checkFilter();
  }
  /* RESET */
  resetTobTable( type:string ) {    
    setTimeout(() => {
      if ( type == 'colsView' ) {
        this.filtsView = false;
        if ( this.colsView ) { this.colsView = false } else { this.colsView = true }
      } else if ( type == 'filtsView' ) {
        this.colsView = false;
        if ( this.filtsView ) { this.filtsView = false } else { this.filtsView = true }
      }
    }, 150);
  }
  resetTable( arrFull:any[] ) {
    let prom = new Promise( (resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];      
        
      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element:any = this.dataSourcefull[d];        
        let item = {
          exit_id: element.exit_id,
          created_at: element.created_at,
          quantity: element.quantity,
          order: element.order_number,
          comments: element.comments,
          country: element.lote.country,
          reason: element.reason.name,
          provider: element.provider,
          lote: {
            lote_id: element.lote.lote_id,
            number: element.lote.number,
            expiration_date: element.lote.expiration_date,
            ean: element.lote.ean,
            created_at: element.lote.created_at,
          },
          product: element.lote.sku_full,
        }        
        this.dataSource.push( item );
      }
      resolve( true);
    })

    return prom;
  }

  inv_tabs( type:string ) {
    if ( type == 'Entradas' ) {
      this.route.navigate(['/home/inventory/inputs'])
    } else if ( type == 'Salidas' ) {
      this.route.navigate(['/home/inventory/outputs'])
    }
  }

}
