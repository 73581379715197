
<div class="prodStatusOverLay">
    <div [ngClass]="{ 
        'prodStatusBox': type === 'prod_order',
        'prodStatusBoxPedStatus': type === 'prod_pedido'
    }">
        <div class="loaderInfo" *ngIf="showLoader">
            <div class="icon">
                <img src="assets/img/preload.gif" alt="">
            </div>
        </div>
        <button class="closeBtn" (click)="closeModal();" [disabled]="showLoader">
            <span></span>
        </button>

        <div class="infoWrap" *ngIf="type === 'prod_order' ">
            <div class="title">
                Cambiar estado: {{mainInfo.prodName}} 
            </div>

            <form novalidate [formGroup]="newInput">
                <div class="status">

                    <div class="from">
                        <div class="lbl">Estado a cambiar:</div>
                        <div class="dropStatus">
                            <div class="form_field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Seleccione estado</mat-label>
                                    <mat-select formControlName="oldStatus" (ngModelChange)="onChangeStat($event)">
                                        <mat-option disabled value="null">Selecciona estado</mat-option>
                                        <mat-option *ngFor="let opt of mainInfo.from" [value]="opt.status.name">{{opt.status.pretty}} ( {{opt.quantity}} )</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>    
                        </div>
                    </div>
                    <div class="number">
                        <div class="lbl">cantidad cambiar:</div>
                        <div class="dropStatus">
                            <div class="form_field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Seleccione cantidad</mat-label>
                                    <mat-select formControlName="qty" [disabled]="newInput.controls.oldStatus.value == null">
                                        <mat-option disabled value="0">0</mat-option>
                                        <mat-option *ngFor="let opt of qtyArr" [value]="opt">{{opt}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>    
                        </div>
                    </div>
                    <div class="icon">
                        <i class="{{ '48px_arrows-same-direction' | icons }}"></i>
                    </div>
                    <div class="to">
                        <div class="lbl">Nuevo estado:</div>
                        <div class="dropStatus">
                            <div class="form_field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Seleccione estado</mat-label>
                                    <mat-select formControlName="newStatus">
                                        <mat-option disabled value="null">Selecciona estado</mat-option>
                                        <mat-option *ngFor="let opt of mainInfo.to" [value]="opt.name">{{opt.pretty}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>    
                        </div>
                    </div>

                </div>
            </form>

            <div class="buttons">
                <button class="btn" type="button" (click)="closeModal();">
                    cerrar
                </button>
                <button class="btn btn_success" type="button" (click)="changeStatus()">
                    Cambiar el estado
                </button>
            </div>
        </div>

        <div class="infoWrap" *ngIf="type === 'prod_pedido'">
            <div class="title">
                Promesa Entrega: 
                <br/>
                {{mainInfo.prodName}} 
            </div>

            <form novalidate [formGroup]="inputs" class="formStatusContainer">
                <div class="statusImportOrSpecial">
                    <div class="radio_status">
                        <label>
                            <input name="type" type="radio" value="import" formControlName="statusField"/>
                            Importación
                        </label>
                        <label>
                            <input name="type" type="radio" value="special" formControlName="statusField"/>
                            Pedido Especial
                        </label>
                        <label>
                            <input name="type" type="radio" value="report" formControlName="statusField"/>
                            Reportar Fecha Prometida
                        </label>
                        <p class="error_message" [class.active]="statusField?.touched && statusField?.invalid" >
                            <strong *ngIf="statusField?.touched && statusField?.hasError('required')">
                                Es requerido
                            </strong>
                        </p>
                    </div>
                    <div>
                        <div>
                            <mat-form-field appearance="outline" (click)="dp4.open()">
                                <mat-label>Fecha prometida</mat-label>
                                <input [min]="minDate" matInput [matDatepicker]="dp4" formControlName="dateField">
                                <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                                <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <p class="error_message date_error_message" [class.active]="dateField?.touched && dateField?.invalid" >
                                <strong *ngIf="dateField?.touched && dateField?.hasError('required')">
                                    La fecha es requerida
                                </strong>
                            </p>
                        </div>
                    </div>
                    <div class="number" *ngIf="statusField?.value != '' && statusField?.value != 'report'  ">
                        <div class="field_number">
                                <label for="">Cantidad:</label>
                                <input class="field_number_input" type="number" placeholder="0" formControlName="qtyField" min="1" [max]="prod.quantity - (prod.qty_packed - prod.qty_shipped)">
                            </div>
                        <p class="error_message qty_error_message" [class.active]="qtyField?.touched && qtyField?.invalid" >
                            <strong *ngIf="qtyField?.touched && qtyField?.hasError('required')">
                                Cantidad requerida
                            </strong>
                            <strong *ngIf="qtyField?.touched && qtyField?.hasError('max')">
                                <!-- Cantidad máxima: {{prod.quantity - (prod.qty_packed - prod.qty_shipped)}} -->
                                Revisar Cantidad
                            </strong>
                        </p>
                    </div>

                </div>
            </form>

            <div class="buttons">
                <button class="btn" type="button" (click)="closeModal();">
                    cerrar
                </button>
                <button class="btn btn_success" type="button" (click)="saveNewState()" [disabled]="changeDisabled">
                    Cambiar estado
                </button>
            </div>
        </div>
    </div>
</div>