import { Component, OnInit, Input } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { GeneralService } from "src/app/services/general.service";
import { OrdersService } from "src/app/services/orders.service";
import { InventoryService } from "src/app/services/inventory.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Ng2IzitoastService } from "ng2-izitoast";
import { ChangeDetectorRef } from "@angular/core";

import Swal from "sweetalert2";
@Component({
  selector: "app-inventory-entry",
  templateUrl: "./inventory-entry.component.html",
  styleUrls: [],
})
export class InventoryEntryComponent implements OnInit {
  @Input() currCountry: any;

  ocListReason: any = [];
  ocListBodegas: any = [];
  ocListProviders: any = [];
  ocListOpenOrders: any = [];
  ocListOpenOrdersRelatedToReference: any = [];
  loaderView: boolean = this.getShowLoaderView();
  receptionId: number = 0;
  reference: string = "";
  invoiceNumber: string = "";
  statusOrderFilter: string = "created,news,partial";
  orderId: string = "";
  loaderOpenOrdersById: boolean = false;
  loaderReception: boolean = false;
  listProviders: any = [];
  allListBodegas: any = [];
  electronicInvoices: any = [];
  loaderElectronicInvoicesByProvider: boolean = false;
  electronicInvoiceInReception: any;

  invoiceNumberFromParams: any = "";
  orderIdFromParams: any = "";

  disabledTotal = false;
  disableInvoice = false;

  invoiceNumberSearched = "";
  isReceptionWithInvoice: boolean = false;
  isRecepetionToReturn: boolean = false
  initialReference: any = null
  isDefaultEntry:any = true;

  form = new FormGroup({
    entry: new FormControl("", Validators.required),
    reference: new FormControl("", Validators.required),
    provider: new FormControl(""),
    bodega: new FormControl("", Validators.required),
    comment: new FormControl(""),
    invoice_value: new FormControl("", Validators.pattern(/^\d+(?:\.\d+)?$/)),
    orderId: new FormControl(""),
    invoice_number: new FormControl("", Validators.required),
    electronic_invoice: new FormControl(""),
  });

  showScan: boolean = false;

  formData = new FormData();
  uploadedFileNames: string[] = [];

  constructor(
    public _gen: GeneralService,
    public _ord: OrdersService,
    public _inv: InventoryService,
    private route: ActivatedRoute,
    private router: Router,
    public iziToast: Ng2IzitoastService,
    private cdRef: ChangeDetectorRef
  ) {
    this.currCountry = this._gen.currCountry;
  }

  ngOnInit(): void {
    this.getReasons("entry,cross");
    this.getAllEntryBodegas();
    this.getAllProviders();

    this.route.params.subscribe((params) => {
      this.invoiceNumberFromParams = params["invoice_number"];
      this.orderIdFromParams = params["order_id"];
    });

    if (this.invoiceNumberFromParams?.length > 0 && !this.orderIdFromParams) {
      this.loaderView = true;
      this.disabledTotal = false;
      this._inv
        .getReceptionByInvoiceNumberAndOrderId(
          this.invoiceNumberFromParams,
          this._gen.currCountry,
          this.orderIdFromParams
        )
        .then((data) => {
          if (data.data.length) {
            const {
              reference,
              comment,
              provider,
              provider_stock,
              reason,
              reception_id,
              invoice_value,
              invoice_number,
              order,
              products,
              invoice_electronic,
            } = data.data[0];
            if (reason) {
              this.setConditionalValidatorForProvider(reason.reason_id);
              this.form.controls.entry.setValue(reason.reason_id);
              this.isDefaultEntry = false;
            }

            provider
              ? this.form.controls.provider.setValue(provider.provider_id)
              : this.form.controls.provider.setValue("");
            if (provider) {
              this.getDataOpenOrdsByProvider();
            }
            reference && this.form.controls.reference.setValue(reference);
            invoice_number &&
              this.form.controls.invoice_number.setValue(invoice_number);
            provider_stock &&
              this.form.controls.bodega.setValue(provider_stock.provider_id);
            comment && this.form.controls.comment.setValue(comment);
            invoice_value &&
              this.form.controls.invoice_value.setValue(invoice_value);

            this.loaderView = false;
          } else {
            this.disabledTotal = false;
            this.disableInvoice = false;
          }
        });
    }

    if (
      this.invoiceNumberFromParams?.length > 0 &&
      this.orderIdFromParams?.length > 0
    ) {
      this.loaderView = true;
      this.disabledTotal = false;
      this._inv
        .getReceptionByInvoiceNumberAndOrderId(
          this.invoiceNumberFromParams,
          this._gen.currCountry,
          this.orderIdFromParams
        )
        .then((data) => {
          if (data.data.length) {
            const {
              reference,
              comment,
              provider,
              provider_stock,
              reason,
              reception_id,
              invoice_value,
              invoice_number,
              order,
              products,
              invoice_electronic,
            } = data.data[0];

            if (reason) {
              this.setConditionalValidatorForProvider(reason.reason_id);
              this.form.controls.entry.setValue(reason.reason_id);
              this.isDefaultEntry = false;
            }

            if (invoice_electronic.length > 0) {
              this.electronicInvoiceInReception = invoice_electronic[0];
            }

            provider
              ? this.form.controls.provider.setValue(provider.provider_id)
              : this.form.controls.provider.setValue("");

            if (order) {
              this.ocListOpenOrders.push(order);
              this.ocListOpenOrdersRelatedToReference.push(order);
              this.form.controls.orderId.setValue(order.order_id);
            }

            if (provider && order) {
              this.getDataElectronicInvoices();
            }

            provider_stock &&
              this.form.controls.bodega.setValue(provider_stock.provider_id);
            comment && this.form.controls.comment.setValue(comment);
            invoice_value &&
              this.form.controls.invoice_value.setValue(invoice_value);
            invoice_number &&
              this.form.controls.invoice_number.setValue(invoice_number);
            reference && this.form.controls.reference.setValue(reference);
            this.receptionId = reception_id;

            if (this.hasEntries(products)) {
              this.disabledTotal = true;
            } else {
              this.disabledTotal = false;
              this.ocListOpenOrders = [];
              this.ocListOpenOrdersRelatedToReference = [];
              if (this.form.controls["provider"].value != 0) {
                this.getDataOpenOrdsByProvider();
              }
            }
            this.disableInvoice = true;

            this.loaderView = false;
          } else {
            this.disabledTotal = false;
            this.disableInvoice = false;
          }
        });
    }

    this._gen.currCountryObs.subscribe((country) => {
      this.loaderView = true;
      this.form.reset();
      this.resetForm();
      this.ocListReason = [];
      this.ocListBodegas = [];
      this.ocListProviders = [];
      this.listProviders = [];
      this.getReasons("entry,cross");
      this.getAllEntryBodegas();
      this.getAllProviders();
      this.loaderView = false;
    });

    this.form.controls["entry"].valueChanges.subscribe((value) => {
      console.log("se ejecuta this.form.controls[entry].valueChanges")
      if(this.isDefaultEntry) return;
      // '6' es el value del tipo de entrada Devoluciones
      this.setConditionalValidatorForProvider(value);
      this.setConditionalValidatorForInvoiceNumber(value);
      this.setConditionalValidatorForOrderId(value);
      // Si es cross-docking ('1')

      if (value == "1") {
        this.ocListProviders = this.listProviders.filter(
          (item: any) => !item.stock
        );
        this.statusOrderFilter = "created,news,partial";
        this.isRecepetionToReturn = false
        this.getDataOpenOrdsByProviderAndExternalReference();
      } else if (["6", 6, "21", 21].includes(value)) {
        if(this.initialReference && (!["6", 6, "21", 21].includes(this.initialReference.reason))) {
          this.isRecepetionToReturn = true
        } else {
          this.isRecepetionToReturn = false
        }
        this.statusOrderFilter = "created,news,partial,completed,canceled";
        this.ocListProviders = this.listProviders;
        this.getDataOpenOrdsByProviderAndExternalReference();
      } else {
        this.ocListProviders = this.listProviders;
        this.isRecepetionToReturn = false
      }
    });

    this._ord.cleanInvetoryFormObs.subscribe(() => {
      this.resetForm();
      this.form.markAsUntouched();
    });

    this.form.controls["provider"].valueChanges.subscribe((value) => {
      this.getDataElectronicInvoices();
    });
  }

  getDataByInvoiceNumber() {
    if (
      this.form.controls["invoice_number"].value.length > 0 &&
      this.form.controls["orderId"].value.length == 0
    ) {
      this.loaderReception = true;
      this.invoiceNumberSearched = this.form.controls["invoice_number"].value;
      this._inv
        .getReceptionByInvoiceNumberAndOrderId(
          this.form.controls["invoice_number"].value.toUpperCase(),
          this._gen.currCountry,
          this.form.controls["orderId"].value
        )
        .then((data) => {
          if (data.data.length) {
            // provider_stock es Bodega
            const {
              reference,
              comment,
              provider,
              provider_stock,
              reason,
              reception_id,
              invoice_value,
              invoice_number,
              order,
              products,
            } = data.data[0];

            if (reason) {
              this.setConditionalValidatorForProvider(reason.reason_id);
              this.form.controls.entry.setValue(reason.reason_id);
              this.isDefaultEntry = false;
            }

            reference && this.form.controls.reference.setValue(reference);
            provider
              ? this.form.controls.provider.setValue(provider.provider_id)
              : this.form.controls.provider.setValue("");
            if (this.form.controls["provider"].value != 0 && reference) {
              this.getDataOpenOrdsByProviderAndExternalReference();
            }
            provider_stock &&
              this.form.controls.bodega.setValue(provider_stock.provider_id);
            comment && this.form.controls.comment.setValue(comment);
            invoice_value &&
              this.form.controls.invoice_value.setValue(invoice_value);

            this.receptionId = reception_id;
            this.loaderReception = false;
          } else {
            this.form.controls["invoice_number"].setValue(this.invoiceNumberSearched)
            this.loaderReception = false;
          }
        });
    }

    if (
      this.form.controls["invoice_number"].value.length == 0 ||
      this.form.controls["orderId"].value.length == 0
    ) {
      this.form.markAsUntouched();
      return;
    }
    this.loaderReception = true;
    this._inv
      .getReceptionByInvoiceNumberAndOrderId(
        this.form.controls["invoice_number"].value.toUpperCase(),
        this._gen.currCountry,
        this.form.controls["orderId"].value
      )
      .then((data) => {
        if (data.data.length) {
          // provider_stock es Bodega
          const {
            reference,
            comment,
            provider,
            provider_stock,
            reason,
            reception_id,
            invoice_value,
            invoice_number,
            order,
            products,
          } = data.data[0];

          if (reason) {
            this.setConditionalValidatorForProvider(reason.reason_id);
            this.form.controls.entry.setValue(reason.reason_id);
            this.isDefaultEntry = false;
          }

          provider
            ? this.form.controls.provider.setValue(provider.provider_id)
            : this.form.controls.provider.setValue("");

          if (order) {
            this.ocListOpenOrders.push(order);
            this.ocListOpenOrdersRelatedToReference.push(order);
            this.form.controls.orderId.setValue(order.order_id);
          }

          provider_stock &&
            this.form.controls.bodega.setValue(provider_stock.provider_id);
          comment && this.form.controls.comment.setValue(comment);
          invoice_value &&
            this.form.controls.invoice_value.setValue(invoice_value);
          invoice_number &&
            this.form.controls.invoice_number.setValue(invoice_number);
          reference && this.form.controls.reference.setValue(reference);
          this.receptionId = reception_id;
          if (products.length > 0) {
            this.disabledTotal = true;
          } else {
            this.disabledTotal = false;
          }

          this.loaderReception = false;
        } else {
          this.receptionId = 0;
          this.disabledTotal = false;
          this.loaderReception = false;
        }
      });
  }

  getDataByReference() {
    if (
      this.form.controls["reference"].value.trim() == null ||
      this.form.controls["reference"].value.trim().length == 0
    ) {
      this.ocListOpenOrders = [];
      this.ocListOpenOrdersRelatedToReference = [];
      if (this.form.controls["provider"].value != 0) {
        this.getDataOpenOrdsByProvider();
      }
      this.form.markAsUntouched();
      return;
    }

    // if( this.form.controls['invoice_number'].value.length !== 0) return;
    this.loaderReception = true;

    this.ocListOpenOrders = [];
    this.ocListOpenOrdersRelatedToReference = [];
    this._inv
      .getReceptionByReference(
        this.form.controls["reference"].value.trim().toUpperCase()
      )
      .then((data) => {
        // Cuando el reference no existe la respesuta es data.data: []
        if (data.data.length) {
          // provider_stock es Bodega
          const {
            comment,
            provider,
            provider_stock,
            reason,
            reception_id,
            invoice_value,
            order,
            invoice_number,
          } = data.data[0];
          if (reason) {
            this.setConditionalValidatorForProvider(reason.reason_id);
            this.form.controls.entry.setValue(reason.reason_id);
            this.isDefaultEntry = false;
            this.initialReference = {
              reference: this.form.controls["reference"].value.trim(),
              reason: reason.reason_id
            }
          }

          provider
            ? this.form.controls.provider.setValue(provider.provider_id)
            : this.form.controls.provider.setValue("");
          if (provider) {
            this.getDataOpenOrdsByProviderAndExternalReference();
          }
          comment && this.form.controls.comment.setValue(comment);
          invoice_value &&
            this.form.controls.invoice_value.setValue(invoice_value);
          // invoice_number && this.form.controls.invoice_number.setValue(invoice_number)
          if (this.ocListBodegas.length !== this.allListBodegas.length)
            this.ocListBodegas = this.allListBodegas;
          this.receptionId = reception_id;
          this.loaderReception = false;
        } else {
          this.ocListOpenOrders = [];
          this.form.markAsUntouched();

          // Aqui no existe el reception, busco las ordenes para ese pais con External Reference, statuses abiertos y country
          this._ord
            .getOrdsFilterByExternalReference(
              this.form.controls["reference"].value.trim().toUpperCase(),
              this._gen.currCountry,
              this.statusOrderFilter
            )
            .then((data) => {
              if (data.data) {
                this.ocListOpenOrdersRelatedToReference = data.data;
                this.ocListOpenOrders = data.data;
                if (this.ocListOpenOrders.length == 1) {
                  const { order_id, provider, reason } =
                    this.ocListOpenOrders[0];
                  order_id && this.form.controls.orderId.setValue(order_id);
                  provider &&
                    this.form.controls.provider.setValue(provider.provider_id);
                  if (reason.reason_id === 6) {
                    this.ocListBodegas = this.allListBodegas;
                  } else {
                    this.ocListBodegas = this.allListBodegas.filter(
                      (bodega: any) =>
                        bodega.business_unit === provider.business_unit
                    );
                    if (this.ocListBodegas.length === 0)
                      this.ocListBodegas = this.allListBodegas;
                  }
                }
              } else {
                this.receptionId = 0;
                this.form.controls.comment.setValue("");
                this.form.controls.provider.setValue("");
                this.form.controls.bodega.setValue("");
                this.form.markAsUntouched();
              }
              this.loaderOpenOrdersById = false;
              this.loaderReception = false;
            });
        }
      });
  }

  getDataOpenOrdsByProviderAndExternalReference() {
    this.loaderOpenOrdersById = true;
    this._ord
      .getOpenOrdsByProviderAndExternalReference(
        this.form.controls["provider"].value,
        this._gen.currCountry,
        this.form.controls["reference"].value,
        this.statusOrderFilter
      )
      .then((data) => {
        if (data.data.length > 0) {
          const openOrdersFromProvider = data.data;
          this.ocListOpenOrders = openOrdersFromProvider;
          if (this.ocListOpenOrders.length == 1) {
            const { order_id } = this.ocListOpenOrders[0];
            order_id && this.form.controls.orderId.setValue(order_id);
          }
          this.loaderOpenOrdersById = false;
        } else {
          this.getOpenOrdersByProvider();
        }
      })
      .catch((error) => {
        console.log({ error });
        this.loaderOpenOrdersById = false;
      });
  }

  getDataOpenOrdsByProvider() {
    if (
      this.form.controls["provider"].value == null ||
      this.form.controls["provider"].value.length == 0
    ) {
      this.ocListOpenOrders = [];
      return;
    }

    if (this.ocListOpenOrdersRelatedToReference.length) {
      let posibleOrder = this.ocListOpenOrdersRelatedToReference.find(
        (item: any) =>
          item.provider.provider_id == this.form.controls["provider"].value
      );
      if (posibleOrder) {
        this.ocListOpenOrders = this.ocListOpenOrdersRelatedToReference;
        this.form.controls.orderId.setValue(posibleOrder.order_id);
        this.loaderOpenOrdersById = false;
      } else {
        // Hay ordenes pero no con ese proveedor
        this.getOpenOrdersByProvider();
      }
    } else {
      this.getOpenOrdersByProvider();
    }
  }

  getOpenOrdersByProvider() {
    this.loaderOpenOrdersById = true;
    this._ord
      .getOpenOrdsByProvider(
        this.form.controls["provider"].value,
        this._gen.currCountry,
        this.statusOrderFilter
      )
      .then((data) => {
        if (data.data) {
          const openOrdersFromProvider = data.data;
          this.ocListOpenOrders = openOrdersFromProvider;
          this.loaderOpenOrdersById = false;
        }
      })
      .catch((error) => {
        console.log({ error });
        this.loaderOpenOrdersById = false;
      });
  }

  getOrdsProvider(event: any) {
    const posibleValue = event?.target?.value;
    if (posibleValue) {
      const element = this.ocListOpenOrders.find(
        (item: any) => item.order_id === +posibleValue
      );
      if (element) {
        element.provider &&
          this.form.controls.provider.setValue(element.provider.provider_id);
      }
    }
  }

  setValidatorRequired(campo: any, requerido: any) {
    this.form.controls[campo].setValidators(requerido);
    this.form.controls[campo].updateValueAndValidity();
  }

  getShowLoaderView() {
    return (
      this.ocListReason.length == 0 &&
      this.ocListBodegas.length == 0 &&
      this.ocListProviders.length == 0
    );
  }

  getReasons(truetype: any) {
    this._ord.getAllstockReasons(truetype).then(({ data }) => {
      this.ocListReason = data;
    });
  }

  getAllEntryBodegas() {
    this._ord.getAllEntryBodega(this._gen.currCountry).then(({ data }) => {
      this.ocListBodegas = data;
      this.allListBodegas = data;
    });
  }

  getAllProviders(isCrossDocking: boolean = false) {
    return this._ord
      .getAllProviders(this._gen.currCountry, isCrossDocking)
      .then(({ data }) => {
        this.ocListProviders = data;
        this.listProviders = data;
      });
  }

  get entryField() {
    return this.form.get("entry");
  }

  get referenceField() {
    return this.form.get("reference");
  }

  get providerField() {
    return this.form.get("provider");
  }

  get orderField() {
    return this.form.get("orderId");
  }

  get bodegaField() {
    return this.form.get("bodega");
  }

  get invoiceValueField() {
    return this.form.get("invoice_value");
  }

  get invoiceNumberField() {
    return this.form.get("invoice_number");
  }

  electronicInvoiceChange() {
    
    if (this.form.controls.electronic_invoice.value.length > 0) {
      this.form.controls.electronic_invoice.value;
    }
  }

  async save(event: any) {
    if (this.form.valid) {
      this.reference = this.form.controls["reference"].value
        .trim()
        .toUpperCase();
      this.invoiceNumber =
        this.form.controls["invoice_number"].value.toUpperCase();
      this.orderId = this.form.controls["orderId"].value;

      this.loaderView = true;
      await this.getIsRecepetionWithInvoiceNumber();
      this.loaderView = false;

      if (this.receptionId && this.isReceptionWithInvoice) {
        const data: any = {
          reception_id: this.receptionId,
        };
        if (this.form.controls.comment.value.length > 0) {
          data.comment = this.form.controls.comment.value
            ? this.form.controls.comment.value
            : "";
        }
        if(this.form.controls.invoice_value.value.length > 0) {
          data.invoice_value = this.form.controls.invoice_value.value
            ? this.form.controls.invoice_value.value
            : 0;
        }
        if (this.form.controls.electronic_invoice.value.length > 0) {
          data.invoice_id = this.form.controls.electronic_invoice.value;
          data.invoice_date = new Date(Date.now());
        }

        if(this.receptionId && this.isRecepetionToReturn) {
          this.createReception()
        } else {
          const rta = await this._inv.updateReception(data);
        }
        this._gen.barCodeEntryViewPeds = true;
        this.form.reset();
        this.resetForm();
      } else {
        this.createReception()
        
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  createReception() {
    const data: any = {
      reason_id: this.form.controls.entry.value,
      provider_stock_id: this.form.controls.bodega.value,
      reference: this.form.controls.reference.value.toUpperCase(),
      invoice_number: this.form.controls.invoice_number.value.toUpperCase(),
    };
    if (
      this.form.controls.provider.value &&
      (typeof this.form.controls.provider.value == "string" ||
        typeof this.form.controls.provider.value == "number")
    )
      data.provider_id = this.form.controls.provider.value;
    if (this.form.controls.comment.value)
      data.comment = this.form.controls.comment.value;
    if (this.form.controls.invoice_value.value)
      data.invoice_value = this.form.controls.invoice_value.value;
    if (this.form.controls.orderId.value)
      data.order_id = this.form.controls.orderId.value;
    if (this.form.controls.electronic_invoice.value.length > 0) {
      data.invoice_id = this.form.controls.electronic_invoice.value;
      data.invoice_date = new Date(Date.now());
    }

    this._inv
      .createReception(data)
      .then((data) => {
        this.receptionId = data.data.ids;
        this._gen.barCodeEntryViewPeds = true;
        this.formData.append("reception", data.data.ids);
        if (this.uploadedFileNames.length > 0) {
          this._inv
            .uploadFiles(this.formData)
            .then(() => {
              this._gen.tableLoader = false;
              this.form.reset();
              this.resetForm();
            })
            .catch(() => {
              this._gen.tableLoader = false;
              return this.iziToast.show({
                title: "Lo sentimos, no se pudo adjuntar el archivo",
                position: "topRight",
                color: "red",
                transitionIn: "fadeInLeft",
                transitionOut: "fadeOutRight",
                toastOnce: true,
              });
            });
        }
        this.form.reset();
        this.resetForm();
      })
      .catch((error) => {
        this.swalEditMssg(error.response.data.error.reference[0], "error");
      });
  }

  swalEditMssg(ttl: string, kind: any = "success") {
    return Swal.fire({
      title: ttl,
      icon: kind,
    });
  }

  resetForm() {
    this.receptionId = 0;
    this.form.controls.comment.setValue("");
    this.form.controls.entry.setValue("");
    this.form.controls.provider.setValue("");
    this.form.controls.bodega.setValue("");
    this.form.controls.orderId.setValue("");
    this.form.controls.invoice_value.setValue("");
    this.form.controls.invoice_number.setValue("");
    this.form.controls.reference.setValue("");
    this.electronicInvoices = [];
    this.form.controls.electronic_invoice.setValue("");
    this.disabledTotal = false;
    this.ocListOpenOrders = [];
    this.ocListOpenOrdersRelatedToReference = [];
    this.disableInvoice = false;
    this.electronicInvoiceInReception = "";
    this.cdRef.detectChanges();
    // this.form.reset();
  }

  setConditionalValidatorForProvider(value: string | number) {
    if (!["6", 6, "21", 21].includes(value)) {
      this.setValidatorRequired("provider", Validators.required);
    } else {
      this.setValidatorRequired("provider", null);
    }
  }

  setConditionalValidatorForInvoiceNumber(value: string | number) {
    if (!["6", 6, "21", 21].includes(value)) {
      this.setValidatorRequired("invoice_number", Validators.required);
    } else {
      this.setValidatorRequired("invoice_number", null);
    }
  }

  setConditionalValidatorForOrderId(value: string | number) {
    if (!["6", 6, "21", 21].includes(value)) {
      this.setValidatorRequired("orderId", Validators.required);
    } else {
      this.setValidatorRequired("orderId", null);
    }
  }

  hasEntries(products: any) {
    return products.some((p: any) => p.quantity > 0);
  }

  onChangeFileInput(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element.files) {
      this._gen.tableLoader = true;
      const files = element.files;
      for (let i = 0; i < files.length; i++) {
        this.uploadedFileNames.push(files[i].name);
        this.formData.append("files", files[i]);
      }
    }
  }

  validateBodegasToShow(event: any) {
    const reasonId = event?.target?.value;

    if (reasonId === "6") {
      this.ocListBodegas = this.allListBodegas;
      return;
    }

    const selectedValue = this.orderField?.value;

    const selectedOption = this.ocListOpenOrders.find(
      (item: any) => item.order_id === selectedValue
    );
    if (selectedOption) {
      this.ocListBodegas = this.allListBodegas.filter(
        (bodega: any) =>
          bodega.business_unit === selectedOption.provider.business_unit
      );
    }
  }

  getDataElectronicInvoices() {
    if (
      this.form.controls["provider"].value == null ||
      this.form.controls["provider"].value.length == 0 ||
      this.form.controls["orderId"].value == null ||
      this.form.controls["orderId"].value.length == 0
    ) {
      this.electronicInvoices = [];
      return;
    }

    this._inv
      .getElectronicInvoicesByProviderId(
        this.form.controls["provider"].value,
        this.form.controls["orderId"].value
      )
      .then((data) => {
        if (data.data) {
          this.electronicInvoices = data.data.invoinces;
          if (this.electronicInvoiceInReception) {
            this.electronicInvoices.unshift(this.electronicInvoiceInReception);
            this.form.controls.electronic_invoice.setValue(
              this.electronicInvoiceInReception.id
            );
          }
          this.loaderElectronicInvoicesByProvider = false;
        } else {
          this.electronicInvoices = [];
        }
      })
      .catch((error) => {
        console.error({ error });
        this.electronicInvoices = [];
        this.loaderElectronicInvoicesByProvider = false;
      });
  }

  async getIsRecepetionWithInvoiceNumber() {
    try {
      const response = await this._inv.getReceptionWithInvoiceNumber(
        this.invoiceNumber,
        this._gen.currCountry,
        this.reference
      )
      if(response.data.length > 0) {
        this.isReceptionWithInvoice = true;
      } else {
        this.isReceptionWithInvoice = false;
      }

    } catch(error) {
      console.log({error})
      this.isReceptionWithInvoice = false;
    }
  }
}
