import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogService } from 'src/app/services/catalog.service';
import { GeneralService } from 'src/app/services/general.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PedidosService } from 'src/app/services/pedidos.service';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-pedido-item',
  templateUrl: './pedido-item.component.html',
  styles: [
  ]
})
export class PedidoItemComponent implements OnInit {

  detailId:any;
  pedData:any;

  loaderView:boolean = false;

  currStatus:any;
  currTempStatus:any;
  posibleStatus:any;
  statusComment:string = "";

  currCarriers:any[] = [];

  viewreadCode:boolean = false;
  viewreadCodeSaleId:string = "";

  changeStatus:boolean = false;
  changeStatusSave:boolean = false;
  currCommentItem:any = null;
  
  prevPed:any;
  nextPed:any;
  
  showMoreComments:boolean = false;

  editProduct:number = 0;
  changeStatidView:number = 0;
  changeStatView:boolean = false;
  changeStatDisabled:boolean = false;
  
  changeStatQtyView:boolean = false;
  
  isAcconteView:boolean = false;
  showPartialShipButton:boolean = false;
  showShipButton:boolean = false;

  disabledButton:boolean = false;

  constructor( public _ord:OrdersService, public _gen:GeneralService, public _cat:CatalogService, public _ped:PedidosService, public aRoute:ActivatedRoute, public route:Router ) {

    this.aRoute.params.subscribe( ({id}) => {
      this.detailId = id;      
    })
    
  }

  ngOnInit(): void {    
    this._gen.getPedStatuses().then( () => {
        this.getData();
    });

    this.getPedsIds();

    this._gen.barCodeEntryViewObs.subscribe(( view ) => {
      this.loaderView = true;
      
      this._gen.getPedStatuses().then( () => {
        this.getData();

        this.loaderView = false;
      });
      this.getPedsIds();
    })
    
    this._gen._prodStatusViewChange.subscribe(( view ) => {
      this.loaderView = true;
      
      this._gen.getPedStatuses().then( () => {
        this.getData();

        this.loaderView = false;
      });
      this.getPedsIds();
    })

    this._gen.getProdsStatuses();
    
    this._gen.getCarriers().then( ({data}) => {
      this.currCarriers = data;      
    })


    this._gen.currCountryObs.subscribe((country) => {
      this.getData();

      // this._gen.getProdsStatuses();

      // this._gen.getCarriers().then( ({data}) => {
      //   this.currCarriers = data;      
      // })
      var url = `/#/home/pedidos`;
      window.open(url, '_self');
    })
  }

  getPedsIds() {
    // get curr index
    const index = this._ped.pedListsIds.map(e => e.sale_id).indexOf( parseInt(this.detailId) );
    
    if ( index < 0 ) {
      this.prevPed = {};
      this.nextPed = {}

      return;
    }
    if ( index == 0 ) {
      this.prevPed = {};
      this.nextPed = this._ped.pedListsIds[ index + 1 ]

      return;
    }
    if ( index == this._ped.pedListsIds.length - 1 ) {
      this.prevPed = this._ped.pedListsIds[ index - 1 ];
      this.nextPed = {}
      
      return;
    }
    if ( index != 0 && index != this._ped.pedListsIds.length - 1 ) {
      this.nextPed = this._ped.pedListsIds[ index + 1 ]
      this.prevPed = this._ped.pedListsIds[ index - 1 ];
      
      return;
    }
  }
  
  async getData() {    
    await this.getAllData().then( (data:any) => {
      this.isAcconte();
    });

  }

  getProdData( products:any ) {
    let prods:any[] = []; 
    if ( products.length >= 1 ) {
      for (let p = 0; p < products.length; p++) {
        const element = products[p];
        let partSku = element.sku_full.sku_full.split('_');

        prods.push( {
          comment: element.comment,
          created_at: element.created_at,
          formula: element.formula,
          formula_json: element.formula_json,
          item_id: element.item_id,
          order_product: element.order_product,
          quantity: element.quantity,
          reference: this.getReference( element.reference ),
          newQuantity: 1,
          newStatus: "created",
          prod: element.sku_full,
          partSku: partSku,
          qty_completed: element.qty_completed,
          qty_import: element.qty_import,
          qty_packed: element.qty_packed,
          qty_shipped: element.qty_shipped,
          qty_special: element.qty_special,
          qty_canceled: element.qty_canceled,
          promise_dates: element.promise_dates
        } );
      }

      return prods;
    } else {
      return [];
    }
  }
  getReference( ref:any ){
    return ref.replace(/\s/g, '').split(',');
  }
  getAllData() {

    let prom = new Promise( (resolve,reject) => {
      
      this._ped.getAllProdsById( this.detailId ).then( ({ data }) => {
        
        this.pedData = {
          sale_id: data.sale_id,
          coupon: data.coupon,
          products_sum: data.products_sum,
          order_number: data.order_number,
          created_at: data.created_at,
          status: data.status,
          user: {
            customer_name: data.customer_name,
            customer_mail: data.customer_mail,
            customer_phone: data.customer_phone,
          },
          shipment: {
            country: data.country.name,
            address: data.address,
            city: data.city,
            location_other: {
              colony: data.location_other.colony,
              delegation: data.location_other.delegation,
              all: data.location_other,
            },
            region: data.region,
            postcode: data.postcode,
          },
          delivery_promise: {
            from_day: data.delivery_promise_from ? new Date( data.delivery_promise_from.replace(/-/g, " ") ).getDate(): null,
            from_month: data.delivery_promise_from ? new Date( data.delivery_promise_from.replace(/-/g, " ") ).getMonth():null,
            to_day: data.delivery_promise_to ? new Date( data.delivery_promise_to.replace(/-/g, " ") ).getDate() : null,
            to_month: data.delivery_promise_to  ? new Date( data.delivery_promise_to.replace(/-/g, " ") ).getMonth(): null,
          },
          carrier: {
            carrier: data.carrier,
            tracking_number: data.tracking_number,
          },
          products: this.getProdData( data.products ),
          logs: data.logs.reverse(),
          partial_shipped: data.scan_shipment.partial_shipped,
          scan: data.scan_shipment.scan,
          total_shipped: data.scan_shipment.total_shipped
        } 

        this.getStatusInfo( data.status );   
        this.showPartialShipButton = data.scan_shipment.partial_shipped === "true";
        this.showShipButton = !this.showPartialShipButton && data.scan_shipment.total_shipped === "true";

        resolve( true )

      })
    })
    return prom;

  }

  validatePartialShip(data:any){
    if(!this.pedData.carrier.carrier) return false;

    // al menos un item en recibido 
    let atLeastOnePackedAll = false;
    for (let d = 0; d < data.products.length; d++) {
      const element = data.products[d]
      if(element.qty_packed > 0 && element.qty_packed !== element.qty_shipped)  {
        atLeastOnePackedAll = true
      }
    }
    return atLeastOnePackedAll
  }

  getStatusInfo( status:any ) {    
    this.currStatus = status;
    this.currTempStatus = status;
        
    switch ( status.name ) {
      case "confirmed":
        this.posibleStatus = this._gen.allStatusChange["confirmed"];
        break;
      case "contactlenses_confirmed":
        this.posibleStatus = this._gen.allStatusChange["contactlenses_confirmed"];
        break;
      case "order_preparation":
        this.posibleStatus = this._gen.allStatusChange["order_preparation"];
        break;
      case "supplying":
        this.posibleStatus = this._gen.allStatusChange["supplying"];
        break;
      case "processing_special":
        this.posibleStatus = this._gen.allStatusChange["processing_special"];
        break;
      case "order_import":
        this.posibleStatus = this._gen.allStatusChange["order_import"];
        break;
      case "packing":
        this.posibleStatus = this._gen.allStatusChange["packing"];
        break;
      case "order_shipped":
        this.posibleStatus = this._gen.allStatusChange["order_shipped"];
        break;
      case "logistics_operator_notification":
        this.posibleStatus = this._gen.allStatusChange["logistics_operator_notification"];
        break;
      case "holded_news":
        this.posibleStatus = this._gen.allStatusChange["holded_news"];
        break;
      case "wrong_address":
        this.posibleStatus = this._gen.allStatusChange["wrong_address"];
        break;
      case "envio_parcial":
        this.posibleStatus = this._gen.allStatusChange["envio_parcial"];
        break;
        
      default:
        this.posibleStatus = this._gen.allStatusChange["no_status"];
        break;
    }

    // console.log( this.currStatus, this.posibleStatus );
    
  }

  changeStatusFx() {
    this._gen.getPedStatuses();
    console.log( this._gen.allStatusChange );
    if(this.currStatus === "holded_news" && this._gen.typifications.length === 0) {
      this._gen.getTypifications()
    }
    if ( this.currStatus != this.currTempStatus ) {
      this.changeStatusSave = true;
    } else {
      this.changeStatusSave = false;

      this._gen.getPedStatuses().then( () => {
        this.getData();

        this.loaderView = false;
      });
      this.getPedsIds();

    }
  }
  changeStatusSaveFx() {

    this.changeStatDisabled = !this.changeStatDisabled;

    let update = [
      {
        sale_id: this.pedData.sale_id,
        status: this.currStatus,
        comment: this.statusComment
      }
    ];

    console.log( update );
    
    
    this._ped.changeStatus( update ).then( ({data}) => {
      
      if (data[0] === "ok") {
        this.changeStatus = !this.changeStatus;
        
        Swal.fire(
          'Cambio de estado exitoso',
          `Se cambio el estado de la orden: ${ this.pedData?.order_number } </br></br>
          ${ this.currTempStatus.name } > ${ this.currStatus }`,
          'success'
        ).then( (data) => {        
          this.pedData = null;
          this._gen.getPedStatuses().then( () => {
            this.getData();
            this.changeStatDisabled = !this.changeStatDisabled;
          });
          this.getPedsIds();
        })
      } else {
        this.showErrorChangeStatusMessage()
      }

    }).catch( (error) => {
      console.error({error});
      this.showErrorChangeStatusMessage()
    })
  }

  showErrorChangeStatusMessage() {
    Swal.fire(
      'Error en cambio de estado',
      `no es posible cambiar el estado de la orden: ${ this.pedData?.order_number } `,
      'error'
    ).then( (data) => {        
      this.pedData = null;
      this._gen.getPedStatuses().then( () => {
        this.getData();
        this.changeStatDisabled = !this.changeStatDisabled;
      });
      this.getPedsIds();
    })
  }

  changeProdCommentFx( item:any ) {
    this.currCommentItem = null
    
    this.loaderView = true;
    
    let prodData = {
      item_id: item.item_id,
      comment: item.comment
    };      
    
    // console.log( prodData );

    this._ord.changeOrderProdComment( prodData )
    .then( ({data}) => {
      
      Swal.fire(
        'Cambio de comentario exitoso',
        ``,
        'success'
      ).then( () => {
        this.loaderView = false;

        this.getData();
      })

    }).catch( (error:any) => {
      let data = error.response.data;

      Swal.fire(
        'Error al crear comentario en item',
        `${data.error}`,
        'error'
      ).then( () => {
        this.loaderView = false;

        this.getData();
      })
      
    })
    
  }

  /* back to pedidos */
  backToPeds() {
    this.route.navigate(['home/pedidos/'])
  }

  goToOrder( orderRef:string ) {
    this._ord.geOrdertByReference( orderRef, this._gen.currCountry ).then( ({data}) => {
      // console.log( data[0].order_id );
      var url = `/#/home/ordenes/${data[0].order_id}`;
      window.open(url, '_blank');
    })
  }

  goToNextPed( id:any ){
    if( id.sale_id ) {
      var url = `/#/home/pedidos/${id.sale_id}`;
      window.open(url, '_self');

      setTimeout(() => {
        this.pedData = null;

        this._gen.getPedStatuses().then( () => {
          this.getData();
        });
        this.getPedsIds();
      }, 150);
      
    }
  }

  goToPrevPed( id:any ){
    if( id.sale_id ) {
      var url = `/#/home/pedidos/${id.sale_id}`;
      window.open(url, '_self');

      setTimeout(() => {
        this.pedData = null;

        this._gen.getPedStatuses().then( () => {
          this.getData();
        });
        this.getPedsIds();
      }, 150);

    }
  }

  /* prod status */
  editProdFx( itm:any ) {
    this._gen.prodStatusView = !this._gen.prodStatusView;
    this.editProduct = itm;
  }


   /* prod status */
  isAcconte( ) {
    if (["closed", "order_canceled", "canceled"].includes(this.pedData?.status.name)){
      this.isAcconteView = false;
      return;
    }
    this.isAcconteView = this.pedData?.scan === "true";
  }

  changeCarrier() {

    let data = [{
      sale_id: this.pedData.sale_id,
      carrier: this.pedData.carrier.carrier
    }]

    Swal.fire({
      title: 'Desea cambiar de O. logístico',
      html: `Se cambiara el O. logístico: <b>${ this.pedData?.order_number }</b> a <br/> <b>${ this.pedData?.carrier.carrier }</b>`,
      icon: 'info',
      confirmButtonText: 'Cambiar',
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {      
      if (result.isConfirmed) {
        
        this._ped.changeInfoPed( data ).then( ({data}) => {      
          this.changeStatus = false;
          
          Swal.fire(
            'Cambio de O. logístico exitoso',
            `Se cambio el estado del pedido: ${ this.pedData?.order_number } a ${ this.pedData?.carrier.carrier }`,
            'success'
          ).then( (data) => {        
            this.pedData = null;
            this._gen.getPedStatuses().then( () => {
              this.getData();
            });
            this.getPedsIds();
          })
    
        }).catch( (error) => {
          // console.log( error );
          
          Swal.fire(
            'Error en cambio de O. logístico',
            `no es posible cambiar el O. logístico del pedido`,
            'error'
          ).then( (data) => {        
            this.pedData = null;
            this._gen.getPedStatuses().then( () => {
              this.getData();
            });
            this.getPedsIds();
          })
          
        })

      } else {
        this.pedData = null;
        this._gen.getPedStatuses().then( () => {
          this.getData();
        });
        this.getPedsIds();
      }

    })



  }
  genShipFx() {
    this.disabledButton = true;
    Swal.fire({
      title: '¿Confirma generar envío?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let data = [
          {
            sale_id: this.pedData.sale_id,
            carrier: this.pedData.carrier.carrier,
          }
        ]
        this.showLoader()
        this._ped.genShipment( data ).then( (data) => {
          this.closeLoader()
          if(data.data[0].status == 'ok'){
            Swal.fire(
              `Envio de pedido ${ this.pedData?.order_number } exitoso`,
              '',
              // `Se cambio el estado del pedido: ${ this.pedData?.order_number } a ${ this.pedData?.carrier.carrier }`,
              'success'
            ).then( () => {    
              this.pedData = null;
              this.printGuide(data)
              this._gen.getPedStatuses().then( () => {
                this.getData();
              });
              this.getPedsIds();
            })
          } else {
            Swal.fire(
              `Hubo un error al crear el envío`,
              '',
              'error'
            ).then( (data) => {    
              this.pedData = null;
              this._gen.getPedStatuses().then( () => {
                this.getData();
              });
              this.getPedsIds();
            })
          }
        })
        .catch(err => {
          this.closeLoader()
          Swal.fire(
            `Hubo un error al crear el envío`,
            '',
            'error'
          )
        })
        // Swal.fire('Saved!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
        this.disabledButton = false;
      }
    })
  }

  printGuide(data: any){
    const url = data.data[0].guide
    this._ped.getPdfToPrint(url).subscribe((response) => { 
      // download file
      var blob = new Blob([response], {type: 'application/pdf'});
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe?.contentWindow?.print();
    })
  }

  showLoader() {
    this._gen.generalLoader = true;
  }

  closeLoader() {
    this._gen.generalLoader = false;
    this.disabledButton = false;
  }

  showEditStateBtn(item:any){
    return item.quantity !== item.qty_packed
  }

  showDatePromise(item:any) {
    if (["order_special", "order_import"].includes(item.status.name))
      return `${item.status.pretty} (${item.quantity}) `
    return `${item.status.pretty}: `
  }

  showColumn(pedData:any){
    return pedData?.products.some((item:any) => item.promise_dates.length > 0)
  }
  
}

