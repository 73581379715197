
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { InnerComponent } from './pages/inner/inner.component';

import { DashboardComponent } from './pages/inner/dashboard/dashboard.component';
import { InventoryComponent } from './pages/inner/inventory/inventory.component';
import { InputsComponent } from './pages/inner/inventory/inputs/inputs.component';
import { OutputsComponent } from './pages/inner/inventory/outputs/outputs.component';
import { FileUploadComponent } from './pages/inner/file-upload/file-upload.component';
import { CatalogComponent } from './pages/inner/catalog/catalog.component';
import { PedidosComponent } from './pages/inner/pedidos/pedidos.component';
import { OrdersComponent } from './pages/inner/orders/orders.component';
import { PedidoItemComponent } from './pages/inner/pedidos/pedido-item/pedido-item.component';
import { PedidoListComponent } from './pages/inner/pedidos/pedido-list/pedido-list.component';
import { OrderItemComponent } from './pages/inner/orders/order-item/order-item.component';
import { OrderListComponent } from './pages/inner/orders/order-list/order-list.component';
import { PendingsComponent } from './pages/inner/orders/pendings/pendings.component';
import { CatalogListComponent } from './pages/inner/catalog/catalog-list/catalog-list.component';
import { CatalogStockComponent } from './pages/inner/catalog/catalog-stock/catalog-stock.component';
import { InventoryEntryComponent } from './pages/inner/orders/inventory-entry/inventory-entry.component';
import { ConfigurationComponent } from './pages/inner/inventory/configuration/configuration.component';
import { InvoicesComponent } from './pages/inner/orders/invoices/invoices.component';
import { CountsComponent } from './pages/inner/inventory/counts/counts.component';
import { ScannedProductsComponent } from './pages/inner/inventory/scanned-products/scanned-products.component';

// GUARDS
import { LoginGuardGuard } from './guards/login-guard.guard';
import { ElectronicInvoicesComponent } from './pages/inner/billing/electronic-invoices/electronic-invoices.component';
import { ProvidersComponent } from './pages/inner/billing/providers/providers.component';
import { BillingComponent } from './pages/inner/billing/billing.component';

const appRoutes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'home', component: InnerComponent, children: [
    { path: 'dashboard', canActivate: [LoginGuardGuard], component: DashboardComponent },
    { path: 'inventory', component: InventoryComponent, children: [
      { path: 'inputs', canActivate: [LoginGuardGuard], component: InputsComponent },
      { path: 'outputs', canActivate: [LoginGuardGuard], component: OutputsComponent },
      { path: 'counts', component: InventoryComponent, children: [
        { path: ':id', canActivate: [LoginGuardGuard], component: ScannedProductsComponent },
        { path: '', canActivate: [LoginGuardGuard], component: CountsComponent },
      ] },
      { path: 'configuration', canActivate: [LoginGuardGuard], component: ConfigurationComponent },
      { path: '', canActivate: [LoginGuardGuard], component: InputsComponent },
    ] },
    { path: 'catalog', component: CatalogComponent, children: [
      { path: 'list', canActivate: [LoginGuardGuard], component: CatalogListComponent },
      { path: 'stock', canActivate: [LoginGuardGuard], component: CatalogStockComponent },
      { path: '', canActivate: [LoginGuardGuard], component: PedidoListComponent },
    ] },
    { path: 'pedidos', component: PedidosComponent, children: [
      { path: ':id', canActivate: [LoginGuardGuard], component: PedidoItemComponent },
      { path: '', canActivate: [LoginGuardGuard], component: PedidoListComponent },
    ] },
    { path: 'ordenes', component: OrdersComponent, children: [
      { path: 'pending_prods', canActivate: [LoginGuardGuard], component: PendingsComponent },
      { path: 'inventory_entry', canActivate: [LoginGuardGuard], component: InventoryEntryComponent },
      { path: 'inventory_entry/:invoice_number', canActivate: [LoginGuardGuard], component: InventoryEntryComponent },
      { path: 'inventory_entry/:invoice_number/:order_id', canActivate: [LoginGuardGuard], component: InventoryEntryComponent },
      { path: 'invoices', canActivate: [LoginGuardGuard], component: InvoicesComponent },
      { path: ':id', canActivate: [LoginGuardGuard], component: OrderItemComponent },
      { path: '', canActivate: [LoginGuardGuard], component: OrderListComponent },
    ]  },
    { path: 'upload', canActivate: [LoginGuardGuard], component: FileUploadComponent },
    { path: 'billing', component: BillingComponent, children: [
      { path: 'electronic_invoices', canActivate: [LoginGuardGuard], component: ElectronicInvoicesComponent },
      { path: 'providers', canActivate: [LoginGuardGuard], component: ProvidersComponent },
    ]},
    { path: '', canActivate: [LoginGuardGuard], component: DashboardComponent },
  ] },
  { path: '', component: LoginComponent },
  { path: '**', component: LoginComponent }
];

export const APP_ROUTES = RouterModule.forRoot( appRoutes, {
  useHash: true,
  scrollPositionRestoration: 'enabled'
} );
