
<div class="loaderTable" *ngIf="type != 'syncs'  && _gen.tableLoader">
    <div class="icon">
        <img src="assets/img/preload.gif" alt="">
    </div>
</div>

<div class="loaderTableSyncs" *ngIf="type == 'syncs' && _gen.tableLoader">
    <div class="icon">
        <img src="assets/img/preload.gif" alt="">
    </div>
</div>

<div class="tableWrap customScrollBar" [ngClass]="{'tableWrapSyncs': type == 'syncs'}">
    <div class="tableBox" *ngIf="data.length >= 1">
        <table *ngIf="!_gen.tableLoader">
            <thead>
                <tr>
                    <td *ngIf="type != 'syncs' && type != 'configuration' && type != 'invoices' && type != 'counts' && type != 'scanned_products' && type != 'electronic_invoices' && type != 'provider_cuts'">
                        <div class="custom_checkbox">
                            <input type="checkbox" name="" id="check_all" (change)="itmCheckedAll($event)">
                            <label for="check_all"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i">
                        <span *ngIf="cols[i].visible && cols[i].type !== 'close_counting'" [appSort]="data" data-order="desc" [attr.data-name]="col.varId" [attr.data-type]="col.type">
                            {{col.label}}
                            <i [class]="'48px_arrow-s' | icons"></i>
                            <i [class]="'48px_arrow-n' | icons"></i>
                        </span>
                        <span *ngIf="cols[i].visible && type == 'counts' && cols[i].type == 'close_counting'" [appSort]="data" data-order="desc" [attr.data-name]="col.varId" [attr.data-type]="col.type">
                            {{col.label}}
                            <i [class]="'48px_arrow-s' | icons"></i>
                            <i [class]="'48px_arrow-n' | icons"></i>
                        </span>
                    </td>
                </tr>
            </thead>

            <!-- Inventory Inputs ROW -->
            <tbody *ngIf="type == 'inputs'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _inv.editId == itm[uniqueIDVar], 'inactive': _inv.editId != itm[uniqueIDVar] && _inv.editId  }">
                    <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_inputs" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _inv.editId">
                            <i *ngIf="cols[i].type == 'uniqueId'" class="idClick">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type != 'uniqueId' && cols[i].type != 'status' && cols[i].type != 'provider' && cols[i].type != 'user' && cols[i].type != 'qty' && cols[i].type != 'date'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'qty'">{{ itm[col.varId].length }}</i>
                            <i *ngIf="cols[i].type == 'provider'">{{ itm[col.varId]?.name }}</i>
                            <i *ngIf="cols[i].type == 'user'">{{ itm[col.varId].first_name }} {{ itm[col.varId].last_name }}</i>
                            <i *ngIf="cols[i].type == 'status'">
                                <span class="badge {{ itm[col.varId] }}">{{itm[col.varId]}}</span>
                            </i>
                            <i *ngIf="cols[i].type == 'date'">{{itm[col.varId] | date:'d-M-yyyy h:mm'}}</i>
                        </span>
                        
                        <div *ngIf="cols[i].visible && itm[uniqueIDVar] == _inv.editId">
                            <!-- input Type -->
                            <div class="form_field" *ngIf="cols[i].type != 'dd_manufact' && cols[i].type != 'dd_brand'">
                                <input *ngIf="cols[i].type != 'countryType' && cols[i].type != 'bUnitType' && cols[i].type != 'qty'" type="text" name="" value="{{ itm[col.varId] }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'countryType'" type="text" name="" value="{{ itm[col.varId].code }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'bUnitType'" type="text" name="" value="{{ itm[col.varId].name }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'qty'" type="text" name="" value="{{ itm[col.varId].length }}" [disabled]="cols[i].disabled">
                            </div>
                        </div>
    
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn" (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- Inventory outputs ROW -->
            <tbody *ngIf="type == 'outputs'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _inv.editId == itm[uniqueIDVar], 'inactive': _inv.editId != itm[uniqueIDVar] && _inv.editId  }">
                    <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_outputs" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _inv.editId">
                            <i *ngIf="cols[i].type == 'uniqueId'" class="idClick">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type != 'uniqueId' && cols[i].type != 'country'
                            && cols[i].type != 'lote' && cols[i].type != 'sku' && cols[i].type != 'ean' && cols[i].type != 'sku_full' && cols[i].type != 'name' && cols[i].type != 'manufacturer' && cols[i].type != 'date' && cols[i].type != 'provider'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'country'">{{ itm[col.varId].name }}</i>
                            <i *ngIf="cols[i].type == 'lote'">{{ itm[col.varId].number }}</i>
                            <i *ngIf="cols[i].type == 'sku'">{{ itm['product'].sku }}</i>
                            <i *ngIf="cols[i].type == 'ean'">
                                <div *ngIf="itm.product && itm.product.ean && itm.product.ean.ean">
                                    <p *ngFor="let ean of itm.product.ean.ean">
                                        {{ean}}
                                    </p>
                                </div>
                            </i>
                            <i *ngIf="cols[i].type == 'sku_full'">{{ itm['product'].sku_full }}</i>
                            <i *ngIf="cols[i].type == 'name'">{{ itm['product'].product.name }}</i>
                            <i *ngIf="cols[i].type == 'manufacturer'">{{ itm['product'].product.brand?.manufacturer.name }}</i>
                            <i *ngIf="cols[i].type == 'provider'">{{ itm[col.varId]?.name }}</i>
                            <i *ngIf="cols[i].type == 'date'">{{itm[col.varId] | date:'d-M-yyyy h:mm'}}</i>
                        </span>
                        
                        <div *ngIf="cols[i].visible && itm[uniqueIDVar] == _inv.editId">
                            <!-- input Type -->
                            <div class="form_field" *ngIf="cols[i].type != 'dd_manufact' && cols[i].type != 'dd_brand'">
                                <input *ngIf="cols[i].type != 'countryType' && cols[i].type != 'bUnitType' && cols[i].type != 'qty'" type="text" name="" value="{{ itm[col.varId] }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'countryType'" type="text" name="" value="{{ itm[col.varId].code }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'bUnitType'" type="text" name="" value="{{ itm[col.varId].name }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'qty'" type="text" name="" value="{{ itm[col.varId].length }}" [disabled]="cols[i].disabled">
                            </div>
                        </div>
    
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn" (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- Inventory counts ROW -->
            <tbody *ngIf="type == 'counts'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _inv.editId == itm[uniqueIDVar], 'inactive': _inv.editId != itm[uniqueIDVar] && _inv.editId  }">
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _inv.editId">
                            <i *ngIf="cols[i].type == 'uniqueId'" class="idClick" (click)="goToScannedProducts(itm)">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type != 'uniqueId' && cols[i].type != 'date' && cols[i].type != 'name' && cols[i].type != 'type' && cols[i].type != 'bodega' && cols[i].type != 'number' && cols[i].type != 'user'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'name'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'type'">{{ itm[col.varId]}}</i>
                            <i *ngIf="cols[i].type == 'date'">{{itm[col.varId] | date:'d-M-yyyy h:mm'}}</i>
                            <i *ngIf="cols[i].type == 'bodega'">{{ itm[col.varId].name }}</i>
                            <i *ngIf="cols[i].type == 'number'">{{ itm[col.varId]}}</i>
                            <i *ngIf="cols[i].type == 'user'">{{ itm[col.varId]}}</i>
                            <i *ngIf="cols[i].type == 'scan_products_button' && itm.enabled">
                                <span class="btn_scan_products_container">
                                    <button 
                                        class="btn btn_fill btn_success btn_scan_products"
                                        (click)="scanProducts(itm)"
                                    >
                                        Escanear productos
                                    </button>
                                </span>
                            </i>
                            <i *ngIf="cols[i].type == 'close_counting' && itm.enabled && _gen.userAdmin">
                                <span class="btn_close_counting_container">
                                    <button 
                                        class="btn btn-fill btn_orange btn_close_counting"
                                        (click)="closeCounting(itm)"
                                    >
                                        Cerrar Conteo
                                    </button>
                                </span>
                            </i>
                            <i *ngIf="cols[i].type == 'close_counting' && !itm.enabled">
                                <span class="btn_close_counting_container">
                                    <p class="btn_close_counting_label">
                                        Conteo Cerrado
                                    </p>
                                </span>
                            </i>
                            
                        </span>
    
                    </td>
                </tr>
            </tbody>

            <!--Invetory Scanned Products by Count ROW-->
            <tbody *ngIf="type == 'scanned_products'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _cat.editId == itm[uniqueIDVar], 'inactive': _cat.editId != itm[uniqueIDVar] && _cat.editId  }">
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _cat.editId">
                            <i *ngIf="cols[i].type == 'ean'">
                                <div *ngIf="itm.ean && itm.ean.ean">
                                    <p *ngFor="let ean of itm.ean.ean">
                                        {{ean}}
                                    </p>
                                </div>
                            </i>
                            <i *ngIf="cols[i].type != 'ean'">{{ itm[col?.varId] }}</i>
                            <i *ngIf="cols[i].type == 'decrease_button'">
                                <span class="btn_scan_products_container" *ngIf="itm.scanned_products > 0                                ">
                                    <div [ngStyle]="{'pointer-events': loading ? 'none' : 'auto'}" class="btnSubtractAmount" (click)="subtractCountAmount(itm)">
                                        <span></span>
                                    </div>
                                </span>
                            </i>
                        </span>

                        <div *ngIf="cols[i].visible && itm[uniqueIDVar] == _cat.editId">

                            <span *ngIf="col.varId != 'newName' ">
                                <i *ngIf="cols[i].type == 'ean'">
                                    <div *ngIf="itm.ean && itm.ean.ean">
                                        <p *ngFor="let ean of itm.ean.ean">
                                            {{ean}}
                                        </p>
                                    </div>
                                </i>
                                <i *ngIf="cols[i].type != 'ean'">{{ itm[col?.varId] }}</i>
                            </span>

                        </div>
                        

                    </td>
                </tr>
            </tbody>

            <!-- Inventory Configuration ROW -->
            <tbody *ngIf="type == 'configuration'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _inv.editId == itm[uniqueIDVar], 'inactive': _inv.editId != itm[uniqueIDVar] && _inv.editId  }">
                    <!-- <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_configs" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td> -->
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _inv.editId">
                            <i *ngIf="cols[i].type != 'name' && cols[i].type != 'sku_full' && cols[i].type != 'maximum_stock' && cols[i].type != 'minimum_stock' && cols[i].type != 'backorder' && cols[i].type != 'stock_status'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'name'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'sku_full'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'maximum_stock'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'minimum_stock'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'backorder' && itm[col.varId]" class="backorderIcon">
                                <i [class]="'48px_file-check' | icons"></i>
                            </i>
                            <i *ngIf="cols[i].type == 'backorder' && !itm[col.varId]" class="backorderIcon">
                                <i [class]="'48px_i-remove' | icons"></i>
                            </i>
                            <i *ngIf="cols[i].type == 'stock_status' && itm[col.varId]">
                                <span class="badge stock">In Stock</span>
                            </i>
                            <i *ngIf="cols[i].type == 'stock_status' && !itm[col.varId]">
                                <span class="badge no_stock">Out of Stock</span>
                            </i>
                        </span>
                    </td>
                </tr>
            </tbody>

            <!-- Catalog list ROW -->
            <tbody *ngIf="type == 'catalog'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _cat.editId == itm[uniqueIDVar], 'inactive': _cat.editId != itm[uniqueIDVar] && _cat.editId  }">
                    <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_catalog" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _cat.editId">
                            <i *ngIf="cols[i].type == 'ean'">
                                <div *ngIf="itm.ean && itm.ean.ean">
                                    <p *ngFor="let ean of itm.ean.ean; index as eanIndex" [style.marginBottom]="itm.ean.ean.length > 1 && itm.ean.ean.length-1 !== eanIndex ? '12px' : '0'">
                                        {{ean}}
                                    </p>
                                </div>
                            </i>
                            <i *ngIf="cols[i].type != 'ean'">{{ itm[col?.varId] }}</i>
                        </span>

                        <div *ngIf="cols[i].visible && itm[uniqueIDVar] == _cat.editId">

                            <span *ngIf="col.varId != 'newName' ">
                                <i *ngIf="cols[i].type == 'ean'">
                                    <div *ngIf="itm.ean && itm.ean.ean">
                                        <p *ngFor="let ean of itm.ean.ean">
                                            {{ean}}
                                        </p>
                                    </div>
                                </i>
                                <i *ngIf="cols[i].type != 'ean'">{{ itm[col?.varId] }}</i>
                            </span>

                            <!-- input Type -->
                            <div class="form_field" *ngIf="col.varId == 'newName' " >
                                <input type="text" name="" [(ngModel)]=" itm[col.varId] ">
                            </div>

                        </div>
                        
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn"
                        (click)="saveBtnFx( itm )"
                        [disabled]="itm['newName'] == itm['name'] && itm['newEan'] == itm['ean']">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- Catalog list ROW -->
            <tbody *ngIf="type == 'catalogStock'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _cat.editStockId == itm[uniqueIDVar], 'inactive': _cat.editStockId != itm[uniqueIDVar] && _cat.editStockId  }">
                    <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_catalogStock" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _cat.editStockId">
                            <i *ngIf="cols[i].type == 'uniqueId'">{{ itm[col.varId].sku_full }}</i>
                            <i *ngIf="cols[i].type == 'sku'">{{ itm.sku_full.sku }}</i>
                            <i *ngIf="cols[i].type == 'name'">{{ itm.sku_full.product.name }}</i>
                            <i *ngIf="cols[i].type == 'ean'">
                                <div *ngIf="itm.sku_full && itm.sku_full.ean && itm.sku_full.ean.ean">
                                    <p *ngFor="let ean of itm.sku_full.ean.ean">
                                        {{ean}}
                                    </p>
                                </div>
                            </i>
                            <i *ngIf="cols[i].type == 'manufacturer'">{{ itm.sku_full.product.brand?.manufacturer.name }}</i>
                            <i *ngIf="cols[i].type == 'quantity'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'reserved'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'provider'">{{ itm[col.varId].name }}</i>
                            <i *ngIf="cols[i].type == 'country'">{{ itm.provider.country.name }}</i>
                            <i *ngIf="cols[i].type == 'cost'">{{ itm[col.varId] | currency }}</i>
                            <i *ngIf="cols[i].type == 'sap'">{{ itm[col.varId] }}</i>
                        </span>

                        <div *ngIf="cols[i].visible && itm[uniqueIDVar] == _cat.editStockId">

                            <span *ngIf="col.varId != 'newName' && col.varId != 'newEan' ">
                                <i>{{ itm[col.varId] }}</i>
                            </span>

                        </div>
                        
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn"
                        (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- Pedidos ROW -->
            <tbody *ngIf="type == 'pedidos'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _ped.editId == itm[uniqueIDVar], 'inactive': _ped.editId != itm[uniqueIDVar] && _ped.editId  }">
                    <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_pedidos" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _ped.editId">
                            <i *ngIf="cols[i].type == 'uniqueId' && itm.order_number" class="idClick" (click)="gotoPed( itm.sale_id )">{{ itm.order_number }}</i>
                            <i *ngIf="cols[i].type != 'status' && cols[i].type != 'uniqueId' && cols[i].type != 'countryType' && cols[i].type != 'bUnitType' && cols[i].type != 'qty' && cols[i].type != 'products' && cols[i].type != 'productsName' && cols[i].type != 'date' && cols[i].type != 'postcode'  && cols[i].type != 'location_other' ">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'countryType'">{{ itm[col.varId].code }}</i>
                            <i *ngIf="cols[i].type == 'bUnitType'">{{ itm[col.varId].name }}</i>
                            <i *ngIf="cols[i].type == 'qty'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'postcode'">{{ itm[col.varId] | postCode }}</i>
                            <i *ngIf="cols[i].type == 'status'">
                                <span class="badge {{ itm[col.varId]?.name }}">{{itm[col.varId]?.pretty }}</span>
                            </i>
                            <i *ngIf="cols[i].type == 'location_other'">
                                <span *ngIf="itm[col.varId].colony">Colonia: <b>{{itm[col.varId].colony}}</b> </span>
                                <span *ngIf="itm[col.varId].delegation">Delegación: <b>{{itm[col.varId].delegation}}</b> </span>
                            </i>
                            <i *ngIf="cols[i].type == 'products'">
                                <div *ngFor="let prod of itm[col.varId]">
                                    <p>{{prod}}</p>
                                </div>
                            </i>
                            <i *ngIf="cols[i].type == 'productsName'">
                                <div *ngFor="let prod of itm[col.varId]">
                                    <p>{{prod}}</p>
                                </div>
                            </i>
                            <i *ngIf="cols[i].type == 'date'">{{itm[col.varId] | date:'d-M-yyyy h:mm a'}}</i>
                        </span>
                        
                        <div *ngIf="cols[i].visible && itm[uniqueIDVar] == _ped.editId">
                            <!-- input Type -->
                            <div class="form_field" *ngIf="cols[i].type != 'dd_manufact' && cols[i].type != 'dd_brand'">
                                <input *ngIf="cols[i].type != 'countryType' && cols[i].type != 'bUnitType' && cols[i].type != 'qty'" type="text" name="" value="{{ itm[col.varId] }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'countryType'" type="text" name="" value="{{ itm[col.varId].code }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'bUnitType'" type="text" name="" value="{{ itm[col.varId].name }}" [disabled]="cols[i].disabled">
                                <input *ngIf="cols[i].type == 'qty'" type="text" name="" value="{{ itm[col.varId].length }}" [disabled]="cols[i].disabled">
                            </div>
                        </div>
    
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn" (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- orders ROW -->
            <tbody *ngIf="type == 'orders'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _ord.editId == itm[uniqueIDVar], 'inactive': _ord.editId != itm[uniqueIDVar] && _ord.editId  }">
                    <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_orders" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _ord.editId">
                            <i *ngIf="cols[i].type == 'uniqueId'" class="idClick" (click)="gotoOrd( itm.order_id )">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type != 'uniqueId' && cols[i].type != 'status' && cols[i].type != 'provider' && cols[i].type != 'username' && cols[i].type != 'qty' && cols[i].type != 'reason' && cols[i].type != 'date' && cols[i].type != 'providerReference'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'qty'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'provider'">{{ itm[col.varId].name }}</i>
                            <i *ngIf="cols[i].type == 'providerReference'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'reason'">{{ itm[col.varId].name }}</i>
                            <i *ngIf="cols[i].type == 'username'">{{ itm[col.varId].first_name }} {{ itm[col.varId].last_name }}</i>
                            <i *ngIf="cols[i].type == 'status'">
                                <span class="badge {{ itm[col.varId] }}">{{itm[col.varId]}}</span>
                            </i>
                            <i *ngIf="cols[i].type == 'date'">{{itm[col.varId] | date:'d-M-yyyy h:mm'}}</i>
                        </span>
                        
                        <div *ngIf="cols[i].visible && itm[uniqueIDVar] == _ord.editId">
                            <span *ngIf="cols[i].type != 'invoice'">
                                <i *ngIf="cols[i].type == 'qty'">{{ itm[col.varId].length }}</i>
                                <i *ngIf="cols[i].type == 'username'">{{ itm[col.varId].first_name }} {{ itm[col.varId].last_name }}</i>
                                <i *ngIf="cols[i].type == 'usermail'">{{ itm[col.varId].email }}</i>
                                <i *ngIf="cols[i].type == 'provider'">{{ itm[col.varId].name }}</i>
                                <i *ngIf="cols[i].type == 'status'">
                                    <span class="badge {{ itm[col.varId] }}">{{itm[col.varId]}}</span>
                                </i>
                                <i *ngIf="cols[i].type != 'provider' && cols[i].type != 'username' && cols[i].type != 'usermail' && cols[i].type != 'qty' && cols[i].type != 'status'">{{ itm[col.varId] }}</i>
                            </span>
                            <!-- input Type -->
                            <div class="form_field" *ngIf="cols[i].type == 'invoice'">
                                <input type="text" name="" [(ngModel)]="itm['newInvoice']" [disabled]="cols[i].disabled">
                            </div>
                        </div>
    
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn" (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- pendiente products ROW -->
            <tbody *ngIf="type == 'pending'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _ord.editPendingId == itm[uniqueIDVar], 'inactive': _ord.editPendingId != itm[uniqueIDVar] && _ord.editPendingId  }">
                    <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_pending" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _ord.editId" [ngClass]="{'providerBox': cols[i].type == 'provider'}">
                            <i *ngIf="cols[i].type == 'uniqueId'" class="idClick" (click)="gotoPed( itm.sale_id )">{{ itm.order_number }}</i>
                            <i *ngIf="cols[i].type != 'uniqueId' && cols[i].type != 'comment'">{{ itm[col.varId] }}</i>

                            <div class="providerList" *ngIf="cols[i].type == 'provider'">
                                <div class="noProvider" *ngIf="itm.currproviders.provider_id == null">
                                    <div class="noProvider_textContainer">
                                        <i class="{{ '48px_e-remove' | icons }}"></i>
                                        <b>Sin proveedor</b>
                                    </div>
                                    <div>
                                        <button class="edit" (click)="showAssignProvider(itm)">
                                            <i class="{{ '48px_pencil' | icons }}"></i>
                                            <span>Asignar</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="providerSel" *ngIf="itm.currproviders.provider_id != null">
                                    <div class="currItem" *ngIf="!providerSel" (click)="providerSel = true; provideritem = itm.item_id">
                                        <div class="name">{{itm.currproviders.name}}</div>
                                        <div class="cost">Prioridad: {{itm.currproviders.priority}}</div>
                                        <div class="cost">Cantidad: {{itm.currproviders.quantity}}</div>                                        
                                    </div>
                                    <div class="currItem" *ngIf="providerSel" (click)="providerSel = false">
                                        <div class="name">{{itm.currproviders.name}}</div>
                                        <div class="cost">Prioridad: {{itm.currproviders.priority}}</div>
                                        <div class="cost">Cantidad: {{itm.currproviders.quantity}}</div>                                        
                                    </div>
                                    <div class="selItems" [ngClass]="{'upper': (data.length - 5) <= h && data.length >= 5}" *ngIf="providerSel == true &&  provideritem == itm.item_id ">
                                        <div class="item" *ngFor="let opt of itm.providers" [ngClass]="{'active': opt.name == itm.currproviders.name}" (click)="itm.currproviders = opt; providerSel = false">
                                            <div class="name">{{opt.name}}</div>
                                            <div class="cost">Prioridad: {{opt.priority}}</div>
                                            <div class="cost">Cantidad: {{opt.quantity}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <button class="edit" (click)="showAssignProvider(itm)">
                                            <i class="{{ '48px_pencil' | icons }}"></i>
                                            <span>Asignar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="Comment" *ngIf="cols[i].type == 'comment'">

                                <div class="commentWrap">
                                    <div class="form_field form_fieldTextArea">

                                        <p *ngIf="i != currCommentItem">
                                            <span class="noComment" *ngIf="!itm[col.varId]">Sin comentario</span>
                                            <span *ngIf="itm[col.varId]">{{itm[col.varId]}}</span>
                                        </p>
                                        <mat-form-field class="example-full-width" appearance="outline" *ngIf="i == currCommentItem">
                                            <mat-label>Deja un comentario</mat-label>
                                            <textarea [(ngModel)]="itm.comment" matInput placeholder="Deja un comentario"></textarea>
                                        </mat-form-field>

                                        <button class="edit" *ngIf="i == currCommentItem" (click)="changeProdCommentFx( itm )">
                                            <i class="{{ '48px_floppy-disk' | icons }}"></i>
                                            <span>Guardar comentario</span>
                                        </button>
                                        <button class="edit" *ngIf="i != currCommentItem" (click)="currCommentItem = i">
                                            <i class="{{ '48px_pencil' | icons }}"></i>
                                            <span>Editar comentario</span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </span>
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn" (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- facturas ROW -->
            <tbody *ngIf="type == 'invoices'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _ord.editPendingId == itm[uniqueIDVar], 'inactive': _ord.editPendingId != itm[uniqueIDVar] && _ord.editPendingId  }">
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _ord.editId">
                            <i *ngIf="cols[i].type != 'invoice_number' && cols[i].type != 'invoice_value' && cols[i].type != 'reference' && cols[i].type != 'provider' && cols[i].type != 'bodega' && cols[i].type != 'entry_type' && cols[i].type != 'comment' && cols[i].type != 'upload_file' && cols[i].type != 'order' && cols[i].type != 'order_date' && cols[i].type != 'inventory_entry_date' && cols[i].type != 'electronic_invoice_assignment_date' && cols[i].type != 'electronic_invoice' && cols[i].type != 'electronic_invoice_value' && cols[i].type != 'cost_order_value'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].varId == 'invoice_number'" class="idClick" (click)="goToInvoice(itm)">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'invoice_value'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'electronic_invoice_value' && itm.electronic_invoices.length > 0">
                                <p *ngFor="let elect_inv of itm['electronic_invoices']; index as invIndex" [style.marginBottom]="itm['electronic_invoices'].length > 1 && itm['electronic_invoices'].length-1 !== invIndex ? '12px' : '0'">
                                    {{elect_inv.total}}
                                </p>
                            </i>
                            <i *ngIf="cols[i].type == 'cost_order_value' && itm['cost_order_value'] !== 0">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'upload_file'">
                                <label class="btn_uploadFile" [for]="'upload-file-'+h">Subir Archivo</label>
                                <input 
                                    type="file"
                                    [id]="'upload-file-'+h" 
                                    multiple 
                                    (change)="onChangeFileInput($event, itm.reception_id)"
                                    class="input_uploadFile"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                />
                            </i>
                            <i *ngIf="cols[i].type == 'reference'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'order'">{{ itm[col.varId]?.reference }}</i>
                            <i *ngIf="cols[i].type == 'order_date'">
                                {{ itm[col.varId] | date:'d-M-yyyy h:mm a' }}
                            </i>
                            <i *ngIf="cols[i].type == 'inventory_entry_date'">
                                {{ itm[col.varId] | date:'d-M-yyyy h:mm a' }}
                            </i>
                            <i *ngIf="cols[i].type == 'provider'">
                                {{ itm[col.varId] && itm[col.varId].name }}
                            </i>
                            <i *ngIf="cols[i].type == 'bodega'">
                                {{ itm[col.varId] && itm[col.varId].name }}
                            </i>
                            <i *ngIf="cols[i].type == 'entry_type'">
                                {{ itm[col.varId] && itm[col.varId].name }}
                            </i>
                            <i *ngIf="cols[i].type == 'comment'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'files'">
                                <span *ngIf="itm.reception_files.length === 0">No tiene archivos</span>
                                <button 
                                    *ngIf="itm.reception_files.length > 0"
                                    (click)="download(itm)"
                                    class="btn_downloadFile"
                                >
                                    {{getLabelFiles(itm.reception_files)}}
                                </button>
                            </i>
                            <i *ngIf="cols[i].type == 'electronic_invoice' && itm.electronic_invoices.length > 0">
                                <p *ngFor="let elect_inv of itm['electronic_invoices']; index as invIndex" [style.marginBottom]="itm['electronic_invoices'].length > 1 && itm['electronic_invoices'].length-1 !== invIndex ? '12px' : '0'">
                                    {{elect_inv.consecutive}}
                                </p>
                            </i>
                            <i *ngIf="cols[i].type == 'electronic_invoice' && itm.electronic_invoices.length === 0">
                                <span class="btn_close_counting_container">
                                    <button 
                                        class="btn btn-fill btn_orange btn_close_counting"
                                        (click)="showElectronicInvoice(itm)"
                                    >
                                        Añadir
                                    </button>
                                </span>
                            </i>
                            <i *ngIf="cols[i].type == 'electronic_invoice_assignment_date'">
                                <p *ngFor="let elect_inv of itm['electronic_invoices']; index as invIndex" [style.marginBottom]="itm['electronic_invoices'].length > 1 && itm['electronic_invoices'].length-1 !== invIndex ? '12px' : '0'">
                                    {{elect_inv.broadcast_date}}
                                </p>
                            </i>
                        </span>
                    </td>
                    
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn" (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>


            <!-- facturas electrónicas ROW -->
            <tbody *ngIf="type == 'electronic_invoices'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _ord.editPendingId == itm[uniqueIDVar], 'inactive': _ord.editPendingId != itm[uniqueIDVar] && _ord.editPendingId }">
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _ord.editId">
                            <i *ngIf="cols[i].type != 'document_identifier' && cols[i].type != 'date_issue'">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'document_identifier'" [title]="itm[col.varId]" (click)="copyToClipboard(itm[col.varId])" style="cursor: pointer">{{ itm[col.varId] }}</i>
                            <i *ngIf="cols[i].type == 'date_issue'">
                            {{ itm[col.varId] | date:'d-M-yyyy h:mm a' }}
                            </i>
                        </span>
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn" (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- providers ROW -->
            <tbody *ngIf="type == 'providers'" >
                <tr *ngFor="let itm of data; index as h" [ngClass]="{'active': _provs.editId == itm[uniqueIDVar], 'inactive': _provs.editId != itm[uniqueIDVar] && _provs.editId }">
                    <td>
                        <div class="custom_checkbox">
                            <input type="checkbox" name="check_providers" id="check_{{h}}" (change)="itmChecked($event, itm, type)">
                            <label for="check_{{h}}"></label>
                        </div>
                    </td>
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _provs.editId">
                            <!-- <i *ngIf="['bs_account_number', 'bs_account_name', 'pl_account_name', 'pl_account_number'].includes(cols[i].type)">
                                <p *ngFor="let item of itm[col.varId]">
                                
                                </p>
                            </i>
                            <i *ngIf="!['bs_account_number', 'bs_account_name', 'pl_account_name', 'pl_account_number'].includes(cols[i].type)">{{ itm[col?.varId] }}</i> -->
                            <i>{{ itm[col.varId] }}</i>
                        </span>
                        <div *ngIf="cols[i].visible && itm[uniqueIDVar] == _provs.editId">
                        <span *ngIf="!['provider_type', 'default_center_cost', 'detailed_description', 'comments', 'contact_email', 'name'].includes(col.varId)">
                            <i>{{ itm[col.varId] }}</i>
                        </span>
                        <!-- input Type -->
                            <div class="form_field" *ngIf="['provider_type', 'default_center_cost', 'detailed_description', 'comments', 'contact_email', 'name'].includes(col.varId)" >
                                <input type="text" name="" [(ngModel)]=" itm[col.varId] ">
                            </div>
                        </div>
                    </td>
                    <div class="actionsEdit">
                        <button class="btn btn_sm btn_success btn_fill editBtn animate__animated animate__fadeIn" (click)="saveBtnFx( itm )">
                            <i [class]="'48px_floppy-disk' | icons"></i>
                            <span>Guardar</span>
                        </button>
                        <button class="btn btn_sm btn_error btn_fill editBtn animate__animated animate__fadeIn" (click)="cancelBtnFx()">
                            <i [class]="'48px_e-remove' | icons"></i>
                            <span>Cancelar</span>
                        </button>
                    </div>
                </tr>
            </tbody>

            <!-- Sincrronizaciones con Magento ROW -->
            <tbody *ngIf="type == 'syncs'" >
                <tr *ngFor="let itm of data; index as h">
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _ord.editId" [ngClass]="{'providerBox': cols[i].type == 'provider'}">
                            <i *ngIf="cols[i].type == 'date'">{{itm[col.varId] | date:'d-M-yyyy h:mm a'}}</i>
                        </span>
                    </td>
                </tr>
            </tbody>

            <!-- Cortes Programados -->
            <tbody *ngIf="type == 'provider_cuts'" >
                <tr *ngFor="let itm of data; index as h">
                    <td *ngFor="let col of cols; index as i" class="{{col.class}}">
                        <span *ngIf="cols[i].visible && itm[uniqueIDVar] != _ord.editId" [ngClass]="{'providerBox': cols[i].type == 'provider'}">
                            <i *ngIf="cols[i].type == 'date'" class="dateWrapper">
                                <span>{{itm[col.varId] | date:'d-M-yyyy'}}</span>
                                <span>{{itm[col.varId] | date:'h:mm a'}}</span>
                            </i>
                            <i *ngIf="cols[i].type == 'status'">
                                {{getStatusTranslate(itm[col.varId])}}
                            </i>
                            <i *ngIf="cols[i].type != 'date' && cols[i].type != 'status'">{{ itm[col.varId] }}</i>
                        </span>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
    <div class="tableBoxEmpty" *ngIf="type != 'syncs' && data.length <= 0">
        <div class="info">
            <img src="assets/img/emptyTable.png" alt="">
            <p>No se han encontrado resultados</p>
        </div>
    </div>
    <div class="tableBoxEmptySyncs" *ngIf="type == 'syncs' && data.length <= 0">
        <div class="info">
            <img src="assets/img/emptyTable.png" alt="">
            <p>No se han encontrado resultados</p>
        </div>
    </div>
</div>

<app-assign-provider [skuFull]="skuFullToAssignProvider" [providersBySkuFull]="providersBySkuFull" *ngIf="_gen.assignProviderView" (reloadTable)="onReloadTable()"></app-assign-provider>

<app-read-code  
  [type]="'count'" 
  *ngIf="_gen.barCodeCountView"
  [countData]="tableCountData"
  [createCountId]="tableCreateCountId"
  (reloadTable)="onReloadTable()"
></app-read-code>

<app-process-electronic-invoices *ngIf="_elecInv.processElecetronicInvoicesView" [item]="itemToProcessElectronicInvoices" (reloadTable)="onReloadTable()"></app-process-electronic-invoices>