<div class="topttls topttlsDashboard">
    <app-tittle [label]="'Dashboard'"></app-tittle>

    <div class="btns">
        &nbsp;
    </div>
</div>

<div class="dashWrapper">
    <div class="cutsWrapper">
        <div class="loaderCut" *ngIf="showLoaderCuts">
            <div class="icon">
                <img src="assets/img/preload.gif" alt="">
            </div>
        </div>
        <div *ngIf="!showLoaderCuts">
            <div class="cutsWrapper_title">Cortes Programables</div>
            <app-table [cols]="columnsConfigCutTable" [data]="dataSourceCutTable" [type]="tableType"></app-table>
        </div>
    </div>
    <div class="dashBox">
        <div class="loaderSync" *ngIf="showLoaderSyncs">
            <div class="icon">
                <img src="assets/img/preload.gif" alt="">
            </div>
        </div>
        <div *ngIf="!showLoaderSyncs">
            <div class="ttl ttlUltSync">Última Sincronización</div>
            <app-table [cols]="columnsConfig" [data]="dataSource" [type]="secondTableType"></app-table>
        </div>
    </div>
</div>

