import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'idBodega'
})
export class IdBodegaPipe implements PipeTransform {

  private val:string = "";

  constructor(public _inv: InventoryService){}

  transform(value: string, ...args: unknown[]): any {
    
    if ( this._inv.ocListBodegas ) {
      
      for (let f = 0; f < this._inv.ocListBodegas.length; f++) {
        const element = this._inv.ocListBodegas[f];      
        
        if ( element.provider_id.toString() == value.toString() ) {
          this.val = element.name
        }
      }
    }

    return this.val
  }

}
