import { Pipe, PipeTransform } from '@angular/core';
import { CatalogService } from '../services/catalog.service';
import { InventoryService } from '../services/inventory.service';


@Pipe({
  name: 'idManufac'
})
export class IdManufacPipe implements PipeTransform {

  private val:string = "";

  constructor( public _cat:CatalogService, public _inv:InventoryService ) {
  }

  transform(value:string ): any {    
    if ( this._inv.filt_manufac ) {
      
      for (let f = 0; f < this._inv.filt_manufac.length; f++) {
        const element = this._inv.filt_manufac[f];      
        
        if ( element.manufacturer_id.toString() == value.toString() ) {
          this.val = element.name
        }
      }

    } 
    if ( this._cat.filt_manufac ) {
      
      for (let f = 0; f < this._cat.filt_manufac.length; f++) {
        const element = this._cat.filt_manufac[f];
        
        if ( element.manufacturer_id == value ) {
          this.val = element.name
        }
      }

    } 

    return this.val
  }


}
