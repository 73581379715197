import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styles: [
  ]
})
export class OrderListComponent implements OnInit {


  tableType:string = "orders";
  columnsConfig = [
    { varId: "reference", label: "Orden Interna", visible: true, type: "uniqueId", class: "", disabled: true },
    // { varId: "order_id", label: "Número de orden", visible: false, type: "", class: "idCell", disabled: true },
    { varId: "created_at", label: "Fecha de creación", visible: true, type: "date", class: "", disabled: true },
    { varId: "provider", label: "Proveedor", visible: true, type: "provider", class: "", disabled: true },
    { varId: "external_reference", label: "Orden Proveedor", visible: true, type: "providerReference", class: "longCell", disabled: true },
    { varId: "status", label: "Estado", visible: true, type: "status", class: "", disabled: true },
    { varId: "products_sum", label: "Cantidad prods.", visible: true, type: "qty", class: "idCell", disabled: true },
    { varId: "user", label: "Nombre", visible: true, type: "username", class: "", disabled: true },
    { varId: "user", label: "Mail", visible: false, type: "usermail", class: "", disabled: true },
    { varId: "reason", label: "Tipo", visible: true, type: "reason", class: "", disabled: true },
  ]
  dataSourcefull:any[] = [];

  colsView:boolean = false;
  filtsView:boolean = false;
  

  dataSourceCount = 0;
  currPage = 1; 
  currPageView = 100; 
  currPageTotal = 0; 

  dataSource:any[] = [];

  maxDate:Date = new Date( Date.now() );

  constructor( public _gen:GeneralService, public _ord:OrdersService, public route:Router ) {  }

  ngOnInit(): void {
    this.getAllProds();
    this.getFiltersOpts();

    this._gen.currCountryObs.subscribe((country) => {
      // console.log(country)
      this.getAllProds();
      this.getFiltersOpts();
    })
  }

  /* Filter Opts */
  getFiltersOpts() {
    // var country = this._ord.filterOpts.country.join();

    var country = this._gen.currCountry.toString();
    this._ord.filterOpts.country = country.split(',');

    this._gen.getCountry().then( (data:any) => {
      this._gen.allCountries = data.data;
    })

    this._gen.getBUnit( country ).then( (data:any) => {
      this._ord.filt_bUnit = data.data;
    })

    this._gen.getProvider( country ).then( (data:any) => {
      this._ord.filt_provider = data.data;      
    })
    this._gen.getReason().then( (data:any) => {
      this._ord.filt_reason = data.data;      
    })

    this._gen.getOrderStatus().then( (data:any) => {
      this._ord.filt_orderStatus = data.data;      
    })
  }

  /* PAGE COUNT */
  getAllProdsCountFilt( filt:any ) {
    let prom = new Promise ( ( resolve, reject ) => {
      this._ord.getAllProdsCountFilt( filt ).then( (data:any) => {
        this.dataSourceCount = data.data.count;        
        this.currPageTotal = Math.ceil( this.dataSourceCount / this.currPageView );      
        
        if ( this.currPageTotal == 0 ) {
          this.currPageTotal = 1;
        }
        if ( this.currPage > this.currPageTotal ) {
          this.currPage = this.currPageTotal
        }

        resolve( true );
      })
    })
    return prom;
  }

  /* RESET Filters */
  resetFilters_item(item:string) {
    switch (item) {
      // case 'country':
      //   this._ord.filterOpts.country = [];
      //   break;
      case 'business_unit':
        this._ord.filterOpts.business_unit = [];
        break;
      case 'provider':
        this._ord.filterOpts.provider = [];
        break;
      case 'status':
        this._ord.filterOpts.status = [];
        break;
      case 'reason':
        this._ord.filterOpts.reason = [];
        break;
      case 'from':
        this._ord.filterOpts.from = "";
        break;
      case 'to':
        this._ord.filterOpts.to = "";
        break;
      case 'order_number':
        this._ord.filterOpts.order_number = "";
        break;
      case 'reference':
        this._ord.filterOpts.reference = "";
        break;
      case 'order_provider': 
        this._ord.filterOpts.order_provider = "";
        break;
      
      default:
        this._ord.filterOpts = {
          // country: [],
          business_unit: [],
          provider: [],
          reason: [],
          status: [],
          from: "",
          to: "",
          order_number: "",
          reference: "",
          order_provider: ""
        };

        break;
    }
    this.checkFilter();
  }
  /* RESET */
  resetTobTable( type:string ) {   
    setTimeout(() => {
      if ( type == 'colsView' ) {
        this.filtsView = false;
        if ( this.colsView ) { this.colsView = false } else { this.colsView = true }
      } else if ( type == 'filtsView' ) {
        this.colsView = false;
        if ( this.filtsView ) { this.filtsView = false } else { this.filtsView = true }
      }
    }, 150); 
  }
  resetTable( arrFull:any[] ) {
    let prom = new Promise( (resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];      

      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element:any = this.dataSourcefull[d];        
        let item = {
          order_id: element.order_id,
          products: element.products,
          products_sum: element.products_sum,
          provider: element.provider,
          user: element.user,
          mail: element.user.email,
          created_at: element.created_at,
          reference: element.reference,
          status: element.status,
          invoice: element.invoice,
          newInvoice: element.invoice,
          reason: element.reason,
          external_reference: element.external_reference
        }
        this.dataSource.push( item );
      }      
      resolve( true);
    })

    return prom;
  }

  /* PAGER FX */
  getAllProds() {
    this._gen.tableLoader = true;
    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._ord.getAllProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( ({data}) => {        
        this.resetTable( data ).then( (data:any) => {
          this._gen.tableLoader = false;
        });
      })
    });
  }
  changePageInp( page:any, input:boolean = false ) {
    if ( input ) {
      this.currPage = parseInt( page.target.value );
    } else {
      this.currPage = parseInt( page );
    }
    this._gen.tableLoader = true;
    
    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._ord.getAllProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });

  }

  /* filters */
  checkFilterVal() {
    let filt_business_unit, filt_country, filt_status, filt_from, filt_to, filt_provider, filt_reason, filt_order_number, filt_reference, filt_order_provider;

    filt_country = `country=${ this._gen.currCountry }&`;

    if ( this._ord.filterOpts.business_unit.length ) {
      filt_business_unit = `business_unit=${this._ord.filterOpts.business_unit}&`;
    } 
    // if ( this._ord.filterOpts.country ) {
    //   filt_country = `country=${ this._ord.filterOpts.country.join() }&`;
    // } 
    if ( this._ord.filterOpts.provider.length ) {
      filt_provider = `provider=${ this._ord.filterOpts.provider.join() }&`;
    } 
    if ( this._ord.filterOpts.status.length ) {
      filt_status = `status=${ this._ord.filterOpts.status.join() }&`;
    } 
    if ( this._ord.filterOpts.reason.length ) {
      filt_reason = `reason_id=${ this._ord.filterOpts.reason.join() }&`;
    } 
    if ( this._ord.filterOpts.from ) {
      filt_from = `created_at_from=${ this._gen.getDateFormat( this._ord.filterOpts.from) }&`;
    } 
    if ( this._ord.filterOpts.to ) {
      filt_to = `created_at_to=${ this._gen.getDateFormat( this._ord.filterOpts.to) } 23:59&`;
    } 
    if ( this._ord.filterOpts.order_number ) {
      filt_order_number = `order_number=${ this._ord.filterOpts.order_number }&`;
    } 
    if ( this._ord.filterOpts.reference ) {
      filt_reference = `reference=${ this._ord.filterOpts.reference }&`;
    }
    if ( this._ord.filterOpts.order_provider ) {
      filt_order_provider = `external_reference=${ this._ord.filterOpts.order_provider }&`;
    } 

    let filter = `${filt_business_unit}${filt_country}${filt_status}${filt_from}${filt_to}${filt_reason}${filt_order_number}${filt_reference}${filt_provider}${filt_order_provider}`;
    return filter.replace(/undefined/g,"") ;
  }
  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._ord.getAllProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });
  }

  /* EDIT */
  editBtnFx() {    
    this._ord.editId = this._ord.sel_orders[0]?.order_id;
    this._ord.editView = !this._ord.editView;
  }
  saveBtnFx() {
    this._ord.editId = "";
    this._ord.editView = !this._ord.editView;
  }
  cancelBtnFx() {
    this._ord.editId = "";
    this._ord.editView = !this._ord.editView;
  }

  /* VIEW DETAIL */
  viewDetail( item:any ) {
    this._ord.detailId = item;
    this.route.navigate([`/home/ordenes/${item}`])
    
    // console.log( item );
    // this._ped.detailView = true;
  }
  newOrder() {
    this.route.navigate(['/home/ordenes/pending_prods']);
  }

}
