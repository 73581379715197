import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { APP_ROUTES } from './app-routing.module';

/* PLUGINS */
import { Ng2IziToastModule } from 'ng2-izitoast';

/* MATERIAL DESIGN */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


/* PAGES */
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { InnerComponent } from './pages/inner/inner.component';

/* SHARED */
import { TopbarComponent } from './components/shared/topbar/topbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SideBarComponent } from './components/shared/side-bar/side-bar.component';
import { DashboardComponent } from './pages/inner/dashboard/dashboard.component';
import { InventoryComponent } from './pages/inner/inventory/inventory.component';
import { InputsComponent } from './pages/inner/inventory/inputs/inputs.component';
import { OutputsComponent } from './pages/inner/inventory/outputs/outputs.component';
import { TableComponent } from './components/shared/templates/table/table.component';
import { TittleComponent } from './components/shared/templates/tittle/tittle.component';
import { SortDirective } from './directives/sort.directive';
import { FileUploadComponent } from './pages/inner/file-upload/file-upload.component';
import { IconsPipe } from './pipes/icons.pipe';
import { CatalogComponent } from './pages/inner/catalog/catalog.component';
import { IdManufacPipe } from './pipes/id-manufac.pipe';
import { IdBrandPipe } from './pipes/id-brand.pipe';
import { ReadCodeComponent } from './components/shared/read-code/read-code.component';
import { PedidosComponent } from './pages/inner/pedidos/pedidos.component';
import { IdCountryPipe } from './pipes/id-country.pipe';
import { IdBunitPipe } from './pipes/id-bunit.pipe';
import { NameManufactPipe } from './pipes/name-manufact.pipe';
import { OrdersComponent } from './pages/inner/orders/orders.component';
import { CardComponent } from './components/shared/dash/card/card.component';
import { PedDetailComponent } from './components/shared/ped-detail/ped-detail.component';
import { MonthNamePipe } from './pipes/month-name.pipe';
import { DiopBySkufullPipe } from './pipes/diop-by-skufull.pipe';
import { IdProviderPipe } from './pipes/id-provider.pipe';
import { PedidoItemComponent } from './pages/inner/pedidos/pedido-item/pedido-item.component';
import { PedidoListComponent } from './pages/inner/pedidos/pedido-list/pedido-list.component';
import { OrderListComponent } from './pages/inner/orders/order-list/order-list.component';
import { OrderItemComponent } from './pages/inner/orders/order-item/order-item.component';
import { ReadCodePedComponent } from './components/shared/read-code-ped/read-code-ped.component';
import { IdOrderPipe } from './pipes/id-order.pipe';
import { PendingsComponent } from './pages/inner/orders/pendings/pendings.component';
import { ProcessPedsComponent } from './components/shared/process-peds/process-peds.component';
import { CatalogListComponent } from './pages/inner/catalog/catalog-list/catalog-list.component';
import { CatalogStockComponent } from './pages/inner/catalog/catalog-stock/catalog-stock.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ProdStatusComponent } from './components/shared/prod-status/prod-status.component';
import { IdProdtypePipe } from './pipes/id-prodtype.pipe';
import { ShipPedsComponent } from './components/shared/ship-peds/ship-peds.component';
import { IdReasonPipe } from './pipes/id-reason.pipe';
import { PostCodePipe } from './pipes/post-code.pipe';
import { ThermalPrintModule } from 'ng-thermal-print';
import { InventoryEntryComponent } from './pages/inner/orders/inventory-entry/inventory-entry.component';
import { ConfigurationComponent } from './pages/inner/inventory/configuration/configuration.component';
import { InvoicesComponent } from './pages/inner/orders/invoices/invoices.component';
import { IdBodegaPipe } from './pipes/id-bodega.pipe';
import { AssignProviderComponent } from './components/shared/assign-provider/assign-provider.component';
import { IdAttachedFilesPipePipe } from './pipes/id-attached-files-pipe.pipe';
import { IdStatusPipe } from './pipes/id-status.pipe';
import { CountsComponent } from './pages/inner/inventory/counts/counts.component';
import { CreateCountComponent } from './components/shared/create-count/create-count.component';
import { ScannedProductsComponent } from './pages/inner/inventory/scanned-products/scanned-products.component';
import { RoleDirective } from './directives/role.directive';
import { ElectronicInvoicesComponent } from './pages/inner/billing/electronic-invoices/electronic-invoices.component';
import { ProvidersComponent } from './pages/inner/billing/providers/providers.component';
import { BillingComponent } from './pages/inner/billing/billing.component';
import { ProcessElectronicInvoicesComponent } from './components/shared/process-electronic-invoices/process-electronic-invoices.component';

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    FooterComponent,
    LoginComponent,
    InnerComponent,
    SideBarComponent,
    DashboardComponent,
    InventoryComponent,
    InputsComponent,
    OutputsComponent,
    TableComponent,
    TittleComponent,
    SortDirective,
    FileUploadComponent,
    IconsPipe,
    CatalogComponent,
    IdManufacPipe,
    IdBrandPipe,
    ReadCodeComponent,
    PedidosComponent,
    IdCountryPipe,
    IdBunitPipe,
    NameManufactPipe,
    OrdersComponent,
    CardComponent,
    PedDetailComponent,
    MonthNamePipe,
    DiopBySkufullPipe,
    IdProviderPipe,
    PedidoItemComponent,
    PedidoListComponent,
    OrderListComponent,
    OrderItemComponent,
    ReadCodePedComponent,
    IdOrderPipe,
    PendingsComponent,
    ProcessPedsComponent,
    CatalogListComponent,
    CatalogStockComponent,
    ClickOutsideDirective,
    ProdStatusComponent,
    IdProdtypePipe,
    ShipPedsComponent,
    IdReasonPipe,
    PostCodePipe,
    InventoryEntryComponent,
    ConfigurationComponent,
    InvoicesComponent,
    IdBodegaPipe,
    AssignProviderComponent,
    IdAttachedFilesPipePipe,
    IdStatusPipe,
    CountsComponent,
    CreateCountComponent,
    ScannedProductsComponent,
    RoleDirective,
    ElectronicInvoicesComponent,
    ProvidersComponent,
    BillingComponent,
    ProcessElectronicInvoicesComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,

    APP_ROUTES,

    Ng2IziToastModule,
    FormsModule,
    HttpClientModule,

    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatAutocompleteModule,

    ThermalPrintModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
