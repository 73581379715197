import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icons'
})
export class IconsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return `wi wi-${value}`;
  }

}
