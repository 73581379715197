import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ProvidersService } from 'src/app/services/providers.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html'
})
export class ProvidersComponent implements OnInit {

  tableType: string = "providers";
  columnsConfig = [
    {
      varId: "id_number",
      label: "Número de Identificación",
      visible: true,
      type: "id_number",
      class: "idCell",
      disabled: true,
    },
    {
      varId: "natial_tax_ID",
      label: "Número de identificación fiscal",
      visible: true,
      type: "natial_tax_ID",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "name",
      label: "Nombre",
      visible: true,
      type: "name",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "bs_account_number",
      label: "Número de cuenta BS",
      visible: true,
      type: "bs_account_number",
      class: "",
      disabled: true,
    },
    {
      varId: "bs_account_name",
      label: "Nombre de la cuenta BS",
      visible: true,
      type: "bs_account_name",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "pl_account_name",
      label: "Nombre de la cuenta PL",
      visible: true,
      type: "pl_account_name",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "pl_account_number",
      label: "Número de cuenta PL",
      visible: true,
      type: "pl_account_number",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "default_center_cost",
      label: "Costo por defecto del centro",
      visible: true,
      type: "default_center_cost",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "detailed_description",
      label: "Descripción detallada",
      visible: true,
      type: "detailed_description",
      class: "longCell noEllipsis",
      disabled: true,
    },
    {
      varId: "comments",
      label: "Comentario",
      visible: true,
      type: "Comments",
      class: "longCell noEllipsis",
      disabled: true,
    },
    {
      varId: "approval_email",
      label: "Correo electrónico de aprobación",
      visible: true,
      type: "approval_email",
      class: "longCell noEllipsis",
      disabled: true,
    },
    {
      varId: "contact_email",
      label: "Email de contacto",
      visible: true,
      type: "contact_email",
      class: "longCell noEllipsis",
      disabled: true,
    },
    {
      varId: "contact_number",
      label: "Número de contacto",
      visible: true,
      type: "contact_number",
      class: "longCell noEllipsis",
      disabled: true,
    }
  ];

  dataSourcefull: any[] = [];

  colsView: boolean = false;
  filtsView: boolean = false;

  dataSourceCount = 0;
  currPage = 1;
  currPageView = 100;
  currPageTotal = 0;

  dataSource: any[] = [];

  listProviders: any = [];

  constructor(
    public _gen: GeneralService,
    public _provs: ProvidersService
  ) {}

  ngOnInit(): void {
    this.getAllData();
    this.getFiltersOpts();
    this._gen.currCountryObs.subscribe((country) => {
      this.getAllData();
      this.getFiltersOpts();
      this.colsView = false;
      this.filtsView = false;
    })
  }

  getAllData() {
    this._gen.tableLoader = true;
    this.getAllProvidersCountFilt(this.checkFilterVal()).then(() => {
      this._provs.getAllProvidersFilter(this.checkFilterVal(), this.currPageView, this.currPage).then(({ data }) => {
        this.resetTable(data).then((data: any) => {
          this._gen.tableLoader = false;
        });
      });
    })
  }

  /* PAGE COUNT */
  getAllProvidersCountFilt(filt: any) {
    let prom = new Promise((resolve, reject) => {
      this._provs.getAllProvidersCountFilt(filt).then((data: any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil(
          this.dataSourceCount / this.currPageView
        );

        if (this.currPageTotal == 0) {
          this.currPageTotal = 1;
        }
        if (this.currPage > this.currPageTotal) {
          this.currPage = this.currPageTotal;
        }

        resolve(true);
      });
    });
    return prom;
  }

  resetTable(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];

      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element: any = this.dataSourcefull[d];
        let item = {
          id_number: element.id,
          provider_type: element.provider_type,
          natial_tax_ID: element.no_id,
          bs_account_number: element.bs_account_number,
          bs_account_name: element.bs_account_name,
          pl_account_name: element.pl_account_name,
          pl_account_number: element.pl_account_number,
          default_center_cost: element.center_cost,
          detailed_description: element.detailed_description,
          comments: element.comments,
          approval_email: element.approval_email,
          contact_email: element.contact_email,
          contact_number: element.contact_number,
          name:element.name
        };
        this.dataSource.push(item);
      }

      resolve(true);
    });

    return prom;
  }

  resetTobTable(type: string) {
    setTimeout(() => {
      if (type == "colsView") {
        this.filtsView = false;
        if (this.colsView) {
          this.colsView = false;
        } else {
          this.colsView = true;
        }
      } else if (type == "filtsView") {
        this.colsView = false;
        if (this.filtsView) {
          this.filtsView = false;
        } else {
          this.filtsView = true;
        }
      }
    }, 150);
  }

  /* filters */
  checkFilterVal() {
    let filt_country,
      filt_provider_typer,
      filt_natial_tax_ID,
      filt_contact_email,
      filt_name;

    filt_country = `country=${this._gen.currCountry}&`;

    if (this._provs.filterOpts_providers.natial_tax_ID.trim().length > 0) {
      filt_natial_tax_ID = `no_id=${this._provs.filterOpts_providers.natial_tax_ID.trim()}&`;
    }
    if (this._provs.filterOpts_providers.name.trim().length > 0) {
      filt_name = `name=${this._provs.filterOpts_providers.name.trim()}&`;
    }

    let filter = `${filt_country}${filt_provider_typer}${filt_natial_tax_ID}${filt_contact_email}${filt_name}`;
    return filter.replace(/undefined/g, "");
  }

  resetFilters_item(item: string) {
    switch (item) {
      case "provider_type":
        this._provs.filterOpts_providers.provider_type= "";
        break;
      case "natial_tax_ID":
        this._provs.filterOpts_providers.natial_tax_ID = "";
        break;
      case "contact_email":
        this._provs.filterOpts_providers.contact_email = "";
        break;
      case "name":
        this._provs.filterOpts_providers.name = "";
        break;
      default:
        this._provs.filterOpts_providers = {
          provider_type: "",
          natial_tax_ID: "",
          contact_email: "",
          name: ""
        };

        break;
    }
    this.checkFilter();
  }

  /* Filter Opts */
  getFiltersOpts() {
    var country = this._gen.currCountry.toString();
    this._provs.filterOpts_providers.country = country.split(",");

    this._gen.getCountry().then( (data:any) => {
      this._gen.allCountries = data.data;
    })
  }

  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllProvidersCountFilt(this.checkFilterVal()).then(() => {
      this._provs
        .getAllProvidersFilter(
          this.checkFilterVal(),
          this.currPageView,
          this.currPage
        )
        .then((data: any) => {
          this.resetTable(data.data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  changePageInp(page: any, input: boolean = false) {
    if (input) {
      this.currPage = parseInt(page.target.value);
    } else {
      this.currPage = parseInt(page);
    }
    this._gen.tableLoader = true;

    this.getAllData()
  }

  onReloadPage(){
    this.getAllData();
  }

  /* EDIT */
  editBtnFx() {
    this._provs.editId = this._provs.sel_provider[0]?.id_number;
    this._provs.editView = !this._provs.editView;
    this._provs.originalSelectedItem = {...this._provs.sel_provider[0]};
    
  }

  cancelBtnFx() {
    this._provs.editId = "";
    this._provs.editView = !this._provs.editView;
  }

}
