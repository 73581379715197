import { Pipe, PipeTransform } from '@angular/core';
import { GeneralService } from '../services/general.service';

@Pipe({
  name: 'idCountry'
})
export class IdCountryPipe implements PipeTransform {

  private val:string = "";
  private name:string = "123";

  constructor( public _gen:GeneralService ) {
  }

  transform(value: any, ...args: unknown[]): unknown {
    for (let f = 0; f < this._gen.allCountries.length; f++) {
      const element = this._gen.allCountries[f];
      
      this.name = element;

      if ( element.country_id.toString() == value.toString() ) {                
        this.val = element.name;
      }

    }
    return this.val;
    // return  `${ this.val } - ${ value } - ${ this.name } | ${ typeof(value) }`;

    // return value;
  }

}
