import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { GeneralService } from 'src/app/services/general.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styles: [
  ]
})
export class FileUploadComponent implements OnInit {

  fileUp_cat:FormGroup = new FormGroup({
    file: new FormControl('', Validators.required )
  });
  fileUp_inv:FormGroup = new FormGroup({
    fileType: new FormControl(null, Validators.required ),
    file: new FormControl('', Validators.required),
    fileSource: new FormControl('', Validators.required)
  });

  fileTypes:any[] = [];

  bttmTxt:string = "Selecciona tu archivo y sube todos los productos o inventarios en un mismo lugar, de igual forma podrás agregar un solo regístro en la revisión del archivo"

  loadingFile:boolean = false;

  constructor( public iziToast: Ng2IzitoastService, public _gen:GeneralService ) { }

  ngOnInit(): void {
    this.gettypes();

    this._gen.currCountryObs.subscribe((country) => {
      // console.log(country)
      this.gettypes();
    })
  }

  onFileSelect_cat( evt:any ) {
    if (evt.target.files.length > 0) {
      const file = evt.target.files[0];
      console.log( file );
    }
  }
  onUp_cat( form:FormGroup ) {
    console.log( form );
    this.iziToast.show({title: "Form sended"});
  }
  
  onFileSelect( evt:any ) {
    this.loadingFile = true;

    if (evt.target.files.length > 0) {
      const file = evt.target.files[0];
      this.fileUp_inv.patchValue({
        fileSource: file
      });
      const formData = new FormData();
      formData.append('file', this.fileUp_inv.get('fileSource')?.value);
      formData.append('file_type_id', this.fileUp_inv.get('fileType')?.value);
      
      this._gen.sendFile( formData )
      .then( ({data}) => {
        this.loadingFile = false;
        // console.log( data );

        Swal.fire({
          title: 'Archivo cargado exitosamente',
          html: `El archivo ${file.name} fue cargado con exito <br><br> Items en el archivo: ${ data.rows_in_file } <br> Items actualizados: ${ data.rows_affected }`,
          icon: 'success',
    
          willClose: () => {
            this.fileUp_inv.controls['fileType'].setValue( null );
            this.fileUp_inv.controls['file'].setValue( "" );
            this.fileUp_inv.controls['fileSource'].setValue( "" );
          }
        })

      } ).catch( (err) => {
        this.loadingFile = false;
        console.log( `Error: ${err.response.data.error}` );
        
        Swal.fire({
          title: 'Error al cargar archivo',
          html: `El archivo ${file.name} que intentas subir presenta errores o no es compatible`,
          icon: 'error',
    
          willClose: () => {
            this.fileUp_inv.controls['file'].setValue( "" );
            this.fileUp_inv.controls['fileSource'].setValue( "" );
          }
        })
      })
    }
  }
  onUp_inv( form:FormGroup ) {
    console.log( form );
    this.iziToast.show({title: "Form sended"});
  }

  gettypes() {
    this._gen.getFileTypes().then( ({data}) => {
      this.fileTypes = data;
    })
  }

}
