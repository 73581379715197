import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-prod-status',
  templateUrl: './prod-status.component.html',
  styleUrls: []
})
export class ProdStatusComponent implements OnInit {

  @Input() type:string = '';
  @Input() info:any = [];
  @Input() prod:any = [];

  mainInfo:any = {
    skuFull: 0,
    item_id: 0,
    prodName: "prod name",
    from: [],
    to: [],
    qty: 0
  }

  newInput:FormGroup = new FormGroup({
    orderId: new FormControl(null, [Validators.required] ),
    oldStatus: new FormControl(null, [Validators.required] ),
    qty: new FormControl({ value: '', disabled: true }, [Validators.required] ),
    newStatus: new FormControl('', [Validators.required] ),
    order_product_id: new FormControl('', [Validators.required] ),
  });;

  inputs:FormGroup = new FormGroup({
    statusField: new FormControl('', [Validators.required]),
    dateField: new FormControl('', [Validators.required]),
    qtyField: new FormControl('')
  })

  get statusField(){
    return this.inputs.get('statusField')
  }

  get dateField(){
    return this.inputs.get('dateField')
  }

  get qtyField(){
    return this.inputs.get('qtyField')
  }
 

  agreeField = new FormControl(false)
  optionField = new FormControl('')
  optionField2 = new FormControl('')
  allStatus:any[] = [];
  qtyArr:any[] = [];
  minDate:Date = new Date();
  showLoader:boolean = false;

  statusPedView:boolean = false;

  changeDisabled:boolean = false;

  disabledButton:boolean = false;

  constructor( 
    public _gen:GeneralService, 
    public _ord:OrdersService, 
    public _ped: PedidosService,
    private datePipe: DatePipe 
  ) { }

  ngOnInit(): void {
    // console.log( this.info );
    console.log('type -> ', this.type)
    this.showLoader = true;
    this._gen.getProdsStatuses().then( () => {
      this.getAllInfo();
      this.newInput.controls['order_product_id'].setValue( this.prod.order_product_id );
    });
    
    this.setConditionalValidatorForQty('')

    this.inputs.controls['statusField'].valueChanges
      .subscribe(value => {
        // '6' es el value del tipo de entrada Devoluciones
        this.setConditionalValidatorForQty(value)
        
       });

  }
  
  async getAllInfo() {    
    this.mainInfo.prodName = this.prod.prod.product.name;
    this.mainInfo.from = this.prod.statuses;
    this.mainInfo.to = this._gen.allProdStatus;
    this.mainInfo.skuFull = this.prod.prod.item_id;
    this.mainInfo.item_id = this.prod.item_id;
    this.mainInfo.qty = this.prod.quantity;
    this.qtyArr = Array.from({length: this.prod.quantity}, (_, i) => i + 1)
    console.log({minDate: this.minDate})
    console.log( this.mainInfo.item_id );
    this.showLoader = false;
  }

  onChangeStat( evt:any ) {
    const result = this.mainInfo.from.find( (obj:any) => obj.status.name === evt );

    for (let d = 0; d < result.quantity; d++) {
      this.qtyArr.push( d + 1 );
    }
    console.log( this.qtyArr );

  }
  onChangeOrder(evt:any) { 
    // console.log( evt );
    this._ord.geOrdertByReference( evt, this._gen.currCountry ).then( ({data}) => {
      this._ord.getAllProdsById( data[0].order_id ).then( ({data}) => {
        console.log( data );
        
        const result = data.products.find( (obj:any) => obj.item.item_id === this.mainInfo.item_id );

        console.log( result );
        
        this.mainInfo.from = result.statuses;        
        this.newInput.controls['order_product_id'].setValue( result.order_product_id );
        this.statusPedView = true;

        // console.log( this.mainInfo );
        
      })
    })
  }

  changeStatus() {
    this.changeDisabled = !this.changeDisabled;

    let prodData = {
      order_product_id: this.newInput.controls.order_product_id.value,
      quantity: this.newInput.controls.qty.value,
      status_from: this.newInput.controls.oldStatus.value,
      status_to: this.newInput.controls.newStatus.value
    };

    // console.log( prodData );

    this._ord.changeOrderProdStatus( prodData )
    .then( ({data}) => {
      
      Swal.fire(
        'Cambio de estado exitoso',
        ``,
        'success'
      ).then( () => {
        this.closeModal();

        this.changeDisabled = !this.changeDisabled;
      })

    }).catch( (error:any) => {
      let data = error.response.data;

      Swal.fire(
        'Error al crear orden de compra',
        `${data.error}`,
        'error'
      )
      
    })

  }

  closeModal() {
    this._gen.prodStatusView = false;

    this._gen._prodStatusViewChange.next( false );
  }

  saveNewState(){
    if(this.inputs.valid){
      this.changeDisabled = !this.changeDisabled;
      
      const data: any = {
        item_id: this.prod.item_id,
        status_to: this.inputs.get('statusField')?.value,
        delivery_promise_date: this.datePipe.transform(this.inputs.get('dateField')?.value,'yyyy-MM-dd'),
      }
      if(this.inputs.get('qtyField')?.value === '') {
        data.quantity = 0;
      } else{
        data.quantity = this.inputs.get('qtyField')?.value;
      }
      

      this._ped.changeStatusSpecialImport(data)
      .then( response => {
        this.changeDisabled = !this.changeDisabled;
        this.swalEditMssg('Cambio de estado exitoso', 'success')
      }).catch( (error:any) => {
        this.changeDisabled = !this.changeDisabled;
        console.log({error})
        this.swalEditMssg(error.response.data[0]? error.response.data[0]: 'Error al editar estado', 'error');
      })

    } else {
      this.inputs.markAllAsTouched()
    }
  }

  swalEditMssg( ttl:string, kind:any='success') {
    return Swal.fire({
      title: ttl,
      icon: kind,
    }).then( () => {
      this.closeModal()
    });
  }

  setValidatorRequired(campo:any, requerido:any) {
    this.inputs.controls[campo].setValidators(requerido);
    this.inputs.controls[campo].updateValueAndValidity();
  }

  setConditionalValidatorForQty(value:any) {
    if(value == 'import' && this.prod.qty_import > 0){
      this.setValidatorRequired('qtyField', null);
      return;
    } else if (value == 'special' && this.prod.qty_special > 0) {
      this.setValidatorRequired('qtyField', null);
      return;
    } else if (value !== '' && value !== 'report') {
      this.setValidatorRequired('qtyField', Validators.required);
      return;
    } else {
      this.setValidatorRequired('qtyField', null);
    }
  }

}
