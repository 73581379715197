
<div class="topBarWrap">
    <div class="topBarContent">
        <div class="box_left">
            logo
        </div>
        <div class="box_right">
            <div class="userWrap">
                <div class="userBtn" (click)="_gen.topbar_user_menu = !_gen.topbar_user_menu">
                    <div class="img">
                        <img src="https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9" alt="">
                    </div>
                    <div class="icon">
                        <i class="fi fi-caret-up" *ngIf="_gen.topbar_user_menu"></i>
                        <i class="fi fi-caret-down" *ngIf="!_gen.topbar_user_menu"></i>
                    </div>
                </div> 

                <div class="menu animate__animated animate__fadeInRight" *ngIf="_gen.topbar_user_menu">
                    <ul>
                        <!-- <li>
                            <a class="btn btn_sm" href="#">Mi cuenta</a>
                        </li> -->
                        <li>
                            <a class="btn btn_sm btn_error logout" href="#">Cerrar sesión</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>