import { Component, OnInit } from '@angular/core';
import { ElectronicInvoicesService } from 'src/app/services/electronic-invoices.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-electronic-invoices',
  templateUrl: './electronic-invoices.component.html'
})
export class ElectronicInvoicesComponent implements OnInit {

  tableType: string = "electronic_invoices";
  columnsConfig = [
    {
      varId: "document_identifier",
      label: "Identificador del documento",
      visible: true,
      type: "document_identifier",
      class: "longCell ellipsis",
      disabled: true,
    },
    { varId: "consecutive",
      label: "Consecutivo",
      visible: true,
      type: "consecutive",
      class: "",
      disabled: true
    },
    {
      varId: "tax_classification_document",
      label: "Clasificación Fiscal del documento",
      visible: true,
      type: "tax_classification_document",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "iva",
      label: "IVA",
      visible: true,
      type: "iva",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "total_amount",
      label: "Importe total",
      visible: true,
      type: "total_amount",
      class: "",
      disabled: true,
    },
    {
      varId: "fiscal_identifier",
      label: "Identificador Fiscal",
      visible: true,
      type: "fiscal_identifier",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "name",
      label: "Nombre",
      visible: true,
      type: "name",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "document_type",
      label: "Tipo documento",
      visible: true,
      type: "document_type",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "date_issue",
      label: "Fecha emisión",
      visible: true,
      type: "date_issue",
      class: "noEllipsis",
      disabled: true,
    },
    {
      varId: "document_status",
      label: "Estado documento",
      visible: true,
      type: "document_status",
      class: "longCell noEllipsis",
      disabled: true,
    }
  ];

  dataSourcefull: any[] = [];

  colsView: boolean = false;
  filtsView: boolean = false;

  dataSourceCount = 0;
  currPage = 1;
  currPageView = 100;
  currPageTotal = 0;

  dataSource: any[] = [];

  listProviders: any = [];

  maxDate: Date = new Date(Date.now())

  constructor(
    public _gen: GeneralService,
    public _electInv: ElectronicInvoicesService
  ) {}

  ngOnInit(): void {
    this.getAllData();
    this.getFiltersOpts();
    this._gen.currCountryObs.subscribe((country) => {
      this.getAllData();
      this.getFiltersOpts();
      this.colsView = false;
      this.filtsView = false;
    })
  }

  getAllData() {
    this._gen.tableLoader = true;
    this.getAllElectronicInvoicesCountFilt(this.checkFilterVal()).then(() => {
      this._electInv.getAllElectronicInvoicesFilter(this.checkFilterVal(), this.currPageView, this.currPage).then(({ data }) => {
        this.resetTable(data).then((data: any) => {
          this._gen.tableLoader = false;
        });
      });
    })
  }

  /* PAGE COUNT */
  getAllElectronicInvoicesCountFilt(filt: any) {
    let prom = new Promise((resolve, reject) => {
      this._electInv.getAllElectronicInvoicesCountFilt(filt).then((data: any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil(
          this.dataSourceCount / this.currPageView
        );
        if (this.currPageTotal == 0) {
          this.currPageTotal = 1;
        }
        if (this.currPage > this.currPageTotal) {
          this.currPage = this.currPageTotal;
        }
        resolve(true);
      });
    });
    return prom;
  }

  resetTable(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];

      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element: any = this.dataSourcefull[d];
        let item = {
          document_identifier: element.document_identifier,
          tax_classification_document: element.fiscal_classification_document,
          invoice_value: element.invoice_value,
          iva: element.iva,
          total_amount: element.total,
          fiscal_identifier: element.tax_id,
          name: element.name,
          document_type: element.document_type,
          date_issue: element.broadcast_date,
          document_status: element.document_status,
          consecutive: element.consecutive
        };
        this.dataSource.push(item);
      }

      resolve(true);
    });

    return prom;
  }

  resetTobTable(type: string) {
    setTimeout(() => {
      if (type == "colsView") {
        this.filtsView = false;
        if (this.colsView) {
          this.colsView = false;
        } else {
          this.colsView = true;
        }
      } else if (type == "filtsView") {
        this.colsView = false;
        if (this.filtsView) {
          this.filtsView = false;
        } else {
          this.filtsView = true;
        }
      }
    }, 150);
  }

  /* filters */
  checkFilterVal() {
    let filt_country,
      filt_consecutive,
      filt_name,
      filt_date_issue_from,
      filt_date_issue_to,
      filt_tax;

    filt_country = `country=${this._gen.currCountry}&`;

    if (this._electInv.filterOpts_electronic_invoices.consecutive.trim().length > 0) {
      filt_consecutive = `consecutive=${this._electInv.filterOpts_electronic_invoices.consecutive.trim()}&`;
    }
    if (this._electInv.filterOpts_electronic_invoices.name.trim().length > 0) {
      filt_name = `name=${this._electInv.filterOpts_electronic_invoices.name.trim()}&`;
    }
    if (this._electInv.filterOpts_electronic_invoices.tax.trim().length > 0) {
      filt_tax = `tax=${this._electInv.filterOpts_electronic_invoices.tax.trim()}&`;
    }
    if (this._electInv.filterOpts_electronic_invoices.date_issue_from) {
      filt_date_issue_from = `created_at_from=${this._gen.getDateFormat(this._electInv.filterOpts_electronic_invoices.date_issue_from)}&`;
    }
    if (this._electInv.filterOpts_electronic_invoices.date_issue_to) {
      filt_date_issue_to = `created_at_to=${this._gen.getDateFormat(this._electInv.filterOpts_electronic_invoices.date_issue_to)}&`;
    }

    let filter = `${filt_country}${filt_consecutive}${filt_name}${filt_date_issue_from}${filt_date_issue_to}${filt_tax}`;
    return filter.replace(/undefined/g, "");
  }

  resetFilters_item(item: string) {
    switch (item) {
      case "consecutive":
        this._electInv.filterOpts_electronic_invoices.consecutive= "";
        break;
      case "name":
        this._electInv.filterOpts_electronic_invoices.name = "";
        break;
      case "date_issue_from":
        this._electInv.filterOpts_electronic_invoices.date_issue_from = "";
        break;
      case "date_issue_to":
        this._electInv.filterOpts_electronic_invoices.date_issue_to = "";
        break;
      case "tax":
        this._electInv.filterOpts_electronic_invoices.tax = "";
        break;
      default:
        this._electInv.filterOpts_electronic_invoices = {
          consecutive: "",
          name: "",
          date_issue_from: "",
          date_issue_to: "",
          tax: ""
        };

        break;
    }
    this.checkFilter();
  }

  /* Filter Opts */
  getFiltersOpts() {
    var country = this._gen.currCountry.toString();
    this._electInv.filterOpts_electronic_invoices.country = country.split(",");

    this._gen.getCountry().then( (data:any) => {
      this._gen.allCountries = data.data;
    })
  }

  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllElectronicInvoicesCountFilt(this.checkFilterVal()).then(() => {
      this._electInv
        .getAllElectronicInvoicesFilter(
          this.checkFilterVal(),
          this.currPageView,
          this.currPage
        )
        .then((data: any) => {
          this.resetTable(data.data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  changePageInp(page: any, input: boolean = false) {
    if (input) {
      this.currPage = parseInt(page.target.value);
    } else {
      this.currPage = parseInt(page);
    }
    this._gen.tableLoader = true;

    this.getAllData()
  }

  onReloadPage(){
    this.getAllData();
  }

}
