import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { FormControl, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { OrdersService } from 'src/app/services/orders.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-assign-provider',
  templateUrl: './assign-provider.component.html',
  styleUrls: [
  ] 
})
export class AssignProviderComponent implements OnInit {

  @Input() skuFull:string = '';
  @Input() providersBySkuFull:any = [];
  @Output() reloadTable = new EventEmitter();

  showLoader:boolean = false;
  listProviders:any = [];

  inputs:FormGroup = new FormGroup({
    provider: new FormControl('', [Validators.required]),
    priority: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/),this.priorityProviderValidator()]),
    sap: new FormControl('')
  })

  constructor(
    public _gen: GeneralService,
    private _ord: OrdersService
  ) { }

  ngOnInit(): void {
    this.getAllProviders()
  }

  getAllProviders(){
    this._gen.generalLoader = true;
    return this._ord.getAllProviders( this._gen.currCountry, true ).then( ({data}) => {
      if(this.providersBySkuFull.length === 0) {
        this.listProviders = data;
      } else {
        this.listProviders = this.filterListProviders(data)
      }
      this._gen.generalLoader = false;
    })
  }

  compareProviders(objeto1:any, objeto2:any) {
    return objeto1.provider_id === objeto2.provider_id 
  }

  filterListProviders(data:any){
    let resultado = data.filter((objetoA:any) => !this.providersBySkuFull.some((objetoB:any) => this.compareProviders(objetoA, objetoB)));
    return resultado;
  }

  closeModal() {
    this._gen.assignProviderView = false;
  }

  get providerField(){
    return this.inputs.get('provider')
  }

  get priorityField(){
    return this.inputs.get('priority')
  }

  save(event:any){
    if(this.inputs.valid){
      
      const data:any = {
        provider_id: this.inputs.controls.provider.value,
        sku_full_id: this.skuFull,
        priority: this.inputs.controls.priority.value
      }

      if(this.inputs.controls.sap.value.length > 0) {
        data.sap = this.inputs.controls.sap.value;
      }

      this._ord.assignProviderToSkuFull(data).then( ({data}) => {
        Swal.fire(
          'Se asigna el proveedor de forma exitosa',
          ``,
          'success'
        ).then( () => {
          this.closeModal();
          this.reloadTable.emit()
        })
        .catch( (error:any) => {
          Swal.fire(
            'Error al asignar proveedor',
            `${error}`,
            'error'
          )
        })
      })
      

    } else {
      this.inputs.markAllAsTouched()
    }
  }

  priorityProviderValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const prioritiesUsed = this.providersBySkuFull.map((item:any) => `${item.priority}`);
      const valor = control.value;
      if (prioritiesUsed.includes(valor)) {
        return { priority: true };
      } else {
        return null;
      }
    };
  }

}
