import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: []
})
export class CardComponent implements OnInit {

  @Input() label:string = "";
  @Input() count:string = "";

  constructor() {
  }

  ngOnInit(): void {
  }

}
