import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import axios from 'axios';
import { GeneralService } from './general.service';
import { LoginService } from './login.service';
import { saveAs } from 'file-saver';
import { tap, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  sel_orders:any[] = [];
  editView:boolean = false;
  editId:string = "";
  
  filt_country:any[] = [];
  filt_bUnit:any[] = [];
  filt_provider:any[] = [];
  filt_reason:any[] = [];
  filt_orderStatus:any[] = [];

  filterOpts:any = {
    country: [],
    business_unit: [],
    provider: [],
    status: [],
    reason: [],
    from: "",
    to: "",
    order_number: "",
    reference: "",
    order_provider: ""
  }
  
  filterOptsPen:any = {
    order_number: "",
    country: [],
    business_unit: [],
    provider: [],
    manufacturer: [],
    sku_full: "",
    sku: "",
    name: "",
    priority: [],
  }
  
  detailView:boolean = false;
  detailId:any;
  
  editPendingId:string = "";
  sel_pending:any[] = [];

  _cleanInvetoryForm: Subject<boolean> = new Subject<any>();
  cleanInvetoryFormObs = this._cleanInvetoryForm.asObservable();

  urlBaseFiles = `https://s3.us-east-2.amazonaws.com/lentesplus.wms/${isDevMode() ? 'staging': 'production'}/receptions/`

  constructor( public _gen:GeneralService, public _log:LoginService, public http: HttpClient ) {}

  /* COUNT */
  getAllProdsCountFilt( filters:any) {    
    return axios.get(`${this._gen.serverURL}/providers/order/?${filters}&count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllProdsCountFiltEntry( filters:any) {    
    return axios.get(`${this._gen.serverURL}/providers/order/?${filters}&count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllProdsCountFiltExit( filters:any) {    
    return axios.get(`${this._gen.serverURL}/providers/order/?${filters}&count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  /* products */
  getAllProdsFilter( filters:any, limit:any = 100, page:number = 1) {
    return axios.get(`${this._gen.serverURL}/providers/order/?${filters}limit=${limit}&page=${page}&products=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  /* GET BY ID */
  getAllProdsById( id:any ) {    
    return axios.get(`${this._gen.serverURL}/providers/order/${id}/?products=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  geOrdertByReference( ref:any, countryId:number ) {    
    return axios.get(`${this._gen.serverURL}/providers/order/?reference=${ref}&country=${countryId}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }


  /* ENTRIES GET FX */
  getAllOrdsFilterSelect( countryId:number ) {
    return axios.get(`${this._gen.serverURL}/providers/order/?country=${countryId}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllOrdsFilterSelectEntry( countryId:number ) {
    return axios.get(`${this._gen.serverURL}/providers/order/?country=${countryId}&status=created&type=entry,cross`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllOrdsFilterSelectEntryReason( countryId:number, reason:number ) {
    return axios.get(`${this._gen.serverURL}/providers/order/?country=${countryId}&status=created,partial,news&reason_id=${reason}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllOrdsFilterSelectExit( countryId:number ) {
    return axios.get(`${this._gen.serverURL}/providers/order/?country=${countryId}&status=created&type=exit`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllEntryBodega( countryId:number ) {
    return axios.get(`${this._gen.serverURL}/providers/provider/?country=${countryId}&stock=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllstockReasons( type:any ) {
    return axios.get(`${this._gen.serverURL}/stock/reason/?type=${type}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  getAllProviders( countryId:number, isCrossDocking:boolean ) {
    let url = `${this._gen.serverURL}/providers/provider/?country=${countryId}`
    if(isCrossDocking){
      url = url.concat('&stock=0')
    }
    return axios.get(`${url}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }



  getOrdsFilterById( ordId:number ) {
    return axios.get(`${this._gen.serverURL}/providers/order/${ordId}/`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  getOrdsFilterByExternalReference( externalReference:string, countryId:number, statusOrderFilter:string ) {
    return axios.get(`${this._gen.serverURL}/providers/order/?external_reference=${externalReference}&country=${countryId}&status=${statusOrderFilter}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  getOpenOrdsByProviderAndExternalReference( providerId:number, countryId:number, externalReference: string, statusOrderFilter:string ) {
    return axios.get(`${this._gen.serverURL}/providers/order/?provider=${providerId}&country=${countryId}&status=${statusOrderFilter}&external_reference=${externalReference}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
 
  getOpenOrdsByProvider( providerId:number, countryId:number, statusOrderFilter:string ) {
    return axios.get(`${this._gen.serverURL}/providers/order/?provider=${providerId}&country=${countryId}&status=${statusOrderFilter}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
 
  genEntryLoteId( country_id:number, number:string, ean:string, expiration_date:string ) {    
    return axios.post(`${this._gen.serverURL}/stock/lote/`, {
      "country_id": country_id,
      "number": number,
      "ean": ean,
      "expiration_date": expiration_date
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  genEntryScan( order_id:number, reason_id:number, comments:string ) {
    return axios.post(`${this._gen.serverURL}/stock/scan/`, {
      "order_id": order_id,
      "reason_id": reason_id,
      "comments": comments
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  genEntryScanNoId( reason_id:number, comments:string ) {
    return axios.post(`${this._gen.serverURL}/stock/scan/`, {
      "reason_id": reason_id,
      "comments": comments
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  genInventoryEntryScanNoId( reason_id:number, reception_id: number, comments:string, order_id: string|number ) {
    return axios.post(`${this._gen.serverURL}/stock/scan/`, {
      "reason_id": reason_id,
      "reception_id": reception_id,
      "order_id": order_id,
      "comments": comments
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  genPedScan( sale_id:number, reason_id: number ) {
    return axios.post(`${this._gen.serverURL}/stock/scan/`, {
      "sale": sale_id,
      "reason_id": reason_id
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  genEntry( scan_id:number, lote_id:number, bodega:number ) {    
    return axios.post(`${this._gen.serverURL}/stock/entry/`, {
      "scan_id": scan_id,
      "lote_id": lote_id,
      "provider_id": bodega
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  genExit( scan_id:number, lote_id:number ) {    
    return axios.post(`${this._gen.serverURL}/stock/exit/`, {
      "scan_id": scan_id,
      "lote_id": lote_id
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  genAdd( scan_id:number, lote_id:number, bodega:number, bodegaExit:number , quantity:number ) {    
    return axios.post(`${this._gen.serverURL}/stock/add/`, {
      "scan_id": scan_id,
      "lote_id": lote_id,
      "quantity": quantity,
      "provider_id": bodega,
      "provider_id_transfer": bodegaExit
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  cancelOrder( order_id:number, comment:any ) {    
    return axios.post(`${this._gen.serverURL}/providers/cancel_order/`, {
      "order_id": order_id,
      "comment": comment
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }


  /* PENDIENG PRODUCTS */
  /* COUNT */
  getAllPendingProdsCountFilt( filters:any) {    
    return axios.get(`${this._gen.serverURL}/providers/pending_products/?${filters}&count=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  /* products */
  getAllPendingProdsFilter( filters:any, limit:any = 100, page:number = 1) {
    var url = `${this._gen.serverURL}/providers/pending_products/?${filters}limit=${limit}&page=${page}&products=1`;
    let encoded = encodeURIComponent( url );
    console.log( encoded );
    
    return axios.get(`${ url }`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  assignProviderToSkuFull(data:any) {
    return axios.post(`${this._gen.serverURL}/providers/provider_product/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  createOrder( data:any ) {
    return axios.post(`${this._gen.serverURL}/providers/order/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  updateOrder( data:any ) {
    return axios.put(`${this._gen.serverURL}/providers/order/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  updateProviderReferenceOrder( data:any ) {
    return axios.put(`${this._gen.serverURL}/providers/order/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }
  
  changeOrderProdStatus( data:any ) {
    return axios.put(`${this._gen.serverURL}/providers/order_product_status_change/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  changeOrderProdComment( data:any ) {
    return axios.put(`${this._gen.serverURL}/magento/sale_product/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  downloadFiles(name:string, url:string, type: string) {
    return this.http.get(url, {responseType: 'blob'})
    .pipe(
      tap( content => {
        const blob = new Blob([content], {type});
        saveAs(blob, name)
      }),
      map(() => true)
    )
  }

}
