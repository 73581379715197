import { Component, OnInit } from "@angular/core";
import { InventoryService } from "src/app/services/inventory.service";
import { GeneralService } from "src/app/services/general.service";
import { CatalogService } from "src/app/services/catalog.service";

@Component({
  selector: "app-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: [],
})
export class ConfigurationComponent implements OnInit {
  inv_type: string = "Configuración";
  tableType: string = "configuration";
  filtsView: boolean = false;
  colsView: boolean = false;

  dataSource: any[] = [];
  dataSourcefull: any[] = [];

  columnsConfig = [
    {
      varId: "name",
      label: "Nombre",
      visible: true,
      type: "name",
      class: "longCell noEllipsis",
      disabled: false,
    },
    {
      varId: "sku_full",
      label: "Sku full",
      visible: true,
      type: "sku_full",
      class: "longCell noEllipsis",
      disabled: false,
    },
    {
      varId: "maximum_stock",
      label: "Stock máximo",
      visible: true,
      type: "maximum_stock",
      class: "",
      disabled: false,
    },
    {
      varId: "minimum_stock",
      label: "Stock mínimo",
      visible: true,
      type: "minimum_stock",
      class: "",
      disabled: false,
    },
    {
      varId: "backorder",
      label: "Backorder",
      visible: true,
      type: "backorder",
      class: "",
      disabled: false,
    },
    {
      varId: "stock_status",
      label: "Stock Status",
      visible: true,
      type: "stock_status",
      class: "",
      disabled: false,
    },
  ];

  dataSourceCount = 0;
  currPage = 1;
  currPageView = 100;
  currPageTotal = 0;

  constructor(
    public _inv: InventoryService,
    public _gen: GeneralService,
    public _cat: CatalogService
  ) {}

  ngOnInit(): void {
    this.getAllProds();
    this.getFiltersOpts();
    this._gen.currCountryObs.subscribe((country) => {
      this.getAllProds();
      this.getFiltersOpts();
      this.resetFilters_item('');
    });
  }

  resetTable(arrFull: any[]) {
    let prom = new Promise((resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];

      if (this.dataSourcefull) {
        for (let d = 0; d < this.dataSourcefull.length; d++) {
          const element: any = this.dataSourcefull[d];
          let item = {
            sku_full: element.sku_full.sku_full,
            name: element.sku_full.product.name,
            maximum_stock: element.maximum_stock,
            minimum_stock: element.minimum_stock,
            backorder: element.backorder,
            stock_status: element.stock_status,
          };
          this.dataSource.push(item);
        }
      }
      resolve(true);
    });

    return prom;
  }

  /* PAGE COUNT */
  getCountAllProducts(filt: any) {
    let prom = new Promise((resolve, reject) => {
      this._inv.getCountAllProducts(filt).then((data: any) => {
        this.dataSourceCount = data.data.count;
        this.currPageTotal = Math.ceil(
          this.dataSourceCount / this.currPageView
        );

        if (this.currPageTotal == 0) {
          this.currPageTotal = 1;
        }
        if (this.currPage > this.currPageTotal) {
          this.currPage = this.currPageTotal;
        }

        resolve(true);
      });
    });
    return prom;
  }

  /* RESET Filters */
  resetFilters_item(item: string) {
    switch (item) {
      case "sku_full":
        this._inv.filterOpts_config.sku_full = "";
        break;
      case "sku":
        this._inv.filterOpts_config.sku = "";
        break;
      case "name":
        this._inv.filterOpts_config.name = "";
        break;
      case "product_type":
        this._inv.filterOpts_config.type = [];
        break;
      case "brand":
        this._inv.filterOpts_config.brand = [];
        break;
      case "manufacturer":
        this._inv.filterOpts_config.manufacturer = [];
        break;
      case "stock_status":
        this._inv.filterOpts_config.stock_status = "";
        break;
      default:
        this._inv.filterOpts_config = {
          sku_full: "",
          sku: "",
          name: "",
          type: [],
          brand: [],
          manufacturer: [],
          stock_status: ""
        };

        break;
    }
    this.checkFilter();
  }

  checkFilterVal() {
    let filt_country,
      filt_sku_full,
      filt_name,
      filt_product_type,
      filt_brand,
      filt_manufacturer,
      filt_sku,
      filt_stock_status;

    filt_country = `country=${this._gen.currCountry}&`;

    if (this._inv.filterOpts_config.name) {
      filt_name = `name=${this._inv.filterOpts_config.name.trim()}&`;
    }
    if (this._inv.filterOpts_config.type.length) {
      filt_product_type = `type=${this._inv.filterOpts_config.type}&`;
    }
    if (this._inv.filterOpts_config.manufacturer.length) {
      filt_manufacturer = `manufacturer=${this._inv.filterOpts_config.manufacturer}&`;
    }
    if (this._inv.filterOpts_config.brand.length) {
      filt_brand = `brand=${this._inv.filterOpts_config.brand}&`;
    }
    if (this._inv.filterOpts_config.sku_full) {
      filt_sku_full = `sku_full=${encodeURIComponent(
        this._inv.filterOpts_config.sku_full.toUpperCase().trim()
      )}&`;
    }
    if (this._inv.filterOpts_config.sku) {
      filt_sku = `sku=${encodeURIComponent(
        this._inv.filterOpts_config.sku.toUpperCase()
      )}&`;
    }
    if (this._inv.filterOpts_config.stock_status) {
      filt_stock_status = `stock_status=${this._inv.filterOpts_config.stock_status}&`;
    }

    let filter = `${filt_country}${filt_name}${filt_product_type}${filt_manufacturer}${filt_brand}${filt_sku_full}${filt_sku}${filt_stock_status}`;
    return filter.replace(/undefined/g, "");
  }

  checkFilter() {
    this._gen.tableLoader = true;
    this.filtsView = false;
    this.getFiltersOpts();

    this.getCountAllProducts(this.checkFilterVal()).then(() => {
      this._inv
        .getAllProducts(this.checkFilterVal(), this.currPageView, this.currPage)
        .then((data: any) => {
          this.resetTable(data.data).then((data: any) => {
            // console.log( data );
            this._gen.tableLoader = false;
          });
        });
    });
  }

  getAllProds() {
    this._gen.tableLoader = true;
    this.getCountAllProducts(this.checkFilterVal()).then(() => {
      this._inv
        .getAllProducts(this.checkFilterVal(), this.currPageView, this.currPage)
        .then((data: any) => {
          this.resetTable(data.data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  /* RESET */
  resetTobTable(type: string) {
    setTimeout(() => {
      if (type == "colsView") {
        this.filtsView = false;
        if (this.colsView) {
          this.colsView = false;
        } else {
          this.colsView = true;
        }
      } else if (type == "filtsView") {
        this.colsView = false;
        if (this.filtsView) {
          this.filtsView = false;
        } else {
          this.filtsView = true;
        }
      }
    }, 150);
  }

  /* Filter Opts */
  getFiltersOpts() {
    var manufact = this._inv.filterOpts_config.manufacturer.join();
    var country = this._gen.currCountry.toString();
    this._inv.filterOpts_config.country = country.split(",");

    this._gen.getCountry().then((data: any) => {
      this._gen.allCountries = data.data;
    });
    this._gen.getProdType().then(({ data }) => {
      this._gen.allProdTypes = data;
    });
    this._cat.getBrands(manufact).then((data: any) => {
      this._inv.filt_brand = data.data;
    });
    this._cat.getManufact().then((data: any) => {
      this._inv.filt_manufac = data.data;
    });
  }

  changePageInp(page: any, input: boolean = false) {
    if (input) {
      this.currPage = parseInt(page.target.value);
    } else {
      this.currPage = parseInt(page);
    }
    this._gen.tableLoader = true;

    this.getCountAllProducts(this.checkFilterVal()).then(() => {
      this._inv
        .getAllProducts(this.checkFilterVal(), this.currPageView, this.currPage)
        .then((data: any) => {
          this.resetTable(data.data).then((data: any) => {
            // console.log( data );
            this._gen.tableLoader = false;
          });
        });
    });
  }

  getAllProducts() {
    this._gen.tableLoader = true;
    this.getCountAllProducts(this.checkFilterVal()).then(() => {
      this._inv
        .getAllProducts(this.checkFilterVal(), this.currPageView, this.currPage)
        .then((data: any) => {
          this.resetTable(data.data).then((data: any) => {
            this._gen.tableLoader = false;
          });
        });
    });
  }

  /* EDIT */
  editBtnFx() {}
}
