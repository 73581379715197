import { Pipe, PipeTransform } from '@angular/core';
import { GeneralService } from '../services/general.service';

@Pipe({
  name: 'idProdtype'
})
export class IdProdtypePipe implements PipeTransform {

  private val:string = "";
  private name:string = "123";

  constructor( public _gen:GeneralService ) {
  }

  transform(value: any, ...args: unknown[]): unknown {
    for (let f = 0; f < this._gen.allProdTypes.length; f++) {
      const element = this._gen.allProdTypes[f];
      
      this.name = element;

      if ( element.type_id.toString() == value.toString() ) {                
        this.val = element.name;
      }

    }
    return this.val;
  }

}
