import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { TableService } from 'src/app/services/table.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-pendings',
  templateUrl: './pendings.component.html',
  styles: [
  ]
})
export class PendingsComponent implements OnInit {

  tableType:string = "pending";
  columnsConfig = [
    // { varId: "item_id", label: "Id", visible: true, type: "", class: "", disabled: true },
    { varId: "order_number", label: "Numero de pedido", visible: true, type: "uniqueId", class: "", disabled: true },
    { varId: "sku_full", label: "SKUFULL", visible: true, type: "", class: "longCell noEllipsis", disabled: true },
    { varId: "sku", label: "SKU", visible: true, type: "", class: "", disabled: true },
    { varId: "name", label: "Nombre", visible: true, type: "name", class: "longCell noEllipsis", disabled: true },
    { varId: "quantity", label: "Cantidad", visible: true, type: "qty", class: "idCell", disabled: true },
    { varId: "manufacturer", label: "Laboratorio", visible: true, type: "", class: "", disabled: true },
    { varId: "provider", label: "Proveedor", visible: true, type: "provider", class: "longCell", disabled: true },
    { varId: "comment", label: "Comentario", visible: true, type: "comment", class: "longCell", disabled: true },
  ]
  dataSourcefull:any[] = [];

  colsView:boolean = false;
  filtsView:boolean = false;

  btnAble:boolean = true;

  dataSourceCount = 0;
  currPage = 1; 
  currPageView = 100; 
  currPageTotal = 0; 

  dataSource:any[] = [];


  constructor( public _ord:OrdersService, public _gen:GeneralService, public _ped:PedidosService, public _inv:InventoryService, public _table:TableService ) { }

  ngOnInit(): void {
    this.getAllProds();
    this.getFiltersOpts();

    this._gen.currCountryObs.subscribe((country) => {
      // console.log(country)
      this.getAllProds();
      this.getFiltersOpts();
    })
  }

  /* Filter Opts */
  getFiltersOpts() {
    // var country = this._ord.filterOptsPen.country.join();

    var country = this._gen.currCountry.toString();
    this._ord.filterOptsPen.country = country.split(',');

    this._gen.getCountry().then( (data:any) => {      
      this._gen.allCountries = data.data;
    })

    this._gen.getBUnit( country ).then( (data:any) => {
      this._ord.filt_bUnit = data.data;
      this._ped.filt_bUnit = data.data;
    })

    this._gen.getProvider( country ).then( (data:any) => {
      this._ord.filt_provider = data.data;      
    })
    this._inv.getManufact().then( (data:any) => {
      this._inv.filt_manufac = data.data;
    })
  }

  /* PAGE COUNT */
  getAllProdsCountFilt( filt:any ) {
    let prom = new Promise ( ( resolve, reject ) => {
      this._ord.getAllPendingProdsCountFilt( filt ).then( ({data}) => {        
        this.dataSourceCount = data.length;        
        this.currPageTotal = Math.ceil( this.dataSourceCount / this.currPageView );      
        
        if ( this.currPageTotal == 0 ) {
          this.currPageTotal = 1;
        }
        if ( this.currPage > this.currPageTotal ) {
          this.currPage = this.currPageTotal
        }

        resolve( true );
      })
    })
    return prom;
  }

  /* RESET Filters */
  resetFilters_item(item:string) {
    switch (item) {
      case 'order_number':
        this._ord.filterOptsPen.order_number = [];
        break;
      // case 'country':
      //   this._ord.filterOptsPen.country = [];
      //   break;
      case 'business_unit':
        this._ord.filterOptsPen.business_unit = [];
        break;
      case 'provider':
        this._ord.filterOptsPen.provider = [];
        break;
      case 'sku_full':
        this._ord.filterOptsPen.sku_full = "";
        break;
      case 'sku':
        this._ord.filterOptsPen.sku = "";
        break;
      case 'name':
        this._ord.filterOptsPen.name = "";
        break;
      case 'priority':
        this._ord.filterOptsPen.priority = [];
        break;
      case 'manufacturer':
        this._ord.filterOptsPen.manufacturer = [];
        break;

      default:
        this._ord.filterOptsPen = {
          order_number: "",
          // country: [],
          business_unit: [],
          provider: [],
          manufacturer: [],
          sku_full: "",
          sku: "",
          name: "",
          priority: [],
        };

        break;
    }
    this.checkFilter();
  }
  /* RESET */
  resetTobTable( type:string ) {    
    setTimeout(() => {
      if ( type == 'colsView' ) {
        this.filtsView = false;
        if ( this.colsView ) { this.colsView = false } else { this.colsView = true }
      } else if ( type == 'filtsView' ) {
        this.colsView = false;
        if ( this.filtsView ) { this.filtsView = false } else { this.filtsView = true }
      }
    }, 150);
  }
  resetTable( arrFull:any[] ) {
    let prom = new Promise( (resolve, reject) => {
      this.dataSourcefull = arrFull;
      this.dataSource = [];

      // console.log( this.dataSourcefull );
      
                  
      for (let d = 0; d < this.dataSourcefull.length; d++) {
        const element:any = this.dataSourcefull[d];

        let item = {

          item_id: element.item_id,
          sale_id: element.sale_id,
          order_number: element.order_number,
          sku_full: element.sku_full,
          sku: element.sku,
          quantity: element.quantity,
          name: element.product_name,
          comment: element.comment,
          // provider: element.provider[0].name,
          providers: (element.provider) ? element.provider : [],
          currproviders: (element.provider) ? element.provider[0] : [],
          business_type: element.business_type,
          business_unit: element.business_unit,
          manufacturer: element.manufacturer,

        }
        this.dataSource.push( item );
        
      }
      
      resolve( true);
    })

    return prom;
  }

  /* PAGER FX */
  getAllProds() {
    this._gen.tableLoader = true;
    this.clearSelectedPendingsTable()
    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._ord.getAllPendingProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( ({data}) => {
        this.resetTable( data ).then( () => {
          this._gen.tableLoader = false;
        });
      })
    });
  }
  changePageInp( page:any, input:boolean = false ) {
    if ( input ) {
      this.currPage = parseInt( page.target.value );
    } else {
      this.currPage = parseInt( page );
    }
    this._gen.tableLoader = true;
    
    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._ord.getAllPendingProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });

  }
  

  /* filters */
  checkFilterVal() {
    let filt_country, filt_provider, filt_sku_full, filt_sku, filt_name, filt_business_unit, filt_order_number, filt_priority, filt_manufacturer;

    filt_country = `country=${ this._gen.currCountry }&`;

    if ( this._ord.filterOptsPen.order_number ) {
      filt_order_number = `order_number=${ this._ord.filterOptsPen.order_number }&`;
    }
    // if ( this._ord.filterOptsPen.country ) {
    //   filt_country = `country=${ this._ord.filterOptsPen.country.join() }&`;
    // }
    if ( this._ord.filterOptsPen.business_unit ) {
      filt_business_unit = `business_unit=${this._ord.filterOptsPen.business_unit}&`;
    }
    if ( this._ord.filterOptsPen.provider ) {
      filt_provider = `provider=${ this._ord.filterOptsPen.provider.join() }&`;
    }
    if ( this._ord.filterOptsPen.priority ) {
      filt_priority = `priority=${ this._ord.filterOptsPen.priority.join() }&`;
    }
    if ( this._ord.filterOptsPen.sku_full ) {
      filt_sku_full = `sku_full=${ encodeURIComponent(this._ord.filterOptsPen.sku_full.toUpperCase()) }&`;
    }
    if ( this._ord.filterOptsPen.sku ) {
      filt_sku = `sku=${ this._ord.filterOptsPen.sku }&`;
    }
    if ( this._ord.filterOptsPen.name ) {
      filt_name = `name=${ this._ord.filterOptsPen.name }&`;
    }
    if ( this._ord.filterOptsPen.manufacturer ) {
      filt_manufacturer = `manufacturer=${ this._ord.filterOptsPen.manufacturer }&`;
    }

    let filter = `${filt_country}${filt_provider}${filt_sku_full}${filt_sku}${filt_name}${filt_business_unit}${filt_order_number}${filt_priority}${filt_manufacturer}`;
    return filter.replace(/undefined/g,"") ;
  }
  checkFilter() {
    this._gen.tableLoader = true;
    this.clearSelectedPendingsTable()
    this.filtsView = false;
    this.getFiltersOpts();

    this.getAllProdsCountFilt( this.checkFilterVal() ).then( () => {
      this._ord.getAllPendingProdsFilter( this.checkFilterVal() , this.currPageView, this.currPage).then( (data:any) => {
        this.resetTable( data.data ).then( (data:any) => {
          // console.log( data );
          this._gen.tableLoader = false;
        });
      })
    });
  }

  /* * * */
  newOrder() {
    this.btnAble = !this.btnAble;
    let provListOrder:any[] = [];
    let provListOrder_regex:any[] = [];

    let currCreate:any[] = [];
    let currCreateList:string = "";

    /* providers */
    for (let p = 0; p < this._ord.filt_provider.length; p++) {
      const element = this._ord.filt_provider[p];      

      provListOrder.push( {
        code: element.code,
        country: element.country,
        name: element.name,
        stock: element.stock,
        provider_id: element.provider_id,
        order_number: element.order_number,
        business_unit: null,
        business_type: "B2C",
        lente_prueba: false,
        prods: []
      } )
      provListOrder.push( {
        code: element.code,
        country: element.country,
        name: element.name,
        stock: element.stock,
        provider_id: element.provider_id,
        order_number: element.order_number,
        business_unit: null,
        business_type: "B2B",
        lente_prueba: false,
        prods: []
      } )
      provListOrder.push( {
        code: element.code,
        country: element.country,
        name: element.name,
        stock: element.stock,
        provider_id: element.provider_id,
        order_number: element.order_number,
        business_unit: null,
        business_type: "B2C",
        lente_prueba: true,
        prods: []
      } )
      provListOrder.push( {
        code: element.code,
        country: element.country,
        name: element.name,
        stock: element.stock,
        provider_id: element.provider_id,
        order_number: element.order_number,
        business_unit: null,
        business_type: "B2B",
        lente_prueba: true,
        prods: []
      } )
    }    

    /* product selected */    
    for (let f = 0; f < this._ord.sel_pending.length; f++) {
      const sel = this._ord.sel_pending[f];            
            
      for (let j = 0; j < provListOrder.length; j++) {
        const element = provListOrder[j];        

        let prodInfo:any = {
          item_id: sel.item_id,
          sku: sel.sku,
          sku_full: sel.sku_full,
          quantity: sel.quantity,
          order_number: sel.order_number,
          business_type: sel.business_type,
          business_unit: null,
          isPrueba: false
        }   

        if ( element.name == sel.currproviders.name ) {

          // element.business_type = sel.business_type;

          if ( prodInfo.sku.slice(-3) == "-LP" || prodInfo.sku.slice(-4) == "-LPZ" ) {
            
            if ( prodInfo.business_type == provListOrder[j].business_type && provListOrder[j].lente_prueba ) {
              element.isPrueba = true;
              element.business_unit = sel.business_unit;
              provListOrder[j].prods.push( prodInfo )
            }

          } else {
            
            if ( prodInfo.business_type == provListOrder[j].business_type && !provListOrder[j].lente_prueba ) {
              element.business_unit = sel.business_unit;
              provListOrder[j].prods.push( prodInfo )
            }

          }
          
          
        }
        
      }

    }
    
    /* order regEx */

    for (let d = 0; d < provListOrder.length; d++) {
      const element = provListOrder[d];  
      
      if (element.prods.length >= 1 ) {        
        provListOrder_regex.push( {
          provider_id: element.provider_id,
          business_unit: element.business_unit,
          business_type: element.business_type,
          reference: this.getOrderId( element.code, element.business_unit, element.business_type, element.isPrueba ) ,
          reason_id: this.getReason( element.stock ),
          products: this.prodRegexTransform(element.prods)
        } )

      }
    }

    // console.log( provListOrder_regex );

    if ( provListOrder_regex.length >= 1 ) {
      let orders:any[] = [];      

      for (let r = 0; r < provListOrder_regex.length; r++) {
        const element = provListOrder_regex[r];
        orders.push( element )
      }

      console.log( orders );
      

      for (let m = 0; m < orders.length; m++) {
        const element = orders[m];
        currCreateList += `<strong> # ${ element.reference } </strong> </br>`;
      }      
      
      this._ord.createOrder( orders ).then( ({data}) => {
        
        Swal.fire({
          title: '<strong>Creando ordenes</strong>',
          icon: 'info',
          timer: 1500,
          html: `<img src="assets/img/preload.gif" alt="">`
        }).then( () => {

        Swal.fire({
          title: '<strong>Ordenes creadas con exito!</strong>',
          icon: 'success',
          html: `Ordenes creadas: </br> ${ currCreateList }`
          }).then( () => {
            this.getAllProds();
            this.getFiltersOpts();

            orders  = [];
            provListOrder = [];
            provListOrder_regex = [];

            this.btnAble = !this.btnAble;

          })

        })
             
      }).catch( (error:any) => {

        let data = error.response.data;
        let mssg = "";

        // console.log( data.error );

        if ( data.error.products ) {
          mssg = `sku_full: <br>${data.error.products[0].sku_full[0]}`
        } else {
          mssg = `${data.error}`
        }

        Swal.fire(
          'Error al crear orden de compra',
          `${mssg}`,
          'error'
        ).then( () => {
          this.btnAble = !this.btnAble;
        })

      } )

    } else {
      Swal.fire(
        'Error al crear orden de compra',
        `la orden no cuenta con proveedores validos para crearse`,
        'error'
      )
    }

  }

  getOrderId( code:string, business_unit:string, business_type:string, isPrueba:string ) {

    // console.log( code, business_unit, business_type );
    
    let date = new Date( Date.now() );
    let regEx;
    // let dateReg = `${ date.getFullYear() }${ ("0" + (date.getMonth() + 1)).slice(-2) }${ ("0" + date.getDate()).slice(-2) }${ ("0" + date.getHours()).slice(-2) }${ ("0" + date.getMinutes()).slice(-2) }`
    let dateReg = `${ date.getFullYear().toString().slice(-2) }${ ("0" + (date.getMonth() + 1)).slice(-2) }${ ("0" + date.getDate()).slice(-2) }${ ("0" + date.getHours()).slice(-2) }${ ("0" + date.getMinutes()).slice(-2) }`
    
    if ( isPrueba ) {
      
      if ( business_type == "B2C" ) {
        regEx = `${code}${dateReg}P`;
      } else if ( business_type == "B2B" ) {
        if ( business_unit == "ZC" ) {
          regEx = `${code}${dateReg}ZCP`;
        }
        if ( business_unit == "ZM" ) {
          regEx = `${code}${dateReg}ZMP`;
        }
        if ( business_unit == "GL" ) {
          regEx = `${code}${dateReg}GLP`;
        }
        if ( business_unit == "GA" ) {
          regEx = `${code}${dateReg}GAP`;
        }
      }
      
    } else {
      
      if ( business_type == "B2C" ) {
        regEx = `${code}${dateReg}`;
      } else if ( business_type == "B2B" ) {
        if ( business_unit == "ZC" ) {
          regEx = `${code}${dateReg}ZC`;
        }
        if ( business_unit == "ZM" ) {
          regEx = `${code}${dateReg}ZM`;
        }
        if ( business_unit == "GL" ) {
          regEx = `${code}${dateReg}GL`;
        }
        if ( business_unit == "GA" ) {
          regEx = `${code}${dateReg}GA`;
        }
      }
    }

    return regEx;
  }

  prodRegexTransform( prods:any ) {
    let tempProds:any[] = [];
    for (let g = 0; g < prods.length; g++) {
      const element = prods[g];
      
      tempProds.push({
        item_id: element.item_id,
        quantity: element.quantity,
        sku_full: element.sku_full
      })
    }

    return tempProds;
  }

  getReason( stock:any ) {
    if (stock) {
      return 2;
    } else {
      return 1;
    }
  }

  clearSelectedPendingsTable() {
    this._table.selectedLine = [];
    this._ord.sel_pending = [];
  }

  onReloadPage() {
    this.getAllProds()
  }

}
