import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner',
  templateUrl: './inner.component.html',
  styles: [
  ]
})
export class InnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
