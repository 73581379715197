import { Injectable } from "@angular/core";
import axios from "axios";

import { GeneralService } from "./general.service";

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  editView: boolean = false;
  editId: string = "";

  sel_invInputs: any[] = [];
  sel_invOutputs: any[] = [];
  sel_invConfigs: any[] = [];

  filt_orders: any[] = [];
  filt_prodType: any[] = [];
  filt_reason: any[] = [];
  filt_manufac: any[] = [];
  filt_brand: any[] = [];
  filt_bodega:any[] = [];

  detailView: boolean = false;
  detailId: any;

  count_listTypes: any = [
    {
      value: "Mensual",
      name: "Mensual",
    },
    {
      value: "Ciclico",
      name: "Cíclico",
    },
  ];

  filterOpts_input: any = {
    country: [],
    order: [],
    status: ["created", "partial", "news"],

    entry_from: "",
    entry_to: "",
    product_type: [],
    reason: [],

    lote: "",
    exp_from: "",
    exp_to: "",
    sku: "",
    sku_full: "",
    name: "",
    manufacturer: [],
    order_id: "",
    invoice: "",
    ean: "",
    bodega: "",
  };
  filterOpts_exit: any = {
    country: [],
    order: [],
    status: [],

    entry_from: "",
    entry_to: "",
    product_type: [],
    reason: [],

    lote: "",
    exp_from: "",
    exp_to: "",
    sku: "",
    sku_full: "",
    name: "",
    manufacturer: [],
    order_id: "",
    invoice: "",
    ean: "",
    bodega: "",
  };

  filterOpts_config: any = {
    country: [],
    name: "",
    type: [],
    brand: [],
    manufacturer: [],
    sku_full: "",
    sku: "",
    stock_status: "",
  };

  filterOpts_invoices: any = {
    country: [],
    reference: "",
    invoice_number: "",
    reason: "",
    provider_stock: "",
    provider: "",
    order_reference: "",
    files: ""
  };

  filterOpts_counts: any = {
    country: [],
    count_id: "",
    name: "",
    type: [],
    bodega: [],
    user_id: "",
    created_at_from: "",
    created_at_to: ""
  };

  filterOpts_scannedProducts: any = {
    country: [],
    product_type: [],
    sku_full: "",
    sku: "",
    manufacturer: [],
    diff: ""
  };

  ocListReason: any = [];
  ocListBodegas: any = []
  ocListProviders: any = [];

  listInternalOrders: any = [];

  createCountView:boolean = false;

  constructor(public _gen: GeneralService) {}

  getAllOrders() {
    return axios.get(`${this._gen.serverURL}/providers/order/`, {
      headers: { Authorization: `${this._gen.serverTkn}` },
    });
  }

  getAllEntryCountFilt(filters: any) {
    return axios.get(
      `${this._gen.serverURL}/stock/entry/?${filters}&count=1&type=entry`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }
  getAllentryFilter(filters: any, limit: any = 100, page: number = 1) {
    return axios.get(
      `${this._gen.serverURL}/stock/entry/?${filters}limit=${limit}&page=${page}&products=1&type=entry`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getAllExitCountFilt(filters: any) {
    return axios.get(
      `${this._gen.serverURL}/stock/exit/?${filters}&count=1&type=exit`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }
  getAllExitFilter(filters: any, limit: any = 100, page: number = 1) {
    return axios.get(
      `${this._gen.serverURL}/stock/exit/?${filters}limit=${limit}&page=${page}&products=1&type=exit`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }
  getManufact() {
    return axios.get(`${this._gen.serverURL}/catalog/manufacturer/`, {
      headers: { Authorization: `${this._gen.serverTkn}` },
    });
  }

  // Inventory-entry services
  createReception(data: any) {
    return axios.post(`${this._gen.serverURL}/stock/reception/`, data, {
      headers: {
        Authorization: `${this._gen.serverTkn}`,
      },
    });
  }
  updateReception(data: any) {
    return axios.put(`${this._gen.serverURL}/stock/reception/`, data, {
      headers: {
        Authorization: `${this._gen.serverTkn}`,
      },
    });
  }

  getReceptionByReference(reference: any) {
    return axios.get(
      `${this._gen.serverURL}/stock/reception/?reference=${reference}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getReceptionByInvoiceNumber(invoice_number: any, countryId: number) {
    return axios.get(
      `${this._gen.serverURL}/stock/reception/?invoice_number=${invoice_number}&country=${countryId}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getReceptionByInvoiceNumberAndOrderId(invoice_number: any, countryId: number, order_id?:any) {
    let urlParamsCompleted;
    if(order_id) {
      urlParamsCompleted = `invoice_number=${invoice_number}&order=${order_id}&country=${countryId}`;
    } else {
      urlParamsCompleted = `invoice_number=${invoice_number}&country=${countryId}`;
    }
    return axios.get(
      `${this._gen.serverURL}/stock/reception/?${urlParamsCompleted}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getAllReceptionData(countryId: number) {
    return axios.get(
      `${this._gen.serverURL}/stock/reception/?country=${countryId}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  subtractItemAmount(data: any) {
    return axios.post(`${this._gen.serverURL}/stock/remove/`, data, {
      headers: {
        Authorization: `${this._gen.serverTkn}`,
      },
    });
  }

  // Configuration services
  getAllProducts(filters: any, limit: any = 100, page: number = 1) {
    return axios.get(
      `${this._gen.serverURL}/stock/configuration/?${filters}limit=${limit}&page=${page}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getCountAllProducts(filters: any) {
    return axios.get(
      `${this._gen.serverURL}/stock/configuration/?${filters}count=1`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  // Invoices Services
  /* COUNT */
  getAllInvoicesCountFilt(countryId: number, filters: any) {
    return axios.get(
      `${this._gen.serverURL}/stock/reception/?country=${countryId}&${filters}count=1`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getAllInvoicesFilter(countryId: number, filters: any, limit: any = 100, page: number = 1) {
    return axios.get(
      `${this._gen.serverURL}/stock/reception/?country=${countryId}&${filters}limit=${limit}&page=${page}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  uploadFiles(data:FormData){
    return axios.post(`${this._gen.serverURL}/stock/reception_file/`, data, {
      headers: {
        Authorization: `${this._gen.serverTkn}`,
        'Content-type': 'multipart/form-data'
      },
    });
  }

  getAllCountsFilter(filters: any, limit: any = 100, page: number = 1) {
    return axios.get(
      `${this._gen.serverURL}/stock/cycle_count/?${filters}limit=${limit}&page=${page}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getAllCountsCountFilt(filters: any) {
    return axios.get(
      `${this._gen.serverURL}/stock/cycle_count/?${filters}count=1`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getCountById(id: any, filters:any = '', limit: any = 15, page: number = 1) {
    return axios.get(
      `${this._gen.serverURL}/stock/product_count/?${filters}cycle_count=${id}&limit=${limit}&page=${page}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getAllCountsCountById(id: any, filters: any) {
    return axios.get(
      `${this._gen.serverURL}/stock/product_count/?${filters}cycle_count=${id}&count=1`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

  getAllBodega( countryId:number ) {
    return axios.get(`${this._gen.serverURL}/providers/provider/?country=${countryId}&stock=1`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  createCount(data: any) {
    return axios.post(`${this._gen.serverURL}/stock/cycle_count/`, data, {
      headers: {
        Authorization: `${this._gen.serverTkn}`,
      },
    });
  }

  genCountScanNoId( data:any ) {
    return axios.post(`${this._gen.serverURL}/stock/scan/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  genProductEntryInCount( cycle_count_id:any, lote_id:any, quantity:any, bodega:any ) {   
    return axios.post(`${this._gen.serverURL}/stock/count_scan/`, {
      "cycle_count_id": cycle_count_id,
      "lote_id": lote_id,
      "quantity": quantity,
      "provider_id": bodega
    }, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  removeQuantityProductFromCount(data:any) {
    return axios.post(`${this._gen.serverURL}/stock/remove_count/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  closeCounting(data:any) {
    return axios.put(`${this._gen.serverURL}/stock/cycle_count/`, data, {
      headers: {'Authorization': `${this._gen.serverTkn}`}
    })
  }

  getElectronicInvoicesByProviderId( id:any, order_id:any ) {        
    return axios.get(`${this._gen.serverURL}/invoicing/invoice_provider/?provider=${id}&order=${order_id}`, {
      headers: {'Authorization': `${this._gen.serverTkn}`},
    })
  }

  getReceptionWithInvoiceNumber(invoice_number: any, countryId: number, reference: any) {
    let urlParamsCompleted = `invoice_number=${invoice_number}&reference=${reference}&country=${countryId}`;
    return axios.get(
      `${this._gen.serverURL}/stock/reception/?${urlParamsCompleted}`,
      {
        headers: { Authorization: `${this._gen.serverTkn}` },
      }
    );
  }

}
