<div class="topttls">
  <div class="topttlsContainer">
    <app-tittle [label]="'Conteo: ' + countName" [bottomTxt]="'Bodega: '+ bodegaCountName"></app-tittle>
    
  </div>

  <div class="btns">
    &nbsp;

    <div class="totalProds">
      <div>
        <span>Total registros:</span> <b>{{ dataSourceCount }}</b>
      </div>
    </div>
  </div>
</div>

<div class="topTableWrap">
  <div class="left">
  </div>
  <div class="right">
    <button class="btn" (click)="backToCounts()">Volver</button>

    <div class="filterView">
      <button class="btn editBtn" (click)="resetTobTable('filtsView')">
        <i [class]="'48px_filter' | icons"></i>
        <span>Filtrar</span>
      </button>
      <div
        class="filterItems animate__animated animate__fadeInRight"
        *ngIf="filtsView"
      >
        <div class="closeFilt" (click)="filtsView = false">
          <button>
            <i [class]="'48px_e-remove' | icons"></i>
          </button>
        </div>
        <div class="filtTtl">
          <h3>Filtros</h3>
        </div>
        <div class="filtActive customScrollBar">
          <div
            class="filtActItm"
            *ngIf="_inv.filterOpts_scannedProducts.country.length >= 1"
          >
            <span
              ><b>País: </b>
              <i
                *ngFor="
                  let country of _inv.filterOpts_scannedProducts.country;
                  index as i
                "
              >
                <i *ngIf="i >= 1"> - 

                </i>{{ country | idCountry }}
                </i>
            </span>
          </div>
          <div
            class="filtActItm"
            *ngIf="_inv.filterOpts_scannedProducts.sku"
            (click)="resetFilters_item('sku')"
          >
            <span><b>SKU: </b>{{ _inv.filterOpts_scannedProducts.sku }}</span>
            <i [class]="'48px_e-remove' | icons"></i>
          </div>
          <div
            class="filtActItm"
            *ngIf="_inv.filterOpts_scannedProducts.sku_full"
            (click)="resetFilters_item('sku_full')"
          >
            <span><b>SKU FULL: </b>{{ _inv.filterOpts_scannedProducts.sku_full }}</span>
            <i [class]="'48px_e-remove' | icons"></i>
          </div>
          <div
            class="filtActItm"
            *ngIf="_inv.filterOpts_scannedProducts.product_type.length >= 1"
            (click)="resetFilters_item('type')"
          >
            <span
              ><b>Tipo de producto: </b>
              <i *ngFor="let type of _inv.filterOpts_scannedProducts.product_type; index as i">
                <i *ngIf="i >= 1"> - </i> {{ type | idProdtype }}</i
              >
            </span>
            <i [class]="'48px_e-remove' | icons"></i>
          </div>
          <div
            class="filtActItm"
            *ngIf="_inv.filterOpts_scannedProducts.diff"
            (click)="resetFilters_item('diff')"
          >
            <span
              ><b>Diferencia (escaneados/esperados): </b>
              <i>
                {{
                  _inv.filterOpts_scannedProducts.diff === "True"
                    ? "Con diferencia"
                    : "Sin diferencia"
                }}</i
              >
            </span>
            <i [class]="'48px_e-remove' | icons"></i>
          </div>
        </div>
        <div class="filtList customScrollBar">
          <div class="filItm filItm_hide">
            <!-- <div class="labl">Marca</div> -->
            <div class="form_field">
              <mat-form-field appearance="outline">
                <mat-label>País</mat-label>
                <mat-select
                  [(ngModel)]="_inv.filterOpts_scannedProducts.country"
                  multiple
                  (ngModelChange)="getFiltersOpts()"
                >
                  <mat-option
                    *ngFor="let opt of _gen.allCountries"
                    value="{{ opt.country_id }}"
                    >{{ opt.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="filItm">
            <!-- <div class="labl">Name</div> -->
            <div class="form_field">
              <mat-form-field appearance="outline">
                <mat-label>SKU</mat-label>
                <input
                  matInput
                  placeholder="Placeholder"
                  [(ngModel)]="_inv.filterOpts_scannedProducts.sku"
                  name="sku"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="filItm">
            <!-- <div class="labl">Name</div> -->
            <div class="form_field">
              <mat-form-field appearance="outline">
                <mat-label>SKU FULL</mat-label>
                <input
                  matInput
                  placeholder="Placeholder"
                  [(ngModel)]="_inv.filterOpts_scannedProducts.sku_full"
                  name="sku_full"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="filItm">
            <div class="form_field">
              <mat-form-field appearance="outline">
                <mat-label>Tipo de producto</mat-label>
                <mat-select
                  [(ngModel)]="_inv.filterOpts_scannedProducts.product_type"
                  multiple
                  (ngModelChange)="getFiltersOpts()"
                >
                  <mat-option
                    *ngFor="let opt of _gen.allProdTypes"
                    value="{{ opt.type_id }}"
                    >{{ opt.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="filItm">
            <div class="form_field">
              <mat-form-field appearance="outline">
                <mat-label>Diferencia (escaneados/esperados)</mat-label>
                <mat-select
                  [(ngModel)]="_inv.filterOpts_scannedProducts.diff"
                  (keydown.enter)="checkFilter()"
                >
                  <mat-option value="True">Con diferencia</mat-option>
                  <mat-option value="False">Sin diferencia</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="filtSend">
          <button class="btn" (click)="resetFilters_item('')">
            Borrar filtros
          </button>
          <button class="btn btn_success btn_fill" (click)="checkFilter()">
            Aplicar filtros
          </button>
        </div>
      </div>
    </div>

    <div class="colsView">
      <button class="btn editBtn" (click)="resetTobTable('colsView')">
        <i [class]="'48px_separate-2' | icons"></i>
        <span>Columnas</span>
      </button>
      <div
        class="columnsItems animate__animated animate__fadeInUp"
        *ngIf="colsView"
        appClickOutside
        (clickOutside)="colsView = false"
      >
        <div class="colItem" *ngFor="let col of columnsConfig; index as i">
          <div class="ios_checkBox">
            <input
              type="checkbox"
              id="checkbox-{{ i }}"
              [checked]="col.visible"
              (change)="col.visible = !col.visible"
            />
            <label for="checkbox-{{ i }}">{{ col.label }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-table
  [cols]="columnsConfig"
  [data]="dataSource"
  [type]="tableType"
  (reloadPage)="onReloadPage()"
></app-table>

<div class="bottomTable">
  <div class="pageInp">
    <div class="form_field">
      <input
        type="text"
        name=""
        id=""
        value="{{ currPage }}"
        (change)="changePageInp($event, true)"
      />
    </div>
    <span>de {{ currPageTotal }}</span>
  </div>

  <div class="pagerWrap">
    <div class="pagerItms">
      <button
        class="btn btn_sm prev"
        [disabled]="currPage == 1"
        (click)="changePageInp(currPage - 1)"
      >
        <i [class]="'48px_left-arrow' | icons"></i>
      </button>
      <button
        class="btn btn_sm page"
        (click)="changePageInp(currPage - 2)"
        [disabled]="currPage <= 2"
      >
        {{ currPage - 2 }}
      </button>
      <button
        class="btn btn_sm page"
        (click)="changePageInp(currPage - 1)"
        [disabled]="currPage == 1"
      >
        {{ currPage - 1 }}
      </button>
      <button class="btn btn_sm page active">{{ currPage }}</button>
      <button
        class="btn btn_sm page"
        (click)="changePageInp(currPage + 1)"
        [disabled]="currPage == currPageTotal"
      >
        {{ currPage + 1 }}
      </button>
      <button
        class="btn btn_sm page"
        (click)="changePageInp(currPage + 2)"
        [disabled]="currPage >= currPageTotal - 1"
      >
        {{ currPage + 2 }}
      </button>
      <button class="btn btn_sm page" disabled>...</button>
      <button
        class="btn btn_sm next"
        (click)="changePageInp(currPageTotal)"
        [disabled]="currPage == currPageTotal"
      >
        {{ currPageTotal }}
      </button>
      <button
        class="btn btn_sm page last"
        [disabled]="currPage == currPageTotal"
        (click)="changePageInp(currPage + 1)"
      >
        <i [class]="'48px_right-arrow' | icons"></i>
      </button>
    </div>
  </div>

  <div class="perPage">
    <div class="form_field">
      <div class="select_box">
        <select
          name=""
          id=""
          [(ngModel)]="currPageView"
          (change)="getAllScannedProducts()"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </select>
        <i [class]="'48px_down-arrow' | icons"></i>
      </div>
    </div>
    <span>por página</span>
  </div>
</div>