import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styles: [
  ]
})
export class CatalogComponent implements OnInit {

  constructor(  ) {
  }
  
  ngOnInit(): void {
    
  }

}
