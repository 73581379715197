<div class="sideBarWrap">
  <div class="topLogo">
    <img src="assets/img/logoLp.png" alt="lentesplus.com" />
  </div>
  <div class="userWrap">
    <div class="avatar">
      <img src="assets/img/avatar.png" alt="" />
    </div>
    <div class="name">
      <p>{{ _gen.currUser }}</p>
    </div>
    <div class="countrySel">
      <div class="customSelect_Country">
        <div class="select" (click)="countryView = !countryView">
          {{ currCountrySelInfo.name }}
        </div>
        <div class="selOptions" *ngIf="countryView">
          <div
            class="item"
            *ngFor="let item of countryList"
            [ngClass]="{ active: item.country_id == _gen.currCountry }"
            (click)="selCountry(item.country_id)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="logOut">
      <a href="javascript:;" (click)="logOut()">cerrar sesión</a>
    </div>
  </div>
  <div class="uploadBtn">
    <a
      class="btn btn_success btn_fill"
      [routerLink]="['/home/upload']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      >Carga masiva</a
    >
  </div>

  <div class="menuWrapper customScrollBar transScrollBar">
    <ul>
      <li>
        <a
          [routerLink]="['/home']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <b>
            <i [class]="'48px_box-3d-50' | icons"></i>
            <span>Inicio</span>
          </b>
        </a>
      </li>
      <li *appRole="['stock_view_entry', 'stock_view_cyclecount', 'stock_view_configuration', 'stock_view_exit']">
        <a (click)="accorClick('inv')">
          <b>
            <i [class]="'48px_box-2' | icons"></i>
            <span>Inventario</span>
          </b>
          <i [class]="'48px_right-arrow' | icons" *ngIf="!inv_menu"></i>
          <i [class]="'48px_down-arrow' | icons" *ngIf="inv_menu"></i>
        </a>

        <ul *ngIf="inv_menu" class="animate__animated animate__fadeInLeft">
          <li *appRole="['stock_view_entry']">
            <a
              [routerLink]="['/home/inventory/inputs']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_box-arrow-right' | icons"></i>
                <span>Entradas</span>
              </b>
            </a>
          </li>
          <li *appRole="['stock_view_exit']">
            <a
              [routerLink]="['/home/inventory/outputs']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_box-arrow-left' | icons"></i>
                <span>Salidas</span>
              </b>
            </a>
          </li>
          <li *appRole="['stock_view_cyclecount']">
            <a
              [routerLink]="['/home/inventory/counts']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_white-balance' | icons"></i>
                <span>Conteo</span>
              </b>
            </a>
          </li>
          <li *appRole="['stock_view_configuration']">
            <a
              [routerLink]="['/home/inventory/configuration']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_system-configuration' | icons"></i>
                <span>Configuración</span>
              </b>
            </a>
          </li>
        </ul>
      </li>
      <li *appRole="['catalog_view_product', 'catalog_view_productsku']">
        <a (click)="accorClick('cat')">
          <b>
            <i [class]="'48px_box-3d-50' | icons"></i>
            <span>Catálogo</span>
          </b>
          <i [class]="'48px_right-arrow' | icons" *ngIf="!cat_menu"></i>
          <i [class]="'48px_down-arrow' | icons" *ngIf="cat_menu"></i>
        </a>

        <ul *ngIf="cat_menu" class="animate__animated animate__fadeInLeft">
          <li *appRole="['catalog_view_product', 'catalog_view_productsku']">
            <a
              [routerLink]="['/home/catalog/list']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_box-arrow-right' | icons"></i>
                <span>Lista</span>
              </b>
            </a>
          </li>
          <li *appRole="['catalog_view_product', 'catalog_view_productsku']">
            <a
              [routerLink]="['/home/catalog/stock']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_box-arrow-left' | icons"></i>
                <span>Stock</span>
              </b>
            </a>
          </li>
        </ul>
      </li>
      <li *appRole="['magento_view_sale']">
        <a
          [routerLink]="['/home/pedidos']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <b>
            <i [class]="'48px_box-3d-50' | icons"></i>
            <span>Pedidos</span>
          </b>
        </a>
      </li>
      <li *appRole="['providers_view_order', 'providers_view_orderproduct', 'stock_view_reception', 'stock_view_receptionfile']">
        <a (click)="scrollToLast();accorClick('ord')">
          <b>
            <i [class]="'48px_box-2' | icons"></i>
            <span>Ordenes de compra</span>
          </b>
          <i [class]="'48px_right-arrow' | icons" *ngIf="!ord_menu"></i>
          <i [class]="'48px_down-arrow' | icons" *ngIf="ord_menu"></i>
        </a>

        <ul *ngIf="ord_menu" class="animate__animated animate__fadeInLeft">
          <li id="orderMiddleList" *appRole="['providers_view_order']">
            <a
              [routerLink]="['/home/ordenes']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_box-arrow-right' | icons"></i>
                <span>Lista</span>
              </b>
            </a>
          </li>
          <li *appRole="['providers_view_orderproduct']">
            <a
              [routerLink]="['/home/ordenes/pending_prods']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_box-arrow-left' | icons"></i>
                <span>Productos pendientes</span>
              </b>
            </a>
          </li>
          <li *appRole="['stock_view_reception']">
            <a
              [routerLink]="['/home/ordenes/inventory_entry']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="cleanInventoryForm()"
            >
              <b>
                <i [class]="'48px_box-arrow-right' | icons"></i>
                <span>Entrada de Inventario</span>
              </b>
            </a>
          </li>
          <li *appRole="['stock_view_receptionfile']">
            <a
              [routerLink]="['/home/ordenes/invoices']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_receipt-list-42' | icons"></i>
                <span>Facturas</span>
              </b>
            </a>
          </li>
        </ul>
      </li>
      <li *appRole="['invoicing_view_invoices', 'invoicing_view_provider', 'invoicing_view_invoicesco', 'invoicing_view_invoicesar', 'invoicing_view_invoicescl', 'invoicing_view_invoicesmx']">
        <a (click)="scrollToLast(); accorClick('fac')">
          <b>
            <i [class]="'48px_round-dollar' | icons"></i>
            <span>Facturación</span>
          </b>
          <i [class]="'48px_right-arrow' | icons" *ngIf="!fac_menu"></i>
          <i [class]="'48px_down-arrow' | icons" *ngIf="fac_menu"></i>
        </a>

        <ul *ngIf="fac_menu" class="animate__animated animate__fadeInLeft">
          <li *appRole="['invoicing_view_invoices', 'invoicing_view_invoicesco', 'invoicing_view_invoicesar', 'invoicing_view_invoicescl', 'invoicing_view_invoicesmx']">
            <a
              [routerLink]="['/home/billing/electronic_invoices']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_receipt-list-42' | icons"></i>
                <span>Facturas Electrónicas</span>
              </b>
            </a>
          </li>
          <li *appRole="['invoicing_view_provider']">
            <a
              [routerLink]="['/home/billing/providers']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <b>
                <i [class]="'48px_box-3d-67' | icons"></i>
                <span>Proveedores</span>
              </b>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
