import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'idReason'
})
export class IdReasonPipe implements PipeTransform {

  private val:string = "";

  constructor( public _inv:InventoryService ) {
  }

  transform(value: any, ...args: unknown[]): any {
    if ( this._inv.filt_reason ) {
      
      for (let f = 0; f < this._inv.filt_reason.length; f++) {
        const element = this._inv.filt_reason[f];      
        
        if ( element.reason_id.toString() == value.toString() ) {
          this.val = element.name
        }
      }
    } 

    if ( this._inv.ocListReason ) {
      
      for (let f = 0; f < this._inv.ocListReason.length; f++) {
        const element = this._inv.ocListReason[f];      
        
        if ( element.reason_id.toString() == value.toString() ) {
          this.val = element.name
        }
      }
    } 

    return this.val;
  }

}
